import { Injectable } from "@angular/core";
import { ProductType } from "@domain/product/product";
import { ProductService } from "@domain/product/product.service";
import { GasWarningCenterConfiguration } from "@domain/project/configurations/gas-warning-center-configuration";
import { AttachmentDataService } from "@domain/project/product-data/attachment-data.service";
import { ProductData } from "@domain/project/product-data/product-data";
import { Observable, forkJoin, map, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GasWarningCenterDataService {
  constructor(
    private productService: ProductService,
    private attachmentDataService: AttachmentDataService,
  ) {}

  getGasWarningCenterData(
    gasWarningCenters: readonly GasWarningCenterConfiguration[],
    localeId: string,
  ): Observable<ProductData[][]> {
    if (gasWarningCenters.length == 0) {
      return of([]);
    }
    return forkJoin(gasWarningCenters.map((gasWarningCenter) => this.getProducts(gasWarningCenter, localeId)));
  }

  private getProducts(gasWarningCenterConfig: GasWarningCenterConfiguration, localeId: string): Observable<ProductData[]> {
    const productPlacements = gasWarningCenterConfig.countPlacedProducts();
    const gasWarningCenter$ = this.productService.getProductById(gasWarningCenterConfig.productId).pipe(
      map((product) => {
        if (!product || product.type !== ProductType.GASWARNINGCENTER) {
          throw new Error(`Gas warning center with product id '${gasWarningCenterConfig.productId}' not found.`);
        }

        const productData = ProductData.create(product, productPlacements, localeId);
        productData.position = gasWarningCenterConfig.positionNumber!;
        return productData;
      }),
    );

    const attachments$ = this.attachmentDataService.getProductData(
      gasWarningCenterConfig,
      gasWarningCenterConfig.attachmentIds,
      localeId,
      productPlacements,
    );
    return forkJoin([gasWarningCenter$, attachments$]).pipe(map((results: any[]) => [results[0]].concat(results[1])));
  }
}
