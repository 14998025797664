<div odxLayout="flex" class="flex-column h-100">
  <odx-content-box>
    <div odxLayout="flex">
      <h6 class="odx-title-6 header-prefix" i18n="@@transmitterConfiguration.selectTransmitter.headerPrefix">Transmitter für</h6>
      <app-configuration-wizard-header class="product-header" [data]="selectedGas" [showCosts]="showCosts">
      </app-configuration-wizard-header>
    </div>
  </odx-content-box>

  <odx-content-box class="flex-column h-100 scroll" odxLayout="flex">
    <odx-content-box-header>
      <h5
        class="odx-title-5"
        i18n="@@transmitterConfiguration.selectTransmitter.header"
        data-testid="transmitterConfiguration.selectTransmitter.header"
      >
        Transmitter auswählen
      </h5>
    </odx-content-box-header>
    <div odxLayout="flex vertical-center">
      <app-filter-product (search)="search($event)"></app-filter-product>
      <form [formGroup]="detectingRangeForm">
        <odx-radio-group formControlName="detectingRange" [horizontal]="true" [required]="true"
          ><span i18n="@@transmitterConfiguration.selectTransmitter.filter.prefix">Messbereiche:</span>
          <odx-radio-button [value]="NOFILTER" i18n="@@transmitterConfiguration.selectTransmitter.filter.all"
            >Alle</odx-radio-button
          >
          <odx-radio-button [value]="PPM" i18n="@@transmitterConfiguration.selectTransmitter.filter.ppm">PPM</odx-radio-button>
          <odx-radio-button [value]="VOLUMEPERCENT" i18n="@@transmitterConfiguration.selectTransmitter.filter.volume"
            >Volume-%</odx-radio-button
          >
          <odx-radio-button [value]="UEGPERCENT" i18n="@@transmitterConfiguration.selectTransmitter.filter.ueg"
            >UEG-%</odx-radio-button
          >
        </odx-radio-group>
      </form>
    </div>

    <form [formGroup]="selectedTransmitterForm" class="overflow-hidden h-100">
      <odx-radio-group formControlName="selectedTransmitter" required="true" class="overflow-hidden h-100">
        <div odxLayout="grid 3@desktop 6@tablet 12@phone" class="w-100 scroll mb-36">
          <app-transmitter-card
            class="overflow-hidden"
            *ngFor="let product of filteredSelectableProducts"
            [transmitterData]="product"
            [showCosts]="showCosts"
            [isSelected]="selectedTransmitter?.id === product.id"
            (click)="select(product)"
            (transmitterSelected)="select(product)"
            >{{ product | localizeProductName }}</app-transmitter-card
          >
        </div>
      </odx-radio-group>
    </form>
  </odx-content-box>
</div>
