import { FloorplanImage } from "@domain/project/floorplan/floorplan-image";
import { TableColumn } from "@pdf/helpers/default-table/table-column";
import { Headlines, ImagePaths, TableProperties } from "@pdf/pdf-properties";
import { jsPDF } from "jspdf";
import { FloorplanInformationTable } from "../floorplan-information-table";
import { ImagesRow } from "./images-row";

export class ImagesRowHeaders {
  public readonly NUMBER: string = $localize`:@@pdfExport.imagesTable.header.number:Nr.`;
  public readonly NAME: string = $localize`:@@pdfExport.imagesTable.header.name:Name des Bildes`;
  public readonly NOTES: string = $localize`:@@pdfExport.imagesTable.header.notes:Notizen`;
}

export class ImagesTable extends FloorplanInformationTable {
  readonly headlines = new Headlines();
  readonly rowHeaders = new ImagesRowHeaders();
  override readonly headline = this.headlines.IMAGES;
  override readonly columns: TableColumn[];
  override readonly rows: ImagesRow[];

  constructor(pdf: jsPDF, floorplanImages: FloorplanImage[]) {
    super(ImagePaths.IMAGE);
    this.columns = [
      new TableColumn(this.rowHeaders.NUMBER, 60, 96),
      new TableColumn(this.rowHeaders.NAME, 180, 216),
      new TableColumn(this.rowHeaders.NOTES, 420, 1020),
    ];

    this.rows = floorplanImages.map(
      (floorplanImage) =>
        new ImagesRow(
          pdf,
          this.textToColumnWidth(
            pdf,
            TableProperties.TEXT_FONT_SIZE,
            this.preProcessText(floorplanImage.positionId),
            this.rowHeaders.NUMBER,
          ),
          this.textToColumnWidth(
            pdf,
            TableProperties.TEXT_FONT_SIZE,
            this.preProcessText(floorplanImage.projectImage.name),
            this.rowHeaders.NAME,
          ),
          this.textToColumnWidth(
            pdf,
            TableProperties.TEXT_FONT_SIZE,
            this.preProcessText(floorplanImage.notes),
            this.rowHeaders.NOTES,
          ),
        ),
    );
    this.height = this.calculateHeight(this.rows);
  }
}
