<odx-modal-header>
  <odx-area-header i18n="@@modal.generalConfirmation.header">Bitte bestätigen</odx-area-header>
</odx-modal-header>
<odx-modal-content>
  <p>{{ text }}</p>
</odx-modal-content>
<odx-modal-footer>
  <button odxButton variant="secondary" odxModalDismiss i18n="@@global.dismiss">Abbrechen</button>
  <button odxButton variant="primary" (click)="onConfirm()" i18n="@@modal.generalConfirmation.button.confirm">OK</button>
</odx-modal-footer>
