<odx-content-box *ngIf="project" class="flex-column h-100 scroll" odxLayout="flex">
  <odx-content-box-header>
    <h5 class="odx-title-5" i18n="@@plasticSignConfiguration.selectPlasticSign.header">Kunststoffschild auswählen</h5>
  </odx-content-box-header>
  <app-filter-product (search)="search($event)"></app-filter-product>
  <form [formGroup]="plasticSignForm" class="h-100 overflow-hidden" *ngIf="filteredPlasticSigns$ | async; let plasticSigns">
    <odx-radio-group formControlName="selectedPlasticSign" required="true" class="overflow-hidden h-100">
      <div odxLayout="grid 3@desktop 6@tablet 12@phone" class="w-100 scroll">
        <app-product-card
          class="overflow-hidden"
          *ngFor="let plasticSign of plasticSigns"
          [product]="plasticSign"
          [showCosts]="project.showCostFlag"
          [isSelected]="plasticSignForm.value.selectedPlasticSign?.id === plasticSign.id"
          (click)="updateSelectedPlasticSign(plasticSign)"
          (select$)="updateSelectedPlasticSign(plasticSign)"
          selectionMode="single"
        >
          <div *ngIf="plasticSign.id" class="feature">
            <p class="p-label odx-text--small" i18n="@@global.partNumber">Sachnummer</p>
            <p class="odx-text--small">{{ plasticSign.id }}</p>
          </div>
        </app-product-card>
      </div>
    </odx-radio-group>
  </form>
</odx-content-box>
