import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { ConfigurationWizardHeaderAccordionComponent } from "@components/configuration-wizard-header-accordion/configuration-wizard-header-accordion.component";
import { FilterProductComponent } from "@components/filter-product/filter-product.component";
import { ProductCardComponent } from "@components/product-card/product-card.component";
import { Gas } from "@domain/gas/gas";
import { LocalizeProductNamePipe, Product, ProductType, RelationshipType } from "@domain/product/product";
import { ProductService } from "@domain/product/product.service";
import { untilDestroyed } from "@odx/angular/utils";
import { Observable, map, of } from "rxjs";

interface SensorSelectorProducts {
  selectedGas: Gas;
  selectedTransmitter: Product;
  preselectedSensor?: Product;
}

@Component({
  selector: "app-sensor-selector",
  templateUrl: "./sensor-selector.component.html",
  styleUrls: ["./sensor-selector.component.scss"],
  standalone: true,
  imports: [
    UiKitModule,
    ReactiveFormsModule,
    ConfigurationWizardHeaderAccordionComponent,
    FilterProductComponent,
    ProductCardComponent,
    LocalizeProductNamePipe,
  ],
})
export class SensorSelectorComponent {
  @Input({ required: true }) set selectedProducts(products: SensorSelectorProducts) {
    this.selectedSensorForm.patchValue({ selectedSensor: undefined });
    this.gas = products.selectedGas;
    this.transmitter = products.selectedTransmitter;
    this.headerProducts = [this.gas, this.transmitter];
    const sensorIds: string[] = this.getSensorIds();
    this.needsSensor$.emit(sensorIds.length > 0);
    this.sensors$ = this.collectSensors(sensorIds);
    this.filteredSensors$ = this.sensors$;
    if (products.preselectedSensor && products.preselectedSensor.isAvailable) {
      this.select(products.preselectedSensor);
    }
  }

  @Input({ required: true }) showCosts!: boolean;

  @Output() sensorSelect$ = new EventEmitter<Product>();
  @Output() needsSensor$ = new EventEmitter<boolean>();

  protected gas!: Gas;
  protected transmitter!: Product;
  protected selectedSensor?: Product;
  protected sensors$!: Observable<Product[]>;
  protected filteredSensors$!: Observable<Product[]>;
  protected selectedSensorForm: FormGroup;
  protected headerProducts: (Product | Gas)[] = [];
  protected headerAccordionPrefix: string = $localize`:@@transmitterConfiguration.selectSensor.headerAccordionPrefix:Sensor für`;

  private readonly takeUntilDestroyed = untilDestroyed();

  constructor(
    private formBuilder: FormBuilder,
    private productService: ProductService,
  ) {
    this.selectedSensorForm = formBuilder.group({
      selectedSensor: [null, Validators.required],
    });
    this.selectedSensorForm.statusChanges.pipe(this.takeUntilDestroyed()).subscribe(() => {
      this.selectedSensor = this.selectedSensorForm.value.selectedSensor;
      return this.sensorSelect$.emit(this.selectedSensorForm.value.selectedSensor);
    });
  }

  protected search(value: string) {
    this.filteredSensors$ = this.productService.filter(this.sensors$, value);
  }

  protected select(sensor: Product) {
    this.selectedSensorForm.patchValue({ selectedSensor: sensor });
  }

  private getSensorIds() {
    return this.transmitter.relationships
      .filter((product) => product.relationshipType == RelationshipType.SENSOR)
      .filter((sensor) => this.gas.productSensors.find((product) => product.productId == sensor.productId))
      .map((sensor) => sensor.productId);
  }

  private collectSensors(sensorIds: string[]) {
    if (this.transmitter) {
      return this.productService
        .getProductsByIds(sensorIds, ProductType.SENSOR)
        .pipe(map((products) => products.filter((product) => product.isAvailable)));
    } else {
      return of([]);
    }
  }
}
