<button odxButton [odxMenu]="menu" odxMenuPosition="bottom-end" data-testid="btn.importProject">
  <span i18n="@@projectImport.header">Projekt importieren</span>
  <odx-icon name="chevron-down" alignRight></odx-icon>
</button>

<!-- added import input here because it doesn't work if it's located in odx-menu below -->
<input type="file" class="hidden" accept=".navinta" (change)="onFileSelected($event)" #fileInput />

<ng-template #menu>
  <odx-menu>
    <button odxButton odxMenuItem (click)="fileInput.click()" data-testid="btn.importProjectFromComputer">
      <odx-icon name="copy-file" alignLeft></odx-icon>
      <span i18n="@@projectImport.button.importFromComputer">Von meinem Computer importieren</span>
    </button>
    <button odxButton odxMenuItem (click)="importCloudProject()">
      <odx-icon name="cloud" alignLeft></odx-icon>
      <span i18n="@@projectImport.button.importFromCloud">Aus der Cloud importieren</span>
    </button>
  </odx-menu>
</ng-template>
