<div odxLayout="flex gap-small" class="flex-column h-100">
  <odx-area-header class="info-header">
    <h5 class="odx-title-5" i18n="@@checklist.header">Checkliste</h5>
  </odx-area-header>
  <form odxForm [formGroup]="checklistForm" class="form">
    <div odxLayout="grid">
      <span odxLayout="5@desktop 6@tablet" class="odx-text" i18n="@@checklist.operator.label">Wer ist Betreiber der Anlage?</span>
      <odx-form-field odxLayout="5@desktop 6@tablet">
        <input
          formControlName="operator"
          odxFormFieldControl
          i18n-placeholder="@@checklist.operator.placeholder"
          placeholder="Betreiber der Anlage"
        />
      </odx-form-field>
    </div>
    <div odxLayout="grid">
      <span odxLayout="5@desktop 6@tablet" class="odx-text" i18n="@@checklist.client.label">Wer ist Auftraggeber?</span>
      <odx-form-field odxLayout="5@desktop 6@tablet">
        <input
          formControlName="client"
          odxFormFieldControl
          i18n-placeholder="@@checklist.client.placeholder"
          placeholder="Auftraggeber"
        />
      </odx-form-field>
    </div>

    <app-checklist-item
      i18n-label="@@checklist.switch1.label"
      label="Sind alle Sensoren mit Gasart und Messbereich berücksichtigt?"
      groupName="sensorGasAndMeasurementRangeConsidered"
    >
    </app-checklist-item>
    <app-checklist-item
      i18n-label="@@checklist.switch2.label"
      label="Gibt es Störeinflüsse (Sensorgifte, etc.)?"
      groupName="peturbationsExist"
    ></app-checklist-item>
    <app-checklist-item
      i18n-label="@@checklist.switch3.label"
      label="Sind alle benötigten Relaisausgänge berücksichtigt?"
      groupName="relayOutputsConsidered"
    >
    </app-checklist-item>
    <app-checklist-item
      i18n-label="@@checklist.switch4.label"
      label="Ist die Betriebsspannung der Alarmmittel definiert?"
      groupName="operatingVoltageOfMeansOfAlarmDefined"
    >
    </app-checklist-item>
    <app-checklist-item
      i18n-label="@@checklist.switch5.label"
      label="Sind alle Sensoren / Gaswarnzentrale / Alarmmittel positioniert?"
      groupName="sensorsGasAlarmDeviceAndMeansOfAlarmSet"
    >
    </app-checklist-item>
    <app-checklist-item
      i18n-label="@@checklist.switch6.label"
      label="Anforderungen Installation / Montage"
      groupName="installationRequirementsDefined"
    >
    </app-checklist-item>
    <app-checklist-item
      i18n-label="@@checklist.switch7.label"
      label="Gibt es Werksnormen / besondere Auflagen?"
      groupName="specialStandardsExist"
    ></app-checklist-item>
    <app-checklist-item
      i18n-label="@@checklist.switch8.label"
      label="Werden Steiger / Gerüste benötigt / bereitgestellt?"
      groupName="scaffoldingRequired"
    >
    </app-checklist-item>
    <app-checklist-item
      i18n-label="@@checklist.switch9.label"
      label="Gibt es Ex-Zonen?"
      groupName="exZonesExist"
    ></app-checklist-item>
    <app-checklist-item
      i18n-label="@@checklist.switch10.label"
      label="Freimessung notwendig? Wenn ja, durch wen?"
      groupName="clearanceMeasurementRequired"
    >
    </app-checklist-item>
    <app-checklist-item
      i18n-label="@@checklist.switch11.label"
      label="Ist ein Erdungskonzept vorhanden?"
      groupName="electricalGroundingConceptExists"
    >
    </app-checklist-item>
    <app-checklist-item
      i18n-label="@@checklist.switch12.label"
      label="Ist eine Be-/Entlüftung vorhanden?"
      groupName="ventilationExists"
    ></app-checklist-item>

    <div odxLayout="grid">
      <span odxLayout="5@desktop 6@tablet" class="odx-text" i18n="@@checklist.personToInstruct.label"
        >Wer ist bei der IBN zugegen, bzw. wer soll eingewiesen werden?</span
      >
      <odx-form-field odxLayout="5@desktop 6@tablet">
        <input
          formControlName="personToInstruct"
          odxFormFieldControl
          i18n-placeholder="@@checklist.personToInstruct.placeholder"
          placeholder="Einzuweisende Person"
        />
      </odx-form-field>
    </div>
  </form>
</div>
