import { Component, inject, OnInit } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { Modal, ModalRef } from "@odx/angular/components/modal";

@Component({
  selector: "app-operate-on-config-modal",
  templateUrl: "./operate-on-config-modal.component.html",
  styleUrls: ["./operate-on-config-modal.component.scss"],
  standalone: true,
  imports: [UiKitModule],
})
export class OperateOnConfigModalComponent implements Modal<any, string>, OnInit {
  public readonly modalRef = inject(ModalRef<any, string>);

  protected mode!: "edit" | "delete";
  protected header: string = "";
  protected content: string = "";
  protected continueButtonText: string = "";

  ngOnInit(): void {
    this.mode = this.modalRef.data;
    this.header =
      this.mode === "edit"
        ? $localize`:@@modal.editConfig.header:Konfiguration ändern`
        : $localize`:@@modal.deleteConfig.header:Konfiguration löschen`;
    this.content =
      this.mode === "edit"
        ? $localize`:@@modal.editConfig.text:Sie werden auf allen Grundrissen dieses Projektes alle mit dieser Konfiguration platzierten Produkte gleichzeitig ändern.`
        : $localize`:@@modal.deleteConfig.text:Sie werden auf allen Grundrissen dieses Projektes alle mit dieser Konfiguration platzierten Produkte gleichzeitig löschen.`;
    this.continueButtonText =
      this.mode === "edit"
        ? $localize`:@@modal.editConfig.button.confirm:Fortfahren`
        : $localize`:@@modal.deleteConfig.button.confirm:Löschen`;
  }

  onConfirm() {
    this.modalRef.close("confirmed");
  }
}
