import { Injectable } from "@angular/core";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { FloorplanToPngService } from "@pdf/export-services/floorplan-page/floorplan-to-png.service";
import { DraegerFonts } from "@pdf/helpers/draeger-fonts";
import { ContainerProperties, FontNames, Headlines, PdfProperties } from "@pdf/pdf-properties";
import { jsPDF } from "jspdf";
import { Observable, map, tap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FloorplanPagePdfService {
  private headlines = new Headlines();
  private static readonly MAX_WIDTH = 3241;
  private static readonly MAX_HEIGHT = 1975;

  constructor(private floorplanToPngService: FloorplanToPngService) {}

  generate(pdf: jsPDF, activeFloorplan: Floorplan): Observable<void> {
    return new Observable<void>((observer) => {
      this.addFonts(pdf);
      pdf.setFont(FontNames.DRAEGER_PANGEA);
      pdf.setTextColor(PdfProperties.DRAEGERBLUE);

      this.addHeading(pdf, activeFloorplan);
      this.addFloorplan(pdf, activeFloorplan).subscribe(() => {
        this.addFloorplanBorder(pdf);
        observer.next();
        observer.complete();
      });
    });
  }

  private addFloorplan(pdf: jsPDF, floorplan: Floorplan): Observable<void> {
    return this.floorplanToPngService.getPng(floorplan).pipe(
      tap((pngUrl: string) => {
        const [fittedWidth, fittedHeight] = this.fitImageToFrame({
          width: floorplan.floorplanState.borderWidth,
          height: floorplan.floorplanState.borderHeight,
        });
        const xStart = this.calculateXStart(fittedWidth);
        pdf.addImage(pngUrl, "PNG", xStart, ContainerProperties.Y, fittedWidth, fittedHeight);
      }),
      map(() => undefined),
    );
  }

  private fitImageToFrame(borderDimensions: { width: number; height: number }) {
    const ratio: number = Math.min(
      FloorplanPagePdfService.MAX_WIDTH / borderDimensions.width,
      FloorplanPagePdfService.MAX_HEIGHT / borderDimensions.height,
    );
    return [borderDimensions.width * ratio, borderDimensions.height * ratio];
  }

  private calculateXStart(srcWidth: number) {
    return (FloorplanPagePdfService.MAX_WIDTH - srcWidth) / 2 + ContainerProperties.LEFT_X;
  }

  private addFloorplanBorder(pdf: jsPDF) {
    pdf.setDrawColor(PdfProperties.BORDER_COLOR);
    pdf.setLineWidth(3);
    pdf.rect(
      ContainerProperties.LEFT_X,
      ContainerProperties.Y,
      FloorplanPagePdfService.MAX_WIDTH,
      FloorplanPagePdfService.MAX_HEIGHT,
    );
  }

  private addHeading(pdf: jsPDF, activeFloorplan: Floorplan) {
    pdf.setFontSize(PdfProperties.HEADLINE_FONT_SIZE);
    pdf.text(this.headlines.FLOORPLAN + " | " + activeFloorplan.name, ContainerProperties.LEFT_X, PdfProperties.HEADLINE_Y, {
      baseline: "top",
    });
  }

  private addFonts(doc: jsPDF) {
    new DraegerFonts().fonts.forEach((font) => {
      doc.addFileToVFS(font.ttfName, font.base64);
      doc.addFont(font.ttfName, font.fontName, font.fontWeight);
    });
  }
}
