export class GasWarningCenterType {
  static readonly TYPES: GasWarningCenterType[] = [
    new GasWarningCenterType("Regard 2400", "Regard_2400", true),
    new GasWarningCenterType("Regard 3000", "Regard_3000", true),
    new GasWarningCenterType("Regard 3900", "Regard_3900", true),
    new GasWarningCenterType("VarioGard", "VarioGard", false),
  ];

  name: string;
  key: string;
  hasExcelsheet: boolean;

  constructor(name: string, key: string, hasExcelsheet: boolean) {
    this.name = name;
    this.key = key;
    this.hasExcelsheet = hasExcelsheet;
  }

  isMatchingProduct(productName: string): boolean {
    productName = productName.toLocaleLowerCase();
    const typeName = this.name.toLocaleLowerCase();
    if (this.key === "Regard_3000") {
      // one Regard 3900 product also includes the type name "Regard 3000". That must not match here.
      return productName.includes(typeName) && productName.includes("basiseinheit");
    }
    return productName.includes(typeName);
  }
}
