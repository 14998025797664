import { Component, EventEmitter, inject, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { UiKitModule } from "@app/ui-kit.module";
import { ConfirmDeleteProjectModalComponent } from "@components/confirm-delete-project-modal/confirm-delete-project-modal.component";
import { Project } from "@domain/project/project";
import { ProjectService } from "@domain/project/project.service";
import { ModalService } from "@odx/angular/components/modal";
import { PdfService } from "@pdf/pdf.service";
import { ToastHelper } from "@utils/toast-helper.service";
import { switchMap } from "rxjs";
import { LocalizeDatePipe } from "src/locale/localize-date";
import { LocalizeTimePipe } from "src/locale/localize-time";

@Component({
  selector: "app-project-card",
  templateUrl: "./project-card.component.html",
  styleUrls: ["./project-card.component.scss"],
  standalone: true,
  imports: [UiKitModule, LocalizeDatePipe, LocalizeTimePipe],
})
export class ProjectCardComponent {
  @Input() project!: Project;

  @Input() showMenu: boolean = true;

  @Input() linkToProject: boolean = true;

  @Output() deleteEmitter$ = new EventEmitter();

  private readonly modalService = inject(ModalService);

  constructor(
    private projectService: ProjectService,
    private toastHelper: ToastHelper,
    private router: Router,
    private pdfService: PdfService,
  ) {}

  exportProjectToFile(project: Project) {
    this.projectService.exportProjectToFile(project);
    this.toastHelper.success(
      $localize`:@@toast.projectExport.successful:Projekt '${project.name}:projectName:' wurde erfolgreich auf Ihren Computer exportiert`,
    );
  }

  exportProjectToPdf(project: Project) {
    this.pdfService.exportProjectToPdf(project).subscribe(({ pdf, fileName }) => pdf.save(fileName));
  }

  onClick() {
    this.router.navigate([this.linkToProject ? "/projects/" + this.project.id : null]);
  }

  onDelete() {
    this.modalService
      .open(ConfirmDeleteProjectModalComponent)
      .onClose$.pipe(
        switchMap((shouldDeleteFromCloud) => {
          if (shouldDeleteFromCloud && this.project.cloudId) {
            this.projectService.deleteProjectFromCloud(this.project.cloudId).subscribe();
          }
          return this.projectService.deleteProject(this.project);
        }),
      )
      .subscribe(() => this.deleteEmitter$.emit());
  }
}
