import { Product } from "@domain/product/product";
import { GasWarningCenterConfiguration } from "@domain/project/configurations/gas-warning-center-configuration";

export class OriginalGasWarningCenterConfiguration {
  productConfiguration?: GasWarningCenterConfiguration;
  gasWarningCenter?: Product;
  attachments?: Product[];

  constructor(productConfiguration: GasWarningCenterConfiguration, gasWarningCenter?: Product, attachments?: Product[]) {
    this.productConfiguration = productConfiguration;
    this.gasWarningCenter = gasWarningCenter;
    this.attachments = attachments;
  }
}
