import { Component, Input, OnInit } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { ProductService } from "@domain/product/product.service";
import { EMPTY, Observable, forkJoin, map } from "rxjs";

@Component({
  selector: "app-floorplan-item-attachments",
  templateUrl: "./floorplan-item-attachments.component.html",
  styleUrl: "./floorplan-item-attachments.component.scss",
  standalone: true,
  imports: [UiKitModule],
})
export class FloorplanItemAttachmentsComponent implements OnInit {
  @Input({ required: true }) attachmentIds!: string[];

  protected attachments$!: Observable<string[]>;

  constructor(private productService: ProductService) {}

  ngOnInit() {
    this.attachments$ = this.resolveAttachments();
  }

  private resolveAttachments(): Observable<string[]> {
    if (!this.attachmentIds.length) {
      return EMPTY;
    }
    return forkJoin(this.attachmentIds.map((id) => this.productService.getProductNameById(id).pipe(map((name) => name || id))));
  }
}
