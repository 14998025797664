<div odxLayout="flex" class="flex-column h-100">
  <odx-content-box class="w-100 {{ headerProducts.length !== 1 ? 'hidden' : '' }}">
    <div odxLayout="flex">
      <h6 class="odx-title-6 header-prefix" i18n="@@productConfiguration.attachments.headerPrefix">Zubehör für</h6>
      <app-configuration-wizard-header class="product-header" [data]="headerProducts[0]" [showCosts]="showCosts">
      </app-configuration-wizard-header>
    </div>
  </odx-content-box>

  <odx-content-box class="w-100 {{ headerProducts.length === 1 ? 'hidden' : '' }}">
    <app-configuration-wizard-header-accordion
      [showCosts]="showCosts"
      [selectedData]="headerProducts"
      [prefix]="headerAccordionPrefix"
      [title]="headerTitle"
    ></app-configuration-wizard-header-accordion>
  </odx-content-box>

  <odx-content-box *ngIf="selectedProducts.length" class="flex-column h-100 scroll" odxLayout="flex">
    <odx-content-box-header>
      <h5
        class="odx-title-5"
        i18n="@@productConfiguration.attachments.header"
        data-testid="productConfiguration.attachments.header"
      >
        Zubehör auswählen
      </h5>
    </odx-content-box-header>
    <app-filter-product (search)="search($event)"></app-filter-product>

    <div class="attachments scroll h-100" odxLayout="flex">
      <div *ngIf="filteredReqAttachments$ | async as reqAttachments" [hidden]="reqAttachments.length < 1">
        <h6 class="odx-title-6" i18n="@@productConfiguration.mandatoryAttachments.header">Pflichtzubehör</h6>
        <form [formGroup]="radioButtonForm">
          <odx-radio-group formControlName="selectedAttachment" required="true" class="overflow-hidden h-100">
            <div odxLayout="grid 3@desktop 6@tablet 12@phone" class="w-100 scroll">
              <app-attachment-card
                class="w-100 overflow-hidden"
                [selectionMode]="selectionMode"
                *ngFor="let attachment of reqAttachments"
                [attachmentData]="attachment"
                [showCosts]="showCosts"
                [isSelected]="checkIfSelected(attachment)"
                (checkboxSelectionChanged$)="updateMultiSelectedAttachments($event, attachment)"
                (click)="
                  selectionMode === 'single'
                    ? radioButtonForm.patchValue({
                        selectedAttachment: attachment,
                      })
                    : undefined
                "
              ></app-attachment-card>
            </div>
          </odx-radio-group>
        </form>
      </div>

      <div *ngIf="filteredOptAttachments$ | async as optAttachments" [hidden]="optAttachments.length < 1">
        <h6 class="odx-title-6" i18n="@@productConfiguration.optionalAttachments.header">Optionales Zubehör</h6>
        <form [formGroup]="radioButtonForm">
          <odx-radio-group formControlName="selectedAttachment" required="true" class="overflow-hidden h-100">
            <div odxLayout="grid 3@desktop 6@tablet 12@phone" class="w-100 scroll">
              <app-attachment-card
                [selectionMode]="selectionMode"
                *ngFor="let attachment of optAttachments"
                [attachmentData]="attachment"
                [showCosts]="showCosts"
                [isSelected]="checkIfSelected(attachment)"
                (checkboxSelectionChanged$)="updateMultiSelectedAttachments($event, attachment)"
                (click)="
                  selectionMode === 'single'
                    ? radioButtonForm.patchValue({
                        selectedAttachment: attachment,
                      })
                    : undefined
                "
              ></app-attachment-card>
            </div>
          </odx-radio-group>
        </form>
      </div>
      <div>
        <app-refresh-products-hint dataType="Zubehör" i18n-dataType="@@global.attachment"></app-refresh-products-hint>
      </div>
    </div>
  </odx-content-box>
</div>
