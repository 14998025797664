import { DefaultRow } from "@pdf/helpers/default-row/default-row";
import { jsPDF } from "jspdf";

export class MeasurementLineRow extends DefaultRow {
  readonly number: string[];
  readonly text: string[];
  readonly notes: string[];

  public constructor(pdf: jsPDF, number: string[], text: string[], notes: string[]) {
    super();
    this.number = number;
    this.text = text;
    this.notes = notes;
    this._textHeight = this.calculateRowTextHeight(pdf);
    this._rowHeight = this.calculateRowHeight(this.textHeight);
  }
  private calculateRowTextHeight(pdf: jsPDF) {
    return Math.max(
      ...[
        this.calculateCellTextHeight(pdf, this.number),
        this.calculateCellTextHeight(pdf, this.text),
        this.calculateCellTextHeight(pdf, this.notes),
      ],
    );
  }
}
