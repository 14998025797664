import { AirPathsRow } from "@pdf/export-services/product-information-pages/floorplan-information-pages/air-paths/air-paths-row";
import {
  AirPathsRowHeaders,
  AirPathsTable,
} from "@pdf/export-services/product-information-pages/floorplan-information-pages/air-paths/air-paths-table";
import { FloorplanInformationTable } from "@pdf/export-services/product-information-pages/floorplan-information-pages/floorplan-information-table";
import { ImagesRow } from "@pdf/export-services/product-information-pages/floorplan-information-pages/images/images-row";
import {
  ImagesRowHeaders,
  ImagesTable,
} from "@pdf/export-services/product-information-pages/floorplan-information-pages/images/images-table";
import { MeasurementLineRow } from "@pdf/export-services/product-information-pages/floorplan-information-pages/measurement-lines/measurement-line-row";
import {
  MeasurementLineRowHeaders,
  MeasurementLinesTable,
} from "@pdf/export-services/product-information-pages/floorplan-information-pages/measurement-lines/measurement-lines-table";
import { NotesRow } from "@pdf/export-services/product-information-pages/floorplan-information-pages/notes/notes-row";
import {
  FloorplanTextsRowHeaders,
  NotesTable,
} from "@pdf/export-services/product-information-pages/floorplan-information-pages/notes/notes-table";
import { PipelineRow } from "@pdf/export-services/product-information-pages/floorplan-information-pages/pipelines/pipeline-row";
import {
  PipelinesRowHeaders,
  PipelinesTable,
} from "@pdf/export-services/product-information-pages/floorplan-information-pages/pipelines/pipelines-table";
import { ZoneRow } from "@pdf/export-services/product-information-pages/floorplan-information-pages/zones/zone-row";
import {
  ZonesRowHeaders,
  ZonesTable,
} from "@pdf/export-services/product-information-pages/floorplan-information-pages/zones/zones-table";
import { ProductNotesRow } from "@pdf/export-services/product-information-pages/product-notes-pages/product-notes-row";
import {
  ProductNotesRowHeaders,
  ProductNotesTable,
} from "@pdf/export-services/product-information-pages/product-notes-pages/product-notes-table";
import { DefaultRow } from "@pdf/helpers/default-row/default-row";
import { TableProperties, TextProperties } from "@pdf/pdf-properties";
import { jsPDF } from "jspdf";
import { Container } from "../container/container";

export class RowService {
  private drawLine(pdf: jsPDF, container: Container) {
    pdf.line(
      container.xPosition + TableProperties.PADDING_X,
      container.yPosition,
      container.xPosition + TableProperties.PADDING_X + TableProperties.ROW_SEPARATOR_LENGTH,
      container.yPosition,
    );
  }

  private preProcess(pdf: jsPDF, container: Container) {
    pdf.setFontSize(TableProperties.TEXT_FONT_SIZE);
    container.yPosition += TableProperties.ROW_PADDING_TOP;
  }

  private postProcess(pdf: jsPDF, textHeight: number, container: Container) {
    container.yPosition += textHeight + TableProperties.ROW_PADDING_BOTTOM;
    this.addSeparator(pdf, container);
  }

  private drawCell(pdf: jsPDF, lines: string[], xPosition: number, yPosition: number) {
    lines.forEach((line, i) => {
      pdf.text(line, xPosition, yPosition + i * TableProperties.TEXT_HEIGHT, {
        baseline: TextProperties.BASELINE_TOP,
      });
    });
  }

  private addZoneRow(pdf: jsPDF, table: ZonesTable, row: ZoneRow) {
    const rowHeaders = new ZonesRowHeaders();
    this.drawCell(pdf, row.number, row.xPosition + table.getColumnByTitle(rowHeaders.NUMBER)!.xPosition, row.yPosition);
    this.drawCell(pdf, row.type, row.xPosition + table.getColumnByTitle(rowHeaders.TYPE)!.xPosition, row.yPosition);
    this.drawCell(pdf, row.notes, row.xPosition + table.getColumnByTitle(rowHeaders.NOTES)!.xPosition, row.yPosition);
  }

  private addPipelineRow(pdf: jsPDF, table: PipelinesTable, row: PipelineRow) {
    const rowHeaders = new PipelinesRowHeaders();
    this.drawCell(pdf, row.number, row.xPosition + table.getColumnByTitle(rowHeaders.NUMBER)!.xPosition, row.yPosition);
    this.drawCell(pdf, row.name, row.xPosition + table.getColumnByTitle(rowHeaders.NAME)!.xPosition, row.yPosition);
    this.drawCell(pdf, row.diameter, row.xPosition + table.getColumnByTitle(rowHeaders.DIAMETER)!.xPosition, row.yPosition);
    this.drawCell(pdf, row.medium, row.xPosition + table.getColumnByTitle(rowHeaders.MEDIUM)!.xPosition, row.yPosition);
    this.drawCell(pdf, row.temperature, row.xPosition + table.getColumnByTitle(rowHeaders.TEMPERATURE)!.xPosition, row.yPosition);
    this.drawCell(pdf, row.material, row.xPosition + table.getColumnByTitle(rowHeaders.MATERIAL)!.xPosition, row.yPosition);
    this.drawCell(pdf, row.notes, row.xPosition + table.getColumnByTitle(rowHeaders.NOTES)!.xPosition, row.yPosition);
  }

  private addMeasurementLinesRow(pdf: jsPDF, table: MeasurementLinesTable, row: MeasurementLineRow) {
    const rowHeaders = new MeasurementLineRowHeaders();
    this.drawCell(pdf, row.number, row.xPosition + table.getColumnByTitle(rowHeaders.NUMBER)!.xPosition, row.yPosition);
    this.drawCell(pdf, row.text, row.xPosition + table.getColumnByTitle(rowHeaders.TEXT)!.xPosition, row.yPosition);
    this.drawCell(pdf, row.notes, row.xPosition + table.getColumnByTitle(rowHeaders.NOTES)!.xPosition, row.yPosition);
  }

  private addImagesRow(pdf: jsPDF, table: ImagesTable, row: ImagesRow) {
    const rowHeaders = new ImagesRowHeaders();
    this.drawCell(pdf, row.number, row.xPosition + table.getColumnByTitle(rowHeaders.NUMBER)!.xPosition, row.yPosition);
    this.drawCell(pdf, row.name, row.xPosition + table.getColumnByTitle(rowHeaders.NAME)!.xPosition, row.yPosition);
    this.drawCell(pdf, row.notes, row.xPosition + table.getColumnByTitle(rowHeaders.NOTES)!.xPosition, row.yPosition);
  }

  private addProductNotesRow(pdf: jsPDF, table: ProductNotesTable, row: ProductNotesRow) {
    const productNotesRowHeaders = new ProductNotesRowHeaders();
    this.drawCell(
      pdf,
      [row.position],
      row.xPosition + table.getColumnByTitle(productNotesRowHeaders.NUMBER)!.xPosition,
      row.yPosition,
    );
    if (row.name) {
      this.drawCell(pdf, row.name, row.xPosition + table.getColumnByTitle(productNotesRowHeaders.TYPE)!.xPosition, row.yPosition);
    }
    if (row.text && row.textColor && row.backgroundColor) {
      this.drawCell(pdf, row.text, row.xPosition + table.getColumnByTitle(productNotesRowHeaders.TEXT)!.xPosition, row.yPosition);
      this.drawCell(
        pdf,
        row.textColor,
        row.xPosition + table.getColumnByTitle(productNotesRowHeaders.TEXT_COLOR)!.xPosition,
        row.yPosition,
      );
      this.drawCell(
        pdf,
        row.backgroundColor,
        row.xPosition + table.getColumnByTitle(productNotesRowHeaders.BACKGROUND_COLOR)!.xPosition,
        row.yPosition,
      );
    }
    this.drawCell(pdf, row.notes, row.xPosition + table.getColumnByTitle(productNotesRowHeaders.NOTES)!.xPosition, row.yPosition);
  }

  private addAirPathsRow(pdf: jsPDF, table: AirPathsTable, row: AirPathsRow) {
    const rowHeaders = new AirPathsRowHeaders();
    this.drawCell(pdf, row.number, row.xPosition + table.getColumnByTitle(rowHeaders.NUMBER)!.xPosition, row.yPosition);
    this.drawCell(pdf, row.notes, row.xPosition + table.getColumnByTitle(rowHeaders.NOTES)!.xPosition, row.yPosition);
  }

  private addNotesRow(pdf: jsPDF, table: NotesTable, row: NotesRow) {
    const rowHeaders = new FloorplanTextsRowHeaders();
    this.drawCell(pdf, row.number, row.xPosition + table.getColumnByTitle(rowHeaders.NUMBER)!.xPosition, row.yPosition);
    this.drawCell(pdf, row.notes, row.xPosition + table.getColumnByTitle(rowHeaders.NOTES)!.xPosition, row.yPosition);
  }

  public addSeparator(pdf: jsPDF, container: Container, isTableHeadline = false) {
    pdf.setDrawColor(TableProperties.SEPARATOR_COLOR);
    if (isTableHeadline) {
      pdf.setDrawColor(TableProperties.SEPARATOR_COLOR_STRONG);
    }
    this.drawLine(pdf, container);
  }

  public add(pdf: jsPDF, table: FloorplanInformationTable, row: DefaultRow, container: Container) {
    this.preProcess(pdf, container);
    row.xPosition = container.xPosition;
    row.yPosition = container.yPosition;

    if (table instanceof ZonesTable && row instanceof ZoneRow) {
      this.addZoneRow(pdf, table, row);
    }
    if (table instanceof PipelinesTable && row instanceof PipelineRow) {
      this.addPipelineRow(pdf, table, row);
    }
    if (table instanceof MeasurementLinesTable && row instanceof MeasurementLineRow) {
      this.addMeasurementLinesRow(pdf, table, row);
    }
    if (table instanceof AirPathsTable && row instanceof AirPathsRow) {
      this.addAirPathsRow(pdf, table, row);
    }
    if (table instanceof NotesTable && row instanceof NotesRow) {
      this.addNotesRow(pdf, table, row);
    }
    if (table instanceof ImagesTable && row instanceof ImagesRow) {
      this.addImagesRow(pdf, table, row);
    }
    if (table instanceof ProductNotesTable && row instanceof ProductNotesRow) {
      this.addProductNotesRow(pdf, table, row);
    }
    this.postProcess(pdf, row.textHeight, container);
  }
}
