<div>
  <app-floorplan-menu-item-buttons>
    <app-toggle-visibility-button [visible]="visible" (toggle)="visibilityUpdated($event)"> </app-toggle-visibility-button>
    <app-toggle-lock-button [locked]="locked" (toggle)="lockUpdated($event)"> </app-toggle-lock-button>
  </app-floorplan-menu-item-buttons>
</div>

<hr />

<form odxForm [formGroup]="floorplanForm">
  <h6 class="odx-title-6" i18n="@@floorplan.menu.changeFloorplan">Grundrissplan wechseln</h6>
  <odx-form-field>
    <odx-select formControlName="activeFloorplan" odxFormFieldControl>
      <odx-option [value]="plan" *ngFor="let plan of project.floorplans">
        {{ plan.name }}
      </odx-option>
    </odx-select>
  </odx-form-field>
</form>
