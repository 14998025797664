import { CdkStep, CdkStepper } from "@angular/cdk/stepper";
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { Router, RouterModule } from "@angular/router";
import { UiKitModule } from "@app/ui-kit.module";
import { UploadedFloorplan } from "@components/floorplan-upload-overlay/floorplan-upload-overlay.component";
import { ContactPersonFormComponent } from "@components/project-forms/contact-person-form/contact-person-form.component";
import { CustomerFormComponent } from "@components/project-forms/customer-form/customer-form.component";
import { GoodsRecipientFormComponent } from "@components/project-forms/goods-recipient/goods-recipient-form.component";
import { ProjectFormComponent } from "@components/project-forms/project-form/project-form.component";
import { ProjectFormsService } from "@components/project-forms/project-forms.service";
import { NewProjectStepperComponent } from "@components/stepper/new-project-stepper/new-project-stepper.component";
import { CrmEntry } from "@domain/crm/crm";
import { CrmProjectMapperService } from "@domain/crm/crm-project-mapper.service";
import { ContactPerson } from "@domain/project/contact-person";
import { Customer } from "@domain/project/customer";
import { GoodsRecipient } from "@domain/project/goods-recipient";
import { Project } from "@domain/project/project";
import { ProjectImage } from "@domain/project/project-image";
import { ProjectService } from "@domain/project/project.service";
import { NewProjectFloorplanUploadComponent } from "@pages/new-project/new-project-floorplan-upload/new-project-floorplan-upload.component";
import { NewProjectImageUploadComponent } from "@pages/new-project/new-project-image-upload/new-project-image-upload.component";

@Component({
  selector: "app-new-project",
  templateUrl: "./new-project.component.html",
  styleUrls: ["./new-project.component.scss"],
  standalone: true,
  imports: [
    UiKitModule,
    RouterModule,
    ReactiveFormsModule,
    CdkStep,
    NewProjectStepperComponent,
    ProjectFormComponent,
    CustomerFormComponent,
    ContactPersonFormComponent,
    GoodsRecipientFormComponent,
    NewProjectFloorplanUploadComponent,
    NewProjectImageUploadComponent,
  ],
})
export class NewProjectComponent implements OnInit, AfterViewInit {
  projectForm?: FormGroup;
  customerForm?: FormGroup;
  contactPersonForm?: FormGroup;
  goodsRecipientForm?: FormGroup;
  imageForm?: FormGroup;
  floorplanForm?: FormGroup;
  crmEntry?: CrmEntry;

  @ViewChild("stepper")
  private stepper!: CdkStepper;

  // Needed to show validation errors on submit without userinput
  @ViewChild("projectFormComponent")
  projectFormComponent!: ProjectFormComponent;
  @ViewChild("customerFormComponent")
  customerFormComponent!: CustomerFormComponent;
  @ViewChild("contactPersonFormComponent")
  contactPersonFormComponent!: ContactPersonFormComponent;
  @ViewChild("goodsRecipientFormComponent")
  goodsRecipientFormComponent!: GoodsRecipientFormComponent;
  @ViewChild("imageFormComponent")
  imageUploadComponent!: NewProjectImageUploadComponent;
  @ViewChild("floorplanFormComponent")
  floorplanFormComponent!: NewProjectFloorplanUploadComponent;

  constructor(
    private projectService: ProjectService,
    private router: Router,
    private projectFormsService: ProjectFormsService,
    private crmProjectMapperService: CrmProjectMapperService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.crmEntry = history.state.crmEntry;
    if (this.crmEntry) {
      this.projectFormsService.initializeProject(this.crmProjectMapperService.createProjectFromCrmEntry(this.crmEntry));
    }
  }

  ngAfterViewInit() {
    // to display correct number of total steps
    this.cdr.detectChanges();
  }

  onSubmit() {
    if (this.stepper.selectedIndex == 0) {
      this.projectFormComponent.triggerSubmit();
      this.customerFormComponent.triggerSubmit();
      this.contactPersonFormComponent.triggerSubmit();
      this.goodsRecipientFormComponent.triggerSubmit();

      if (
        !this.projectForm!.valid ||
        !this.customerForm!.valid ||
        !this.contactPersonForm!.valid ||
        !this.goodsRecipientForm!.valid
      ) {
        return;
      }
    }

    if (this.stepper.selectedIndex == 1) {
      this.floorplanFormComponent.triggerSubmit();
      if (!this.floorplanForm?.valid) {
        return;
      }
    }

    if (this.stepper.selectedIndex == 2) {
      this.imageUploadComponent.triggerSubmit();
      if (!this.imageForm?.valid) {
        return;
      }
    }

    if (this.stepper.selectedIndex === this.stepper.steps.length - 1) {
      const newProject = this.mapProject();
      this.projectService.createProject(newProject).subscribe((project) => this.router.navigate([`/projects/${project.id}`]));
    } else {
      this.stepper.next();
    }
  }

  private mapProject(): Project {
    const project = Project.create(this.projectForm!.value.name);
    project.country = this.projectForm!.value.country;
    project.forzaId = this.projectForm!.value.forzaId;
    project.placeName = this.projectForm!.value.placeName;
    project.crmEntryId = this.crmEntry?.id;
    project.notes = this.projectForm!.value.notes;

    this.mapFormFieldsToCustomer(project.customer, this.customerForm!.value);
    this.mapContactPerson(project.contactPerson, this.contactPersonForm!.value);
    this.mapGoodsRecipient(project.goodsRecipient, this.goodsRecipientForm!.value);
    this.mapFloorplans(project, this.floorplanFormComponent.floorplans);
    this.mapImages(project, this.imageUploadComponent.getImages());
    return project;
  }

  private mapFormFieldsToCustomer(customer: Customer, formValue: any) {
    customer.name = formValue.name;
    customer.customerNumber = formValue.customerNumber;
    customer.street = formValue.street;
    customer.zipCode = formValue.zipCode;
    customer.city = formValue.city;
  }

  private mapContactPerson(contactPerson: ContactPerson, formValue: any) {
    contactPerson.firstname = formValue.firstname;
    contactPerson.lastname = formValue.lastname;
    contactPerson.department = formValue.department;
    contactPerson.email = formValue.email;
    contactPerson.phoneNumber = formValue.phoneNumber;
  }

  private mapGoodsRecipient(goodsRecipient: GoodsRecipient, formValue: any) {
    goodsRecipient.information = formValue.information;
  }

  private mapFloorplans(project: Project, floorplans: UploadedFloorplan[]) {
    floorplans.forEach((floorplan) => project.addFloorplan(floorplan.name, floorplan.fileUrl));
  }

  private mapImages(project: Project, images: ProjectImage[]) {
    images.forEach((image) => project.addImages(image));
  }
}
