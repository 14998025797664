import { Injectable } from "@angular/core";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { TableOfContents } from "@pdf/export-services/table-of-contents/table-of-contents";
import { Container } from "@pdf/helpers/container/container";
import { ContainerService } from "@pdf/helpers/container/container-service";
import { TableService } from "@pdf/helpers/default-table/table-service";
import { Headlines } from "@pdf/pdf-properties";
import { jsPDF } from "jspdf";
import { AirPathsTable } from "./air-paths/air-paths-table";
import { ImagesTable } from "./images/images-table";
import { MeasurementLinesTable } from "./measurement-lines/measurement-lines-table";
import { NotesTable } from "./notes/notes-table";
import { PipelinesTable } from "./pipelines/pipelines-table";
import { ZonesTable } from "./zones/zones-table";

@Injectable({
  providedIn: "root",
})
export class FloorplanInformationPdfService {
  readonly headlines = new Headlines();
  containerService: ContainerService;
  tableService: TableService;
  constructor() {
    this.containerService = new ContainerService();
    this.tableService = new TableService();
  }

  private isDataAvailable(floorplan: Floorplan) {
    return (
      floorplan.exZones.length > 0 ||
      floorplan.dangerAreas.length > 0 ||
      floorplan.pipelines.length > 0 ||
      floorplan.measurementLines.length > 0 ||
      floorplan.airPaths.length > 0 ||
      floorplan.floorplanTexts.length > 0 ||
      floorplan.images.length > 0
    );
  }
  public generatePages(pdf: jsPDF, floorplan: Floorplan, container: Container, tableOfContents: TableOfContents) {
    if (this.isDataAvailable(floorplan)) {
      this.containerService.addOnNewPage(
        pdf,
        container,
        `${this.headlines.FLOORPLAN_INFORMATION} | ${floorplan.name}`,
        tableOfContents,
      );
    }
    if (floorplan.exZones.length || floorplan.dangerAreas.length) {
      this.tableService.tableToPdf(
        pdf,
        new ZonesTable(pdf, floorplan.exZones, floorplan.dangerAreas),
        container,
        tableOfContents,
        `${this.headlines.FLOORPLAN_INFORMATION} | ${floorplan.name}`,
      );
    }
    if (floorplan.pipelines.length) {
      this.tableService.tableToPdf(
        pdf,
        new PipelinesTable(pdf, floorplan.pipelines),
        container,
        tableOfContents,
        `${this.headlines.FLOORPLAN_INFORMATION} | ${floorplan.name}`,
      );
    }
    if (floorplan.measurementLines.length) {
      this.tableService.tableToPdf(
        pdf,
        new MeasurementLinesTable(pdf, floorplan.measurementLines),
        container,
        tableOfContents,
        `${this.headlines.FLOORPLAN_INFORMATION} | ${floorplan.name}`,
      );
    }
    if (floorplan.airPaths.length) {
      this.tableService.tableToPdf(
        pdf,
        new AirPathsTable(pdf, floorplan.airPaths),
        container,
        tableOfContents,
        `${this.headlines.FLOORPLAN_INFORMATION} | ${floorplan.name}`,
      );
    }
    if (floorplan.floorplanTexts.length) {
      this.tableService.tableToPdf(
        pdf,
        new NotesTable(pdf, floorplan.floorplanTexts),
        container,
        tableOfContents,
        `${this.headlines.FLOORPLAN_INFORMATION} | ${floorplan.name}`,
      );
    }
    if (floorplan.images.length) {
      this.tableService.tableToPdf(
        pdf,
        new ImagesTable(pdf, floorplan.images),
        container,
        tableOfContents,
        `${this.headlines.FLOORPLAN_INFORMATION} | ${floorplan.name}`,
      );
    }
  }
}
