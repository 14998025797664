import { DefaultRow } from "@pdf/helpers/default-row/default-row";
import { TableColumn } from "@pdf/helpers/default-table/table-column";
import { TableProperties } from "@pdf/pdf-properties";
import { jsPDF } from "jspdf";

export class FloorplanInformationTable {
  private static readonly MAX_TEXT_LENGTH: number = 2000;
  private static readonly ELLIPSIS: string = "...";
  readonly icon: HTMLImageElement = new Image();
  readonly headline!: string;
  columns!: TableColumn[];
  rows!: DefaultRow[];
  height: number = 0;

  public constructor(iconUrl: string) {
    this.icon.src = iconUrl;
  }

  public calculateHeight(rows: DefaultRow[]) {
    let tableHeight = 0;
    rows.forEach((row) => {
      tableHeight += row.textHeight;
    });
    return tableHeight;
  }

  public getColumnByTitle(title: string) {
    return this.columns.find((column) => column.title === title);
  }

  protected textToColumnWidth(pdf: jsPDF, fontsize: number, text: string, columnName: string): string[] {
    pdf.setFontSize(fontsize);
    return pdf.splitTextToSize(text, this.getColumnByTitle(columnName)!.width - TableProperties.ROW_PADDING_X);
  }

  private limitTextLength(text: string) {
    if (text.length > FloorplanInformationTable.MAX_TEXT_LENGTH) {
      return text.slice(0, FloorplanInformationTable.MAX_TEXT_LENGTH) + FloorplanInformationTable.ELLIPSIS;
    }
    return text;
  }

  private replaceEmptyTextWithDefault(text: string | undefined) {
    if (text == undefined || text === "") {
      return "--";
    }
    return text;
  }

  protected preProcessText(text?: string) {
    text = this.replaceEmptyTextWithDefault(text);
    return this.limitTextLength(text);
  }
}
