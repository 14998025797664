import { Injectable } from "@angular/core";
import { Toast, ToastRef, ToastService, ToastVariant } from "@odx/angular/components/toast";
import { ToastOptions } from "@odx/angular/components/toast/lib/models";

@Injectable({
  providedIn: "root",
})
export class ToastHelper {
  private static readonly MESSAGE_DURATION = 5000;
  private static readonly SUCCESS_DURATION = 10000;
  private static readonly ERROR_DURATION = 30000;

  private openToasts = new Map<string, ToastRef>();

  constructor(private toastService: ToastService) {
    this.toastService.onRemove$.subscribe((toastRef) => this.openToasts.delete(toastRef.item.title.toString()));
  }

  message(title: string) {
    this.addToast(
      {
        title,
        variant: ToastVariant.DEFAULT,
      },
      { duration: ToastHelper.MESSAGE_DURATION },
    );
  }

  success(title: string) {
    this.addToast(
      {
        title,
        variant: ToastVariant.SUCCESS,
      },
      { duration: ToastHelper.SUCCESS_DURATION },
    );
  }

  error(title: string, description?: string, keepUntilDismissed: boolean = false) {
    this.addToast(
      {
        title,
        description,
        variant: ToastVariant.DANGER,
      },
      { duration: keepUntilDismissed ? 0 : ToastHelper.ERROR_DURATION },
    );
  }

  warning(title: string, description: string, label: string, action: () => Promise<void>) {
    this.addToast({
      title: title,
      description: description,
      actions: [
        {
          label: label,
          action: async () => await action(),
        },
      ],
      variant: ToastVariant.WARNING,
    });
  }

  errorOffline() {
    this.error(
      $localize`:@@toast.error.offline.header:Keine Verbindung zur Cloud`,
      $localize`:@@toast.error.offline.message:Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut.`,
    );
  }

  private addToast(toast: Toast, options?: Partial<ToastOptions>) {
    const title = toast.title.toString();
    this.closeOpenToast(title);
    const toastRef = this.toastService.add(toast, options);
    this.openToasts.set(title, toastRef);
  }

  /**
   * This prevents from duplicate toast messages
   *
   * @param title
   * @private
   */
  private closeOpenToast(title: string) {
    const toastRef = this.openToasts.get(title);
    if (toastRef) {
      this.toastService.remove(toastRef);
    }
  }
}
