<odx-modal-header>
  <odx-area-header i18n="@@modal.deleteCloudProjects.header">Cloudprojekte löschen</odx-area-header>
</odx-modal-header>
<odx-modal-content>
  <p i18n="@@modal.deleteCloudProjects.instructions">
    Klicken Sie auf das entsprechende Icon, um das gewünschte Projekt aus Ihrer Cloud zu löschen.
  </p>
  <app-cloud-projects-odx-table
    *ngIf="cloudProjects$ | async as cloudProjects; else loading"
    [cloudProjectMetadata]="cloudProjects"
    [variant]="'delete'"
    (projectSelected)="onClickDelete($event)"
  ></app-cloud-projects-odx-table>
  <ng-template #loading>
    <p [odxLoadingSpinner]="true" [odxLoadingSpinnerMinHeight]="500"></p>
  </ng-template>
</odx-modal-content>
