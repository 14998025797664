import { Component } from "@angular/core";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { UiKitModule } from "@app/ui-kit.module";
import { ConfirmModalComponent } from "@components/confirm-modal/confirm-modal.component";
import { ProjectCardComponent } from "@components/project-cards/project-card/project-card.component";
import { CrmEntry } from "@domain/crm/crm";
import { CrmProjectMapperService } from "@domain/crm/crm-project-mapper.service";
import { CrmService } from "@domain/crm/crm.service";
import { Project } from "@domain/project/project";
import { ProjectService } from "@domain/project/project.service";
import { ModalService } from "@odx/angular/components/modal";
import { ToastHelper } from "@utils/toast-helper.service";
import { EMPTY, Observable, catchError, switchMap } from "rxjs";

@Component({
  selector: "app-crm-receiver",
  templateUrl: "./crm-receiver.component.html",
  styleUrls: ["./crm-receiver.component.scss"],
  standalone: true,
  imports: [UiKitModule, RouterModule, ProjectCardComponent],
})
export class CrmReceiverComponent {
  crmEntry$: Observable<CrmEntry>;
  projects$: Observable<Project[]>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private crmService: CrmService,
    private projectService: ProjectService,
    private crmProjectMapperService: CrmProjectMapperService,
    private modalService: ModalService,
    private toastHelper: ToastHelper,
  ) {
    this.projects$ = this.projectService.projects$;
    this.crmEntry$ = this.route.queryParams.pipe(
      switchMap((params) =>
        crmService.findById(params["crmContactId"]).pipe(
          catchError(() => {
            this.toastHelper.error(
              $localize`:@@toast.crmProjectNotFound:CRM Projekt mit id ${params["crmContactId"]}:crmContactId: konnte nicht gefunden werden`,
            );
            this.router.navigate([""]);
            return EMPTY;
          }),
        ),
      ),
    );
  }

  linkCrmEntryToProject(crmEntry: CrmEntry, project: Project) {
    this.modalService
      .open(ConfirmModalComponent, {
        data: {
          text: $localize`:@@modal.crmProject.overwriteProject:Wollen Sie die Projektinformationen wirklich mit den Daten aus dem CRM-Kontakt überschreiben?`,
        },
      })
      .onClose$.pipe(
        switchMap(() => {
          this.crmProjectMapperService.overwriteProjectWithCrmEntry(project, crmEntry);
          return this.projectService.update(project);
        }),
      )
      .subscribe(() => {
        this.router.navigate([`/projects/${project.id}`]);
        this.toastHelper.success(
          $localize`:@@toast.crmProject.overwriteSuccessful:Projekt '${project.name}:projectName:' wurde erfolgreich mit CRM-Kontakt überschrieben`,
        );
      });
  }
}
