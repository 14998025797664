import { IsOptional, IsString } from "class-validator";

export class GoodsRecipient {
  @IsString()
  @IsOptional()
  public information?: string;

  constructor(information?: string) {
    this.information = information;
  }
}
