import { Component } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ProjectStateService } from "@project/project-state.service";

@Component({
  selector: "app-project",
  template: `<router-outlet></router-outlet>`,
  providers: [ProjectStateService],
  standalone: true,
  imports: [RouterModule],
})
export class ProjectComponent {}
