import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class NavigationService {
  constructor(private router: Router) {}

  toDeviceConfiguration(device: string, floorplanId: string, existingConfigId?: string) {
    const url = this.router.url;
    this.router.navigate([url.slice(0, url.indexOf("floorplans")) + device], {
      queryParams: {
        floorplan_id: floorplanId,
        config_id: existingConfigId,
      },
    });
  }
}
