import { Project } from "@domain/project/project";
import { Exclude } from "class-transformer";
import { IsOptional, IsString, IsUUID } from "class-validator";

export abstract class ProductConfiguration {
  @Exclude()
  protected project!: Project;

  @IsUUID()
  public readonly id: string;

  @IsString()
  public name: string;

  @IsString()
  @IsOptional()
  public positionNumber?: string;

  protected constructor(id: string, name: string, project: Project, positionNumber?: string) {
    this.id = id;
    this.name = name;
    this.init(project);
    this.positionNumber = positionNumber;
  }

  init(project: Project) {
    this.project = project;
  }

  get productDataName(): string {
    return this.name;
  }

  get configuredProductIds(): string[] {
    return [this.mainProductId];
  }

  abstract countPlacedProducts(project?: Project): number;

  abstract get mainProductId(): string;
}
