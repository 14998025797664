import { Component, Input, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { ProductService } from "@domain/product/product.service";
import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { FloorplanPlaceholder } from "@domain/project/floorplan/floorplan-placeholder";
import { FloorplanTransmitterPlaceholder } from "@domain/project/floorplan/floorplan-transmitter-placeholder";
import { PlaceholderProduct } from "@domain/project/floorplan/placeholder-product";
import { FloorplanItemDetailsComponent } from "@project/floorplanner/floorplan-additional-notes/floorplan-item-details/floorplan-item-details.component";
import { first } from "rxjs";

@Component({
  selector: "app-placeholder-notes",
  templateUrl: "./placeholder-notes.component.html",
  styleUrls: ["./placeholder-notes.component.scss"],
  standalone: true,
  imports: [UiKitModule, FloorplanItemDetailsComponent, ReactiveFormsModule],
})
export class PlaceholderNotesComponent implements OnInit {
  protected availableProductIds: string[] = [];
  protected showWarning: boolean = false;
  protected title!: string;
  protected imageUrl!: string;
  protected placeholderProductsForm!: FormGroup;

  private _floorplanItem!: FloorplanPlaceholder | FloorplanTransmitterPlaceholder;

  @Input({
    transform: (value: FloorplanItem): FloorplanPlaceholder | FloorplanTransmitterPlaceholder => {
      if (value instanceof FloorplanPlaceholder) {
        return value as FloorplanPlaceholder;
      } else if (value instanceof FloorplanTransmitterPlaceholder) {
        return value as FloorplanTransmitterPlaceholder;
      } else {
        throw new Error("Invalid FloorplanItem type");
      }
    },
    required: true,
  })
  set floorplanItem(value: FloorplanPlaceholder | FloorplanTransmitterPlaceholder) {
    this._floorplanItem = value;
  }

  get floorplanItem(): FloorplanPlaceholder | FloorplanTransmitterPlaceholder {
    return this._floorplanItem;
  }

  constructor(
    private formBuilder: FormBuilder,
    private productService: ProductService,
  ) {}

  ngOnInit(): void {
    this.setPlaceholderData();
    this.initForm();
    this.productService
      .getAllProductIds()
      .pipe(first())
      .subscribe((productIds: string[]) => {
        this.availableProductIds = productIds;
        this.updateShowWarning();
      });
  }

  protected addFormGroup(): void {
    const placeholderProducts: FormArray = this.placeholderProductsForm.get("placeholderProducts") as FormArray;
    placeholderProducts.push(this.createPlaceholderProductFormGroup());
  }

  protected getProductsControls() {
    const productsArray = this.placeholderProductsForm.get("placeholderProducts") as FormArray;
    return productsArray ? productsArray.controls : [];
  }

  private setPlaceholderData() {
    if (this.floorplanItem instanceof FloorplanTransmitterPlaceholder) {
      this.title = $localize`:@@floorplan.details.placeholder.transmitter.header:Platzhalter (Transmitter)`;
      this.imageUrl = "./assets/transmitter_platzhalter_nicht_odx.svg";
      return;
    }
    switch ((this.floorplanItem as FloorplanPlaceholder).type) {
      case "alarmDevice":
        this.title = $localize`:@@floorplan.details.placeholder.alarmDevice.header:Platzhalter (Alarmmittel)`;
        this.imageUrl = "./assets/alarmmittel_platzhalter_nicht_odx.svg";
        break;
      case "gasWarningCenter":
        this.title = $localize`:@@floorplan.details.placeholder.gasWarningCenter.header:Platzhalter (Gaswarnzentrale)`;
        this.imageUrl = "./assets/gaswarnanlage_platzhalter_nicht_odx.svg";
        break;
      case "plasticSign":
        this.title = $localize`:@@floorplan.details.placeholder.plasticSign.header:Platzhalter (Kunststoffschild)`;
        this.imageUrl = "./assets/kunststoffschild_platzhalter_nicht_odx.svg";
        break;
      case "signalElement":
        this.title = $localize`:@@floorplan.details.placeholder.signalElement.header:Platzhalter (Leuchttransparent)`;
        this.imageUrl = "./assets/leuchttransparente_platzhalter_nicht_odx.svg";
    }
  }

  private initForm() {
    const placeholderProductsFormArray = this.formBuilder.array(
      this.floorplanItem.products.map((product: PlaceholderProduct) =>
        this.formBuilder.group({
          name: [product.name],
          id: [product.id],
        }),
      ),
    );

    this.placeholderProductsForm = this.formBuilder.group({
      placeholderProducts: placeholderProductsFormArray,
    });

    if (!placeholderProductsFormArray.length) {
      this.addFormGroup();
    }

    this.placeholderProductsForm.valueChanges.subscribe(() => {
      if (!this.placeholderProductsForm.valid || this.placeholderProductsForm.pristine) return;
      this.update();
    });
  }

  private createPlaceholderProductFormGroup(): FormGroup {
    return this.formBuilder.group({
      name: [null],
      id: [null],
    });
  }

  private update() {
    const formArray = this.placeholderProductsForm.get("placeholderProducts") as FormArray;
    if (!formArray) {
      return;
    }

    this.floorplanItem.products = formArray.controls
      .map((control) => {
        const formGroup = control as FormGroup;
        const name = formGroup.get("name")?.value?.trim() || "";
        const productNumber = formGroup.get("id")?.value?.trim() || "";

        return name || productNumber ? new PlaceholderProduct(name, productNumber) : undefined;
      })
      .filter((placeholderProduct): placeholderProduct is PlaceholderProduct => placeholderProduct !== undefined);

    this.updateShowWarning();
  }

  private updateShowWarning() {
    this.showWarning =
      this.floorplanItem.products.length > 0 &&
      this.floorplanItem.products.every((product: PlaceholderProduct) => this.availableProductIds.includes(product.id));
  }
}
