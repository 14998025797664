import { PdfProperties, TableProperties } from "@pdf/pdf-properties";
import { jsPDF } from "jspdf";

export class DefaultRow {
  protected _textHeight: number = 0;
  protected _rowHeight: number = 0;
  public yPosition: number = 0;
  public xPosition: number = 0;

  get textHeight() {
    return this._textHeight;
  }

  get rowHeight() {
    return this._rowHeight;
  }
  private calculateLineHeight(pdf: jsPDF, lines: string[]) {
    let lineHeight = 0;
    lines.find((line) => {
      lineHeight = pdf.getTextDimensions(line).h * PdfProperties.POINT_TO_PIXEL_RATIO;
      return lineHeight > 0;
    });
    return lineHeight;
  }

  protected calculateRowHeight(textHeight: number) {
    return TableProperties.ROW_PADDING_Y + textHeight;
  }

  protected calculateCellTextHeight(pdf: jsPDF, text: string[]) {
    const lineHeight = this.calculateLineHeight(pdf, text);
    return text.length * lineHeight;
  }
}
