import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { STORE_NAME_PRODUCTS } from "@app/indexed-db-config";
import { LastProductUpdateService } from "@domain/product/last-product-update.service";
import { FullProductType, Product, ProductType } from "@domain/product/product";
import { ProductMapperService } from "@domain/product/product-mapper.service";
import { environment } from "@environments/environment";
import { ObservableInstanceMapper } from "@utils/observable-instance-mapper";
import { instanceToPlain } from "class-transformer";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { Observable, forkJoin, map, of, switchMap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ProductService {
  public static readonly PRODUCTS_URL = environment.apiUrl + "/product/products";
  public static readonly PRODUCT_PROPERTIES_URL = environment.apiUrl + "/product-property/product-properties";

  constructor(
    private http: HttpClient,
    private dbService: NgxIndexedDBService,
    private productMapperService: ProductMapperService,
    private productUpdateService: LastProductUpdateService,
    @Inject(LOCALE_ID) private localeId: string,
  ) {}

  loadAll(): Observable<Product[]> {
    return this.dbService.count(STORE_NAME_PRODUCTS).pipe(
      switchMap((count: number) => {
        if (count > 0) {
          return this.loadProductsFromDB().pipe(
            switchMap((products) => {
              if (this.checkDataRevisions(products)) {
                return of(products);
              }
              return this.refreshAll();
            }),
          );
        }
        return this.refreshAll();
      }),
    );
  }

  private checkDataRevisions(products: Product[]) {
    return this.checkForAlarmDeviceFullTypes(products) && this.checkForLocalizedNames(products, this.localeId);
  }

  private checkForAlarmDeviceFullTypes(products: Product[]) {
    return products.some((product) => product.fullType == FullProductType.ALARMDEVICE_A24);
  }

  private checkForLocalizedNames(products: Product[], key: string) {
    if (!products[0].localizedName) {
      return false;
    }
    return products.every((product) => {
      return key in product.localizedName;
    });
  }

  getProductById(id: string): Observable<Product | undefined> {
    return ObservableInstanceMapper.valueToInstance(this.dbService.getByKey<Product>(STORE_NAME_PRODUCTS, id), Product);
  }

  getProductNameById(id: string): Observable<string | undefined> {
    return this.getProductById(id).pipe(map((product) => product?.localizedName[this.localeId]));
  }

  getProductsByIds(ids: string[], productType: ProductType): Observable<Product[]> {
    return ObservableInstanceMapper.valuesToInstance(this.dbService.bulkGet<Product>(STORE_NAME_PRODUCTS, ids), Product).pipe(
      map((products: Product[]) => products.filter((product) => product?.type === productType)),
    );
  }

  getProductNamesByIds(ids: string[], productType: ProductType): Observable<string[]> {
    return this.getProductsByIds(ids, productType).pipe(
      map((products) => products.map((product) => product.localizedName[this.localeId])),
    );
  }

  getDiscontinuedProductIds(): Observable<string[]> {
    return ObservableInstanceMapper.valuesToInstance(this.dbService.getAll<Product>(STORE_NAME_PRODUCTS), Product).pipe(
      map((products: Product[]) => products.filter((product) => !product.isAvailable).map((product) => product.id)),
    );
  }

  getAllProductIds(): Observable<string[]> {
    return ObservableInstanceMapper.valuesToInstance(this.dbService.getAll<Product>(STORE_NAME_PRODUCTS), Product).pipe(
      map((products: Product[]) => products.map((product) => product.id)),
    );
  }

  refreshAll(): Observable<Product[]> {
    if (this.localeId === "en-US" || this.localeId === "de-DE") {
      return forkJoin([this.loadProductsFromApi("en-US"), this.loadProductsFromApi("de-DE")]).pipe(
        switchMap((products) => this.clearAll().pipe(map(() => products))),
        switchMap((products) => this.storeProductsInDB(...products)),
      );
    }
    return forkJoin([
      this.loadProductsFromApi("en-US"),
      this.loadProductsFromApi("de-DE"),
      this.loadProductsFromApi(this.localeId),
    ]).pipe(
      switchMap((products) => this.clearAll().pipe(map(() => products))),
      switchMap((products) => this.storeProductsInDB(...products)),
    );
  }

  clearAll(): Observable<boolean> {
    return this.dbService.clear(STORE_NAME_PRODUCTS);
  }

  filter(products$: Observable<Product[]>, query: string): Observable<any> {
    return products$.pipe(map((products) => products.filter((product) => this.searchFilter(product, query))));
  }

  private loadProductsFromApi(languageString: string): Observable<Product[]> {
    const params = new HttpParams().set("lang", languageString);
    return ObservableInstanceMapper.valuesToInstance(
      forkJoin([
        this.http.get(ProductService.PRODUCTS_URL, { params }),
        this.http.get(ProductService.PRODUCT_PROPERTIES_URL, { params }),
      ]).pipe(
        map((results: any[]) => {
          const productsResponse = results[0];
          const productPropsResponse = results[1];

          return this.productMapperService.mapProductAndProperties(productsResponse, productPropsResponse);
        }),
      ),
      Product,
    );
  }

  private loadProductsFromDB(): Observable<Product[]> {
    return ObservableInstanceMapper.valuesToInstance(this.dbService.getAll<Product>(STORE_NAME_PRODUCTS), Product);
  }

  private storeProductsInDB(
    productsEnglish: Product[],
    productsGerman: Product[],
    productsCurrentLocale?: Product[],
  ): Observable<Product[]> {
    return this.dbService
      .bulkPut(
        STORE_NAME_PRODUCTS,
        productsEnglish.map((product, index) => {
          product.localizedName = { "en-US": product.name };
          product.localizedName["de-DE"] = productsGerman[index].name;
          if (productsCurrentLocale) {
            const matchingProducts = productsCurrentLocale.filter((localeProduct) => localeProduct.id === product.id);
            if (matchingProducts.length === 1) {
              product.localizedName[this.localeId] = matchingProducts[0].name;
            }
          }
          return instanceToPlain(product);
        }),
      )
      .pipe(
        switchMap(() => this.productUpdateService.saveLastUpdate()),
        map(() => productsEnglish), // return added product instances not the plain ones from dbService.bulkAdd
      );
  }

  searchFilter(product: Product, query: string): boolean {
    return (
      product.localizedName[this.localeId].toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) >= 0 ||
      product.id.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) >= 0
    );
  }
}
