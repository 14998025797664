import { Injectable } from "@angular/core";
import { TableOfContents } from "@pdf/export-services/table-of-contents/table-of-contents";
import { ContainerProperties, FontNames, PdfProperties } from "@pdf/pdf-properties";
import { jsPDF } from "jspdf";
import { ActiveSide, Container } from "./container";

@Injectable({
  providedIn: "root",
})
export class ContainerService {
  private addRect(pdf: jsPDF, container: Container) {
    pdf.setLineWidth(PdfProperties.BORDER_WIDTH);
    pdf.setDrawColor(PdfProperties.BORDER_COLOR);
    pdf.setFillColor(PdfProperties.BACKGROUND_COLOR);
    pdf.rect(container.xPosition, ContainerProperties.Y, ContainerProperties.WIDTH, ContainerProperties.HEIGHT);
  }

  public add(pdf: jsPDF, container: Container, headline: string, tableOfContents?: TableOfContents) {
    container.toggleActiveSide();
    pdf.setFont(FontNames.DRAEGER_PANGEA);
    pdf.setFontSize(PdfProperties.HEADLINE_FONT_SIZE);
    if (container.activeSide === ActiveSide.LEFT) {
      pdf.addPage();
      if (tableOfContents) {
        tableOfContents.appendToTableOfContents(headline);
      }
      pdf.text(headline, container.xPosition, PdfProperties.HEADLINE_Y, { baseline: "top" });
    }
    pdf.setFont(FontNames.DRAEGER_PANGEA_TEXT);
    this.addRect(pdf, container);
  }

  public next(pdf: jsPDF, container: Container, headline: string, tableOfContents?: TableOfContents) {
    container.toggleActiveSide();
    pdf.setFont(FontNames.DRAEGER_PANGEA);
    pdf.setFontSize(PdfProperties.HEADLINE_FONT_SIZE);
    if (container.activeSide === ActiveSide.LEFT) {
      pdf.setPage(pdf.getNumberOfPages() + 1);
      if (tableOfContents) {
        tableOfContents.appendToTableOfContents(headline);
      }
      pdf.text(headline, container.xPosition, PdfProperties.HEADLINE_Y, { baseline: "top" });
    }
    pdf.setFont(FontNames.DRAEGER_PANGEA_TEXT);
    this.addRect(pdf, container);
  }

  public addOnCurrentPage(pdf: jsPDF, container: Container, headline: string, tableOfContents?: TableOfContents) {
    if (tableOfContents) {
      tableOfContents.appendToTableOfContents(headline);
    }
    container.resetActiveSideToLeft();
    pdf.setFont(FontNames.DRAEGER_PANGEA);
    pdf.setFontSize(PdfProperties.HEADLINE_FONT_SIZE);
    pdf.text(headline, container.xPosition, PdfProperties.HEADLINE_Y, { baseline: "top" });
    pdf.setFont(FontNames.DRAEGER_PANGEA_TEXT);
    this.addRect(pdf, container);
  }

  public addOnNewPage(pdf: jsPDF, container: Container, headline: string, tableOfContests?: TableOfContents) {
    pdf.addPage();
    this.addOnCurrentPage(pdf, container, headline, tableOfContests);
  }
}
