<div *ngIf="selectedItem$ | async as selectedItem" odxlayout="flex gap-none" class="flex-column w-100 container">
  <div class="scrollable-content">
    <app-measurement-line-notes *ngIf="itemType?.measurementLine" [floorplanItem]="selectedItem"></app-measurement-line-notes>
    <app-image-notes *ngIf="itemType?.image" [floorplanItem]="selectedItem"></app-image-notes>
    <app-transmitter-notes *ngIf="itemType?.transmitter" [floorplanItem]="selectedItem"></app-transmitter-notes>
    <app-gaswarningcenter-notes *ngIf="itemType?.gasWarningCenter" [floorplanItem]="selectedItem"></app-gaswarningcenter-notes>
    <app-signal-notes *ngIf="itemType?.signalElement" [floorplanItem]="selectedItem"></app-signal-notes>
    <app-alarm-notes *ngIf="itemType?.alarmDevice" [floorplanItem]="selectedItem"></app-alarm-notes>
    <app-plasticsign-notes *ngIf="itemType?.plasticSign" [floorplanItem]="selectedItem"></app-plasticsign-notes>
    <app-pipeline-notes *ngIf="itemType?.pipeline" [floorplanItem]="selectedItem"></app-pipeline-notes>
    <app-placeholder-notes *ngIf="itemType?.placeholder" [floorplanItem]="selectedItem"></app-placeholder-notes>
    <app-floorplan-item-details *ngIf="itemType?.defaultItem" [title]="title!" [floorplanItem]="selectedItem">
    </app-floorplan-item-details>
  </div>
</div>
