import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { ConfigurationWizardHeaderComponent } from "@components/configuration-wizard-header/configuration-wizard-header.component";
import { FilterProductComponent } from "@components/filter-product/filter-product.component";
import { Gas, GasProduct } from "@domain/gas/gas";
import { LocalizeProductNamePipe, Product, ProductType } from "@domain/product/product";
import { ProductService } from "@domain/product/product.service";
import { untilDestroyed } from "@odx/angular/utils";
import { TransmitterCardComponent } from "@project/transmitter-configuration/transmitter-card/transmitter-card.component";
import { first, map } from "rxjs";

@Component({
  selector: "app-transmitter-selector",
  templateUrl: "./transmitter-selector.component.html",
  styleUrls: ["./transmitter-selector.component.scss"],
  standalone: true,
  imports: [
    UiKitModule,
    ReactiveFormsModule,
    ConfigurationWizardHeaderComponent,
    FilterProductComponent,
    TransmitterCardComponent,
    LocalizeProductNamePipe,
  ],
  providers: [LocalizeProductNamePipe],
})
export class TransmitterSelectorComponent {
  protected readonly NOFILTER = "NOFILTER";
  protected readonly VOLUMEPERCENT = "%V/V";
  protected readonly PPM = "ppm";
  protected readonly UEGPERCENT = "%UEG";

  @Input({ required: true }) set selectedGasData(gas: Gas | undefined) {
    this.clearTransmitterSelection();
    this.clearProducts();
    if (gas) {
      this.selectedGas = gas;
      this.collectProducts();
    }
  }

  @Input({ required: true }) showCosts!: boolean;

  @Input() set originalTransmitter(transmitter: Product | undefined) {
    if (transmitter && transmitter.isAvailable) {
      this.select(transmitter);
    }
  }

  @Output() transmitterSelect$ = new EventEmitter<Product>();

  protected selectedGas?: Gas;
  protected selectedTransmitter?: Product;
  protected filteredSelectableProducts: Product[] = [];
  protected detectingRangeForm: FormGroup;
  protected selectedTransmitterForm: FormGroup;

  private selectableProducts: Product[] = [];
  private selectableGasProducts: GasProduct[] = [];
  private readonly takeUntilDestroyed = untilDestroyed();

  constructor(
    private productService: ProductService,
    private formBuilder: FormBuilder,
  ) {
    this.detectingRangeForm = this.formBuilder.group({
      detectingRange: [this.NOFILTER],
    });

    this.selectedTransmitterForm = formBuilder.group({
      selectedTransmitter: [this.originalTransmitter || null, Validators.required],
    });

    this.detectingRangeForm.valueChanges.pipe(this.takeUntilDestroyed()).subscribe(() => {
      this.clearTransmitterSelection();
      this.clearProducts();
      this.collectProducts();
    });

    this.selectedTransmitterForm.valueChanges.pipe(this.takeUntilDestroyed()).subscribe(() => {
      this.selectedTransmitter = this.selectedTransmitterForm.value.selectedTransmitter;
      return this.transmitterSelect$.emit(this.selectedTransmitter);
    });
  }

  protected search(value: string) {
    this.filteredSelectableProducts = this.selectableProducts.filter((product) =>
      this.productService.searchFilter(product, value),
    );
  }

  protected select(transmitter: Product) {
    this.selectedTransmitterForm.patchValue({
      selectedTransmitter: transmitter,
    });
  }

  private collectProducts() {
    if (!this.selectedGas) {
      return;
    }

    this.filterProductsByDetectingRange();
    const ids = this.selectableGasProducts.filter((gasProduct) => gasProduct.productId).map((gasProduct) => gasProduct.productId);

    if (ids.length) {
      this.productService
        .getProductsByIds(ids, ProductType.TRANSMITTER)
        .pipe(
          map((products: Product[]) => Product.sort(products).filter((product) => product.isAvailable)),
          first(),
        )
        .subscribe((products: Product[]) => this.setProducts(products));
    }
  }

  private filterProductsByDetectingRange() {
    if (this.detectingRangeForm.value.detectingRange === this.NOFILTER) {
      this.selectableGasProducts = this.selectedGas!.products;
    } else {
      this.selectableGasProducts = this.selectedGas!.products.filter(
        (product) =>
          product.detectingRange.filter((range) => range.indexOf(this.detectingRangeForm.value.detectingRange) > -1).length > 0,
      );
    }
  }

  private clearProducts() {
    this.setProducts([]);
  }

  private setProducts(products: Product[]) {
    this.selectableProducts = products;
    this.filteredSelectableProducts = products;
  }

  private clearTransmitterSelection() {
    this.selectedTransmitterForm.patchValue({ selectedTransmitter: undefined });
  }
}
