import { Component, EventEmitter, Input, Optional, Output } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { ExportProductListService } from "@components/project-detail-header/export-product-list.service";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { Project } from "@domain/project/project";
import { ProjectService } from "@domain/project/project.service";
import { PdfService } from "@pdf/pdf.service";
import { FloorplanService } from "@project/floorplanner/floorplan.service";
import { ToastHelper } from "@utils/toast-helper.service";
import { LocalizeDatePipe } from "src/locale/localize-date";
import { LocalizeTimePipe } from "src/locale/localize-time";

@Component({
  selector: "app-project-detail-header",
  templateUrl: "./project-detail-header.component.html",
  styleUrls: ["./project-detail-header.component.scss"],
  standalone: true,
  imports: [UiKitModule, LocalizeDatePipe, LocalizeTimePipe],
})
export class ProjectDetailHeaderComponent {
  @Input({ required: true }) project!: Project;
  @Input() activeFloorplan?: Floorplan;
  @Output() navigateBack = new EventEmitter<void>();

  protected floorplanExportEnabled: boolean = false;

  constructor(
    private projectService: ProjectService,
    private toastHelper: ToastHelper,
    private exportProjectService: ExportProductListService,
    private pdfService: PdfService,
    @Optional() private floorplanService: FloorplanService,
  ) {
    this.floorplanExportEnabled = !!this.floorplanService;
  }

  exportProjectToFile(project: Project) {
    this.projectService.exportProjectToFile(project);
    this.toastHelper.success(
      $localize`:@@toast.projectExport.exportSuccessful:Projekt '${project.name}:projectName:' wurde erfolgreich auf ihren Computer exportiert`,
    );
  }

  exportProductList(project: Project) {
    this.exportProjectService.export(project);
  }

  exportProjectToPdf(project: Project) {
    this.pdfService.exportProjectToPdf(project).subscribe(({ pdf, fileName }) => pdf.save(fileName));
  }

  exportFloorplanToPdf() {
    if (!this.activeFloorplan) {
      return;
    }
    this.pdfService.exportFloorplanToPdf(this.activeFloorplan).subscribe(({ pdf, fileName }) => pdf.save(fileName));
  }

  onNavigateBack() {
    this.navigateBack.emit();
  }
}
