<odx-modal-header>
  <odx-area-header i18n="@@modal.importedProjectExists.header">Das zu importierende Projekt existiert bereits</odx-area-header>
</odx-modal-header>
<odx-modal-content>
  <p *ngIf="newProjectCreationEnabled" i18n="@@modal.importedProjectExists.option1">
    Drücken Sie "Neues Projekt", um ein neues Projekt aus den importierten Daten zu erstellen.
  </p>
  <br />
  <p i18n="@@modal.importedProjectExists.option2">
    Drücken Sie "Projekt überschreiben", um das existierende Projekt mit den importierten Daten zu überschreiben.
  </p>
</odx-modal-content>
<odx-modal-footer>
  <button odxButton variant="secondary" odxModalDismiss i18n="@@global.dismiss">Abbrechen</button>
  <button
    *ngIf="newProjectCreationEnabled"
    odxButton
    variant="secondary"
    (click)="onConfirmNewProject()"
    i18n="@@modal.importedProjectExists.button.newProject"
  >
    Neues Projekt
  </button>
  <button odxButton variant="primary" (click)="onConfirmOverride()" i18n="@@modal.importedProjectExists.button.overwriteProject">
    Projekt überschreiben
  </button>
</odx-modal-footer>
