<div class="h-100 odx-bar-parent">
  <app-new-project-stepper #stepper>
    <cdk-step i18n-label="@@newProject.stepperLabel.projectInformation" label="Projektinformation">
      <div odxLayout="flex" class="flex-column h-100 overflow-hidden">
        <odx-area-header
          ><h5 class="odx-title-5" i18n="@@newProject.header.projectInformation">Projektinformation</h5></odx-area-header
        >
        <div class="scroll project-info">
          <div odxLayout="grid 4@tablet 12@phone">
            <app-project-form (projectFormEmitter$)="projectForm = $event" #projectFormComponent></app-project-form>
            <div>
              <app-customer-form (customerFormEmitter$)="customerForm = $event" #customerFormComponent></app-customer-form>
              <app-goods-recipient-form
                (goodsRecipientFormEmitter$)="goodsRecipientForm = $event"
                #goodsRecipientFormComponent
              ></app-goods-recipient-form>
            </div>
            <app-contact-person-form
              (contactPersonFormEmitter$)="contactPersonForm = $event"
              #contactPersonFormComponent
            ></app-contact-person-form>
          </div>
        </div>
      </div>
    </cdk-step>

    <cdk-step i18n-label="@@newProject.stepperLabel.floorplans" label="Grundrisspläne">
      <app-new-project-floorplan-upload
        (floorplanFormEmitter$)="floorplanForm = $event"
        #floorplanFormComponent
      ></app-new-project-floorplan-upload>
    </cdk-step>

    <cdk-step i18n-label="@@newProject.stepperLabel.images" label="Fotos">
      <app-new-project-image-upload (imageFormEmitter$)="imageForm = $event" #imageFormComponent></app-new-project-image-upload>
    </cdk-step>
  </app-new-project-stepper>

  <odx-bar>
    <button odxBarButton (click)="stepper.previous()" [disabled]="stepper.selectedIndex === 0">
      <odx-icon size="large" name="arrow-left"></odx-icon>
    </button>
    <label odxBarLabel i18n="@@newProject.stepperNavigation.label"
      >Schritt {{ stepper.selectedIndex + 1 }} von {{ stepper.steps.length }}</label
    >
    <button odxBarButton variant="small" routerLink="">
      <odx-icon size="large" name="close"></odx-icon>
    </button>
    <button odxBarButton (click)="onSubmit()">
      <odx-icon size="large" name="arrow-right"></odx-icon>
    </button>
  </odx-bar>
</div>
