<div *ngIf="crmEntry$ | async as crmEntry; else loading" odxLayout="flex" class="container flex-column">
  <odx-area-header>
    <h5 class="odx-title-5" i18n="@@crmProject.header" data-testid="heading.importCrm">
      CRM Kontakt in existierendes Projekt importieren
    </h5>
    <odx-area-header-content>
      <button
        odxButton
        variant="primary"
        routerLink="/new-project"
        odxLayout="flex gap-small"
        [state]="{ crmEntry }"
        data-testid="btn.newCrmProject"
      >
        <odx-icon name="plus"></odx-icon>
        <span i18n="@@crmProject.button.createNewProject">Neues Projekt mit CRM Kontakt erstellen</span>
      </button>
    </odx-area-header-content>
  </odx-area-header>

  <div class="scroll h-100">
    <div odxLayout="grid 3@desktop 4@tablet 12@phone">
      <app-project-card
        *ngFor="let project of projects$ | async"
        [project]="project"
        (click)="linkCrmEntryToProject(crmEntry, project)"
        [showMenu]="false"
        [linkToProject]="false"
      ></app-project-card>
    </div>
  </div>
</div>

<ng-template #loading>
  <p [odxLoadingSpinner]="true" [odxLoadingSpinnerMinHeight]="500"></p>
</ng-template>
