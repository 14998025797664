<odx-card class="container" (interact)="onClick()" variant="default">
  {{ project.customer.name }}
  <odx-card-content>
    <h5 class="odx-title-4">
      {{ project.name }}
    </h5>
    <h5 class="odx-subtitle-5">
      {{ project.placeName }}
    </h5>
  </odx-card-content>
  <odx-card-footer>
    <p class="odx-text--small status-text">
      <odx-icon name="save" inline></odx-icon>
      <span i18n="@@projectCard.lastModified">
        Letzte Änderung:
        {{ project.lastModified | localizeDate }} um
        {{ project.lastModified | localizeTime }}
      </span>
    </p>
    <p class="odx-text--small status-text cloud-save">
      <odx-icon name="cloud" inline></odx-icon>
      <span i18n="@@projectCard.lastSynced.prefix"> Letzte Cloud Synchronisierung: </span>
      <span *ngIf="project.lastCloudSync" i18n="@@projectCard.lastSynced.dateAndTime">
        {{ project.lastCloudSync | localizeDate }} um
        {{ project.lastCloudSync | localizeTime }}
      </span>
      <span *ngIf="!project.lastCloudSync" i18n="@@projectCard.lastSynced.never">-- </span>
    </p>
  </odx-card-footer>

  <odx-menu *ngIf="showMenu">
    <button
      odxButton
      odxMenuItem
      (click)="$event.stopPropagation(); exportProjectToFile(project)"
      data-testid="btn.exportProjectCopy"
    >
      <odx-icon name="copy-file" alignleft></odx-icon>
      <span i18n="@@projectCard.button.exportCopy">Kopie auf meinen Computer exportieren</span>
    </button>
    <button odxButton odxMenuItem (click)="$event.stopPropagation(); exportProjectToPdf(project)">
      <odx-icon name="file-pdf" alignleft></odx-icon>
      <span i18n="@@projectCard.button.exportToPdf">Projekt als PDF exportieren</span>
    </button>
    <button odxButton odxMenuItem (click)="$event.stopPropagation(); onDelete()" data-testid="btn.deleteProject">
      <odx-icon name="delete" alignleft></odx-icon>
      <span i18n="@@projectCard.button.deleteProject">Projekt löschen</span>
    </button>
  </odx-menu>
</odx-card>
