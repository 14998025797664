import { Pipe, PipeTransform } from "@angular/core";

export enum FloorplanMenuType {
  MAIN_SETTINGS,
  ZONE,
  GAS_WARNING_CENTER,
  TRANSMITTER,
  SIGNAL_ELEMENT,
  PLASTIC_SIGN,
  ALARM_DEVICE,
  IMAGE,
  PIPELINE,
  AIR_PATH,
  TEXT,
  MEASUREMENT_LINE,
}

@Pipe({
  standalone: true,
  name: "localizeFloorplanMenuType",
})
export class LocalizeFloorplanMenuTypePipe implements PipeTransform {
  private readonly menuTypeToLocalizedStringMap = {
    [FloorplanMenuType.MAIN_SETTINGS]: $localize`:@@floorplan.menu.mainSettings.header:Allgemeine Einstellungen`,
    [FloorplanMenuType.ZONE]: $localize`:@@global.zones:Zonen`,
    [FloorplanMenuType.GAS_WARNING_CENTER]: $localize`:@@global.gasWarningCenters:Gaswarnzentralen`,
    [FloorplanMenuType.TRANSMITTER]: $localize`:@@global.transmitters:Transmitter`,
    [FloorplanMenuType.SIGNAL_ELEMENT]: $localize`:@@global.signalElements:Leuchttransparente`,
    [FloorplanMenuType.PLASTIC_SIGN]: $localize`:@@global.plasticSigns:Kunststoffschilder`,
    [FloorplanMenuType.ALARM_DEVICE]: $localize`:@@global.alarmDevices:Alarmmittel`,
    [FloorplanMenuType.IMAGE]: $localize`:@@global.images:Fotos`,
    [FloorplanMenuType.PIPELINE]: $localize`:@@global.pipelines:Rohrleitungen`,
    [FloorplanMenuType.AIR_PATH]: $localize`:@@global.airPaths:Zu-/Abluftwege`,
    [FloorplanMenuType.TEXT]: $localize`:@@global.notes:Notizen`,
    [FloorplanMenuType.MEASUREMENT_LINE]: $localize`:@@global.measurements:Bemaßungen`,
  };

  transform(value: FloorplanMenuType): string {
    return this.menuTypeToLocalizedStringMap[value];
  }
}
