import { Component, inject, OnInit } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { Modal, ModalRef } from "@odx/angular/components/modal";

@Component({
  selector: "app-not-recommended-modal",
  templateUrl: "./device-not-recommended-modal.component.html",
  styleUrls: ["./device-not-recommended-modal.component.scss"],
  standalone: true,
  imports: [UiKitModule],
})
export class DeviceNotRecommendedModalComponent implements Modal<any, void>, OnInit {
  public readonly modalRef = inject(ModalRef<any, void>);

  introduction = "";
  highlighted = "";
  solution = "";

  ngOnInit(): void {
    this.introduction = this.modalRef.data.introduction;
    this.highlighted = this.modalRef.data.highlighted;
    this.solution = this.modalRef.data.solution;
  }

  onConfirm() {
    this.modalRef.close(true);
  }
}
