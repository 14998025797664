<odx-card
  [menuIcon]="'more'"
  [variant]="'launch-tile'"
  odxLayout="flex"
  [ngClass]="(isDiscontinued$ | async) ? 'configured-item discontinued' : 'configured-item'"
  (click)="onAdd($event)"
>
  <odx-avatar [ngClass]="'avatar'">
    <app-standard-image [imageUrl]="imageUrl" [fallbackImageUrl]="fallbackImageUrl" [iconSize]="'56px'"></app-standard-image>
  </odx-avatar>
  <div class="title" [odxTooltip]="localizedName$ | async" odxTooltipSize="medium">
    {{ localizedName$ | async }}
  </div>
  <odx-launch-card-subtitle>
    <ng-container *ngIf="subtitle$ | async; let subtitle">
      <p class="odx-text--small" [odxTooltip]="subtitle" odxTooltipSize="medium">
        {{ subtitle }}
      </p>
    </ng-container>
    <p class="odx-text--xsmall" i18n="@@floorplan.menu.timesPlaced">{{ numberOfFloorplanItems }}x platziert</p>
  </odx-launch-card-subtitle>
  <odx-card-footer *ngIf="isDiscontinued$ | async">
    <odx-inline-message variant="warning" i18n="@@floorplan.menu.warningDiscontinued"
      >Enthält abgekündigte Komponenten</odx-inline-message
    >
  </odx-card-footer>
  <odx-menu>
    <button odxButton class="odx-button" type="button" (click)="onEditConfig($event)" data-testid="btn.edit">
      <odx-icon name="edit"></odx-icon><span i18n="@@global.edit">Bearbeiten</span>
    </button>
    <button odxButton class="odx-button" type="button" (click)="onDeleteConfig($event)" data-testid="btn.delete">
      <odx-icon name="delete"></odx-icon><span i18n="@@global.delete">Löschen</span>
    </button>
  </odx-menu>
</odx-card>
