<div odxLayout="flex">
  <h6 class="odx-title-6 header-prefix">{{ prefix }}</h6>
  <odx-accordion class="w-100">
    <odx-accordion-item>
      <odx-accordion-item-title>
        <div odxLayout="flex" class="space-between">
          <p class="odx-text">{{ title }}</p>
          <p class="odx-text" *ngIf="showCosts">
            {{ totalCosts | currency: "EUR" }}
          </p>
        </div>
      </odx-accordion-item-title>
      <div *ngFor="let selectedItem of data" class="product-list-item">
        <app-configuration-wizard-header [data]="selectedItem" [showCosts]="showCosts"></app-configuration-wizard-header>
      </div>
    </odx-accordion-item>
  </odx-accordion>
</div>
