import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { STORE_NAME_PROJECTS } from "@app/indexed-db-config";
import { CloudProjectMetadata } from "@domain/project/cloud-project-metadata";
import { Project } from "@domain/project/project";
import { ProjectValidationService } from "@domain/project/project-validation.service";
import { environment } from "@environments/environment";
import { ObservableInstanceMapper } from "@utils/observable-instance-mapper";
import { instanceToPlain, plainToInstance } from "class-transformer";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { Observable, ReplaySubject, Subject, first, interval, map, switchMap, takeUntil, tap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ProjectService implements OnDestroy {
  public static PROJECTS_URL = environment.apiUrl + "/projects";

  private readonly _projects$ = new ReplaySubject<Project[]>(1);
  private _projects!: Project[];
  private readonly destroyed$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private dbService: NgxIndexedDBService,
    private projectValidationService: ProjectValidationService,
  ) {
    this._projects$.subscribe((projects) => (this._projects = projects));

    this.loadProjectsFromDB().subscribe((projects) => {
      this._projects$.next(projects);
    });

    interval(60000)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.syncProjectsWithCloud());
  }

  ngOnDestroy(): void {
    this._projects$.complete();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  get projects$() {
    return this._projects$.asObservable();
  }

  getById(id: string): Observable<Project | undefined> {
    return this.projects$.pipe(
      first(),
      map((projects) => projects.find((project) => project.id === id)),
    );
  }

  createProject(project: Project): Observable<Project> {
    return this.dbService.add(STORE_NAME_PROJECTS, instanceToPlain(project)).pipe(
      map(() => {
        this._projects.push(project);
        this._projects$.next(this._projects);
        return project;
      }),
    );
  }

  update(project: Project): Observable<void> {
    return this.dbService.update(STORE_NAME_PROJECTS, instanceToPlain(project)).pipe(
      map(() => {
        if (this._projects.indexOf(project) > -1) {
          return; // same project instance was updated so this._projects doesn't need to be updated
        }
        this.replaceProjectInstance(project);
      }),
    );
  }

  findByCloudId(cloudId: string): Observable<Project | undefined> {
    return this.projects$.pipe(
      first(),
      map((projects) => projects.find((project) => project.cloudId === cloudId)),
    );
  }

  deleteProject(project: Project): Observable<boolean> {
    return this.dbService.deleteByKey(STORE_NAME_PROJECTS, project.id).pipe(
      tap(() => {
        this.deleteProjectById(project.id);
        this._projects$.next(this._projects);
        return project;
      }),
    );
  }

  exportProjectToFile(project: Project) {
    const plainProject = this.mapAndCleanProjectForExport(project);
    const blob = new Blob([JSON.stringify(plainProject)], {
      type: "application/json",
    });
    this.saveFile(blob, project.name + ".navinta");
  }

  loadProjectFromFile(file: File): Observable<Project> {
    const reader = new FileReader();
    const observable = new Observable<Project>((observer: any) => {
      reader.onload = (event) => {
        if (!event.target) {
          return;
        }
        const project = plainToInstance(Project, [JSON.parse(<string>event.target.result)])[0];

        this.projectValidationService.validateTypes(project).subscribe((errors) => {
          if (errors.length > 0) {
            observer.error(errors);
          } else {
            try {
              this.projectValidationService.validateContent(project);
              observer.next(project);
            } catch (error) {
              observer.error(new Error(`ContentValidationError ${error}`));
            }
          }
          observer.complete();
        });
      };
    });
    reader.readAsText(file);
    return observable;
  }

  exportProjectToCloud(project: Project): Observable<Project> {
    const plainProject = this.mapAndCleanProjectForExport(project);
    let request;

    if (project.cloudId) {
      request = this.http.put(`${ProjectService.PROJECTS_URL}/${project.cloudId}`, plainProject);
    } else {
      request = this.http.post(ProjectService.PROJECTS_URL, plainProject);
    }

    return request.pipe(
      map((result: any) => {
        project.updateCloudSync(result.id);
        return project;
      }),
    );
  }

  loadCloudProjectsMetadata(): Observable<CloudProjectMetadata[]> {
    return ObservableInstanceMapper.valuesToInstance(
      this.http.get(ProjectService.PROJECTS_URL).pipe(
        map((result: any) => {
          return result.map((item: any) => {
            return {
              id: item.id,
              displayName: item.name,
              lastModifiedOn: item.updatedAt,
            };
          });
        }),
      ),
      CloudProjectMetadata,
    );
  }

  loadProjectFromCloud(cloudId: string): Observable<Project> {
    return ObservableInstanceMapper.valueToInstance(
      this.http.get(`${ProjectService.PROJECTS_URL}/${cloudId}`).pipe(
        map((result: any) => {
          return result;
        }),
      ),
      Project,
    );
  }

  deleteProjectFromCloud(cloudId: string): Observable<any> {
    return this.http.delete(`${ProjectService.PROJECTS_URL}/${cloudId}`);
  }

  private mapAndCleanProjectForExport(project: Project): any {
    const plainProject = instanceToPlain(project);
    plainProject["cloudId"] = undefined; // unset cloudId to not save it to cloud
    plainProject["lastCloudSync"] = undefined; // unset lastCloudSync to not save it to cloud
    return plainProject;
  }

  private syncProjectsWithCloud() {
    if (!navigator.onLine) {
      return;
    }
    this.projects$.pipe(first()).subscribe((projects) =>
      projects
        .filter((project) => !project.inSyncWithCloud)
        .forEach((project) => {
          this.exportProjectToCloud(project)
            .pipe(switchMap((syncedProject) => this.update(syncedProject)))
            .subscribe();
        }),
    );
  }

  private saveFile = async (blob: any, suggestedName: any) => {
    const blobURL = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = blobURL;
    a.download = suggestedName;
    a.style.display = "none";
    document.body.append(a);
    a.click();
    setTimeout(() => {
      URL.revokeObjectURL(blobURL);
      a.remove();
    }, 1000);
  };

  private loadProjectsFromDB(): Observable<Project[]> {
    return ObservableInstanceMapper.valuesToInstance(this.dbService.getAll<Project>(STORE_NAME_PROJECTS), Project);
  }

  private replaceProjectInstance(project: Project): void {
    this.deleteProjectById(project.id);
    this._projects.push(project);
    this._projects$.next(this._projects);
  }

  private deleteProjectById(id: string) {
    const index = this._projects.findIndex((p) => p.id === id);
    if (index > -1) {
      this._projects.splice(index, 1);
    }
  }
}
