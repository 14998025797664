export interface Language {
  languageCode: string;
  displayName: string;
}

export class Languages {
  static readonly LANGUAGES: Language[] = [
    { languageCode: "en-US", displayName: "English" },
    { languageCode: "de-DE", displayName: "German" },
  ];
}
