<odx-area-header class="header">
  <button (click)="onNavigateBack()" odxNavigationBack></button>

  <span data-testid="projectDetails.header.projectName">{{ project.name }}</span>

  <odx-area-header-subtitle>
    <span i18n="@@projectDetailHeader.lastModified"
      >Letzte Änderung: {{ (project.lastModified | localizeDate) + ", " + (project.lastModified | localizeTime) }}</span
    >
    <span class="cloud-save">
      <odx-icon name="cloud" inline></odx-icon>
      <span i18n="@@projectDetailHeader.lastSavedToCloud"
        >Letzte Speicherung in der Cloud:
        {{
          project.lastCloudSync ? (project.lastCloudSync | localizeDate) + ", " + (project.lastCloudSync | localizeTime) : "--"
        }}</span
      >
    </span>
  </odx-area-header-subtitle>
  <odx-area-header-content>
    <odx-action-group>
      <button odxButton [odxMenu]="menu" odxMenuPosition="bottom-end" data-testid="btn.export">
        <span i18n="@@projectDetailHeader.button.export">Exportieren</span>
        <odx-icon name="chevron-down" alignRight></odx-icon>
      </button>
    </odx-action-group>
  </odx-area-header-content>
</odx-area-header>

<ng-template #menu>
  <odx-menu>
    <button odxButton odxMenuItem (click)="exportProjectToFile(project)" data-testid="btn.exportProjectCopy">
      <odx-icon name="copy-file" alignLeft></odx-icon>
      <span i18n="@@projectDetailHeader.button.exportProjectCopy">Kopie auf meinen Computer exportieren</span>
    </button>
    <button odxButton odxMenuItem (click)="exportProductList(project)" data-testid="btn.exportProductList">
      <odx-icon name="export" alignLeft></odx-icon>
      <span i18n="@@projectDetailHeader.button.exportProductList">Produktliste exportieren</span>
    </button>
    <button
      odxButton
      odxMenuItem
      (click)="exportProjectToPdf(project)"
      *ngIf="!floorplanExportEnabled"
      data-testid="btn.exportProjectAsPdf"
    >
      <odx-icon name="file-pdf" alignLeft></odx-icon>
      <span i18n="@@projectDetailHeader.button.exportProjectPdf">Projekt als PDF exportieren</span>
    </button>
    <button
      odxButton
      odxMenuItem
      (click)="exportFloorplanToPdf()"
      *ngIf="floorplanExportEnabled"
      data-testid="btn.exportFloorplanAsPdf"
    >
      <odx-icon name="file-pdf" alignLeft></odx-icon>
      <span i18n="@@projectDetailHeader.button.exportFloorplanPdf">Grundrissplan als PDF exportieren</span>
    </button>
  </odx-menu>
</ng-template>
