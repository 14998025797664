import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { Pipeline } from "@domain/project/floorplan/pipeline";
import { FloorplanItemDetailsComponent } from "@project/floorplanner/floorplan-additional-notes/floorplan-item-details/floorplan-item-details.component";

@Component({
  selector: "app-pipeline-notes",
  templateUrl: "./pipeline-notes.component.html",
  styleUrls: ["./pipeline-notes.component.scss"],
  standalone: true,
  imports: [UiKitModule, ReactiveFormsModule, FloorplanItemDetailsComponent],
})
export class PipelineNotesComponent implements OnInit {
  @Input({ required: true }) set floorplanItem(item: FloorplanItem) {
    this.pipeline = item as Pipeline;
  }

  protected pipelineForm!: FormGroup;
  protected pipeline!: Pipeline;
  protected fallbackTitle: string = $localize`:@@floorplan.details.pipeline.header:Rohrleitung`;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.initForm();
  }

  private update(): void {
    this.pipeline.updateMetadata(
      this.pipelineForm.value.diameter,
      this.pipelineForm.value.medium,
      this.pipelineForm.value.temperature,
      this.pipelineForm.value.material,
      this.pipelineForm.value.name,
    );
    this.pipeline.notes = this.pipelineForm.value.notes;
  }

  private initForm() {
    this.pipelineForm = this.formBuilder.group({
      name: [this.pipeline.name],
      diameter: [this.pipeline.diameter],
      medium: [this.pipeline.medium],
      temperature: [this.pipeline.temperature],
      material: [this.pipeline.material],
    });

    this.pipelineForm.valueChanges.subscribe(() => {
      if (!this.pipelineForm.valid || this.pipelineForm.pristine) return;
      this.update();
    });
  }
}
