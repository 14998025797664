import { Product } from "@domain/product/product";
import { ProductConfiguration } from "@domain/project/configurations/product-configuration";
import { Project } from "@domain/project/project";
import { ProjectEventType } from "@domain/project/project-event";
import { IsArray, IsOptional, IsString } from "class-validator";
import { v4 as uuidv4 } from "uuid";

export class TransmitterConfiguration extends ProductConfiguration {
  private static readonly NAME_REGEX = /((?:\bPolytron\b|\bPIR\b)\s\d+)/g;

  @IsString()
  public gasId: string;

  @IsString()
  public productId: string;

  @IsArray()
  @IsString({ each: true })
  public attachmentIds: string[];

  @IsOptional()
  @IsString()
  public sensorId?: string;

  constructor(
    id: string,
    name: string,
    project: Project,
    gasId: string,
    productId: string,
    attachmentIds: string[],
    sensorId?: string,
  ) {
    super(id, name, project);
    this.gasId = gasId;
    this.productId = productId;
    this.attachmentIds = attachmentIds;
    this.sensorId = sensorId;
  }

  static create(
    name: string,
    project: Project,
    gasId: string,
    productId: string,
    attachmentIds: string[],
    sensorId?: string,
  ): TransmitterConfiguration {
    return new TransmitterConfiguration(uuidv4(), name, project, gasId, productId, attachmentIds, sensorId);
  }

  override get productDataName(): string {
    return TransmitterConfiguration.getProductDataName(this.name);
  }

  countPlacedProducts(project = this.project): number {
    return project ? project.countPlacedTransmitters(this) : 0;
  }

  update(gasId: string, transmitter: Product, attachmentIds: string[], sensorId?: string) {
    this.gasId = gasId;
    this.name = transmitter.name;
    this.productId = transmitter.id;
    this.sensorId = sensorId;
    this.attachmentIds = attachmentIds;
    this.project.publishUpdate(ProjectEventType.TRANSMITTER_CONFIGURATION_UPDATED, this);
  }

  override get mainProductId(): string {
    return this.productId;
  }

  override get configuredProductIds(): string[] {
    const productIds = this.attachmentIds.concat(this.productId);
    if (this.sensorId) {
      productIds.push(this.sensorId);
    }
    return productIds;
  }

  static getProductDataName(name: string) {
    const regexMatch = name.match(TransmitterConfiguration.NAME_REGEX);
    if (!regexMatch || !regexMatch.length) {
      return "";
    }
    return regexMatch[0].replace(" ", "_");
  }
}
