import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular";
import "reflect-metadata"; // needed for class-transformer
import { AppModule } from "@app/app.module";
import { environment } from "@environments/environment";
import '@angular/localize/init';

if (environment.production) {
  enableProdMode();
}

if (environment.googleTagManagerId?.length) {
  const script1 = document.createElement("script");
  script1.async = true;
  script1.src = "https://www.googletagmanager.com/gtag/js?id=" + environment.googleTagManagerId;

  const script2 = document.createElement("script");
  script2.innerHTML =
    'window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag("js", new Date());gtag("config", "' +
    environment.googleTagManagerId +
    '");';

  document.head.appendChild(script1);
  document.head.appendChild(script2);
}

initSentry();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

function initSentry() {
  if (environment.sentryEnvironment && environment.sentryIngestUrl) {
    Sentry.init({
      dsn: environment.sentryIngestUrl,
      integrations: [],
      environment: environment.sentryEnvironment,
    });
  }
}
