import { Component, EventEmitter, inject, OnDestroy, OnInit, Output, ViewChildren } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { ConfirmModalComponent } from "@components/confirm-modal/confirm-modal.component";
import { ImageUploadOverlayComponent } from "@components/image-upload-overlay/image-upload-overlay.component";
import { NewProjectFileCardComponent } from "@components/project-cards/new-project-file-card/new-project-file-card.component";
import { ProjectFormsService } from "@components/project-forms/project-forms.service";
import { ProjectImage } from "@domain/project/project-image";
import { ModalService } from "@odx/angular/components/modal";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-new-project-image-upload",
  templateUrl: "./new-project-image-upload.component.html",
  styleUrls: ["./new-project-image-upload.component.scss"],
  standalone: true,
  imports: [UiKitModule, ReactiveFormsModule, ImageUploadOverlayComponent, NewProjectFileCardComponent],
})
export class NewProjectImageUploadComponent implements OnInit, OnDestroy {
  private readonly modalService = inject(ModalService);

  @ViewChildren("imageCard")
  imageCards!: NewProjectFileCardComponent[];

  images: ProjectImage[] = [];

  @Output() imageFormEmitter$ = new EventEmitter<FormGroup>();
  imageForm!: FormGroup;

  private readonly _destroying = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private projectFormsService: ProjectFormsService,
  ) {
    this.imageForm = this.formBuilder.group({
      images: this.formBuilder.array([]),
    });
    this.projectFormsService.projectReset$.pipe(takeUntil(this._destroying)).subscribe(() => {
      this.imageForm.reset();
    });

    this.imageForm.statusChanges.pipe(takeUntil(this._destroying)).subscribe(() => this.imageFormEmitter$.emit(this.imageForm));
  }

  ngOnInit(): void {
    this.imageFormEmitter$.emit(this.imageForm);
  }

  ngOnDestroy(): void {
    this._destroying.next();
    this._destroying.complete();
  }

  triggerSubmit() {
    this.imageCards.forEach((card) => card.triggerSubmit());
  }

  onCardSubmit(cardForm: FormGroup, image: ProjectImage) {
    image.name = cardForm.value.name;
    this.imagesForm.push(cardForm);
  }

  onImageUploaded(projectImages: ProjectImage[]) {
    this.images.push(...projectImages);
  }

  onDelete(image: ProjectImage) {
    this.modalService
      .open(ConfirmModalComponent, {
        data: {
          text: $localize`:@@modal.deleteImage:Wollen Sie das Bild wirklich löschen?`,
        },
      })
      .onClose$.subscribe(() => {
        this.deleteImageFromList(image);
      });
  }

  getImages(): ProjectImage[] {
    return this.images;
  }

  private deleteImageFromList(image: ProjectImage) {
    this.images = this.images.filter((img) => image !== img);
  }

  private get imagesForm() {
    return this.imageForm.controls["images"] as FormArray;
  }
}
