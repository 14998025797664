import { Injectable, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ProductService } from "@domain/product/product.service";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { FloorplanEvent, FloorplanEventType } from "@domain/project/floorplan/floorplan-event";
import { Project } from "@domain/project/project";
import { ProjectEvent, ProjectEventType } from "@domain/project/project-event";
import { ProjectUpdatePublisher } from "@domain/project/project-update-publisher";
import { ProjectService } from "@domain/project/project.service";
import { Observable, ReplaySubject, Subject, filter, map, switchMap } from "rxjs";

@Injectable()
export class ProjectStateService implements ProjectUpdatePublisher, OnDestroy {
  private projectEvents = new Subject<ProjectEvent>();
  private floorplanEvents = new Subject<FloorplanEvent>();
  private discontinuedConfigIds = new ReplaySubject<string[]>(1);

  private readonly project: Project;

  constructor(
    private projectService: ProjectService,
    private productService: ProductService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.project = this.activatedRoute.snapshot.data["project"];
    this.init();
    this.updateDiscontinuedConfigIds();
    this.initProjectEventHandler();
  }

  get projectEvents$(): Observable<ProjectEvent> {
    return this.projectEvents.asObservable();
  }

  get floorplanEvents$(): Observable<FloorplanEvent> {
    return this.floorplanEvents.asObservable();
  }

  get discontinuedConfigIds$() {
    return this.discontinuedConfigIds.asObservable();
  }

  isConfigDiscontinued(configId: string): Observable<boolean> {
    return this.discontinuedConfigIds.pipe(map((discontinuedConfigIds) => discontinuedConfigIds.includes(configId)));
  }

  publishProjectEvent(type: ProjectEventType, subject: any) {
    this.projectEvents.next({ project: this.project, type, subject });
  }

  publishFloorplanEvent(floorplan: Floorplan, type: FloorplanEventType, subject: any) {
    this.floorplanEvents.next({ project: this.project, floorplan, type, subject });
  }

  updateDiscontinuedConfigIds() {
    this.productService.getDiscontinuedProductIds().subscribe((discontinuedProductIds) => {
      const discontinuedConfigIds = this.project.productConfigurations
        .filter((config) =>
          config.configuredProductIds.some((configuredProductId) => discontinuedProductIds.includes(configuredProductId)),
        )
        .map((config) => config.id);
      this.discontinuedConfigIds.next(discontinuedConfigIds);
    });
  }

  ngOnDestroy() {
    this.publishProjectEvent(ProjectEventType.PROJECT_CLOSED, this.project);
    this.project.updatePublisher = undefined;
    this.projectEvents.complete();
    this.floorplanEvents.complete();
    this.discontinuedConfigIds.complete();
  }

  private init() {
    this.project.updatePublisher = this;
    this.publishProjectEvent(ProjectEventType.PROJECT_OPENED, this.project);

    this.projectEvents$
      .pipe(
        filter((event) => event.type !== ProjectEventType.PROJECT_OPENED && event.type !== ProjectEventType.PROJECT_CLOSED),
        switchMap((event) => {
          console.debug("received project event", event);
          return this.projectService.update(event.project);
        }),
      )
      .subscribe();
    this.floorplanEvents$
      .pipe(
        switchMap((event) => {
          console.debug("received floorplan event", event);
          return this.projectService.update(event.project);
        }),
      )
      .subscribe();
  }

  private initProjectEventHandler() {
    this.projectEvents$.subscribe((event) => {
      switch (event.type) {
        case ProjectEventType.GAS_WARNING_CENTER_CONFIGURATION_UPDATED:
        case ProjectEventType.TRANSMITTER_CONFIGURATION_UPDATED:
        case ProjectEventType.ALARM_DEVICE_CONFIGURATION_UPDATED:
        case ProjectEventType.PLASTIC_SIGN_CONFIGURATION_UPDATED:
        case ProjectEventType.SIGNAL_ELEMENT_CONFIGURATION_UPDATED:
          this.updateDiscontinuedConfigIds();
          break;
        case ProjectEventType.PLACEHOLDER_PRODUCTS_UPDATED:
          this.project.setPositionNumbers();
          break;
      }
    });
  }
}
