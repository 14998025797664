<odx-modal-header>
  <odx-area-header i18n="@@modal.selectCloudProject.header">Bitte Cloud-Projekt auswählen</odx-area-header>
</odx-modal-header>
<odx-modal-content>
  <app-cloud-projects-odx-table
    *ngIf="cloudProjects$ | async as cloudProjects; else loading"
    [cloudProjectMetadata]="cloudProjects"
    [variant]="'import'"
    (projectSelected)="selectedProject = $event"
  ></app-cloud-projects-odx-table>
  <ng-template #loading>
    <p [odxLoadingSpinner]="true" [odxLoadingSpinnerMinHeight]="500"></p>
  </ng-template>
</odx-modal-content>
<odx-modal-footer>
  <button odxButton variant="secondary" odxModalDismiss i18n="@@global.dismiss">Abbrechen</button>
  <button odxButton variant="primary" (click)="onConfirm()" i18n="@@modal.selectCloudProject.button.select">Auswählen</button>
</odx-modal-footer>
