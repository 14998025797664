import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import Konva from "konva";

export class PositionIdTextKonva {
  static init(element: FloorplanItem): Konva.Text {
    return new Konva.Text({
      text: element.positionId,
      fontSize: 16,
      fill: "#002766",
    });
  }
}
