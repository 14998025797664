import { Pipe, PipeTransform } from "@angular/core";

interface DeviceClassToLocalizedStringMap {
  [key: string]: string;
}

export enum VoltageClass {
  VOLT_24 = "24V",
  VOLT_230 = "230V",
}

export enum SafetyClass {
  EX = "Ex-Alarmmittel",
  NON_EX = "Non-Ex-Alarmmittel",
}

@Pipe({
  standalone: true,
  name: "localizeDeviceClass",
})
export class LocalizeDeviceClassPipe implements PipeTransform {
  private readonly deviceClassToLocalizedStringMap: DeviceClassToLocalizedStringMap = {
    EX: $localize`:@@alarmDeviceConfiguration.safetyClass.ex:Ex-Alarmmittel`,
    NON_EX: $localize`:@@alarmDeviceConfiguration.safetyClass.nonEx:Non-Ex-Alarmmittel`,
    VOLT_24: "24V",
    VOLT_230: "230V",
  };

  transform(value: string): string {
    return this.deviceClassToLocalizedStringMap[value];
  }
}
