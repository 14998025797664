import { IsPhoneNumberWithoutRegionCheck } from "@utils/class-validator/class-validator-constraints";
import { IsEmail, IsOptional, IsString } from "class-validator";

export class ContactPerson {
  @IsString()
  @IsOptional()
  public firstname?: string;

  @IsString()
  @IsOptional()
  public lastname?: string;

  @IsString()
  @IsOptional()
  public department?: string;

  @IsEmail()
  @IsOptional()
  public email?: string;

  @IsPhoneNumberWithoutRegionCheck()
  @IsOptional()
  public phoneNumber?: string;

  constructor(firstname?: string, lastname?: string, department?: string, email?: string, phoneNumber?: string) {
    this.firstname = firstname;
    this.lastname = lastname;
    this.department = department;
    this.email = email;
    this.phoneNumber = phoneNumber;
  }
}
