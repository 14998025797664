<app-floorplan-upload-overlay (floorplan$)="onPlanUploaded($event)">
  <div class="container scroll h-100 p-0-24-24">
    <!-- prettier-ignore -->
    <h6 class="odx-subtitle-6" *ngIf="project.floorplans.length === 0" i18n="@@floorplanUpload.noFloorplanYet">Sie haben noch keinen Grundrissplan hinzugefügt.</h6>
    <div odxLayout="grid 3@desktop 4@tablet 6@phone">
      <app-project-detail-file-card
        *ngFor="let plan of project.floorplans"
        (delete$)="onDelete(plan)"
        (submit$)="onCardSubmit($event, plan)"
        (click$)="openFloorplan(plan)"
        [fileUrl]="plan.fileUrl!"
        [fileName]="plan.name"
      >
      </app-project-detail-file-card>
    </div>
  </div>
</app-floorplan-upload-overlay>
