import { Floorplan } from "@domain/project/floorplan/floorplan";
import { FloorplanEventType } from "@domain/project/floorplan/floorplan-event";
import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { Point } from "@utils/point";
import { Exclude, Expose, Transform } from "class-transformer";
import { IsBoolean, IsNumber, IsPositive } from "class-validator";

export class FloorplanState {
  private static readonly SCALE_FACTOR = 1.2;
  @Exclude()
  private _floorplan?: Floorplan;

  @IsNumber()
  @Expose({ name: "x" })
  @Transform((params) => params.value || 0, { toClassOnly: true })
  private _x: number = 0;

  @IsNumber()
  @Expose({ name: "y" })
  @Transform((params) => params.value || 0, { toClassOnly: true })
  private _y: number = 0;

  @IsNumber()
  @Expose({ name: "borderWidth" })
  @Transform((params) => params.value || 0, { toClassOnly: true })
  private _borderWidth: number = 0;

  @IsNumber()
  @Expose({ name: "borderHeight" })
  @Transform((params) => params.value || 0, { toClassOnly: true })
  private _borderHeight: number = 0;

  @IsNumber()
  @IsPositive({ message: "The value must be higher than 0" })
  @Expose({ name: "scale" })
  private _scale: number = 1;

  @IsBoolean()
  @Expose({ name: "exZones" })
  private _exZonesVisible: boolean = true;

  @IsBoolean()
  @Expose({ name: "monitoringAreas" })
  private _monitoringAreasVisible: boolean = false;

  @IsBoolean()
  @Expose({ name: "dangerAreas" })
  private _dangerAreasVisible: boolean = true;

  @IsBoolean()
  @Expose({ name: "images" })
  private _imagesVisible: boolean = true;

  @IsBoolean()
  @Expose({ name: "gasWarningCenters" })
  private _gasWarningCentersVisible: boolean = true;

  @IsBoolean()
  @Expose({ name: "transmitters" })
  private _transmittersVisible: boolean = true;

  @IsBoolean()
  @Expose({ name: "alarmDevices" })
  private _alarmDevicesVisible: boolean = true;

  @IsBoolean()
  @Expose({ name: "signalElements" })
  private _signalElementsVisible: boolean = true;

  @IsBoolean()
  @Expose({ name: "plasticSigns" })
  private _plasticSignsVisible: boolean = true;

  @IsBoolean()
  @Expose({ name: "floorplanTexts" })
  private _floorplanTextsVisible: boolean = true;

  @IsBoolean()
  @Expose({ name: "measurementLines" })
  private _measurementLinesVisible: boolean = true;

  @IsBoolean()
  @Expose({ name: "airPaths" })
  private _airPathsVisible: boolean = true;

  @IsBoolean()
  @Expose({ name: "pipelines" })
  private _pipelinesVisible: boolean = true;

  @IsBoolean()
  @Expose({ name: "exZonesLocked" })
  private _exZonesLocked: boolean = false;

  @IsBoolean()
  @Expose({ name: "dangerAreasLocked" })
  private _dangerAreasLocked: boolean = false;

  @IsBoolean()
  @Expose({ name: "imagesLocked" })
  private _imagesLocked: boolean = false;

  @IsBoolean()
  @Expose({ name: "gasWarningCentersLocked" })
  private _gasWarningCentersLocked: boolean = false;

  @IsBoolean()
  @Expose({ name: "transmittersLocked" })
  private _transmittersLocked: boolean = false;

  @IsBoolean()
  @Expose({ name: "alarmDevicesLocked" })
  private _alarmDevicesLocked: boolean = false;

  @IsBoolean()
  @Expose({ name: "signalElementsLocked" })
  private _signalElementsLocked: boolean = false;

  @IsBoolean()
  @Expose({ name: "plasticSignsLocked" })
  private _plasticSignsLocked: boolean = false;

  @IsBoolean()
  @Expose({ name: "floorplanTextsLocked" })
  private _floorplanTextsLocked: boolean = false;

  @IsBoolean()
  @Expose({ name: "measurementLinesLocked" })
  private _measurementLinesLocked: boolean = false;

  @IsBoolean()
  @Expose({ name: "airPathsLocked" })
  private _airPathsLocked: boolean = false;

  @IsBoolean()
  @Expose({ name: "pipelinesLocked" })
  private _pipelinesLocked: boolean = false;

  init(floorplan: Floorplan) {
    this._floorplan = floorplan;
  }

  setVisibilityForAll(visible: boolean) {
    this.exZonesVisible = visible;
    this.monitoringAreasVisible = visible;
    this.dangerAreasVisible = visible;
    this.imagesVisible = visible;
    this.measurementLinesVisible = visible;
    this.airPathsVisible = visible;
    this.pipelinesVisible = visible;
    this.floorplanTextsVisible = visible;
    this.transmittersVisible = visible;
    this.alarmDevicesVisible = visible;
    this.gasWarningCentersVisible = visible;
    this.signalElementsVisible = visible;
    this.plasticSignsVisible = visible;
  }

  setLockForAll(locked: boolean) {
    this.exZonesLocked = locked;
    this.dangerAreasLocked = locked;
    this.imagesLocked = locked;
    this.measurementLinesLocked = locked;
    this.airPathsLocked = locked;
    this.pipelinesLocked = locked;
    this.floorplanTextsLocked = locked;
    this.transmittersLocked = locked;
    this.alarmDevicesLocked = locked;
    this.gasWarningCentersLocked = locked;
    this.signalElementsLocked = locked;
    this.plasticSignsLocked = locked;
  }

  zoomIn(referencePoint: Point) {
    this.scaleAndUpdatePosition(FloorplanState.SCALE_FACTOR, referencePoint);
  }

  zoomOut(referencePoint: Point) {
    this.scaleAndUpdatePosition(1 / FloorplanState.SCALE_FACTOR, referencePoint);
  }

  updatePosition(x: number, y: number) {
    this._x = x;
    this._y = y;
    this.floorplan.publishUpdate(FloorplanEventType.FLOORPLAN_STATE_POSITION_UPDATED, this);
  }

  updateBorderShape(borderShape: { width: number; height: number }) {
    this._borderWidth = borderShape.width;
    this._borderHeight = borderShape.height;
    this.floorplan.publishUpdate(FloorplanEventType.FLOORPLAN_STATE_BORDER_SHAPE_UPDATED, this);
  }

  updateScaleAndPosition(scale: number, x: number, y: number) {
    this._scale = scale;
    this.floorplan.publishUpdate(FloorplanEventType.FLOORPLAN_STATE_SCALE_UPDATED, this);
    this.updatePosition(x, y);
  }

  get floorplan(): Floorplan {
    if (!this._floorplan) {
      throw Error("Floorplan must be set before accessing the state");
    }
    return this._floorplan;
  }

  get scale(): number {
    return this._scale;
  }

  get x(): number {
    return this._x;
  }

  get y(): number {
    return this._y;
  }

  get borderWidth(): number {
    return this._borderWidth;
  }

  get borderHeight(): number {
    return this._borderHeight;
  }

  get monitoringAreasVisible(): boolean {
    return this._monitoringAreasVisible;
  }

  set monitoringAreasVisible(visible: boolean) {
    if (this._monitoringAreasVisible === visible) return;
    this._monitoringAreasVisible = visible;
    this.publishVisibilityChanged();
  }

  get exZonesVisible(): boolean {
    return this._exZonesVisible;
  }

  set exZonesVisible(visible: boolean) {
    if (this._exZonesVisible === visible) return;
    this._exZonesVisible = visible;
    this.publishVisibilityChanged();
  }

  get exZonesLocked(): boolean {
    return this._exZonesLocked;
  }

  set exZonesLocked(locked: boolean) {
    if (this._exZonesLocked === locked) return;
    this._exZonesLocked = locked;
    this.updateLock(this.floorplan.exZones, locked);
  }

  refreshExZonesLock() {
    this.refreshLock(this.floorplan.exZones, this._exZonesLocked, (locked) => (this._exZonesLocked = locked));
  }

  get dangerAreasVisible(): boolean {
    return this._dangerAreasVisible;
  }

  set dangerAreasVisible(visible: boolean) {
    if (this._dangerAreasVisible === visible) return;
    this._dangerAreasVisible = visible;
    this.publishVisibilityChanged();
  }

  get dangerAreasLocked(): boolean {
    return this._dangerAreasLocked;
  }

  set dangerAreasLocked(locked: boolean) {
    if (this._dangerAreasLocked === locked) return;
    this._dangerAreasLocked = locked;
    this.updateLock(this.floorplan.dangerAreas, locked);
  }

  refreshDangerAreasLock() {
    this.refreshLock(this.floorplan.dangerAreas, this._dangerAreasLocked, (locked) => (this._dangerAreasLocked = locked));
  }

  get gasWarningCentersVisible(): boolean {
    return this._gasWarningCentersVisible;
  }

  set gasWarningCentersVisible(visible: boolean) {
    if (this._gasWarningCentersVisible === visible) return;
    this._gasWarningCentersVisible = visible;
    this.publishVisibilityChanged();
  }

  get gasWarningCentersLocked(): boolean {
    return this._gasWarningCentersLocked;
  }

  set gasWarningCentersLocked(locked: boolean) {
    if (this._gasWarningCentersLocked === locked) return;
    this._gasWarningCentersLocked = locked;
    this.updateLock([...this.floorplan.gasWarningCenters, ...this.floorplan.gasWarningCenterPlaceholders], locked);
  }

  refreshGasWarningCentersLock() {
    this.refreshLock(
      [...this.floorplan.gasWarningCenters, ...this.floorplan.gasWarningCenterPlaceholders],
      this._gasWarningCentersLocked,
      (locked) => (this._gasWarningCentersLocked = locked),
    );
  }

  get transmittersVisible(): boolean {
    return this._transmittersVisible;
  }

  set transmittersVisible(visible: boolean) {
    if (this._transmittersVisible === visible) return;
    this._transmittersVisible = visible;
    this.publishVisibilityChanged();
  }

  get transmittersLocked(): boolean {
    return this._transmittersLocked;
  }

  set transmittersLocked(locked: boolean) {
    if (this._transmittersLocked === locked) return;
    this._transmittersLocked = locked;
    this.updateLock([...this.floorplan.transmitters, ...this.floorplan.transmitterPlaceholders], locked);
  }

  refreshTransmittersLock() {
    this.refreshLock(
      [...this.floorplan.transmitters, ...this.floorplan.transmitterPlaceholders],
      this._transmittersLocked,
      (locked) => (this._transmittersLocked = locked),
    );
  }

  get alarmDevicesVisible(): boolean {
    return this._alarmDevicesVisible;
  }

  set alarmDevicesVisible(visible: boolean) {
    if (this._alarmDevicesVisible === visible) return;
    this._alarmDevicesVisible = visible;
    this.publishVisibilityChanged();
  }

  get alarmDevicesLocked(): boolean {
    return this._alarmDevicesLocked;
  }

  set alarmDevicesLocked(locked: boolean) {
    if (this._alarmDevicesLocked === locked) return;
    this._alarmDevicesLocked = locked;
    this.updateLock([...this.floorplan.alarmDevices, ...this.floorplan.alarmDevicePlaceholders], locked);
  }

  refreshAlarmDevicesLock() {
    this.refreshLock(
      [...this.floorplan.alarmDevices, ...this.floorplan.alarmDevicePlaceholders],
      this._alarmDevicesLocked,
      (locked) => (this._alarmDevicesLocked = locked),
    );
  }

  get signalElementsVisible(): boolean {
    return this._signalElementsVisible;
  }

  set signalElementsVisible(visible: boolean) {
    if (this._signalElementsVisible === visible) return;
    this._signalElementsVisible = visible;
    this.publishVisibilityChanged();
  }

  get signalElementsLocked(): boolean {
    return this._signalElementsLocked;
  }

  set signalElementsLocked(locked: boolean) {
    if (this._signalElementsLocked === locked) return;
    this._signalElementsLocked = locked;
    this.updateLock([...this.floorplan.signalElements, ...this.floorplan.signalElementPlaceholders], locked);
  }

  refreshSignalElementsLock() {
    this.refreshLock(
      [...this.floorplan.signalElements, ...this.floorplan.signalElementPlaceholders],
      this._signalElementsLocked,
      (locked) => (this._signalElementsLocked = locked),
    );
  }

  get plasticSignsVisible(): boolean {
    return this._plasticSignsVisible;
  }

  set plasticSignsVisible(visible: boolean) {
    if (this._plasticSignsVisible === visible) return;
    this._plasticSignsVisible = visible;
    this.publishVisibilityChanged();
  }

  get plasticSignsLocked(): boolean {
    return this._plasticSignsLocked;
  }

  set plasticSignsLocked(locked: boolean) {
    if (this._plasticSignsLocked === locked) return;
    this._plasticSignsLocked = locked;
    this.updateLock([...this.floorplan.plasticSigns, ...this.floorplan.plasticSignPlaceholders], locked);
  }

  refreshPlasticSignsLock() {
    this.refreshLock(
      [...this.floorplan.plasticSigns, ...this.floorplan.plasticSignPlaceholders],
      this._plasticSignsLocked,
      (locked) => (this._plasticSignsLocked = locked),
    );
  }

  get imagesVisible(): boolean {
    return this._imagesVisible;
  }

  set imagesVisible(visible: boolean) {
    if (this._imagesVisible === visible) return;
    this._imagesVisible = visible;
    this.publishVisibilityChanged();
  }

  get imagesLocked(): boolean {
    return this._imagesLocked;
  }

  set imagesLocked(locked: boolean) {
    if (this._imagesLocked === locked) return;
    this._imagesLocked = locked;
    this.updateLock(this.floorplan.images, locked);
  }

  refreshImagesLock() {
    this.refreshLock(this.floorplan.images, this._imagesLocked, (locked) => (this._imagesLocked = locked));
  }

  get floorplanTextsVisible(): boolean {
    return this._floorplanTextsVisible;
  }

  set floorplanTextsVisible(visible: boolean) {
    if (this._floorplanTextsVisible === visible) return;
    this._floorplanTextsVisible = visible;
    this.publishVisibilityChanged();
  }

  get floorplanTextsLocked(): boolean {
    return this._floorplanTextsLocked;
  }

  set floorplanTextsLocked(locked: boolean) {
    if (this._floorplanTextsLocked === locked) return;
    this._floorplanTextsLocked = locked;
    this.updateLock(this.floorplan.floorplanTexts, locked);
  }

  refreshFloorplanTextsLock() {
    this.refreshLock(
      this.floorplan.floorplanTexts,
      this._floorplanTextsLocked,
      (locked) => (this._floorplanTextsLocked = locked),
    );
  }

  get measurementLinesVisible(): boolean {
    return this._measurementLinesVisible;
  }

  set measurementLinesVisible(visible: boolean) {
    if (this._measurementLinesVisible === visible) return;
    this._measurementLinesVisible = visible;
    this.publishVisibilityChanged();
  }

  get measurementLinesLocked(): boolean {
    return this._measurementLinesLocked;
  }

  set measurementLinesLocked(locked: boolean) {
    if (this._measurementLinesLocked === locked) return;
    this._measurementLinesLocked = locked;
    this.updateLock(this.floorplan.measurementLines, locked);
  }

  refreshMeasurementLinesLock() {
    this.refreshLock(
      this.floorplan.measurementLines,
      this._measurementLinesLocked,
      (locked) => (this._measurementLinesLocked = locked),
    );
  }

  get airPathsVisible(): boolean {
    return this._airPathsVisible;
  }

  set airPathsVisible(visible: boolean) {
    if (this._airPathsVisible === visible) return;
    this._airPathsVisible = visible;
    this.publishVisibilityChanged();
  }

  get airPathsLocked(): boolean {
    return this._airPathsLocked;
  }

  set airPathsLocked(locked: boolean) {
    if (this._airPathsLocked === locked) return;
    this._airPathsLocked = locked;
    this.updateLock(this.floorplan.airPaths, locked);
  }

  refreshAirPathsLock() {
    this.refreshLock(this.floorplan.airPaths, this._airPathsLocked, (locked) => (this._airPathsLocked = locked));
  }

  get pipelinesVisible(): boolean {
    return this._pipelinesVisible;
  }

  set pipelinesVisible(visible: boolean) {
    if (this._pipelinesVisible === visible) return;
    this._pipelinesVisible = visible;
    this.publishVisibilityChanged();
  }

  get pipelinesLocked(): boolean {
    return this._pipelinesLocked;
  }

  set pipelinesLocked(locked: boolean) {
    if (this._pipelinesLocked === locked) return;
    this._pipelinesLocked = locked;
    this.updateLock(this.floorplan.pipelines, locked);
  }

  refreshPipelinesLock() {
    this.refreshLock(this.floorplan.pipelines, this._pipelinesLocked, (locked) => (this._pipelinesLocked = locked));
  }

  private refreshLock(items: FloorplanItem[], locked: boolean, lockFn: (locked: boolean) => void): void {
    const allItemsLocked = items.every((item) => item.locked);
    if (allItemsLocked && !locked) {
      lockFn(true);
      this.floorplan.publishUpdate(FloorplanEventType.FLOORPLAN_STATE_LOCK_CHANGED, this);
    } else if (!allItemsLocked && locked) {
      lockFn(false);
      this.floorplan.publishUpdate(FloorplanEventType.FLOORPLAN_STATE_LOCK_CHANGED, this);
    }
  }

  private updateLock(items: FloorplanItem[], locked: boolean): void {
    items.filter((item) => item.locked !== locked).forEach((item) => item.setLockedWithoutRefresh(locked));
    this.floorplan.publishUpdate(FloorplanEventType.FLOORPLAN_STATE_LOCK_CHANGED, this);
  }

  private publishVisibilityChanged() {
    this.floorplan.publishUpdate(FloorplanEventType.FLOORPLAN_STATE_VISIBILITY_CHANGED, this);
  }

  private scaleAndUpdatePosition(factor: number, referencePoint: Point) {
    const newScale = Math.min(Math.max(factor * this._scale, 0.2), 5);
    if (newScale === this._scale) {
      return;
    }

    const mousePointTo = {
      x: referencePoint.x / this._scale - this._x! / this._scale,
      y: referencePoint.y / this._scale - this._y! / this._scale,
    };

    const newPos = new Point(
      (referencePoint.x / newScale - mousePointTo.x) * newScale,
      (referencePoint.y / newScale - mousePointTo.y) * newScale,
    );

    this.updateScaleAndPosition(newScale, newPos.x, newPos.y);
  }
}
