import { Component, EventEmitter, Output } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";

@Component({
  selector: "app-new-configuration-button",
  templateUrl: "./new-configuration-button.component.html",
  styleUrl: "./new-configuration-button.component.scss",
  standalone: true,
  imports: [UiKitModule],
})
export class NewConfigurationButtonComponent {
  @Output() newConfiguration = new EventEmitter<void>();

  onClick() {
    this.newConfiguration.emit();
  }
}
