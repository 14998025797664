import { FullProductType, Product, ProductType } from "@domain/product/product";
import { IsInstanceOf } from "@utils/class-validator/class-validator-constraints";
import { IsNumber, IsOptional, IsString, Min, ValidateNested } from "class-validator";

export class ProductDataPlaceholder {
  @IsString()
  public name: string;

  @ValidateNested()
  @IsInstanceOf(ProductType)
  public type: ProductType;

  @IsNumber()
  @Min(0, { message: "The value must not be less than 0" })
  public price: number;

  @IsString()
  @IsOptional()
  public id?: string;

  constructor(name: string, type: ProductType, price: number, id?: string) {
    this.name = name;
    this.type = type;
    this.price = price;
    this.id = id;
  }
}

export class ProductData {
  public position?: string;

  private constructor(
    public productPlacements: number,
    public name: string,
    public productCosts: number,
    public totalProductCost: number,
    public type: string,
    public isAvailable: boolean,
    public fullType?: string,
    public id?: string,
  ) {}

  static create(product: Product | ProductDataPlaceholder, placements: number, localeId: string): ProductData {
    return new ProductData(
      placements,
      product instanceof Product ? product.localizedName[localeId] : product.name,
      product.price,
      product.price * placements,
      product.type,
      product instanceof Product ? product.isAvailable : true,
      product instanceof Product ? product.fullType : undefined,
      product.id,
    );
  }

  static mapAttachmentsAsProductData(attachments: Product[], placements: number, localeId: string) {
    return attachments.map<ProductData>((attachment) => {
      if (attachment.fullType == FullProductType.ATTACHMENT_INSTRUCTIONS) {
        return ProductData.create(attachment, 1, localeId);
      } else {
        return ProductData.create(attachment, placements, localeId);
      }
    });
  }
}
