import { formatCurrency } from "@angular/common";
import { DefaultRow } from "@pdf/helpers/default-row/default-row";
import { TableColumn } from "@pdf/helpers/default-table/table-column";
import { TableProperties } from "@pdf/pdf-properties";
import { jsPDF } from "jspdf";

interface RowData {
  position: number;
  title: string;
  description: string;
  id: string;
  costs: number;
}

export class ProductListServiceRow extends DefaultRow {
  position: string;
  title: string;
  description: string[];
  id: string;
  costs: string;

  constructor(pdf: jsPDF, localeId: string, columns: TableColumn[], servicesRowData: RowData) {
    super();
    this.position = servicesRowData.position.toString();
    this.title = servicesRowData.title;
    this.description = this.splitText(pdf, columns[2].width, servicesRowData.description);
    this.id = servicesRowData.id;
    this.costs = this.formatCosts(servicesRowData.costs, localeId);
    this._textHeight = this.description.length * TableProperties.TEXT_HEIGHT;
  }

  private formatCosts(costs: number, localeId: string) {
    return formatCurrency(costs, localeId, "€");
  }

  private splitText(pdf: jsPDF, width: number, text: string): string[] {
    pdf.setFontSize(TableProperties.TEXT_FONT_SIZE);
    return pdf.splitTextToSize(text, width);
  }
}
