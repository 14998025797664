import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";

export interface Country {
  countryCode: string;
}

export class Countries {
  static readonly COUNTRIES: Country[] = [
    {
      countryCode: "DE",
    },
    {
      countryCode: "AT",
    },
  ];
}

@Pipe({
  standalone: true,
  name: "mapCountryName",
})
export class MapCountryNamePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private localeId: string) {}
  transform(countryCode: string): string {
    const translations = JSON.parse(localStorage.getItem(`${this.localeId}_translations`) || "{}");

    if (translations && translations[`country.${countryCode.toLowerCase()}`]) {
      return translations[`country.${countryCode.toLowerCase()}`];
    }
    return countryCode;
  }
}
