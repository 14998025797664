<div class="selection-container">
  <h6 class="odx-title-6" i18n="@@localeSelection.header2">Sprache auswählen</h6>
  <button
    *ngFor="let language of LANGUAGES"
    (click)="onSelect(language.languageCode)"
    [ngClass]="selectedLanguage === language.languageCode ? 'selected' : ''"
    [attr.data-testid]="'btn.language.' + language.languageCode"
  >
    {{ language.displayName }}
  </button>
</div>
