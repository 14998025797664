import { PlasticSignConfiguration } from "@domain/project/configurations/plastic-sign-configuration";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { FloorplanProductItem } from "@domain/project/floorplan/floorplan-product-item";
import { Project } from "@domain/project/project";
import { Expose } from "class-transformer";
import { IsString } from "class-validator";
import { v4 as uuidv4 } from "uuid";

export class FloorplanPlasticSign extends FloorplanProductItem<PlasticSignConfiguration> {
  @IsString()
  @Expose({ name: "plasticSignId" })
  private readonly _plasticSignId: string;

  constructor(
    floorplan: Floorplan,
    plasticSign: PlasticSignConfiguration,
    id: string,
    x: number,
    y: number,
    size: number,
    notes?: string,
  ) {
    super(floorplan, plasticSign, id, x, y, size, notes);
    this._plasticSignId = plasticSign?.id;
  }

  override duplicate(x: number, y: number): FloorplanItem {
    return this._floorplan.addPlasticSign(this.config, x, y, this.size);
  }

  override delete() {
    this._floorplan.deletePlasticSign(this);
  }

  override get relatedItems(): FloorplanItem[] {
    return this._floorplan.plasticSigns;
  }

  protected override refreshFloorplanLockState() {
    this._floorplan.floorplanState.refreshPlasticSignsLock();
  }

  static create(
    floorplan: Floorplan,
    plasticSign: PlasticSignConfiguration,
    x: number,
    y: number,
    size: number,
  ): FloorplanPlasticSign {
    return new FloorplanPlasticSign(floorplan, plasticSign, uuidv4(), x, y, size);
  }

  override getConfigFromProject(project: Project): PlasticSignConfiguration | undefined {
    return project.getPlasticSignById(this._plasticSignId);
  }
}
