import { Floorplan } from "@domain/project/floorplan/floorplan";
import { FloorplanPlaceholder } from "@domain/project/floorplan/floorplan-placeholder";
import { FloorplanTransmitterPlaceholder } from "@domain/project/floorplan/floorplan-transmitter-placeholder";

interface NotesData {
  position: string;
  notes: string[];
  name?: string;
}

export class ProductNotes {
  private _gasWarningCenterNotes: NotesData[] = [];
  private _transmitterNotes: NotesData[] = [];
  private _alarmDeviceNotes: NotesData[] = [];
  private _signalElementNotes: NotesData[] = [];
  private _plasticSignNotes: NotesData[] = [];
  private _gasWarningCenterPlaceholderNotes: NotesData[] = [];
  private _transmitterPlaceholderNotes: NotesData[] = [];
  private _alarmDevicePlaceholderNotes: NotesData[] = [];
  private _signalElementPlaceholderNotes: NotesData[] = [];
  private _plasticSignPlaceholderNotes: NotesData[] = [];
  private _isDataAvailable = false;

  public constructor(floorplan: Floorplan) {
    this.initData(floorplan);
    this._isDataAvailable = this.checkIfDataIsAvailable();
  }

  private checkIfDataIsAvailable(): boolean {
    return [
      this._gasWarningCenterNotes,
      this._gasWarningCenterPlaceholderNotes,
      this._transmitterNotes,
      this._transmitterPlaceholderNotes,
      this._alarmDeviceNotes,
      this._alarmDevicePlaceholderNotes,
      this._signalElementNotes,
      this._signalElementPlaceholderNotes,
      this._plasticSignNotes,
      this._plasticSignPlaceholderNotes,
    ].some((notes) => notes.length);
  }

  private initData(floorplan: Floorplan) {
    this.initGasWarningNotes(floorplan);
    this.initTransmitterNotes(floorplan);
    this.initAlarmDeviceNotes(floorplan);
    this.initSignalElementNotes(floorplan);
    this.initPlasticSignNotes(floorplan);
    this._gasWarningCenterPlaceholderNotes = this.initPlaceholderNotes(floorplan.gasWarningCenterPlaceholders);
    this._transmitterPlaceholderNotes = this.initPlaceholderNotes(floorplan.transmitterPlaceholders);
    this._alarmDevicePlaceholderNotes = this.initPlaceholderNotes(floorplan.alarmDevicePlaceholders);
    this._signalElementPlaceholderNotes = this.initPlaceholderNotes(floorplan.signalElementPlaceholders);
    this._plasticSignPlaceholderNotes = this.initPlaceholderNotes(floorplan.plasticSignPlaceholders);
  }

  private initGasWarningNotes(floorplan: Floorplan) {
    this._gasWarningCenterNotes = floorplan.gasWarningCenters
      .filter((item) => item.notes.length)
      .map((item) => {
        return {
          position: item.positionId!,
          notes: [item.notes],
          name: item.config.name,
        };
      });
  }

  private initTransmitterNotes(floorplan: Floorplan) {
    this._transmitterNotes = floorplan.transmitters
      .filter((item) => item.notes.length)
      .map((item) => {
        return {
          position: item.positionId!,
          notes: [item.notes],
          name: item.config.name,
        };
      });
  }

  private initAlarmDeviceNotes(floorplan: Floorplan) {
    this._alarmDeviceNotes = floorplan.alarmDevices
      .filter((item) => item.notes.length)
      .map((item) => {
        return {
          position: item.positionId!,
          notes: [item.notes],
          name: item.config.name,
        };
      });
  }

  private initSignalElementNotes(floorplan: Floorplan) {
    this._signalElementNotes = floorplan.signalElements
      .filter((item) => item.notes.length)
      .map((item) => {
        return {
          position: item.positionId!,
          notes: [item.notes],
          name: item.config.name,
        };
      });
  }

  private initPlasticSignNotes(floorplan: Floorplan) {
    this._plasticSignNotes = floorplan.plasticSigns.map((item) => {
      return {
        position: item.positionId!,
        notes: [item.notes],
        name: item.config.name,
        text: item.config.text,
        textColor: item.config.textColor,
        backgroundColor: item.config.backgroundColor,
      };
    });
  }

  private initPlaceholderNotes(placeholders: (FloorplanPlaceholder | FloorplanTransmitterPlaceholder)[]) {
    return placeholders
      .filter((item) => item.notes.length)
      .map((item) => {
        return {
          position: item.positionId!,
          notes: [item.notes],
        };
      });
  }
  get isDataAvailable() {
    return this._isDataAvailable;
  }

  get gasWarningCenterNotes() {
    return this._gasWarningCenterNotes;
  }
  get transmitterNotes() {
    return this._transmitterNotes;
  }
  get alarmDeviceNotes() {
    return this._alarmDeviceNotes;
  }
  get signalElementNotes() {
    return this._signalElementNotes;
  }
  get plasticSignNotes() {
    return this._plasticSignNotes;
  }
  get gasWarningCenterPlaceholderNotes() {
    return this._gasWarningCenterPlaceholderNotes;
  }
  get transmitterPlaceholderNotes() {
    return this._transmitterPlaceholderNotes;
  }
  get alarmDevicePlaceholderNotes() {
    return this._alarmDevicePlaceholderNotes;
  }
  get signalElementPlaceholderNotes() {
    return this._signalElementPlaceholderNotes;
  }
  get plasticSignPlaceholderNotes() {
    return this._plasticSignPlaceholderNotes;
  }
}
