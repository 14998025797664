import { SignalElementConfiguration } from "@domain/project/configurations/signal-element-configuration";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { FloorplanProductItem } from "@domain/project/floorplan/floorplan-product-item";
import { Project } from "@domain/project/project";
import { Exclude, Expose } from "class-transformer";
import { IsString } from "class-validator";
import { v4 as uuidv4 } from "uuid";

export class FloorplanSignalElement extends FloorplanProductItem<SignalElementConfiguration> {
  @Exclude()
  private readonly _signalElement: SignalElementConfiguration;

  @IsString()
  @Expose({ name: "signalElementId" })
  private readonly _signalElementId: string;

  constructor(
    floorplan: Floorplan,
    signalElement: SignalElementConfiguration,
    id: string,
    x: number,
    y: number,
    size: number,
    notes?: string,
  ) {
    super(floorplan, signalElement, id, x, y, size, notes);
    this._signalElementId = signalElement?.id;
    this._signalElement = signalElement;
  }

  override duplicate(x: number, y: number): FloorplanItem {
    return this._floorplan.addSignalElement(this.config, x, y, this.size);
  }

  override delete() {
    this._floorplan.deleteSignalElement(this);
  }

  override get relatedItems(): FloorplanItem[] {
    return this._floorplan.signalElements;
  }

  protected override refreshFloorplanLockState() {
    this._floorplan.floorplanState.refreshSignalElementsLock();
  }

  static create(
    floorplan: Floorplan,
    signalElement: SignalElementConfiguration,
    x: number,
    y: number,
    size: number,
  ): FloorplanSignalElement {
    return new FloorplanSignalElement(floorplan, signalElement, uuidv4(), x, y, size);
  }

  override getConfigFromProject(project: Project): SignalElementConfiguration | undefined {
    return project.getSignalElementById(this._signalElementId);
  }
}
