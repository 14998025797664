<div odxLayout="flex" class="container">
  <odx-area-header>
    <h5 class="odx-title-5" i18n="@@home.header" data-testid="heading.myProjects">Meine Projekte</h5>
    <odx-area-header-content>
      <odx-action-group>
        <a odxButton href="{{ crmUrl }}" i18n="@@home.button.importCrm" data-testid="btn.importProjectFromCrm">
          CRM Kontaktdaten importieren
        </a>
        <button odxButton routerLink="/new-project" data-testid="btn.newProject">
          <odx-icon name="plus" alignLeft></odx-icon>
          <span i18n="@@home.button.newProject">Neues Projekt</span>
        </button>
        <app-project-import odxButton></app-project-import>
      </odx-action-group>
    </odx-area-header-content>
  </odx-area-header>

  <div class="scroll h-100">
    <div odxLayout="grid 3@desktop 12@phone 4@tablet" class="project-card-container">
      <app-project-card *ngFor="let project of projects$ | async" [project]="project"></app-project-card>
      <p *ngIf="!(projects$ | async)?.length" class="odx-text" i18n="@@home.noProjects">Keine Projekte verfügbar</p>
    </div>
  </div>
</div>
