import Konva from "konva";
import { forkJoin, map, Observable } from "rxjs";

export enum ImageType {
  TEXT_ICON = "TEXT_ICON",
  TRANSMITTER_ICON = "TRANSMITTER_ICON",
  TRANSMITTER_PLACEHOLDER_ICON = "TRANSMITTER_PLACEHOLDER_ICON",
  ALARM_DEVICE_ICON = "ALARM_DEVICE_ICON",
  ALARM_DEVICE_PLACEHOLDER_ICON = "ALARM_DEVICE_PLACEHOLDER_ICON",
  GAS_WARNING_CENTER_ICON = "GAS_WARNING_CENTER_ICON",
  GAS_WARNING_CENTER_PLACEHOLDER_ICON = "GAS_WARNING_CENTER_PLACEHOLDER_ICON",
  SIGNAL_ELEMENT_ICON = "SIGNAL_ELEMENT_ICON",
  SIGNAL_ELEMENT_PLACEHOLDER_ICON = "SIGNAL_ELEMENT_PLACEHOLDER_ICON",
  PLASTIC_SIGN_ICON = "PLASTIC_SIGN_ICON",
  PLASTIC_SIGN_PLACEHOLDER_ICON = "PLASTIC_SIGN_PLACEHOLDER_ICON",
  WARNING_SIGN_ICON = "WARNING_SIGN_ICON",
}

interface ImageTypeUrl {
  type: ImageType;
  url: string;
}

export class ImageFactoryKonva {
  private static readonly IMAGE_TYPE_URLS: ImageTypeUrl[] = [
    { type: ImageType.TEXT_ICON, url: "../../assets/notizen_odx.svg" },
    { type: ImageType.TRANSMITTER_ICON, url: "../../assets/transmitter.svg" },
    { type: ImageType.TRANSMITTER_PLACEHOLDER_ICON, url: "../../assets/transmitter_platzhalter_nicht_odx.svg" },
    { type: ImageType.ALARM_DEVICE_ICON, url: "../../assets/alarmmittel_odx.svg" },
    { type: ImageType.ALARM_DEVICE_PLACEHOLDER_ICON, url: "../../assets/alarmmittel_platzhalter_nicht_odx.svg" },
    { type: ImageType.GAS_WARNING_CENTER_ICON, url: "../../assets/gaswarnanlage.svg" },
    { type: ImageType.GAS_WARNING_CENTER_PLACEHOLDER_ICON, url: "../../assets/gaswarnanlage_platzhalter_nicht_odx.svg" },
    { type: ImageType.SIGNAL_ELEMENT_ICON, url: "../../assets/leuchttransparente.svg" },
    { type: ImageType.SIGNAL_ELEMENT_PLACEHOLDER_ICON, url: "../../assets/leuchttransparente_platzhalter_nicht_odx.svg" },
    { type: ImageType.PLASTIC_SIGN_ICON, url: "../../assets/kunststoffschild.svg" },
    { type: ImageType.PLASTIC_SIGN_PLACEHOLDER_ICON, url: "../../assets/kunststoffschild_platzhalter_nicht_odx.svg" },
    { type: ImageType.WARNING_SIGN_ICON, url: "../../assets/warning-colored-odx.svg" },
  ];

  private imagesByType: Map<ImageType, Konva.Image>;

  constructor(imagesByType: Map<ImageType, Konva.Image>) {
    this.imagesByType = imagesByType;
  }

  destroy() {
    Array.from(this.imagesByType.values()).forEach((image: Konva.Image) => image.destroy());
  }

  createImage(type: ImageType) {
    const image = this.imagesByType.get(type);
    if (!image) {
      throw Error(`No image found for type ${type}`);
    }
    return image.clone();
  }

  static create(): Observable<ImageFactoryKonva> {
    return forkJoin(ImageFactoryKonva.IMAGE_TYPE_URLS.map((url) => this.loadImage(url))).pipe(
      map(
        (images) =>
          new ImageFactoryKonva(
            images.reduce(function (map: Map<ImageType, Konva.Image>, obj) {
              map.set(obj.type, obj.image);
              return map;
            }, new Map()),
          ),
      ),
    );
  }

  private static loadImage(imageTypeUrl: ImageTypeUrl): Observable<{ type: ImageType; image: Konva.Image }> {
    return new Observable((subscriber) => {
      Konva.Image.fromURL(imageTypeUrl.url, (image: Konva.Image) => {
        subscriber.next({ type: imageTypeUrl.type, image });
        subscriber.complete();
      });
    });
  }
}
