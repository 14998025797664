import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { ProductCardComponent } from "@components/product-card/product-card.component";
import { Product, ProductPropertyDimension, ProductPropertyText } from "@domain/product/product";
import { ProductImage } from "@domain/product/product-image";
import { GasWarningCenterType } from "@project/gas-warning-center-configuration/gas-warning-center-type";

@Component({
  selector: "app-gas-warning-center-card",
  templateUrl: "./gas-warning-center-card.component.html",
  styleUrls: ["./gas-warning-center-card.component.scss"],
  standalone: true,
  imports: [UiKitModule, ProductCardComponent],
})
export class GasWarningCenterCardComponent {
  protected readonly ProductImage = ProductImage;

  @Input() set gasWarningCenterData(gasWarningCenter: Product) {
    if (gasWarningCenter) {
      this.gasWarningCenter = gasWarningCenter;
      this.mapProperties();
    }
  }

  @Input() showCosts!: boolean;
  @Input() isSelectable: boolean = true;
  @Input() isSelected: boolean = false;
  @Input({ required: true }) gasWarningCenterType!: GasWarningCenterType;
  @Output() gasWarningCenterSelected = new EventEmitter<Product>();

  gasWarningCenter!: Product;

  dimensionsProperty?: ProductPropertyDimension;
  powersupplyProperty?: ProductPropertyText;
  degreeprotectionProperty?: ProductPropertyText;

  private readonly POWERSUPPLY = "Power_Supply_";
  private readonly DIMENSIONS = "Dimensions_";
  private readonly DEGREEPROTECTION = "Degree_protection_";
  private mapProperties() {
    this.powersupplyProperty = this.gasWarningCenter!.getTextPropertyByKey(this.POWERSUPPLY);
    this.degreeprotectionProperty = this.gasWarningCenter!.getTextPropertyByKey(this.DEGREEPROTECTION);
    this.dimensionsProperty = this.gasWarningCenter!.getDimensionsPropertyByKey(this.DIMENSIONS);
  }
}
