import { Component, Input } from "@angular/core";
import { MasterDataService } from "@app/master-data.service";
import { UiKitModule } from "@app/ui-kit.module";

@Component({
  selector: "app-refresh-products-hint",
  templateUrl: "./refresh-products-hint.component.html",
  styleUrls: ["./refresh-products-hint.component.scss"],
  standalone: true,
  imports: [UiKitModule],
})
export class RefreshProductsHintComponent {
  @Input()
  dataType!: string;

  constructor(private masterDataService: MasterDataService) {}

  onClickRefresh() {
    this.masterDataService.refresh();
  }
}
