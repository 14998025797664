import { IsInstanceOf } from "@utils/class-validator/class-validator-constraints";
import { Type } from "class-transformer";
import { IsBoolean, IsOptional, IsString, ValidateNested } from "class-validator";

export class ChecklistItem {
  @IsBoolean()
  checked: boolean = false;

  @IsOptional()
  @IsString()
  notes?: string;
}

export class Checklist {
  @IsOptional()
  @IsString()
  operator?: string;

  @IsOptional()
  @IsString()
  client?: string;

  @IsOptional()
  @IsString()
  personToInstruct?: string;

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  sensorGasAndMeasurementRangeConsidered: ChecklistItem = new ChecklistItem();

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  peturbationsExist: ChecklistItem = new ChecklistItem();

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  relayOutputsConsidered: ChecklistItem = new ChecklistItem();

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  operatingVoltageOfMeansOfAlarmDefined: ChecklistItem = new ChecklistItem();

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  sensorsGasAlarmDeviceAndMeansOfAlarmSet: ChecklistItem = new ChecklistItem();

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  installationRequirementsDefined: ChecklistItem = new ChecklistItem();

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  specialStandardsExist: ChecklistItem = new ChecklistItem();

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  scaffoldingRequired: ChecklistItem = new ChecklistItem();

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  exZonesExist: ChecklistItem = new ChecklistItem();

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  clearanceMeasurementRequired: ChecklistItem = new ChecklistItem();

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  electricalGroundingConceptExists: ChecklistItem = new ChecklistItem();

  @Type(() => ChecklistItem)
  @ValidateNested()
  @IsInstanceOf(ChecklistItem)
  ventilationExists: ChecklistItem = new ChecklistItem();
}
