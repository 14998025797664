import { Injectable } from "@angular/core";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { FloorplanWorkspaceItemService } from "@project/floorplanner/floorplan-workspace-item.service";
import { FloorplanWorkspaceWarningSignService } from "@project/floorplanner/floorplan-workspace-warning-sign.service";
import { FloorplanWorkspaceKonvaAdapter } from "@project/floorplanner/konva/floorplan-workspace-konva.adapter";
import { map, Observable, switchMap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FloorplanToPngService {
  constructor(
    private floorplanWorkspaceItemService: FloorplanWorkspaceItemService,
    private floorplanWorkspaceWarningSignService: FloorplanWorkspaceWarningSignService,
  ) {}

  getPng(floorplan: Floorplan): Observable<string> {
    const floorplanWorkspace = new FloorplanWorkspaceKonvaAdapter();

    const container = document.createElement("div");
    return floorplanWorkspace.init(floorplan, container).pipe(
      switchMap(() => this.floorplanWorkspaceItemService.addItems(floorplan, floorplanWorkspace)),
      switchMap(() => this.floorplanWorkspaceWarningSignService.updateWarningSigns(floorplan, floorplanWorkspace)),
      map(() => {
        const pngUrl = floorplanWorkspace.exportFloorplanToPngUrl();
        floorplanWorkspace.destroy();
        return pngUrl;
      }),
    );
  }
}
