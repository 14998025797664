import { Component, Input } from "@angular/core";
import { ControlContainer, FormGroupDirective, ReactiveFormsModule } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";

@Component({
  selector: "app-checklist-item",
  templateUrl: "./checklist-item.component.html",
  styleUrls: ["./checklist-item.component.scss"],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
  standalone: true,
  imports: [UiKitModule, ReactiveFormsModule],
})
export class ChecklistItemComponent {
  @Input()
  label!: string;

  @Input()
  groupName!: string;
}
