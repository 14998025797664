import { Component, inject, OnInit } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { Modal, ModalRef } from "@odx/angular/components/modal";

@Component({
  selector: "app-confirm-modal",
  templateUrl: "./confirm-modal.component.html",
  styleUrls: ["./confirm-modal.component.scss"],
  standalone: true,
  imports: [UiKitModule],
})
export class ConfirmModalComponent implements Modal<any, string>, OnInit {
  public readonly modalRef = inject(ModalRef<any, string>);

  text = "";

  ngOnInit(): void {
    this.text = this.modalRef.data.text;
  }

  onConfirm() {
    this.modalRef.close("confirmed");
  }
}
