import { Project } from "@domain/project/project";

export enum ProjectEventType {
  PROJECT_OPENED = "PROJECT_OPENED",
  PROJECT_CLOSED = "PROJECT_CLOSED",
  FLOORPLAN_ADDED = "FLOORPLAN_ADDED",
  FLOORPLAN_UPDATED = "FLOORPLAN_UPDATED",
  FLOORPLAN_DELETED = "FLOORPLAN_DELETED",
  IMAGE_ADDED = "IMAGE_ADDED",
  IMAGE_UPDATED = "IMAGE_UPDATED",
  IMAGE_DELETED = "IMAGE_DELETED",
  ALARM_DEVICE_CONFIGURATION_ADDED = "ALARM_DEVICE_CONFIGURATION_ADDED",
  TRANSMITTER_CONFIGURATION_ADDED = "TRANSMITTER_CONFIGURATION_ADDED",
  GAS_WARNING_CENTER_CONFIGURATION_ADDED = "GAS_WARNING_CENTER_CONFIGURATION_ADDED",
  SIGNAL_ELEMENT_CONFIGURATION_ADDED = "SIGNAL_ELEMENT_CONFIGURATION_ADDED",
  PLASTIC_SIGN_CONFIGURATION_ADDED = "PLASTIC_SIGN_CONFIGURATION_ADDED",
  ALARM_DEVICE_CONFIGURATION_UPDATED = "ALARM_DEVICE_CONFIGURATION_UPDATED",
  TRANSMITTER_CONFIGURATION_UPDATED = "TRANSMITTER_CONFIGURATION_UPDATED",
  GAS_WARNING_CENTER_CONFIGURATION_UPDATED = "GAS_WARNING_CENTER_CONFIGURATION_UPDATED",
  SIGNAL_ELEMENT_CONFIGURATION_UPDATED = "SIGNAL_ELEMENT_CONFIGURATION_UPDATED",
  PLASTIC_SIGN_CONFIGURATION_UPDATED = "PLASTIC_SIGN_CONFIGURATION_UPDATED",
  ALARM_DEVICE_CONFIGURATION_DELETED = "ALARM_DEVICE_CONFIGURATION_DELETED",
  TRANSMITTER_CONFIGURATION_DELETED = "TRANSMITTER_CONFIGURATION_DELETED",
  GAS_WARNING_CENTER_CONFIGURATION_DELETED = "GAS_WARNING_CENTER_CONFIGURATION_DELETED",
  SIGNAL_ELEMENT_CONFIGURATION_DELETED = "SIGNAL_ELEMENT_CONFIGURATION_DELETED",
  PLASTIC_SIGN_CONFIGURATION_DELETED = "PLASTIC_SIGN_CONFIGURATION_DELETED",
  SYNCED_TO_CLOUD = "SYNCED_TO_CLOUD",
  PLACEHOLDER_PRODUCTS_UPDATED = "PLACEHOLDER_PRODUCTS_UPDATED",
}

export interface ProjectEvent {
  project: Project;
  type: ProjectEventType;
  subject: any;
}
