import { Component, inject, OnInit } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { ModalRef } from "@odx/angular/components/modal";

@Component({
  selector: "app-project-import-confirm-modal",
  templateUrl: "./project-import-confirm-modal.component.html",
  styleUrls: ["./project-import-confirm-modal.component.scss"],
  standalone: true,
  imports: [UiKitModule],
})
export class ProjectImportConfirmModalComponent implements OnInit {
  private readonly modalRef = inject(ModalRef<any, string>);

  newProjectCreationEnabled!: boolean;

  ngOnInit(): void {
    this.newProjectCreationEnabled = this.modalRef.data.newProjectCreationEnabled;
  }

  onConfirmNewProject() {
    this.modalRef.close(ProjectImportConfirmResult.NEW_PROJECT);
  }

  onConfirmOverride() {
    this.modalRef.close(ProjectImportConfirmResult.OVERRIDE_PROJECT);
  }
}

export enum ProjectImportConfirmResult {
  NEW_PROJECT = "NEW_PROJECT",
  OVERRIDE_PROJECT = "OVERRIDE_PROJECT",
}
