<div class="odx-bar-parent h-100" odxLayout="flex">
  <app-configuration-wizard-back-button #backButton [project]="project" [floorplanGroup]="FloorplanMenuType.GAS_WARNING_CENTER">
  </app-configuration-wizard-back-button>

  <odx-wizard class="odx-gaswarn-center-wizard" #configurationWizard [vertical]="true" (activeStepChanged)="activeStep = $event">
    <odx-wizard-step i18n="@@gasWarningCenterConfiguration.wizard.step1"> Serie</odx-wizard-step>
    <odx-wizard-step i18n="@@gasWarningCenterConfiguration.wizard.step2"> Gaswarnzentrale</odx-wizard-step>
    <odx-wizard-step i18n="@@gasWarningCenterConfiguration.wizard.step3"> Zubehör</odx-wizard-step>
  </odx-wizard>
  <app-gas-warning-center-type-selector
    class="w-100"
    [hidden]="activeStep !== 0"
    [selectedType]="(originalConfiguration?.gasWarningCenter?.localizedName)!['de-DE']"
    (typeSelected)="selectedType = $event"
    (formReady)="selectedGasWarningCenterTypeForm = $event"
  ></app-gas-warning-center-type-selector>
  <app-gas-warning-center-selector
    class="w-100"
    [hidden]="activeStep !== 1"
    [project]="project"
    [type]="selectedType"
    [originalGasWarningCenter]="originalConfiguration?.gasWarningCenter"
    (gasWarningCenterSelect$)="selectGasWarningCenter($event)"
    (formReady)="selectedGasWarningCenterForm = $event"
  >
  </app-gas-warning-center-selector>
  <app-attachment-selector
    *ngIf="selectedGasWarningCenter"
    class="w-100"
    [hidden]="activeStep !== 2"
    [headerProducts]="[selectedGasWarningCenter]"
    [products]="
      selectedGasWarningCenter.id === originalConfiguration?.gasWarningCenter?.id
        ? { selectedProducts: [selectedGasWarningCenter], preselectedAttachments: originalConfiguration!.attachments }
        : { selectedProducts: [selectedGasWarningCenter] }
    "
    [showCosts]="project.showCostFlag"
    (attachmentsSelect$)="selectedAttachments = $event"
  >
  </app-attachment-selector>
  <app-product-config-stepper-bar
    (dismiss)="backButton.navigateBack()"
    (next)="onSubmit()"
    (previous)="configurationWizard.previousStep()"
    [activeStep]="activeStep"
    [steps]="steps"
  >
  </app-product-config-stepper-bar>
</div>
