import { FloorplanInformationTable } from "@pdf/export-services/product-information-pages/floorplan-information-pages/floorplan-information-table";
import { TableColumn } from "@pdf/helpers/default-table/table-column";
import { ImagePaths } from "@pdf/pdf-properties";
import { jsPDF } from "jspdf";
import { ProductNotesRow } from "./product-notes-row";

interface NotesData {
  position: string;
  notes: string[];
  name?: string;
  text?: string;
  textColor?: string;
  backgroundColor?: string;
}

export class ProductNotesRowHeaders {
  public readonly NUMBER = $localize`:@@pdfExport.productNotes.tableHeader.number:Nr.`;
  public readonly TYPE = $localize`:@@pdfExport.productNotes.tableHeader.type:Typ`;
  public readonly TEXT = $localize`:@@pdfExport.productNotes.tableHeader.text:Text`;
  public readonly TEXT_COLOR = $localize`:@@pdfExport.productNotes.tableHeader.textColor:Textfarbe`;
  public readonly BACKGROUND_COLOR = $localize`:@@pdfExport.productNotes.tableHeader.backgroundColor:Hintergrund`;
  public readonly NOTES = $localize`:@@pdfExport.productNotes.tableHeader.notes:Notizen`;
}

export class ProductNotesTable extends FloorplanInformationTable {
  readonly rowHeaders = new ProductNotesRowHeaders();
  override headline: string;
  override columns: TableColumn[];
  override rows: ProductNotesRow[];

  constructor(pdf: jsPDF, productNotesRows: NotesData[], iconPath: ImagePaths, headline: string) {
    super(iconPath);
    this.headline = headline;

    if (!this.hasName(productNotesRows)) {
      this.columns = [new TableColumn(this.rowHeaders.NUMBER, 60, 96), new TableColumn(this.rowHeaders.NOTES, 180, 1260)];
    } else if (this.isPlasticSign(productNotesRows)) {
      this.columns = [
        new TableColumn(this.rowHeaders.NUMBER, 60, 96),
        new TableColumn(this.rowHeaders.TYPE, 180, 276),
        new TableColumn(this.rowHeaders.TEXT, 480, 156),
        new TableColumn(this.rowHeaders.TEXT_COLOR, 660, 156),
        new TableColumn(this.rowHeaders.BACKGROUND_COLOR, 840, 156),
        new TableColumn(this.rowHeaders.NOTES, 1020, 420),
      ];
    } else {
      this.columns = [
        new TableColumn(this.rowHeaders.NUMBER, 60, 96),
        new TableColumn(this.rowHeaders.TYPE, 180, 516),
        new TableColumn(this.rowHeaders.NOTES, 720, 720),
      ];
    }

    this.rows = productNotesRows.map(
      (productNotesRow) =>
        new ProductNotesRow(
          pdf,
          this.columns,
          productNotesRow.position,
          productNotesRow.notes[0],
          productNotesRow.name,
          productNotesRow.text,
          productNotesRow.textColor,
          productNotesRow.backgroundColor,
        ),
    );
  }

  private hasName(productNotesRows: NotesData[]) {
    return productNotesRows.some((productNotesRow) => productNotesRow.name);
  }

  private isPlasticSign(productNotesRows: NotesData[]) {
    return productNotesRows.some(
      (productNotesRow) => productNotesRow.text && productNotesRow.textColor && productNotesRow.backgroundColor,
    );
  }
}
