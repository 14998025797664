import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ConfigurationWizardBackButtonComponent } from "@components/configuration-wizard-back-button/configuration-wizard-back-button.component";
import { ProductConfigStepperBarComponent } from "@components/stepper/product-config-stepper-bar/product-config-stepper-bar.component";
import { LocalizeProductNamePipe, Product } from "@domain/product/product";
import { SignalElementConfiguration } from "@domain/project/configurations/signal-element-configuration";
import { Project } from "@domain/project/project";
import { FloorplanMenuType } from "@project/floorplanner/floorplan-menu/floorplan-menu-type";
import { OriginalProductConfigurationFactory } from "@project/original-product-configuration-factory";
import { OriginalSignalElementConfiguration } from "@project/signal-element-configuration/original-signal-element-configuration";
import { SignalElementSelectorComponent } from "@project/signal-element-configuration/signal-element-selector/signal-element-selector.component";

@Component({
  selector: "app-signal-element-configuration",
  templateUrl: "./signal-element-configuration.component.html",
  styleUrls: ["./signal-element-configuration.component.scss"],
  standalone: true,
  imports: [SignalElementSelectorComponent, ProductConfigStepperBarComponent, ConfigurationWizardBackButtonComponent],
  providers: [OriginalProductConfigurationFactory, LocalizeProductNamePipe],
})
export class SignalElementConfigurationComponent implements OnInit {
  protected readonly FloorplanMenuType = FloorplanMenuType;
  protected project!: Project;
  protected selectedSignalElement?: Product;
  protected originalConfiguration?: OriginalSignalElementConfiguration;

  @ViewChild("backButton")
  private backButton!: ConfigurationWizardBackButtonComponent;

  constructor(
    private activeRoute: ActivatedRoute,
    private productConfigurationService: OriginalProductConfigurationFactory,
    private localizeProductName: LocalizeProductNamePipe,
  ) {}

  ngOnInit(): void {
    this.project = this.activeRoute.parent!.snapshot.data["project"];
    if (this.activeRoute.snapshot.queryParams["config_id"]) {
      this.productConfigurationService
        .createOriginalSignalElementConfiguration(this.project, this.activeRoute.snapshot.queryParams["config_id"])
        .subscribe((originalConfiguration) => {
          this.originalConfiguration = originalConfiguration;
        });
    }
  }

  onSubmit() {
    if (!this.selectedSignalElement) return;
    if (this.originalConfiguration) {
      this.updateSignalElementConfig(this.originalConfiguration.productConfiguration!);
    } else {
      this.createNewSignalElementConfig();
    }
  }

  private createNewSignalElementConfig() {
    const signalElementConfig = SignalElementConfiguration.create(
      this.localizeProductName.transform(this.selectedSignalElement!),
      this.project,
      this.selectedSignalElement!.id,
    );
    this.project.addSignalElements(signalElementConfig);
    this.backButton.navigateBack();
  }

  private updateSignalElementConfig(existingConfig: SignalElementConfiguration) {
    existingConfig.update(this.selectedSignalElement!);
    this.backButton.navigateBack();
  }
}
