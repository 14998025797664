<h5 class="odx-title-5 title">{{ title }}</h5>

<form [formGroup]="form">
  <odx-radio-group formControlName="deviceClass" required="true">
    <div odxLayout="grid 3@desktop 6@tablet 12@phone" class="w-100">
      <app-picture-card
        *ngFor="let deviceClass of deviceClassType | keyvalue"
        (click)="selectDeviceClass(deviceClass.value)"
        [title]="$any(deviceClass.key) | localizeDeviceClass"
        [isSelected]="form.value.deviceClass === deviceClass.value"
      >
        <odx-radio-button [value]="deviceClass.value" i18n="@@global.select">Auswählen</odx-radio-button>
      </app-picture-card>
    </div>
  </odx-radio-group>
</form>
