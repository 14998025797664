import { Gas } from "@domain/gas/gas";
import { Product } from "@domain/product/product";

import { TransmitterConfiguration } from "@domain/project/configurations/transmitter-configuration";

export class OriginalTransmitterConfiguration {
  productConfiguration: TransmitterConfiguration;
  gas?: Gas;
  transmitter?: Product;
  attachments?: Product[];
  sensor?: Product;

  constructor(
    productConfiguration: TransmitterConfiguration,
    gas?: Gas,
    transmitter?: Product,
    attachments?: Product[],
    sensor?: Product,
  ) {
    this.productConfiguration = productConfiguration;
    this.gas = gas;
    this.transmitter = transmitter;
    this.attachments = attachments;
    this.sensor = sensor;
  }
}
