import { Component } from "@angular/core";
import { FloorplanDangerAreaMenuComponent } from "@project/floorplanner/floorplan-menu/zone-menu/danger-area-menu/floorplan-danger-area-menu.component";
import { FloorplanExZoneMenuComponent } from "@project/floorplanner/floorplan-menu/zone-menu/ex-zone-menu/floorplan-ex-zone-menu.component";

@Component({
  selector: "app-floorplan-zone-menu",
  templateUrl: "./floorplan-zone-menu.component.html",
  styleUrls: ["./floorplan-zone-menu.component.scss"],
  standalone: true,
  imports: [FloorplanExZoneMenuComponent, FloorplanDangerAreaMenuComponent],
})
export class FloorplanZoneMenuComponent {}
