import { Injectable } from "@angular/core";
import { FullProductType, Product, ProductType } from "@domain/product/product";
import { ProductService } from "@domain/product/product.service";
import { AlarmDeviceConfiguration } from "@domain/project/configurations/alarm-device-configuration";
import { GasWarningCenterConfiguration } from "@domain/project/configurations/gas-warning-center-configuration";
import { PlasticSignConfiguration } from "@domain/project/configurations/plastic-sign-configuration";
import { ProductConfiguration } from "@domain/project/configurations/product-configuration";
import { SignalElementConfiguration } from "@domain/project/configurations/signal-element-configuration";
import { TransmitterConfiguration } from "@domain/project/configurations/transmitter-configuration";
import { Project } from "@domain/project/project";
import { Observable, forkJoin, map, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ProjectCostService {
  constructor(private productService: ProductService) {}

  calculateProductSubtotal(products: readonly ProductConfiguration[]): Observable<number> {
    const observables: Observable<number>[] = [];
    products.forEach((product) => observables.push(this.calculateTotalProductCosts(product)));
    return forkJoin(observables).pipe(
      map((results: any) => {
        let sum = 0;
        results.forEach((result: number) => (sum += result));
        return sum;
      }),
    );
  }

  calculateServicesCosts(project: Project): number {
    let result = 0;
    if (project.assembly.required) result += +(Number.isNaN(project.assembly.costs!) ? 0 : project.assembly.costs!);
    if (project.installation.required) result += +(Number.isNaN(project.installation.costs!) ? 0 : project.installation.costs!);
    if (project.documentation.required)
      result += +(Number.isNaN(project.documentation.costs!) ? 0 : project.documentation.costs!);
    if (project.engineering.required) result += +(Number.isNaN(project.engineering.costs!) ? 0 : project.engineering.costs!);
    if (project.additionalServices.required)
      result += +(Number.isNaN(project.additionalServices.costs!) ? 0 : project.additionalServices.costs!);
    return result;
  }

  calculateAllProductsTotal(project: Project): Observable<number> {
    const observables: Observable<number>[] = [];
    if (project.gasWarningCenters.length > 0) {
      observables.push(this.calculateProductSubtotal(project.gasWarningCenters));
    }
    if (project.transmitters.length > 0) {
      observables.push(this.calculateProductSubtotal(project.transmitters));
    }
    if (project.alarmDevices.length > 0) {
      observables.push(this.calculateProductSubtotal(project.alarmDevices));
    }
    if (project.signalElements.length > 0) {
      observables.push(this.calculateProductSubtotal(project.signalElements));
    }
    if (project.plasticSigns.length > 0) {
      observables.push(this.calculateProductSubtotal(project.plasticSigns));
    }

    if (observables.length == 0) {
      return of(0);
    }

    return forkJoin(observables).pipe(
      map((results: any) => {
        let sum = 0;
        results.forEach((result: number) => (sum += result));
        return sum;
      }),
    );
  }

  calculateTotalNetCosts(project: Project): Observable<number> {
    return this.calculateAllProductsTotal(project).pipe(map((costs) => costs + this.calculateServicesCosts(project)));
  }

  calculateTotalProductCosts(product: ProductConfiguration): Observable<number> {
    if (product instanceof GasWarningCenterConfiguration) {
      return this.calculateGasWarningCenterConfigurationCosts(product);
    } else if (product instanceof TransmitterConfiguration) {
      return this.calculateTransmitterConfigurationCosts(product);
    } else if (product instanceof AlarmDeviceConfiguration) {
      return this.calculateAlarmDeviceConfigurationCosts(product);
    } else if (product instanceof SignalElementConfiguration) {
      return this.calculateDefaultProductConfigurationCosts(product, product.productId);
    } else if (product instanceof PlasticSignConfiguration) {
      return this.calculateDefaultProductConfigurationCosts(product, product.productId);
    } else {
      throw Error("Unknown product configuration: " + typeof product);
    }
  }

  getAssemblyPosition(project: Project): number {
    return this.countAllProductsAmount(project) + 1;
  }

  getInstallationPosition(project: Project): number {
    let position = this.countAllProductsAmount(project) + 1;
    if (project.assembly.required) position += 1;
    return position;
  }

  getDocumentationPosition(project: Project): number {
    let position = this.countAllProductsAmount(project) + 1;
    if (project.assembly.required) position += 1;
    if (project.installation.required) position += 1;
    return position;
  }

  getEngineeringPosition(project: Project): number {
    let position = this.countAllProductsAmount(project) + 1;
    if (project.assembly.required) position += 1;
    if (project.installation.required) position += 1;
    if (project.documentation.required) position += 1;
    return position;
  }

  getAdditionalServicesPosition(project: Project): number {
    let position = this.countAllProductsAmount(project) + 1;
    if (project.assembly.required) position += 1;
    if (project.installation.required) position += 1;
    if (project.documentation.required) position += 1;
    if (project.engineering.required) position += 1;
    return position;
  }

  calculateMwst(cost: number) {
    return cost * 0.19;
  }

  private countAllProductsAmount(project: Project): number {
    return (
      project.gasWarningCenters.filter((gasWarningCenter) => gasWarningCenter.countPlacedProducts() > 0).length +
      project.transmitters.filter((transmitter) => transmitter.countPlacedProducts() > 0).length +
      project.alarmDevices.filter((alarmDevice) => alarmDevice.countPlacedProducts() > 0).length +
      project.signalElements.filter((signalElement) => signalElement.countPlacedProducts() > 0).length +
      project.plasticSigns.filter((plasticSign) => plasticSign.countPlacedProducts() > 0).length +
      project.floorplans
        .flatMap((floorplan) =>
          [
            floorplan.gasWarningCenterPlaceholders,
            floorplan.transmitterPlaceholders,
            floorplan.alarmDevicePlaceholders,
            floorplan.plasticSignPlaceholders,
            floorplan.signalElementPlaceholders,
          ].flat(),
        )
        .filter((placeholder) => placeholder.products.some((placeholderProduct) => placeholderProduct.id)).length
    );
  }

  private calculateGasWarningCenterConfigurationCosts(gasWarningCenter: GasWarningCenterConfiguration): Observable<number> {
    const placedProducts = gasWarningCenter.countPlacedProducts();
    const product$ = this.productService
      .getProductById(gasWarningCenter.productId)
      .pipe(map((product) => (product ? product.price * placedProducts : 0)));
    let attachments$: Observable<number>;
    if (gasWarningCenter.attachmentIds.length) {
      attachments$ = this.productService.getProductsByIds(gasWarningCenter.attachmentIds, ProductType.ATTACHMENT).pipe(
        map((attachments: Product[]) => {
          return this.calculateAttachmentCosts(attachments, placedProducts);
        }),
      );
    }

    if (gasWarningCenter.attachmentIds.length) {
      return forkJoin([product$, attachments$!]).pipe(
        map((results: number[]) => {
          let sum = 0;
          results.forEach((product: number) => (sum += product));
          return sum;
        }),
      );
    } else {
      return forkJoin([product$]).pipe(
        map((results: number[]) => {
          let sum = 0;
          results.forEach((product: number) => (sum += product));
          return sum;
        }),
      );
    }
  }

  private calculateTransmitterConfigurationCosts(transmitter: TransmitterConfiguration): Observable<number> {
    const placedProducts = transmitter.countPlacedProducts();
    const product$ = this.productService
      .getProductById(transmitter.productId)
      .pipe(map((product) => (product ? product.price * placedProducts : 0)));
    let attachments$: Observable<number>;
    if (transmitter.attachmentIds.length) {
      attachments$ = this.productService.getProductsByIds(transmitter.attachmentIds, ProductType.ATTACHMENT).pipe(
        map((attachments: Product[]) => {
          return this.calculateAttachmentCosts(attachments, placedProducts);
        }),
      );
    }
    if (transmitter.sensorId) {
      const sensor$ = this.productService
        .getProductById(transmitter.sensorId)
        .pipe(map((sensor) => (sensor ? sensor.price * placedProducts : 0)));
      if (transmitter.attachmentIds.length) {
        return forkJoin([product$, attachments$!, sensor$]).pipe(
          map((results: number[]) => {
            let sum = 0;
            results.forEach((product: number) => (sum += product));
            return sum;
          }),
        );
      } else {
        return forkJoin([product$, sensor$]).pipe(
          map((results: number[]) => {
            let sum = 0;
            results.forEach((product: number) => (sum += product));
            return sum;
          }),
        );
      }
    } else {
      if (transmitter.attachmentIds.length) {
        return forkJoin([product$, attachments$!]).pipe(
          map((results: number[]) => {
            let sum = 0;
            results.forEach((product: number) => (sum += product));
            return sum;
          }),
        );
      } else {
        return forkJoin([product$]).pipe(
          map((results: number[]) => {
            let sum = 0;
            results.forEach((product: number) => (sum += product));
            return sum;
          }),
        );
      }
    }
  }

  private calculateAlarmDeviceConfigurationCosts(alarmDevice: AlarmDeviceConfiguration): Observable<number> {
    const placedProducts = alarmDevice.countPlacedProducts();
    const productCosts$ = this.productService
      .getProductsByIds(alarmDevice.productIds, ProductType.ALARMDEVICE)
      .pipe(
        map((products) =>
          products
            .map((product) => product.price * placedProducts)
            .reduce((productCost1, productCost2) => productCost1 + productCost2),
        ),
      );

    if (alarmDevice.attachmentIds.length > 0) {
      const attachmentCosts$ = this.productService.getProductsByIds(alarmDevice.attachmentIds, ProductType.ATTACHMENT).pipe(
        map((attachments: Product[]) => {
          return this.calculateAttachmentCosts(attachments, placedProducts);
        }),
      );

      return forkJoin([productCosts$, attachmentCosts$]).pipe(
        map((results: number[]) => {
          return results.reduce((costs1, costs2) => costs1 + costs2);
        }),
      );
    } else {
      return productCosts$;
    }
  }

  private calculateDefaultProductConfigurationCosts(productConfig: ProductConfiguration, productId: string): Observable<number> {
    const placedProducts = productConfig.countPlacedProducts();
    return this.productService.getProductById(productId).pipe(map((product) => (product ? product.price * placedProducts : 0)));
  }

  private calculateAttachmentCosts(attachments: Product[], placedProducts: number): number {
    let sum = 0;
    attachments.forEach((attachment) => {
      if (!attachment.isAvailable) {
        return;
      }
      if (attachment.fullType == FullProductType.ATTACHMENT_INSTRUCTIONS) {
        sum += attachment.price;
      } else {
        sum += attachment.price * placedProducts;
      }
    });
    return sum;
  }
}
