import { Component, inject, Input, ViewChildren } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { UiKitModule } from "@app/ui-kit.module";
import { ConfirmModalComponent } from "@components/confirm-modal/confirm-modal.component";
import {
  FloorplanUploadOverlayComponent,
  UploadedFloorplan,
} from "@components/floorplan-upload-overlay/floorplan-upload-overlay.component";
import { NewProjectFileCardComponent } from "@components/project-cards/new-project-file-card/new-project-file-card.component";
import { ProjectDetailFileCardComponent } from "@components/project-cards/project-detail-file-card/project-detail-file-card.component";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { Project } from "@domain/project/project";
import { ModalService } from "@odx/angular/components/modal";

@Component({
  selector: "app-floorplan-upload",
  templateUrl: "./floorplan-upload.component.html",
  styleUrls: ["./floorplan-upload.component.scss"],
  standalone: true,
  imports: [UiKitModule, FloorplanUploadOverlayComponent, ProjectDetailFileCardComponent],
})
export class FloorplanUploadComponent {
  private readonly modalService = inject(ModalService);

  @ViewChildren("floorplanCard")
  floorplanCards!: NewProjectFileCardComponent[];

  @Input() set projectData(project: Project) {
    this.project = project;
  }

  project!: Project;

  constructor(private router: Router) {}

  onDelete(floorplan: Floorplan) {
    this.modalService
      .open(ConfirmModalComponent, {
        data: {
          text: $localize`:@@modal.deleteFloorplan:Wollen Sie den Grundrissplan wirklich löschen?`,
        },
      })
      .onClose$.subscribe(() => this.project.deleteFloorplan(floorplan));
  }

  onCardSubmit(form: FormGroup, plan: Floorplan) {
    plan.name = form.value.name;
  }

  onPlanUploaded(plan: UploadedFloorplan) {
    this.project.addFloorplan(plan.name, plan.fileUrl);
  }

  openFloorplan(floorplan: Floorplan) {
    this.router.navigate(["/projects", this.project.id, "floorplans", floorplan.id]);
  }
}
