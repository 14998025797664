<section odxLayout="flex vertical-center horizontal-center" class="section">
  <header odxLayout="flex vertical-center">
    <div class="steps" odxLayout="grid 4">
      <div *ngFor="let step of steps; let i = index" class="step-container" odxLayout="flex gap-small">
        {{ step.label }}
        <div class="step" odxLayout="flex vertical-center horizontal-center" [ngClass]="{ active: selectedIndex === i }">
          <span class="odx-text">{{ i + 1 }}</span>
        </div>
      </div>
    </div>
  </header>

  <div class="content" [style.display]="selected ? 'block' : 'none'">
    <ng-container *ngIf="selected" [ngTemplateOutlet]="selected.content"></ng-container>
  </div>
</section>
