import { Component, Input, Output } from "@angular/core";
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { ConfigurationWizardHeaderComponent } from "@components/configuration-wizard-header/configuration-wizard-header.component";
import { Product } from "@domain/product/product";
import { defer, filter, map, of } from "rxjs";

export interface PlasticSignSpecs {
  text: string;
  backgroundColor: string;
  textColor: string;
}

interface PlasticSignProducts {
  selectedPlasticSign: Product;
  preselectedSpecs?: PlasticSignSpecs;
}

@Component({
  selector: "app-plastic-sign-specs",
  templateUrl: "./plastic-sign-specs.component.html",
  styleUrls: ["./plastic-sign-specs.component.scss"],
  standalone: true,
  imports: [UiKitModule, ReactiveFormsModule, ConfigurationWizardHeaderComponent],
})
export class PlasticSignSpecsComponent {
  @Input({ required: true }) showCosts!: boolean;

  @Input({ required: true }) set selectedProduct(product: PlasticSignProducts) {
    this.specsForm.patchValue({ text: null, backgroundColor: null, textColor: null });
    this.selectedPlasticSign = product.selectedPlasticSign;
    if (product.preselectedSpecs) {
      this.specsForm.patchValue({
        text: product.preselectedSpecs.text,
        backgroundColor: product.preselectedSpecs.backgroundColor,
        textColor: product.preselectedSpecs.textColor,
      });
    }
  }

  protected specsForm = this.formBuilder.group({
    text: new FormControl<null | string>(null, Validators.required),
    backgroundColor: new FormControl<null | string>(null, Validators.required),
    textColor: new FormControl<null | string>(null, Validators.required),
  });

  @Output() formReady = of(this.specsForm);

  @Output() specs$ = defer(() =>
    this.specsForm.valueChanges.pipe(
      filter((formValue) => !!formValue.text && !!formValue.textColor && !!formValue.backgroundColor),
      map((formValue) => formValue as PlasticSignSpecs),
    ),
  );

  protected selectedPlasticSign!: Product;

  constructor(private formBuilder: FormBuilder) {}
}
