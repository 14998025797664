import { Component } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { LocalizeGasNamePipe } from "@domain/gas/gas";
import { GasService } from "@domain/gas/gas.service";
import { LocalizeProductNamePipe, Product } from "@domain/product/product";
import { ProductService } from "@domain/product/product.service";
import { TransmitterConfiguration } from "@domain/project/configurations/transmitter-configuration";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { ProjectEventType } from "@domain/project/project-event";
import { untilDestroyed } from "@odx/angular/utils";
import { ConfiguredProductComponent } from "@project/floorplanner/floorplan-menu/configured-product/configured-product.component";
import { FloorplanProductMenuComponent } from "@project/floorplanner/floorplan-menu/floorplan-product-menu/floorplan-product-menu.component";
import { FloorplanService } from "@project/floorplanner/floorplan.service";
import { ProjectStateService } from "@project/project-state.service";
import { Observable, forkJoin, map, of } from "rxjs";

@Component({
  selector: "app-floorplan-transmitter-menu",
  templateUrl: "./floorplan-transmitter-menu.component.html",
  styleUrls: ["./floorplan-transmitter-menu.component.scss"],
  standalone: true,
  imports: [UiKitModule, FloorplanProductMenuComponent, ConfiguredProductComponent],
  providers: [LocalizeGasNamePipe, LocalizeProductNamePipe],
})
export class FloorplanTransmitterMenuComponent {
  protected floorplan: Floorplan;
  protected transmitterSubtitles = new Map<TransmitterConfiguration, Observable<string>>();
  private takeUntilDestroyed = untilDestroyed();

  protected readonly tooltipTextShowMonitoringArea: string = $localize`:@@floorplan.button.showMonitoringArea:Überwachungsbereich einblenden`;
  protected readonly tooltipTextHideMonitoringArea: string = $localize`:@@floorplan.button.hideMonitoringArea:Überwachungsbereich ausblenden`;
  protected readonly tooltipTextTransmitters: string = $localize`:@@global.transmitters:Transmitter`;

  constructor(
    private floorplanService: FloorplanService,
    private gasService: GasService,
    private productService: ProductService,
    private projectStateService: ProjectStateService,
    private localizeGasNamePipe: LocalizeGasNamePipe,
    private localizeProductNamePipe: LocalizeProductNamePipe,
  ) {
    this.floorplan = floorplanService.selectedFloorplan;
    this.floorplan.project.transmitters.forEach((transmitter) => {
      this.transmitterSubtitles.set(transmitter, this.subtitlePrefix$(transmitter));
    });
    this.projectStateService.projectEvents$.pipe(this.takeUntilDestroyed()).subscribe((event) => {
      if (event.type === ProjectEventType.TRANSMITTER_CONFIGURATION_DELETED) {
        this.transmitterSubtitles.delete(event.subject);
      }
    });
  }

  protected addTransmitter(transmitter: TransmitterConfiguration) {
    this.floorplanService.addTransmitter(transmitter);
  }

  protected deleteTransmitter(transmitter: TransmitterConfiguration) {
    this.floorplan.project.deleteTransmitter(transmitter);
  }

  protected toggleMonitoringAreaVisibility() {
    this.floorplan.floorplanState.monitoringAreasVisible = !this.floorplan.floorplanState.monitoringAreasVisible;
  }

  protected addPlaceholder() {
    this.floorplanService.addTransmitterPlaceholder();
  }

  private subtitlePrefix$(transmitter: TransmitterConfiguration) {
    const gas$ = this.gasService.getGasById(transmitter.gasId);

    let sensor$: Observable<Product | undefined> = of(undefined);
    if (transmitter.sensorId) {
      sensor$ = this.productService.getProductById(transmitter.sensorId);
    }

    return forkJoin([gas$, sensor$]).pipe(
      map((results: any[]) => {
        const gas = results[0];
        const sensor = results[1];

        let subtitle = this.localizeGasNamePipe.transform(gas);

        if (sensor != null) {
          subtitle += `, ${this.localizeProductNamePipe.transform(sensor)}`;
        }
        return subtitle;
      }),
    );
  }
}
