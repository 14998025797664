import { Floorplan } from "@domain/project/floorplan/floorplan";
import { FloorplanEventType } from "@domain/project/floorplan/floorplan-event";
import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { Project } from "@domain/project/project";
import { ProjectImage } from "@domain/project/project-image";
import { Exclude, Expose } from "class-transformer";
import { IsNumber, IsPositive, IsString } from "class-validator";
import { v4 as uuidv4 } from "uuid";

export class FloorplanImage extends FloorplanItem {
  @Exclude()
  private _projectImage: ProjectImage;

  @IsString()
  @Expose({ name: "projectImageId" })
  private readonly _projectImageId: string;

  @IsNumber()
  @IsPositive({ message: "The value must be higher than 0" })
  @Expose({ name: "size" })
  private _size: number;

  constructor(floorplan: Floorplan, projectImage: ProjectImage, id: string, x: number, y: number, size: number, notes?: string) {
    super(floorplan, id, x, y, notes);
    this._projectImageId = projectImage?.id;
    this._projectImage = projectImage;
    this._size = size;
  }

  get size(): number {
    return this._size;
  }

  updateSize(value: number, x: number, y: number) {
    this._size = value;
    this._floorplan.publishUpdate(FloorplanEventType.ITEM_SIZE_CHANGED, this);
    this.updatePosition(x, y);
  }

  get projectImage(): ProjectImage {
    return this._projectImage;
  }

  override init(project: Project, floorplan: Floorplan) {
    super.init(project, floorplan);
    if (this._projectImage) {
      return;
    }

    const image = project.getImageById(this._projectImageId);
    if (!image) {
      throw new Error(`Image with id '${this._projectImageId}' is not contained in project with id '${project.id}' anymore`);
    }
    this._projectImage = image;
  }

  override duplicate(x: number, y: number): FloorplanItem {
    return this._floorplan.addImage(this._projectImageId, x, y, this.size);
  }

  override delete() {
    this._floorplan.deleteImage(this);
  }

  override get relatedItems(): FloorplanItem[] {
    return this._floorplan.images;
  }

  protected override refreshFloorplanLockState() {
    this._floorplan.floorplanState.refreshImagesLock();
  }

  static create(floorplan: Floorplan, projectImage: ProjectImage, x: number, y: number, size: number): FloorplanImage {
    return new FloorplanImage(floorplan, projectImage, uuidv4(), x, y, size);
  }
}
