import { ProductConfiguration } from "@domain/project/configurations/product-configuration";
import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { FloorplanPlaceholder } from "@domain/project/floorplan/floorplan-placeholder";
import { FloorplanProductItem } from "@domain/project/floorplan/floorplan-product-item";
import { FloorplanText } from "@domain/project/floorplan/floorplan-text";
import { FloorplanTransmitterPlaceholder } from "@domain/project/floorplan/floorplan-transmitter-placeholder";
import { FloorplanWorkspaceKonvaAdapter } from "@project/floorplanner/konva/floorplan-workspace-konva.adapter";
import { ImageKonva } from "@project/floorplanner/konva/image-konva";
import { PositionIdTextKonva } from "@project/floorplanner/konva/position-id-text-konva";
import Konva from "konva";
import { Group } from "konva/lib/Group";
import { Text } from "konva/lib/shapes/Text";

export class IconKonva {
  static init(
    element: FloorplanPlaceholder | FloorplanTransmitterPlaceholder | FloorplanProductItem<ProductConfiguration> | FloorplanText,
    icon: Konva.Image,
    warningSignIcon: Konva.Image,
    dragMoveCallback?: (icon: Konva.Image) => void,
  ) {
    const iconGroup = new Group({
      id: element.id,
    });
    iconGroup.setAttr(FloorplanWorkspaceKonvaAdapter.OBJECT_KEY, element);

    const positionId = PositionIdTextKonva.init(element);
    iconGroup.add(positionId);

    icon.setAttrs({
      x: element.x,
      y: element.y,
      scaleX: element.size,
      scaleY: element.size,
      fontFamily: "Times New Roman",
      draggable: !element.locked,
    });
    icon.setAttr(FloorplanWorkspaceKonvaAdapter.OBJECT_KEY, element);

    ImageKonva.setTextPosition(positionId, icon);

    icon.on("transform", () => {
      ImageKonva.setTextPosition(positionId, icon);
    });
    icon.on("transformend", () => {
      element.updateSize(icon.scaleX(), icon.x(), icon.y());
    });
    icon.on("dragmove", () => {
      ImageKonva.setTextPosition(positionId, icon);
      if (dragMoveCallback) {
        dragMoveCallback(icon);
      }
    });

    iconGroup.add(icon);
    this.attachWarningSign(icon, warningSignIcon, iconGroup);
    return iconGroup;
  }

  static refreshPositionId(
    productItem: FloorplanItem | FloorplanProductItem<ProductConfiguration> | FloorplanText,
    group: Group,
  ) {
    const positionId = group.getChildren()[0] as Text;
    const image = group.getChildren()[1] as Konva.Image;
    positionId.text(productItem.positionId!);
    ImageKonva.setTextPosition(positionId, image);
  }

  static refreshWarningSignVisibility(iconGroup: Group, visible: boolean) {
    const warningSign = iconGroup.getChildren()[2];
    warningSign.visible(visible);
  }

  private static attachWarningSign(icon: Konva.Image, warningSignIcon: Konva.Image, iconGroup: Group) {
    warningSignIcon.visible(false);
    IconKonva.setWarningSize(warningSignIcon, icon);
    IconKonva.setWarningPosition(warningSignIcon, icon);

    icon.on("transform", () => {
      IconKonva.setWarningSize(warningSignIcon, icon);
      IconKonva.setWarningPosition(warningSignIcon, icon);
    });
    icon.on("dragmove", () => {
      IconKonva.setWarningPosition(warningSignIcon, icon);
    });

    iconGroup.add(warningSignIcon);
  }

  private static setWarningPosition(warningIcon: Konva.Image, floorplanIcon: Konva.Image) {
    warningIcon.x(
      floorplanIcon.x() + floorplanIcon.width() * floorplanIcon.scaleX() - 0.9 * warningIcon.width() * warningIcon.scaleX(),
    );
    warningIcon.y(floorplanIcon.y());
  }

  private static setWarningSize(warningIcon: Konva.Image, floorplanIcon: Konva.Image) {
    const scalingFactor: number = 0.3;
    warningIcon.scaleX(floorplanIcon.scaleX() * scalingFactor);
    warningIcon.scaleY(floorplanIcon.scaleY() * scalingFactor);
  }
}
