import { Injectable } from "@angular/core";
import { Project } from "@domain/project/project";
import { validate, ValidationError } from "class-validator";
import { from, map, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class ProjectValidationService {
  constructor() {}

  validateTypes(project: Project): Observable<ValidationError[]> {
    return from(validate(project, { skipMissingProperties: true })).pipe(
      map((errors: ValidationError[]) => {
        if (errors.length > 0) {
          return errors;
        } else {
          return [];
        }
      }),
    );
  }

  validateContent(project: Project) {
    project.validate();
  }
}
