import { DefaultRow } from "@pdf/helpers/default-row/default-row";
import { jsPDF } from "jspdf";

export class AirPathsRow extends DefaultRow {
  readonly number: string[];
  readonly notes: string[];

  constructor(pdf: jsPDF, number: string[], notes: string[]) {
    super();
    this.number = number;
    this.notes = notes;
    this._textHeight = this.calculateRowTextHeight(pdf);
    this._rowHeight = this.calculateRowHeight(this.textHeight);
  }

  private calculateRowTextHeight(pdf: jsPDF) {
    return Math.max(...[this.calculateCellTextHeight(pdf, this.number), this.calculateCellTextHeight(pdf, this.notes)]);
  }
}
