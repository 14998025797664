import { Component } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";

@Component({
  selector: "app-floorplan-menu-item-buttons",
  templateUrl: "./floorplan-menu-item-buttons.component.html",
  styleUrl: "./floorplan-menu-item-buttons.component.scss",
  standalone: true,
  imports: [UiKitModule],
})
export class FloorplanMenuItemButtonsComponent {}
