import { Component, Input } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { StandardImageComponent } from "@components/standard-image/standard-image.component";
import { Gas, LocalizeGasNamePipe } from "@domain/gas/gas";
import { LocalizeProductNamePipe, Product, ProductPropertyDimension } from "@domain/product/product";

import { TransmitterConfiguration } from "@domain/project/configurations/transmitter-configuration";

@Component({
  selector: "app-configuration-wizard-header",
  templateUrl: "./configuration-wizard-header.component.html",
  styleUrls: ["./configuration-wizard-header.component.scss"],
  standalone: true,
  imports: [UiKitModule, StandardImageComponent, LocalizeProductNamePipe, LocalizeGasNamePipe],
})
export class ConfigurationWizardHeaderComponent {
  @Input()
  product?: Product;

  dimensionsProperty?: ProductPropertyDimension;
  private readonly DIMENSIONS = "Dimensions_";

  protected gas?: Gas;
  protected dimensions?: string;
  protected imageUrl?: string;
  protected fallbackImageUrl?: string;

  @Input()
  set data(data: any) {
    if (data instanceof Gas) {
      this.gas = data;
    } else if (data instanceof Product) {
      this.product = data;
      this.imageUrl = "https://product-assets.draeger.net/156px/navinta/" + this.product.id + ".png";
      this.fallbackImageUrl = this.getFallbackImageSource();
      if (this.product.type === "TRANSMITTER" || this.product.type === "GASWARNINGCENTER") {
        this.dimensionsProperty = this.product.getDimensionsPropertyByKey(this.DIMENSIONS);
        if (this.dimensionsProperty) {
          this.dimensions = this.dimensionsToString();
        }
      }
    }
  }

  @Input()
  showCosts: boolean = false;

  constructor() {}

  private dimensionsToString() {
    return (
      this.dimensionsProperty!.height * 1000 +
      "mm × " +
      this.dimensionsProperty!.width * 1000 +
      "mm × " +
      this.dimensionsProperty!.depth * 1000 +
      "mm"
    );
  }

  protected getFallbackImageSource() {
    if (this.product instanceof Product) {
      if (this.product.type === "GASWARNINGCENTER") {
        const types = ["2400", "3900", "3000"];
        const type = types.find((type) => this.product!.localizedName["de-DE"].indexOf(type) > -1) || "";
        return "https://product-assets.draeger.net/documents/navinta/placeholderProductImages/Regard_" + type + ".png";
      }
      if (this.product.type === "TRANSMITTER") {
        return (
          "https://product-assets.draeger.net/documents/navinta/placeholderProductImages/" +
          TransmitterConfiguration.getProductDataName(this.product!.localizedName["de-DE"]) +
          ".png"
        );
      }
    }
    return undefined;
  }
}
