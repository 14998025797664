import { Component, Input, Output } from "@angular/core";
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { PictureCardComponent } from "@components/picture-card/picture-card.component";
import { ProductImage } from "@domain/product/product-image";
import { GasWarningCenterType } from "@project/gas-warning-center-configuration/gas-warning-center-type";
import { defer, filter, map, of } from "rxjs";

@Component({
  selector: "app-gas-warning-center-type-selector",
  templateUrl: "./gas-warning-center-type-selector.component.html",
  styleUrls: ["./gas-warning-center-type-selector.component.scss"],
  standalone: true,
  imports: [UiKitModule, ReactiveFormsModule, PictureCardComponent],
})
export class GasWarningCenterTypeSelectorComponent {
  @Input() set selectedType(configName: string | undefined) {
    if (configName) {
      const gasWarningCenterType = GasWarningCenterType.TYPES.find((gasWarningCenterType) =>
        gasWarningCenterType.isMatchingProduct(configName),
      );
      this.typeForm.patchValue({ selectedType: gasWarningCenterType! });
    }
  }

  protected typeForm = this.formBuilder.group({
    selectedType: new FormControl<null | GasWarningCenterType>(null, Validators.required),
  });

  @Output()
  formReady = of(this.typeForm);

  @Output()
  typeSelected = defer(() =>
    this.typeForm.valueChanges.pipe(
      filter((formValue) => !!formValue.selectedType),
      map((formValue) => formValue.selectedType!),
    ),
  );

  protected readonly GasWarningCenterType = GasWarningCenterType;
  protected readonly ProductImage = ProductImage;

  constructor(private formBuilder: FormBuilder) {}

  protected onTypeClicked(gasWarningCenterType: GasWarningCenterType) {
    this.typeForm.patchValue({
      selectedType: this.typeForm.value === gasWarningCenterType ? undefined : gasWarningCenterType,
    });
  }
}
