import { ChecklistRow } from "@pdf/export-services/checklist-page/checklist-row";
import { TableColumn } from "@pdf/helpers/default-table/table-column";
import { jsPDF } from "jspdf";

interface RowData {
  title: string;
  text: string;
  checked?: boolean;
}

export class ChecklistTable {
  readonly columns: TableColumn[];
  readonly rows: ChecklistRow[];

  constructor(pdf: jsPDF, checklistTableRows: RowData[]) {
    this.columns = [new TableColumn("", 60, 584), new TableColumn("", 692, 96), new TableColumn("", 804, 584)];

    this.rows = checklistTableRows.map((checklistTableRow) => new ChecklistRow(pdf, this.columns, checklistTableRow));
  }
}
