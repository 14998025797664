import { ValidationArguments, ValidatorConstraint, ValidatorConstraintInterface } from "class-validator";

@ValidatorConstraint({ name: "isArrayOfInstancesOf", async: false })
export class IsArrayOfInstancesOfConstraint implements ValidatorConstraintInterface {
  validate(value: any, args: ValidationArguments) {
    const targetClass = args.constraints[0];
    if (!Array.isArray(value)) {
      return false;
    }
    return value.every((item: any) => item instanceof targetClass);
  }

  defaultMessage(args: ValidationArguments) {
    const targetClassName = args.constraints[0].name;
    return `${args.property} must be an array of instances of ${targetClassName}`;
  }
}
