import { CommonModule } from "@angular/common";
import { Component, Input, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { ContactPersonFormComponent } from "@components/project-forms/contact-person-form/contact-person-form.component";
import { CustomerFormComponent } from "@components/project-forms/customer-form/customer-form.component";
import { GoodsRecipientFormComponent } from "@components/project-forms/goods-recipient/goods-recipient-form.component";
import { ProjectFormComponent } from "@components/project-forms/project-form/project-form.component";
import { ProjectFormsService } from "@components/project-forms/project-forms.service";
import { Project } from "@domain/project/project";
import { ProjectService } from "@domain/project/project.service";

@Component({
  selector: "app-project-information",
  templateUrl: "./project-information.component.html",
  styleUrls: ["./project-information.component.scss"],
  standalone: true,
  imports: [
    UiKitModule,
    ReactiveFormsModule,
    ProjectFormComponent,
    CustomerFormComponent,
    ContactPersonFormComponent,
    GoodsRecipientFormComponent,
    CommonModule,
  ],
})
export class ProjectInformationComponent {
  @Input() set projectData(project: Project) {
    this.project = project;
    this.projectFormsService.initializeProject(project);
    this.initSwitchForm();
  }

  editEnabled: boolean = false;
  goodsRecipientForm?: FormGroup;

  // Needed to show validation errors on submit without userinput
  @ViewChild("projectFormComponent")
  projectFormComponent!: ProjectFormComponent;
  @ViewChild("customerFormComponent")
  customerFormComponent!: CustomerFormComponent;
  @ViewChild("contactPersonFormComponent")
  contactPersonFormComponent!: ContactPersonFormComponent;
  @ViewChild("goodsRecipientFormComponent")
  goodsRecipientFormComponent!: GoodsRecipientFormComponent;

  switchForm!: FormGroup;

  private project!: Project;

  constructor(
    private formBuilder: FormBuilder,
    private projectFormsService: ProjectFormsService,
    private projectService: ProjectService,
  ) {}

  onEdit() {
    if (!this.editEnabled) {
      this.editEnabled = true;
      return;
    }
    this.onReset();
    this.editEnabled = false;
  }

  onSubmit() {
    this.projectFormComponent?.triggerSubmit();
    this.customerFormComponent?.triggerSubmit();
    this.contactPersonFormComponent?.triggerSubmit();
    this.goodsRecipientFormComponent?.triggerSubmit();
    if (
      !this.projectFormComponent.projectForm?.valid ||
      !this.customerFormComponent.customerForm?.valid ||
      !this.contactPersonFormComponent.contactPersonForm?.valid ||
      !this.goodsRecipientForm?.valid
    ) {
      return;
    }
    this.mapProject();
    this.projectService.update(this.project).subscribe();
    this.editEnabled = false;
  }

  private onReset() {
    this.projectFormsService.resetProject(this.project!);
    this.editEnabled = false;
  }

  private mapProject() {
    const projectForm = this.projectFormComponent.projectForm;
    this.project!.name = projectForm?.value.name;
    this.project!.forzaId = projectForm?.value.forzaId;
    this.project!.placeName = projectForm?.value.placeName;
    this.project!.notes = projectForm?.value.notes;

    const customerForm = this.customerFormComponent.customerForm;
    this.project!.customer!.name = customerForm?.value.name;
    this.project!.customer!.customerNumber = customerForm?.value.customerNumber;
    this.project!.customer!.street = customerForm?.value.street;
    this.project!.customer!.zipCode = customerForm?.value.zipCode;
    this.project!.customer!.city = customerForm?.value.city;

    const contactPersonForm = this.contactPersonFormComponent.contactPersonForm;
    this.project!.contactPerson!.firstname = contactPersonForm?.value.firstname;
    this.project!.contactPerson!.lastname = contactPersonForm?.value.lastname;
    this.project!.contactPerson!.department = contactPersonForm?.value.department;
    this.project!.contactPerson!.email = contactPersonForm?.value.email;
    this.project!.contactPerson!.phoneNumber = contactPersonForm?.value.phoneNumber;

    this.project!.goodsRecipient!.information = this.goodsRecipientForm?.value.information;

    this.project!.lastModified = new Date();
  }

  private initSwitchForm(): void {
    this.switchForm = this.formBuilder.group({
      switch: [this.project.showCostFlag],
    });
    this.switchForm.valueChanges.subscribe(() => {
      this.project.showCostFlag = this.switchForm.value.switch;
      this.project.lastModified = new Date();
      this.projectService.update(this.project).subscribe();
    });
  }
}
