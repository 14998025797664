import { Product } from "@domain/product/product";
import { AlarmDeviceConfiguration } from "@domain/project/configurations/alarm-device-configuration";

export class OriginalAlarmDeviceConfiguration {
  productConfiguration: AlarmDeviceConfiguration;
  alarmDevices: Product[];
  attachments: Product[];

  constructor(productConfiguration: AlarmDeviceConfiguration, alarmDevices: Product[], attachments: Product[]) {
    this.productConfiguration = productConfiguration;
    this.alarmDevices = alarmDevices;
    this.attachments = attachments;
  }
}
