import { Component, Input } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";

@Component({
  selector: "app-standard-image",
  templateUrl: "./standard-image.component.html",
  styleUrl: "./standard-image.component.scss",
  standalone: true,
  imports: [UiKitModule],
})
export class StandardImageComponent {
  @Input() imageUrl?: string;
  @Input() fallbackImageUrl?: string;
  @Input() iconSize: string = "150px";

  protected imageNotFound = false;

  handleImageNotFound($event: ErrorEvent) {
    if (!this.fallbackImageUrl) {
      this.imageNotFound = true;
      return;
    }

    if (($event.target as HTMLImageElement).src == this.imageUrl) {
      ($event.target as HTMLImageElement).src = this.fallbackImageUrl;
    } else {
      this.imageNotFound = true;
    }
  }

  resetImageSource() {
    this.imageNotFound = false;
  }
}
