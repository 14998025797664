import { Component, Input, OnInit } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { ButtonVariant } from "@odx/angular/components/button";

@Component({
  selector: "app-floorplan-menu-button",
  templateUrl: "./floorplan-menu-button.component.html",
  styleUrl: "./floorplan-menu-button.component.scss",
  standalone: true,
  imports: [UiKitModule],
})
export class FloorplanMenuButtonComponent implements OnInit {
  @Input({ required: true }) expanded!: boolean;
  @Input({ required: true }) title!: string;
  @Input() odxVariant: ButtonVariant | null | undefined = "secondary";
  @Input() imageUrl?: string;
  @Input() odxIcon?: string;

  ngOnInit(): void {
    if (!this.imageUrl && !this.odxIcon) {
      throw new Error("Either imageUrl or odxIcon input must be set");
    }
  }
}
