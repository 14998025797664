import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { MeasurementLine } from "@domain/project/floorplan/measurement-line";
import { FloorplanItemDetailsComponent } from "@project/floorplanner/floorplan-additional-notes/floorplan-item-details/floorplan-item-details.component";

@Component({
  selector: "app-measurement-line-notes",
  templateUrl: "./measurement-line-notes.component.html",
  styleUrls: ["./measurement-line-notes.component.scss"],
  standalone: true,
  imports: [UiKitModule, ReactiveFormsModule, FloorplanItemDetailsComponent],
})
export class MeasurementLineNotesComponent implements OnInit {
  @Input({ required: true }) set floorplanItem(floorplanItem: FloorplanItem) {
    this.measurementLine = floorplanItem as MeasurementLine;
  }

  protected measurementLineForm!: FormGroup;
  protected measurementLine!: MeasurementLine;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.measurementLineForm = this.formBuilder.group({
      text: [this.measurementLine.text, Validators.required],
    });

    this.measurementLineForm.valueChanges.subscribe(() => {
      if (!this.measurementLineForm.valid || this.measurementLineForm.pristine) return;
      this.update();
    });
  }

  private update() {
    this.measurementLine.text = this.measurementLineForm.value.text;
  }
}
