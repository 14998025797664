<odx-content-box class="flex-column h-100 scroll" odxLayout="flex">
  <odx-content-box-header>
    <h5
      class="odx-title-5"
      i18n="@@gasWarningCenterConfiguration.selectGasWarningCenter.header"
      data-testid="gasWarningCenterConfiguration.selectGasWarningCenter.header"
    >
      Gaswarnzentrale auswählen
    </h5>
  </odx-content-box-header>

  <app-filter-product (search)="search($event)"></app-filter-product>
  <form [formGroup]="gasWarningCenterForm" class="h-100" *ngIf="filteredGasWarningCenters$ | async; let gasWarningCenters">
    <odx-radio-group formControlName="selectedGasWarningCenter" required="true" class="h-100">
      <div odxLayout="grid 3@desktop 6@tablet 12@phone" class="w-100 scroll mb-36">
        <app-gas-warning-center-card
          class="overflow-hidden"
          *ngFor="let gasWarningCenter of gasWarningCenters"
          [gasWarningCenterData]="gasWarningCenter"
          [showCosts]="project.showCostFlag"
          [isSelected]="gasWarningCenterForm.value.selectedGasWarningCenter?.id === gasWarningCenter.id"
          [gasWarningCenterType]="gasWarningCenterType!"
          (click)="select(gasWarningCenter)"
          (gasWarningCenterSelected)="select(gasWarningCenter)"
        >
        </app-gas-warning-center-card>
      </div>
    </odx-radio-group>
  </form>
</odx-content-box>
