import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { STORE_NAME_GASES } from "@app/indexed-db-config";
import { Gas } from "@domain/gas/gas";
import { environment } from "@environments/environment";
import { ObservableInstanceMapper } from "@utils/observable-instance-mapper";
import { instanceToPlain } from "class-transformer";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { Observable, forkJoin, map, of, switchMap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GasService {
  public static readonly GAS_URL = environment.apiUrl + "/gas/gases";

  constructor(
    private http: HttpClient,
    private dbService: NgxIndexedDBService,
    @Inject(LOCALE_ID) private localeId: string,
  ) {}

  loadAll(): Observable<Gas[]> {
    return this.dbService.count(STORE_NAME_GASES).pipe(
      switchMap((count: number) => {
        if (count > 0) {
          return this.loadGasesFromDB().pipe(
            switchMap((gases) => {
              if (this.checkGasDataRevisions(gases)) {
                return of(gases);
              }
              return this.refreshAll();
            }),
          );
        }
        return this.refreshAll();
      }),
    );
  }

  private checkGasDataRevisions(gases: Gas[]) {
    return this.checkForProductSensors(gases) && this.checkForLocalizedNames(gases, this.localeId);
  }

  private checkForProductSensors(gases: Gas[]) {
    return !gases.some((gas) => !gas.productSensors);
  }

  private checkForLocalizedNames(gases: Gas[], key: string) {
    if (!gases[0].definition.localizedName) {
      return false;
    }
    return gases.every((gas) => {
      return key in gas.definition.localizedName;
    });
  }

  refreshAll(): Observable<Gas[]> {
    if (this.localeId === "en-US") {
      return this.loadGasesFromApi(this.localeId).pipe(
        switchMap((gases) => this.clearAll().pipe(map(() => gases))),
        switchMap((gases) => this.storeGasesInDB(gases)),
      );
    }
    return forkJoin([this.loadGasesFromApi("en-US"), this.loadGasesFromApi(this.localeId)]).pipe(
      switchMap((gases) => this.clearAll().pipe(map(() => gases))),
      switchMap((gases) => this.storeGasesInDB(...gases)),
    );
  }

  getGasById(id: string): Observable<Gas | undefined> {
    return ObservableInstanceMapper.valueToInstance(this.dbService.getByKey<Gas>(STORE_NAME_GASES, id), Gas);
  }

  clearAll(): Observable<boolean> {
    return this.dbService.clear(STORE_NAME_GASES);
  }

  private loadGasesFromApi(languageString: string): Observable<Gas[]> {
    const params = new HttpParams().set("lang", languageString);
    return ObservableInstanceMapper.valuesToInstance(
      this.http.get<Gas[]>(GasService.GAS_URL, { params }).pipe(map((json: any) => json)),
      Gas,
    );
  }

  private loadGasesFromDB(): Observable<Gas[]> {
    return ObservableInstanceMapper.valuesToInstance(this.dbService.getAll<Gas>(STORE_NAME_GASES), Gas);
  }

  private storeGasesInDB(gasesEnglish: Gas[], gasesCurrentLocale?: Gas[]): Observable<Gas[]> {
    return this.dbService
      .bulkAdd(
        STORE_NAME_GASES,
        // get rid of unknown properties by adding excludeExtraneousValues option
        gasesEnglish.map((gas) => {
          gas.id = gas.definition.casNumber;
          gas.productSensors = gas.products_sensor;
          gas.definition.localizedName = { "en-US": gas.definition.name };
          if (gasesCurrentLocale) {
            const matchingGases = gasesCurrentLocale.filter(
              (localeGas) => localeGas.definition.casNumber === gas.definition.casNumber,
            );
            if (matchingGases.length === 1) {
              gas.definition.localizedName[this.localeId] = matchingGases[0].definition.name;
            }
          }
          return instanceToPlain(gas, { excludeExtraneousValues: true });
        }),
      )
      .pipe(map(() => gasesEnglish)); // return added gas instances not the plain ones from dbService.add
  }
}
