import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { Expose } from "class-transformer";
import { IsEnum, IsNumber, IsPositive } from "class-validator";

import { Floorplan } from "@domain/project/floorplan/floorplan";
import { v4 as uuidv4 } from "uuid";

export enum AirPathDirection {
  SUPPLY_AIR = "SUPPLY_AIR",
  EXHAUST_AIR = "EXHAUST_AIR",
}

export class AirPath extends FloorplanItem {
  @IsNumber()
  @IsPositive({ message: "The value must be higher than 0" })
  @Expose({ name: "length" })
  private _length: number;

  @IsNumber()
  @Expose({ name: "strokeWidth" })
  private _strokeWidth: number;

  @IsNumber()
  @Expose({ name: "rotation" })
  private _rotation: number;

  @IsEnum(AirPathDirection)
  public direction: AirPathDirection;

  constructor(
    floorplan: Floorplan,
    id: string,
    direction: AirPathDirection,
    x: number,
    y: number,
    length: number = 150,
    strokeWidth: number = 20,
    rotation: number = 0,
  ) {
    super(floorplan, id, x, y);
    this._length = length;
    this._strokeWidth = strokeWidth;
    this._rotation = rotation;
    this.direction = direction;
  }

  get length(): number {
    return this._length;
  }

  get strokeWidth(): number {
    return this._strokeWidth;
  }

  get rotation(): number {
    return this._rotation;
  }

  override duplicate(x: number, y: number): FloorplanItem {
    return this._floorplan.addAirPath(this.direction, x, y, this.length, this.strokeWidth, this.rotation);
  }

  override delete() {
    this._floorplan.deleteAirPath(this);
  }

  override get relatedItems(): FloorplanItem[] {
    return this._floorplan.airPaths;
  }

  updateForm(length: number, strokeWidth: number, rotation: number, x: number, y: number) {
    this._length = length;
    this._strokeWidth = strokeWidth;
    this._rotation = rotation;
    this.updatePosition(x, y);
  }

  protected override refreshFloorplanLockState() {
    this._floorplan.floorplanState.refreshAirPathsLock();
  }

  static create(
    floorplan: Floorplan,
    direction: AirPathDirection,
    x: number,
    y: number,
    length: number,
    strokeWidth: number,
    rotation: number,
  ) {
    return new AirPath(floorplan, uuidv4(), direction, x, y, length, strokeWidth, rotation);
  }
}
