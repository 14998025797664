import { FloorplanImage } from "@domain/project/floorplan/floorplan-image";
import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { FloorplanPlaceholder } from "@domain/project/floorplan/floorplan-placeholder";
import { FloorplanProductItem } from "@domain/project/floorplan/floorplan-product-item";
import { FloorplanText } from "@domain/project/floorplan/floorplan-text";
import { FloorplanTransmitterPlaceholder } from "@domain/project/floorplan/floorplan-transmitter-placeholder";
import { FloorplanWorkspaceKonvaAdapter } from "@project/floorplanner/konva/floorplan-workspace-konva.adapter";
import Konva from "konva";
import { Circle } from "konva/lib/shapes/Circle";
import { Transformer } from "konva/lib/shapes/Transformer";

export class TransformerKonva {
  private transformer = new Transformer({
    rotationSnaps: [0, 45, 90, 135, 180, 225, 270, 315],
    rotationSnapTolerance: 8,
    anchorSize: 15,
    rotateEnabled: true,
    flipEnabled: false,
    keepRatio: false,
  });
  private circleTransformer = new Transformer({
    anchorSize: 15,
    rotateEnabled: false,
    flipEnabled: false,
    keepRatio: true,
    centeredScaling: true,
  });
  private imageTransformer = new Transformer({
    anchorSize: 15,
    enabledAnchors: ["top-left", "top-right", "bottom-left", "bottom-right"],
    rotateEnabled: false,
    flipEnabled: false,
    keepRatio: true,
  });
  private iconTransformer = new Transformer({
    anchorSize: 15,
    rotateEnabled: false,
    flipEnabled: false,
    keepRatio: true,
    enabledAnchors: ["top-right", "top-left", "bottom-right", "bottom-left"],
    centeredScaling: true,
  });
  private lockedTransformer = new Transformer({
    resizeEnabled: false,
    rotateEnabled: false,
    flipEnabled: false,
  });

  get transformers(): Transformer[] {
    return [this.transformer, this.circleTransformer, this.imageTransformer, this.iconTransformer, this.lockedTransformer];
  }

  reset(shape: Konva.Node): void {
    this.clear();
    this.attach(shape);
  }

  clear(): void {
    this.transformer.nodes([]);
    this.circleTransformer.nodes([]);
    this.iconTransformer.nodes([]);
    this.lockedTransformer.nodes([]);
    this.imageTransformer.nodes([]);
  }

  private attach(shape: Konva.Node): void {
    const transformer = this.select(shape);
    transformer.nodes([shape]);
    transformer.moveToTop();
  }

  private select(shape: Konva.Node): Transformer {
    const floorplanItem = shape.getAttr(FloorplanWorkspaceKonvaAdapter.OBJECT_KEY) as FloorplanItem;
    if (floorplanItem.locked) {
      return this.lockedTransformer;
    }

    if (shape instanceof Circle) {
      return this.circleTransformer;
    } else if (floorplanItem instanceof FloorplanImage) {
      return this.imageTransformer;
    } else if (
      floorplanItem instanceof FloorplanProductItem ||
      floorplanItem instanceof FloorplanText ||
      floorplanItem instanceof FloorplanTransmitterPlaceholder ||
      floorplanItem instanceof FloorplanPlaceholder
    ) {
      return this.iconTransformer;
    } else {
      return this.transformer;
    }
  }
}
