<div class="menu-buttons">
  <app-floorplan-menu-item-buttons>
    <button
      odxButton
      variant="primary"
      (click)="imageInput.click()"
      [odxTooltip]="tooltipTextUploadImage"
      [odxTooltipOptions]="{ position: 'bottom' }"
    >
      <odx-icon name="upload" variant="primary"></odx-icon>
    </button>
    <button
      odxButton
      variant="primary"
      (click)="openCamera()"
      [odxTooltip]="tooltipTextTakeImage"
      [odxTooltipOptions]="{ position: 'bottom' }"
    >
      <odx-icon name="camera" variant="primary"></odx-icon>
    </button>
    <app-toggle-visibility-button
      [visible]="floorplan.floorplanState.imagesVisible"
      (toggle)="floorplan.floorplanState.imagesVisible = $event"
    >
    </app-toggle-visibility-button>
    <app-toggle-lock-button
      [locked]="floorplan.floorplanState.imagesLocked"
      (toggle)="floorplan.floorplanState.imagesLocked = $event"
    >
    </app-toggle-lock-button>
    <input
      type="file"
      (change)="onImageFileSelect($event)"
      accept="image/png, image/jpg, image/jpeg, image/gif, image/svg, image/svg+xml"
      class="hidden"
      multiple
      #imageInput
    />
  </app-floorplan-menu-item-buttons>
</div>

<hr />

<div class="imported-images">
  <h6 class="odx-title-6" i18n="@@floorplan.menu.importedImages.header">Importierte Fotos</h6>
  <div class="images-container">
    <p *ngIf="!floorplan.project.images.length" class="odx-text--small" i18n="@@floorplan.menu.noImportedImagesYet">
      Sie haben noch kein Foto importiert.
    </p>
    <div class="image-container" *ngFor="let image of floorplan.project.images" (click)="addImage(image)">
      <!-- prettier-ignore-attribute style -->
      <div class="image" style="background-image: url({{image.fileUrl}});"></div>
      <p class="image-text" [odxTooltip]="image.name">
        {{ image.name }}
      </p>
    </div>
  </div>
</div>
