import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { NewConfigurationButtonComponent } from "@components/new-configuration-button/new-configuration-button.component";
import { PlaceholderButtonComponent } from "@components/placeholder-button/placeholder-button.component";
import { ToggleLockButtonComponent } from "@components/toggle-lock-button/toggle-lock-button.component";
import { ToggleVisibilityButtonComponent } from "@components/toggle-visibility-button/toggle-visibility-button.component";
import { NavigationService } from "@domain/project/navigation.service";
import { FloorplanMenuItemButtonsComponent } from "@project/floorplanner/floorplan-menu/floorplan-menu-item-buttons/floorplan-menu-item-buttons.component";
import { FloorplanService } from "@project/floorplanner/floorplan.service";

@Component({
  selector: "app-floorplan-product-menu",
  templateUrl: "./floorplan-product-menu.component.html",
  styleUrl: "./floorplan-product-menu.component.scss",
  standalone: true,
  imports: [
    UiKitModule,
    NewConfigurationButtonComponent,
    ToggleLockButtonComponent,
    ToggleVisibilityButtonComponent,
    PlaceholderButtonComponent,
    FloorplanMenuItemButtonsComponent,
  ],
})
export class FloorplanProductMenuComponent {
  @Input({ required: true }) title!: string;
  @Input({ required: true }) hasConfigurations!: boolean;
  @Input({ required: true }) configPath!: string;
  @Input({ required: true }) visible!: boolean;
  @Input({ required: true }) locked!: boolean;
  @Output() visibilityUpdate = new EventEmitter<boolean>();
  @Output() lockUpdate = new EventEmitter<boolean>();
  @Output() addPlaceholderToFloorplan = new EventEmitter<boolean>();

  constructor(
    private navigationService: NavigationService,
    private floorplanService: FloorplanService,
  ) {}

  addPlaceholder() {
    this.addPlaceholderToFloorplan.emit();
  }

  goToConfiguration() {
    this.navigationService.toDeviceConfiguration(this.configPath, this.floorplanService.selectedFloorplan.id);
  }

  updateVisibility(visible: boolean) {
    this.visibilityUpdate.emit(visible);
  }

  updateLock(locked: boolean) {
    this.lockUpdate.emit(locked);
  }
}
