import { Product } from "@domain/product/product";
import { ProductConfiguration } from "@domain/project/configurations/product-configuration";
import { Project } from "@domain/project/project";
import { ProjectEventType } from "@domain/project/project-event";
import { Expose, Transform } from "class-transformer";
import { IsArray, IsString } from "class-validator";
import { v4 as uuidv4 } from "uuid";

export class AlarmDeviceConfiguration extends ProductConfiguration {
  @Expose()
  @Transform(
    // migrate single productIds to array for backward compatibility
    (params) => (params.value?.length ? params.value : params.obj.productId ? [params.obj.productId] : []),
    { toClassOnly: true },
  )
  @IsArray()
  @IsString({ each: true })
  public productIds: string[];

  @IsArray()
  @IsString({ each: true })
  public attachmentIds: string[];

  constructor(id: string, name: string, project: Project, productIds: string[], attachmentIds: string[]) {
    super(id, name, project);
    this.productIds = productIds;
    this.attachmentIds = attachmentIds;
  }

  static create(project: Project, alarmDevices: Product[], attachmentIds: string[]): AlarmDeviceConfiguration {
    return new AlarmDeviceConfiguration(
      uuidv4(),
      AlarmDeviceConfiguration.buildName(alarmDevices),
      project,
      alarmDevices.map((alarmDevice) => alarmDevice.id),
      attachmentIds,
    );
  }

  countPlacedProducts(project = this.project): number {
    return project ? project.countPlacedAlarmDevices(this) : 0;
  }

  update(alarmDevices: Product[], attachmentIds: string[]) {
    this.name = AlarmDeviceConfiguration.buildName(alarmDevices);
    this.productIds = alarmDevices.map((alarmDevice) => alarmDevice.id);
    this.attachmentIds = attachmentIds;
    this.project.publishUpdate(ProjectEventType.ALARM_DEVICE_CONFIGURATION_UPDATED, this);
  }

  override get mainProductId(): string {
    return this.productIds[0];
  }

  override get configuredProductIds(): string[] {
    return this.productIds.concat(this.attachmentIds);
  }

  private static buildName(alarmDevices: Product[]) {
    if (alarmDevices.length === 1) {
      return alarmDevices[0].name;
    }
    return $localize`:@@alarmDeviceConfiguration.combination.name:Alarm-Kombi (${alarmDevices.map((alarmDevice) => alarmDevice.id).join(", ")}:listOfAlarmDeviceIds:)`;
  }
}
