<app-floorplan-item-details [title]="title" [floorplanItem]="floorplanItem" [imageUrl]="imageUrl">
  <form odxForm [formGroup]="placeholderProductsForm" [readonly]="floorplanItem.locked">
    <div formArrayName="placeholderProducts">
      <div *ngFor="let product of getProductsControls(); let index = index" [formGroupName]="index">
        <hr />
        <odx-form-field i18n-label="@@floorplan.details.form.placeholderProduct.name.label" label="Name (optional)">
          <input
            formControlName="name"
            odxFormFieldControl
            i18n-placeholder="@@floorplan.details.form.placeholderProduct.name.placeholder"
            placeholder="Name"
          />
        </odx-form-field>
        <odx-form-field i18n-label="@@floorplan.details.form.placeholderProduct.id.label" label="Sachnummer (optional)">
          <input
            formControlName="id"
            odxFormFieldControl
            i18n-placeholder="@@floorplan.details.form.placeholderProduct.id.placeholder"
            placeholder="Sachnummer"
          />
        </odx-form-field>
      </div>
    </div>

    <odx-inline-message *ngIf="showWarning" variant="warning" i18n="@@floorplan.details.warning.placeholderProductsAvailable"
      >Alle Sachnummern dieses Platzhalters sind in den Produktdaten vorhanden. Es wird empfohlen, das Produkt direkt zu
      konfigurieren.</odx-inline-message
    >

    <div>
      <a
        odxLink
        href="#"
        i18n="floorplan.details.form.placeholderProduct.btnAdd"
        (click)="addFormGroup(); $event.preventDefault()"
      >
        <odx-icon name="plus"></odx-icon>
        Produkt hinzufügen
      </a>
    </div>
  </form>
</app-floorplan-item-details>
