import { Location } from "@angular/common";
import { Component, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UiKitModule } from "@app/ui-kit.module";
import { Project } from "@domain/project/project";
import { FloorplanMenuType } from "@project/floorplanner/floorplan-menu/floorplan-menu-type";

@Component({
  selector: "app-configuration-wizard-back-button",
  templateUrl: "./configuration-wizard-back-button.component.html",
  styleUrl: "./configuration-wizard-back-button.component.scss",
  standalone: true,
  imports: [UiKitModule],
})
export class ConfigurationWizardBackButtonComponent {
  @Input({ required: true }) project!: Project;
  @Input({ required: true }) floorplanGroup!: FloorplanMenuType;

  constructor(
    private router: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute,
  ) {}

  navigateBack() {
    const floorplanId = this.activatedRoute.snapshot.queryParams["floorplan_id"];

    if (floorplanId) {
      this.router.navigate(["/projects", this.project.id, "floorplans", floorplanId], {
        queryParams: {
          selectedGroup: this.floorplanGroup,
        },
      });
    } else {
      this.location.back();
    }
  }
}
