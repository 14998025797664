import { AfterViewInit, Component } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { ServiceWorkerUpdateService } from "@app/service-worker-update.service";
import { UiKitModule } from "@app/ui-kit.module";
import { LocalesSelectionMenuComponent } from "@components/locales-selection-menu/locales-selection-menu.component";
import { MainMenuComponent } from "@components/main-menu/main-menu.component";
import { ToastHelper } from "@utils/toast-helper.service";

@Component({
  selector: "app-base",
  templateUrl: "./base.component.html",
  styleUrls: ["./base.component.scss"],
  standalone: true,
  imports: [UiKitModule, RouterModule, MainMenuComponent, LocalesSelectionMenuComponent],
})
export class BaseComponent implements AfterViewInit {
  newSoftwareVersion?: string;

  protected readonly tooltipNewVersionAvailable: string = $localize`:@@app.newVersionAvailable:Eine neue Softwareversion ist verfügbar.`;
  protected readonly tooltipNoNewVersionAvailable: string = $localize`:@@app.noNewVersionAvailable:Die Software ist auf dem neusten Stand.`;
  protected readonly tooltipLocalizationSettings: string = $localize`:@@app.localizationSettings:Land- und Spracheinstellungen`;

  constructor(
    private router: Router,
    private updateService: ServiceWorkerUpdateService,
    private toastHelper: ToastHelper,
  ) {}

  ngAfterViewInit(): void {
    document.querySelector("odx-logo")?.addEventListener("click", () => this.router.navigate(["/"]));
    this.updateService.isUpdateAvailable().subscribe((newSoftwareVersion) => {
      this.newSoftwareVersion = newSoftwareVersion;
      this.showToastWarning(newSoftwareVersion);
    });
  }

  protected onClickReload() {
    if (!this.newSoftwareVersion) {
      return;
    }
    this.showToastWarning(this.newSoftwareVersion);
  }

  private showToastWarning(availableUpdateVersion: string) {
    this.toastHelper.warning(
      $localize`:@@toast.newVersionAvailable.header:Neue Softwareversion verfügbar`,
      $localize`:@@toast.newVersionAvailable.description:Es ist eine neue Version von Navinta verfügbar. Um die neue Version zu aktivieren, muss die Anwendung einmal neu geladen werden.`,
      $localize`:@@toast.newVersionAvailable.button:Jetzt neu laden`,
      async () => await this.updateService.executeUpdate(availableUpdateVersion),
    );
  }
}
