import { Injectable } from "@angular/core";
import { ProductType } from "@domain/product/product";
import { ProductData } from "@domain/project/product-data/product-data";

@Injectable({
  providedIn: "root",
})
export class SortProductListService {
  private relevantProductTypes: string[];

  private gasWarningCenters: ProductData[][] = [];
  private gasWarningCenterPlaceholders: ProductData[][] = [];

  private transmitters: ProductData[][] = [];
  private transmitterPlaceholders: ProductData[][] = [];

  private alarmDevices: ProductData[][] = [];
  private alarmDevicePlaceholders: ProductData[][] = [];

  private signalElements: ProductData[][] = [];
  private signalElementPlaceholders: ProductData[][] = [];

  private plasticSigns: ProductData[][] = [];
  private plasticSignPlaceholders: ProductData[][] = [];

  constructor() {
    this.relevantProductTypes = [
      ProductType.ALARMDEVICE,
      ProductType.GASWARNINGCENTER,
      ProductType.TRANSMITTER,
      ProductType.PLASTICSIGN,
      ProductType.SIGNALELEMENT,
    ];
  }

  public sortProductData(data: ProductData[][][]) {
    this.sortIntoCategories(data);
    return [
      this.gasWarningCenters,
      this.gasWarningCenterPlaceholders,
      this.transmitters,
      this.transmitterPlaceholders,
      this.alarmDevices,
      this.alarmDevicePlaceholders,
      this.signalElements,
      this.signalElementPlaceholders,
      this.plasticSigns,
      this.plasticSignPlaceholders,
    ];
  }

  private sortIntoCategories(data: ProductData[][][]) {
    data.forEach((rawCategory: ProductData[][]) => {
      if (rawCategory.length) {
        this.sortProductConfigurations(rawCategory);
      }
    });
  }

  private sortProductConfigurations(rawCategory: ProductData[][]) {
    rawCategory.forEach((rawConfiguration: ProductData[]) => {
      if (rawConfiguration.length) {
        this.sortProductItems(rawConfiguration);
      }
    });
  }

  private sortProductItems(rawConfiguration: ProductData[]) {
    for (const item of rawConfiguration) {
      const isRelevantType = this.relevantProductTypes.includes(item.type);
      if (isRelevantType) {
        this.mapToCategory(!item.fullType ? [item] : rawConfiguration, item);
      }
    }
  }

  private mapToCategory(data: ProductData[], item: ProductData) {
    const productCategory: ProductData[][] | undefined = this.getProductCategory(item);
    if (productCategory) {
      productCategory.push(data);
    }
  }

  private getProductCategory(item: ProductData) {
    const isPlaceholder = this.isPlaceholder(item);
    switch (item.type) {
      case ProductType.ALARMDEVICE: {
        return isPlaceholder ? this.alarmDevicePlaceholders : this.alarmDevices;
      }
      case ProductType.GASWARNINGCENTER: {
        return isPlaceholder ? this.gasWarningCenterPlaceholders : this.gasWarningCenters;
      }
      case ProductType.TRANSMITTER: {
        return isPlaceholder ? this.transmitterPlaceholders : this.transmitters;
      }
      case ProductType.PLASTICSIGN: {
        return isPlaceholder ? this.plasticSignPlaceholders : this.plasticSigns;
      }
      case ProductType.SIGNALELEMENT: {
        return isPlaceholder ? this.signalElementPlaceholders : this.signalElements;
      }
    }
    return;
  }

  private isPlaceholder(data: ProductData): boolean {
    return !data.id;
  }
}
