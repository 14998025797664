import { Component, Input, OnInit } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { ProductService } from "@domain/product/product.service";
import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { FloorplanSignalElement } from "@domain/project/floorplan/floorplan-signal-element";
import { FloorplanItemDetailsComponent } from "@project/floorplanner/floorplan-additional-notes/floorplan-item-details/floorplan-item-details.component";
import { ProjectStateService } from "@project/project-state.service";
import { Observable, map } from "rxjs";

@Component({
  selector: " app-signal-notes",
  templateUrl: "./signal-notes.component.html",
  styleUrls: ["./signal-notes.component.scss"],
  standalone: true,
  imports: [UiKitModule, FloorplanItemDetailsComponent],
})
export class SignalNotesComponent implements OnInit {
  @Input({ required: true }) set floorplanItem(item: FloorplanItem) {
    this.signalElement = item as FloorplanSignalElement;
  }

  protected signalElementName$!: Observable<string>;
  protected signalElement!: FloorplanSignalElement;
  protected isDiscontinued$!: Observable<boolean>;

  constructor(
    private productService: ProductService,
    private projectStateService: ProjectStateService,
  ) {}

  ngOnInit() {
    this.isDiscontinued$ = this.projectStateService.isConfigDiscontinued(this.signalElement.config.id);
    this.signalElementName$ = this.productService
      .getProductNameById(this.signalElement.config.productId)
      .pipe(map((name) => name || this.signalElement.config.name));
  }
}
