import { IsBoolean, IsNumber, IsOptional, IsString } from "class-validator";

export class ProjectRelatedService {
  @IsBoolean()
  public required: boolean;

  @IsNumber()
  @IsOptional()
  public costs?: number;

  @IsString()
  public notes: string;

  constructor(required: boolean = true, costs?: number, notes: string = "") {
    this.required = required;
    this.costs = costs;
    this.notes = notes;
  }
}
