<div odxLayout="flex" class="flex-column h-100">
  <odx-content-box class="w-100">
    <app-configuration-wizard-header-accordion
      [showCosts]="showCosts"
      [selectedData]="headerProducts"
      [prefix]="headerAccordionPrefix"
      [title]="transmitter ? (transmitter | localizeProductName) : ''"
    ></app-configuration-wizard-header-accordion>
  </odx-content-box>
  <odx-content-box odxLayout="flex" class="h-100 flex-column scroll" *ngIf="transmitter">
    <odx-content-box-header>
      <h5
        class="odx-title-5"
        i18n="@@transmitterConfiguration.selectSensor.header"
        data-testid="transmitterConfiguration.selectSensor.header"
      >
        Sensor auswählen
      </h5>
    </odx-content-box-header>
    <app-filter-product (search)="search($event)"></app-filter-product>
    <form [formGroup]="selectedSensorForm" class="overflow-hidden h-100" *ngIf="transmitter.isModular">
      <odx-radio-group
        formControlName="selectedSensor"
        required="true"
        class="overflow-hidden h-100 no-padding-left"
        *ngIf="filteredSensors$ | async; let sensors"
      >
        <div odxLayout="grid 3@desktop 6@tablet 12@phone" class="mb-36 w-100 scroll">
          <app-product-card
            class="overflow-hidden"
            *ngFor="let sensor of sensors"
            [product]="sensor"
            [showCosts]="showCosts"
            [isSelected]="selectedSensor?.id === sensor.id"
            [imageUrl]="'https://product-assets.draeger.net/156px/navinta/' + sensor.id + '.png'"
            (click)="select(sensor)"
            (select$)="select(sensor)"
            selectionMode="single"
          >
            <div *ngIf="sensor.id" class="feature">
              <p class="p-label odx-text--small" i18n="@@global.partNumber">Sachnummer</p>
              <p class="odx-text--small">{{ sensor.id }}</p>
            </div>
          </app-product-card>
        </div>
      </odx-radio-group>
    </form>
  </odx-content-box>
</div>
