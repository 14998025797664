import { TransmitterConfiguration } from "@domain/project/configurations/transmitter-configuration";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { FloorplanEventType } from "@domain/project/floorplan/floorplan-event";
import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { FloorplanProductItem } from "@domain/project/floorplan/floorplan-product-item";
import { Project } from "@domain/project/project";
import { IsInstanceOf } from "@utils/class-validator/class-validator-constraints";
import { Exclude, Expose, Transform } from "class-transformer";
import { IsNumber, IsString, ValidateNested } from "class-validator";
import { v4 as uuidv4 } from "uuid";

export class FloorplanTransmitter extends FloorplanProductItem<TransmitterConfiguration> {
  @ValidateNested()
  @IsInstanceOf(TransmitterConfiguration)
  @Exclude()
  private _transmitter: TransmitterConfiguration;

  @IsString()
  @Expose({ name: "transmitterId" })
  private readonly _transmitterId: string;

  @IsNumber()
  @Expose({ name: "monitoringAreaRadius" })
  @Transform((params) => params.value || 200, { toClassOnly: true })
  private _monitoringAreaRadius: number = 200;

  constructor(
    floorplan: Floorplan,
    transmitter: TransmitterConfiguration,
    id: string,
    x: number,
    y: number,
    size: number,
    monitoringAreaRadius: number = 200,
    notes?: string,
  ) {
    super(floorplan, transmitter, id, x, y, size, notes);
    this._transmitterId = transmitter?.id;
    this._transmitter = transmitter;
    this._monitoringAreaRadius = monitoringAreaRadius;
  }

  override duplicate(x: number, y: number): FloorplanItem {
    return this._floorplan.addTransmitter(this.config, x, y, this.size, this._monitoringAreaRadius);
  }

  override delete() {
    this._floorplan.deleteTransmitter(this);
  }

  override get relatedItems(): FloorplanItem[] {
    return this._floorplan.transmitters;
  }

  get monitoringAreaRadius(): number {
    return this._monitoringAreaRadius;
  }

  set monitoringAreaRadius(value: number) {
    this._monitoringAreaRadius = value;
    this._floorplan.publishUpdate(FloorplanEventType.ITEM_UPDATED, this);
  }

  protected override refreshFloorplanLockState() {
    this._floorplan.floorplanState.refreshTransmittersLock();
  }

  static create(
    floorplan: Floorplan,
    transmitter: TransmitterConfiguration,
    x: number,
    y: number,
    size: number,
    monitoringAreaRadius: number,
  ): FloorplanTransmitter {
    return new FloorplanTransmitter(floorplan, transmitter, uuidv4(), x, y, size, monitoringAreaRadius);
  }

  override getConfigFromProject(project: Project): TransmitterConfiguration | undefined {
    return project.getTransmitterById(this._transmitterId);
  }
}
