import { ProductListServiceRow } from "@pdf/export-services/product-information-pages/product-list-pages/product-list-service-row";
import { TableColumn } from "@pdf/helpers/default-table/table-column";
import { jsPDF } from "jspdf";

enum Headers {
  NUMBER,
  TITLE,
  DESCRIPTION,
  ID,
  TOTAL_PRICE,
}

const HeadersLocalized: Record<Headers, string> = {
  [Headers.NUMBER]: $localize`:@@pdfExport.servicesList.tableHeader.number:Nr.`,
  [Headers.TITLE]: $localize`:@@pdfExport.servicesList.tableHeader.title:Art`,
  [Headers.DESCRIPTION]: $localize`:@@pdfExport.servicesList.tableHeader.description:Anmerkungen`,
  [Headers.ID]: $localize`:@@pdfExport.servicesList.tableHeader.partNumber:Sachnummer`,
  [Headers.TOTAL_PRICE]: $localize`:@@pdfExport.servicesList.tableHeader.totalPrice:Preis`,
};

function getLocalized(header: Headers): string {
  return HeadersLocalized[header];
}

interface RowData {
  position: number;
  title: string;
  description: string;
  id: string;
  costs: number;
}

export class ServicesTable {
  readonly columns: TableColumn[];
  readonly rows: ProductListServiceRow[];

  constructor(pdf: jsPDF, localeId: string, servicesTableRows: RowData[]) {
    this.columns = [
      new TableColumn(getLocalized(Headers.NUMBER), 60, 96),
      new TableColumn(getLocalized(Headers.TITLE), 180, 196),
      new TableColumn(getLocalized(Headers.DESCRIPTION), 460, 610),
      new TableColumn(getLocalized(Headers.ID), 1094, 176),
      new TableColumn(getLocalized(Headers.TOTAL_PRICE), 1440, 146),
    ];

    this.rows = servicesTableRows.map(
      (servicesTableRow) => new ProductListServiceRow(pdf, localeId, this.columns, servicesTableRow),
    );
  }
}
