import { Pipeline } from "@domain/project/floorplan/pipeline";
import { TableColumn } from "@pdf/helpers/default-table/table-column";
import { Headlines, ImagePaths, TableProperties } from "@pdf/pdf-properties";
import { jsPDF } from "jspdf";
import { FloorplanInformationTable } from "../floorplan-information-table";
import { PipelineRow } from "./pipeline-row";

export class PipelinesRowHeaders {
  public readonly NUMBER = $localize`:@@pdfExport.pipelinesTable.header.number:Nr.`;
  public readonly NAME = $localize`:@@pdfExport.pipelinesTable.header.name:Name`;
  public readonly DIAMETER = $localize`:@@pdfExport.pipelinesTable.header.diameter:⌀ (mm)`;
  public readonly MEDIUM = $localize`:@@pdfExport.pipelinesTable.header.medium:Medium`;
  public readonly TEMPERATURE = $localize`:@@pdfExport.pipelinesTable.temperature.number:Temperatur`;
  public readonly MATERIAL = $localize`:@@pdfExport.pipelinesTable.header.material:Material`;
  public readonly NOTES = $localize`:@@pdfExport.pipelinesTable.header.notes:Notizen`;
}

export class PipelinesTable extends FloorplanInformationTable {
  readonly headlines = new Headlines();
  readonly rowHeaders = new PipelinesRowHeaders();
  override readonly headline = this.headlines.PIPELINES;
  override readonly columns: TableColumn[];
  override readonly rows: PipelineRow[];

  constructor(pdf: jsPDF, pipelines: Pipeline[]) {
    super(ImagePaths.PIPELINE);
    this.columns = [
      new TableColumn(this.rowHeaders.NUMBER, 60, 96),
      new TableColumn(this.rowHeaders.NAME, 180, 156),
      new TableColumn(this.rowHeaders.DIAMETER, 360, 136),
      new TableColumn(this.rowHeaders.MEDIUM, 520, 146),
      new TableColumn(this.rowHeaders.TEMPERATURE, 690, 176),
      new TableColumn(this.rowHeaders.MATERIAL, 890, 126),
      new TableColumn(this.rowHeaders.NOTES, 1040, 400),
    ];

    this.rows = pipelines.map(
      (pipeline) =>
        new PipelineRow(
          pdf,
          this.textToColumnWidth(
            pdf,
            TableProperties.TEXT_FONT_SIZE,
            this.preProcessText(pipeline.positionId),
            this.rowHeaders.NUMBER,
          ),
          this.textToColumnWidth(pdf, TableProperties.TEXT_FONT_SIZE, this.preProcessText(pipeline.name), this.rowHeaders.NAME),
          this.textToColumnWidth(
            pdf,
            TableProperties.TEXT_FONT_SIZE,
            this.preProcessText(pipeline.diameter),
            this.rowHeaders.DIAMETER,
          ),
          this.textToColumnWidth(
            pdf,
            TableProperties.TEXT_FONT_SIZE,
            this.preProcessText(pipeline.medium),
            this.rowHeaders.MEDIUM,
          ),
          this.textToColumnWidth(
            pdf,
            TableProperties.TEXT_FONT_SIZE,
            this.preProcessText(pipeline.temperature),
            this.rowHeaders.TEMPERATURE,
          ),
          this.textToColumnWidth(
            pdf,
            TableProperties.TEXT_FONT_SIZE,
            this.preProcessText(pipeline.material),
            this.rowHeaders.MATERIAL,
          ),
          this.textToColumnWidth(pdf, TableProperties.TEXT_FONT_SIZE, this.preProcessText(pipeline.notes), this.rowHeaders.NOTES),
        ),
    );
    this.height = this.calculateHeight(this.rows);
  }
}
