import { NgModule } from "@angular/core";
import { ActivatedRouteSnapshot, BaseRouteReuseStrategy, RouteReuseStrategy, RouterModule, Routes } from "@angular/router";
import { AppResolver } from "@app/app-resolver.service";
import { BaseComponent } from "@app/base.component";
import { CrmReceiverComponent } from "@pages/crm-receiver/crm-receiver.component";
import { HomeComponent } from "@pages/home/home.component";
import { ImpressumComponent } from "@pages/impressum/impressum.component";
import { NewProjectComponent } from "@pages/new-project/new-project.component";
import { PrivacyPolicyComponent } from "@pages/privacy-policy/privacy-policy.component";
import { AlarmDeviceConfigurationComponent } from "@pages/project/alarm-device-configuration/alarm-device-configuration.component";
import { FloorplanResolver } from "@pages/project/floorplanner/floorplan-resolver";
import { FloorplannerComponent } from "@pages/project/floorplanner/floorplanner.component";
import { GasWarningCenterConfigurationComponent } from "@pages/project/gas-warning-center-configuration/gas-warning-center-configuration.component";
import { PlasticSignConfigurationComponent } from "@pages/project/plastic-sign-configuration/plastic-sign-configuration.component";
import { ProjectDetailsComponent } from "@pages/project/project-details/project-details.component";
import { SignalElementConfigurationComponent } from "@pages/project/signal-element-configuration/signal-element-configuration.component";
import { TransmitterConfigurationComponent } from "@pages/project/transmitter-configuration/transmitter-configuration.component";
import { ProjectResolver } from "@project/project-resolver";
import { ProjectComponent } from "@project/project.component";

const routes: Routes = [
  {
    path: "",
    component: BaseComponent,
    resolve: {
      initialized: AppResolver,
    },
    children: [
      {
        path: "",
        component: HomeComponent,
      },
      {
        path: "projects/:id",
        component: ProjectComponent,
        resolve: {
          project: ProjectResolver,
        },
        children: [
          {
            path: "",
            component: ProjectDetailsComponent,
          },
          {
            path: "floorplans/:floorplanId",
            component: FloorplannerComponent,
            data: {
              maximizeScreen: true,
            },
            resolve: {
              floorplan: FloorplanResolver,
            },
          },
          {
            path: "transmitter",
            component: TransmitterConfigurationComponent,
          },
          {
            path: "alarm-device",
            component: AlarmDeviceConfigurationComponent,
          },
          {
            path: "gas-warning-center",
            component: GasWarningCenterConfigurationComponent,
          },
          {
            path: "signal-element",
            component: SignalElementConfigurationComponent,
          },
          {
            path: "plastic-sign",
            component: PlasticSignConfigurationComponent,
          },
        ],
      },
      {
        path: "impressum",
        component: ImpressumComponent,
      },
      {
        path: "privacy-policy",
        component: PrivacyPolicyComponent,
      },
      {
        path: "new-project",
        component: NewProjectComponent,
      },
      {
        path: "crm-receiver",
        component: CrmReceiverComponent,
      },
    ],
  },
  {
    path: "**",
    redirectTo: "",
  },
];

class AppRouteReuseStrategy extends BaseRouteReuseStrategy {
  override shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    if (future.component === FloorplannerComponent) {
      return false;
    }
    return super.shouldReuseRoute(future, curr);
  }
}

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [{ provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy }],
})
export class AppRoutingModule {}
