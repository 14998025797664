import { DefaultRow } from "@pdf/helpers/default-row/default-row";
import { TableColumn } from "@pdf/helpers/default-table/table-column";
import { TableProperties } from "@pdf/pdf-properties";
import { jsPDF } from "jspdf";

interface RowData {
  title: string;
  text: string;
  checked?: boolean;
}

export class ChecklistRow extends DefaultRow {
  readonly title: string[];
  readonly text: string[];
  readonly checked?: boolean;

  constructor(pdf: jsPDF, columns: TableColumn[], checklistTableRow: RowData) {
    super();
    this.title = this.splitText(pdf, columns[0].width, checklistTableRow.title);
    this.text = this.splitText(pdf, columns[2].width, checklistTableRow.text);
    this.checked = checklistTableRow.checked;
    this._textHeight =
      this.text.length > this.title.length
        ? this.text.length * TableProperties.TEXT_HEIGHT
        : this.title.length * TableProperties.TEXT_HEIGHT;
  }

  private splitText(pdf: jsPDF, width: number, text: string): string[] {
    pdf.setFontSize(TableProperties.TEXT_FONT_SIZE);
    return pdf.splitTextToSize(text, width);
  }
}
