import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { ProjectFormsService } from "@components/project-forms/project-forms.service";
import { Project } from "@domain/project/project";
import { AreaHeaderComponent } from "@odx/angular/components/area-header";
import { FormFieldComponent, FormGroupComponent } from "@odx/angular/components/form-field";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-contact-person-form",
  templateUrl: "./contact-person-form.component.html",
  styleUrls: ["./contact-person-form.component.scss"],
  standalone: true,
  imports: [UiKitModule, ReactiveFormsModule, AreaHeaderComponent, FormGroupComponent, FormFieldComponent],
})
export class ContactPersonFormComponent implements OnInit, OnDestroy {
  @Input() isReadonly: boolean = false;
  @Output() contactPersonFormEmitter$ = new EventEmitter<FormGroup>();
  contactPersonForm!: FormGroup;

  @ViewChild("submitButton")
  submitButton?: ElementRef;

  private readonly _destroying = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private projectFormsService: ProjectFormsService,
  ) {
    this.contactPersonForm = this.formBuilder.group({
      firstname: [null, Validators.required],
      lastname: [null, Validators.required],
      department: [],
      email: [
        null,
        [
          Validators.required,
          Validators.pattern(
            /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
          ),
        ],
      ],
      phoneNumber: [null, [Validators.required, Validators.pattern(/^(\+[0-9]+)?([0-9]|\/|\(|\)|-| )+$/)]],
    });

    this.projectFormsService.projectInitialized$.pipe(takeUntil(this._destroying)).subscribe((project) => this.initForm(project));

    this.projectFormsService.projectReset$.pipe(takeUntil(this._destroying)).subscribe((project) => {
      this.contactPersonForm.reset();
      this.initForm(project);
    });

    this.contactPersonForm.statusChanges
      .pipe(takeUntil(this._destroying))
      .subscribe(() => this.contactPersonFormEmitter$.emit(this.contactPersonForm));
  }

  ngOnInit(): void {
    this.contactPersonFormEmitter$.emit(this.contactPersonForm);
  }

  ngOnDestroy(): void {
    this._destroying.next();
    this._destroying.complete();
  }

  initForm(project: Project) {
    this.contactPersonForm.patchValue({
      firstname: project.contactPerson?.firstname,
      lastname: project.contactPerson?.lastname,
      department: project.contactPerson?.department,
      email: project.contactPerson?.email,
      phoneNumber: project.contactPerson?.phoneNumber,
    });
  }

  triggerSubmit() {
    this.submitButton?.nativeElement.click();
  }
}
