import { CdkStepper } from "@angular/cdk/stepper";
import { Component } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";

@Component({
  selector: "app-new-project-stepper",
  templateUrl: "./new-project-stepper.component.html",
  styleUrls: ["./new-project-stepper.component.scss"],
  providers: [{ provide: CdkStepper, useExisting: NewProjectStepperComponent }],
  standalone: true,
  imports: [UiKitModule],
})
export class NewProjectStepperComponent extends CdkStepper {}
