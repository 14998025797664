import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { Gas, LocalizeGasNamePipe } from "@domain/gas/gas";
import { GasService } from "@domain/gas/gas.service";
import { Project } from "@domain/project/project";
import { Observable, debounceTime, first, fromEvent, map } from "rxjs";

@Component({
  selector: "app-gas-selector",
  templateUrl: "./gas-selector.component.html",
  styleUrls: ["./gas-selector.component.scss"],
  standalone: true,
  imports: [UiKitModule, LocalizeGasNamePipe],
  providers: [LocalizeGasNamePipe],
})
export class GasSelectorComponent implements AfterViewInit {
  @Input() project!: Project;

  @Input() set originalGas(gas: Gas | undefined) {
    if (gas) {
      this.select(gas);
    }
  }

  @Output() gasSelect$ = new EventEmitter<Gas>();

  protected filteredGases$!: Observable<Gas[]>;
  protected selectedGas?: Gas;

  @ViewChild("searchField")
  private readonly searchField!: ElementRef;
  private readonly gases$!: Observable<Gas[]>;

  constructor(
    private gasService: GasService,
    private localizeGasNamePipe: LocalizeGasNamePipe,
  ) {
    this.gases$ = this.gasService.loadAll().pipe(
      first(),
      map((results) =>
        results.sort((a, b) => {
          if (this.localizeGasNamePipe.transform(a) < this.localizeGasNamePipe.transform(b)) {
            return -1;
          }
          if (this.localizeGasNamePipe.transform(a) > this.localizeGasNamePipe.transform(b)) {
            return 1;
          }
          return 0;
        }),
      ),
    );

    this.filteredGases$ = this.gases$;
  }

  ngAfterViewInit(): void {
    fromEvent(this.searchField.nativeElement, "input")
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.filteredGases$ = this.gases$.pipe(
          map((gases) => gases.filter((item) => this.searchFilter(item, this.searchField.nativeElement.value))),
        );
      });
  }

  protected select(selectedGas: Gas) {
    this.selectedGas = selectedGas;
    this.gasSelect$.emit(this.selectedGas);
  }

  private searchFilter(item: Gas, query: string): boolean {
    return (
      this.localizeGasNamePipe.transform(item).toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) >= 0 ||
      item.id.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) >= 0 ||
      item.definition.formula.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) >= 0
    );
  }
}
