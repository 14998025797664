import { Component, Input } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { ConfigurationWizardHeaderComponent } from "@components/configuration-wizard-header/configuration-wizard-header.component";
import { Gas } from "@domain/gas/gas";
import { Product } from "@domain/product/product";

@Component({
  selector: "app-configuration-wizard-header-accordion",
  templateUrl: "./configuration-wizard-header-accordion.component.html",
  styleUrls: ["./configuration-wizard-header-accordion.component.scss"],
  standalone: true,
  imports: [UiKitModule, ConfigurationWizardHeaderComponent],
})
export class ConfigurationWizardHeaderAccordionComponent {
  @Input({ required: true }) showCosts!: boolean;

  @Input({ required: true }) set selectedData(selectedData: (Gas | Product)[]) {
    this.data = selectedData;
    this.totalCosts = this.calculateTotalCosts();
  }

  @Input({ required: true }) prefix!: string;
  @Input({ required: true }) title!: string;

  protected data!: (Gas | Product)[];
  protected totalCosts: number = 0;

  private calculateTotalCosts() {
    if (!this.data.length) {
      return 0;
    }
    return this.data
      .map((product) => {
        if (product instanceof Product) {
          return product.price;
        }
        return 0;
      })
      .reduce((a, b) => a + b);
  }
}
