import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { ProductCardTooltipService } from "@components/product-card/product-card-tooltip.service";
import { StandardImageComponent } from "@components/standard-image/standard-image.component";
import { DisplayNamePipe, LocalizeProductNamePipe, Product } from "@domain/product/product";
import { CheckBoxControl } from "@odx/angular/cdk/checkbox-control";

@Component({
  selector: "app-product-card",
  templateUrl: "./product-card.component.html",
  styleUrls: ["./product-card.component.scss"],
  standalone: true,
  imports: [UiKitModule, StandardImageComponent, LocalizeProductNamePipe, DisplayNamePipe],
})
export class ProductCardComponent implements OnInit {
  @Input() product!: Product;
  @Input() showCosts!: boolean;
  @Input() selectionMode!: "none" | "single" | "multi";
  @Input() imageUrl?: string;
  @Input() fallbackImageUrl?: string;

  @Input() set isSelected(selected: boolean) {
    if (selected) {
      this.select$.emit(selected);
    }
    this.selected = selected;
  }

  @Output() select$ = new EventEmitter<boolean>();

  @ViewChild("checkbox")
  protected checkbox?: CheckBoxControl;
  protected tooltipVisible: boolean = false;
  protected selected = false;

  constructor(private tooltipService: ProductCardTooltipService) {}

  ngOnInit(): void {
    this.tooltipService.visibleTooltip.subscribe(() => {
      this.tooltipVisible = false;
    });
  }

  protected onTooltipClicked($event: MouseEvent) {
    if (this.isTooltipDisabled()) {
      return;
    }

    if (!this.tooltipVisible) {
      this.tooltipService.tooltipSelected($event.currentTarget);
    }
    this.tooltipVisible = !this.tooltipVisible;
  }

  protected isTooltipDisabled(): boolean {
    return this.product.name.length <= 80;
  }

  protected onCardClicked() {
    if (this.checkbox) {
      this.checkbox.checked = !this.checkbox.checked;
      this.select$.emit(this.checkbox.checked);
    }
  }

  protected onCheckedChanged() {
    if (this.checkbox) {
      this.select$.emit(this.checkbox.checked);
    }
  }
}
