import { IsString } from "class-validator";

export class PlaceholderProduct {
  @IsString()
  name: string;

  @IsString()
  id: string;

  constructor(name: string, id: string) {
    this.name = name;
    this.id = id;
  }
}
