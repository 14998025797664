import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { FilterProductComponent } from "@components/filter-product/filter-product.component";
import { ProductCardComponent } from "@components/product-card/product-card.component";
import { Product, ProductType } from "@domain/product/product";
import { ProductService } from "@domain/product/product.service";
import { Project } from "@domain/project/project";
import { untilDestroyed } from "@odx/angular/utils";
import { Observable, map } from "rxjs";

@Component({
  selector: "app-signal-element-selector",
  templateUrl: "./signal-element-selector.component.html",
  styleUrls: ["./signal-element-selector.component.scss"],
  standalone: true,
  imports: [UiKitModule, FilterProductComponent, ReactiveFormsModule, ProductCardComponent],
})
export class SignalElementSelectorComponent {
  @Input() project!: Project;

  @Input() set originalSignalElement(signalElement: Product | undefined) {
    if (signalElement && signalElement.isAvailable) {
      this.select(signalElement);
    }
  }

  @Output() signalElementSelect$ = new EventEmitter<Product>();

  protected batteryPackFilterOptions: string[];
  protected hornFilterOptions: string[];
  protected feedFilterOptions: string[];
  protected signalElementForm: FormGroup;
  protected selectedSignalElement?: Product;
  protected filteredSignalElements$!: Observable<Product[]>;

  private filterOptionsForm: FormGroup;
  private signalElements$!: Observable<Product[]>;

  // Filter
  private readonly ALL = $localize`:@@signalElementConfiguration.filterOption.all:Alle`;
  private readonly WITHOUTHORN = $localize`:@@signalElementConfiguration.filterOption.withoutHorn:Ohne Hupe`;
  private readonly WITHHORN = $localize`:@@signalElementConfiguration.filterOption.withHorn:Mit Hupe`;
  private readonly WITHOUTBATTERYPACK = $localize`:@@signalElementConfiguration.filterOption.withoutBatteryPack:Ohne Akku`;
  private readonly WITHBATTERYPACK = $localize`:@@signalElementConfiguration.filterOption.withBatteryPack:Mit Akku`;
  private readonly VAC = "230V/AC";
  private readonly VDC = "24V/DC";

  // Properties
  private readonly POWERSUPPLY = "Power_Supply_";

  private readonly takeUntilDestroyed = untilDestroyed();

  constructor(
    private productService: ProductService,
    private formBuilder: FormBuilder,
  ) {
    this.batteryPackFilterOptions = [this.ALL, this.WITHOUTBATTERYPACK, this.WITHBATTERYPACK];
    this.hornFilterOptions = [this.ALL, this.WITHOUTHORN, this.WITHHORN];
    this.feedFilterOptions = [this.ALL, this.VAC, this.VDC];

    this.filterOptionsForm = this.formBuilder.group({
      selectedBatteryPackFilter: [this.ALL],
      selectedHornFilter: [this.ALL],
      selectedFeedFilter: [this.ALL],
    });
    this.filterOptionsForm.valueChanges.pipe(this.takeUntilDestroyed()).subscribe(() => this.collectSignalElements());

    this.signalElementForm = this.formBuilder.group({
      selectedSignalElement: [null, Validators.required],
    });
    this.collectSignalElements();
    this.signalElementForm.valueChanges.pipe(this.takeUntilDestroyed()).subscribe(() => {
      this.selectedSignalElement = this.signalElementForm.value.selectedSignalElement;
      return this.signalElementSelect$.emit(this.signalElementForm.value.selectedSignalElement);
    });
  }

  private collectSignalElements() {
    this.signalElementForm.patchValue({
      selectedSignalElement: null,
    });
    this.signalElements$ = this.productService.loadAll().pipe(
      map((products) =>
        products.filter((product) => {
          return this.filterSignalElements(product);
        }),
      ),
    );
    this.filteredSignalElements$ = this.signalElements$;
  }

  private filterSignalElements(signalElement: Product): boolean {
    if (signalElement.type != ProductType.SIGNALELEMENT || !signalElement.isAvailable) return false;
    const selectedBatteryPackFilter = this.filterOptionsForm.value.selectedBatteryPackFilter;
    const selectedHornFilter = this.filterOptionsForm.value.selectedHornFilter;
    const selectedFeedFilter = this.filterOptionsForm.value.selectedFeedFilter;

    if (selectedBatteryPackFilter == this.ALL && selectedHornFilter == this.ALL && selectedFeedFilter == this.ALL) {
      return true;
    }
    // TODO: Implement filters, when signal-element-properties are set in backend.
    //const batteryPack = signalElement.getBooleanPropertyByKey(this.FLAMEPROOF);
    //const horn = signalElement.getBooleanPropertyByKey(this.FLAMEPROOF);
    const powersupply = signalElement.getTextPropertyByKey(this.POWERSUPPLY);

    if (powersupply) {
      if (powersupply.value.indexOf(selectedFeedFilter) < 0) return false;
    }

    return true;
  }

  protected search(value: string) {
    this.filteredSignalElements$ = this.productService.filter(this.signalElements$, value);
  }

  protected select(signalElement: Product) {
    this.signalElementForm.patchValue({ selectedSignalElement: signalElement });
  }
}
