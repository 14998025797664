import { AlarmDeviceConfiguration } from "@domain/project/configurations/alarm-device-configuration";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { FloorplanProductItem } from "@domain/project/floorplan/floorplan-product-item";
import { Project } from "@domain/project/project";
import { Exclude, Expose } from "class-transformer";
import { IsString } from "class-validator";
import { v4 as uuidv4 } from "uuid";

export class FloorplanAlarmDevice extends FloorplanProductItem<AlarmDeviceConfiguration> {
  @Exclude()
  private readonly _alarmDevice: AlarmDeviceConfiguration;

  @IsString()
  @Expose({ name: "alarmDeviceId" })
  private readonly _alarmDeviceId: string;

  constructor(
    floorplan: Floorplan,
    alarmDevice: AlarmDeviceConfiguration,
    id: string,
    x: number,
    y: number,
    size: number,
    notes?: string,
  ) {
    super(floorplan, alarmDevice, id, x, y, size, notes);
    this._alarmDeviceId = alarmDevice?.id;
    this._alarmDevice = alarmDevice;
  }

  get alarmDevice(): AlarmDeviceConfiguration {
    return this._alarmDevice;
  }

  override duplicate(x: number, y: number): FloorplanItem {
    return this._floorplan.addAlarmDevice(this.config, x, y, this.size);
  }

  protected override refreshFloorplanLockState() {
    this._floorplan.floorplanState.refreshAlarmDevicesLock();
  }

  override get relatedItems(): FloorplanItem[] {
    return this._floorplan.alarmDevices;
  }

  override delete() {
    this._floorplan.deleteAlarmDevice(this);
  }

  static create(
    floorplan: Floorplan,
    alarmDevice: AlarmDeviceConfiguration,
    x: number,
    y: number,
    size: number,
  ): FloorplanAlarmDevice {
    return new FloorplanAlarmDevice(floorplan, alarmDevice, uuidv4(), x, y, size);
  }

  override getConfigFromProject(project: Project): AlarmDeviceConfiguration | undefined {
    return project.getAlarmDeviceById(this._alarmDeviceId);
  }
}
