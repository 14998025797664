<odx-area-header><h6 class="odx-title-6" i18n="@@newProject.projectForm.header.project">Projekt</h6></odx-area-header>
<form odxForm [formGroup]="projectForm" class="form">
  <odx-form-field i18n-label="@@newProject.projectForm.label.projectName" label="Projektname">
    <input
      type="text"
      formControlName="name"
      odxFormFieldControl
      i18n-placeholder="@@newProject.projectForm.placeholder.projectName"
      placeholder="Projektname"
      [readonly]="isReadonly"
      data-testid="projectForm.projectName"
    />
  </odx-form-field>
  <odx-form-field i18n-label="@@newProject.projectForm.label.forzaId" label="Forza-ID">
    <input
      formControlName="forzaId"
      odxFormFieldControl
      i18n-placeholder="@@newProject.projectForm.placeholder.forzaId"
      placeholder="Forza-ID"
      [readonly]="isReadonly"
      data-testid="projectForm.forzaId"
    />
  </odx-form-field>
  <odx-form-field i18n-label="@@newProject.projectForm.label.locationName" label="Ortsbezeichnung">
    <input
      formControlName="placeName"
      odxFormFieldControl
      i18n-placeholder="@@newProject.projectForm.placeholder.locationName"
      placeholder="Ortsbezeichnung"
      [readonly]="isReadonly"
      data-testid="projectForm.location"
    />
  </odx-form-field>
  <odx-form-field label="Land" i18n-label="@@newProject.projectForm.label.country">
    <odx-select *ngIf="!projectInitialized" formControlName="country" odxFormFieldControl>
      <odx-select-option *ngFor="let country of countries" [value]="country.countryCode">{{
        country.countryCode | mapCountryName
      }}</odx-select-option>
    </odx-select>
    <input *ngIf="projectInitialized" odxFormFieldControl readonly [value]="projectCountry! | mapCountryName" />
  </odx-form-field>
  <odx-inline-message *ngIf="!projectInitialized" i18n="@@newProject.projectForm.inlineMessage.countrySelection"
    >Es werden die für dieses Land verfügbaren Artikel und die festgelegten Preise angezeigt. Später können Sie diese Einstellung
    für dieses Projekt nicht mehr ändern.</odx-inline-message
  >
  <odx-form-field i18n-label="@@newProject.projectForm.label.notes" label="Notizen">
    <textarea
      formControlName="notes"
      odxFormFieldControl
      i18n-placeholder="@@newProject.projectForm.placeholder.notes"
      placeholder="Notizen"
      class="textarea-large"
      [readonly]="isReadonly"
      data-testid="projectForm.notes"
    ></textarea>
  </odx-form-field>
  <!--Needed to show validation errors on submit without userinput-->
  <button type="submit" class="hidden" #submitButton></button>
</form>
