<app-floorplan-menu-item-buttons>
  <app-add-button
    (add)="addPipeline()"
    [odxTooltip]="tooltipTextAddPipeline"
    [odxTooltipOptions]="{ position: 'bottom' }"
  ></app-add-button>
  <app-toggle-visibility-button [visible]="floorplanState.pipelinesVisible" (toggle)="floorplanState.pipelinesVisible = $event">
  </app-toggle-visibility-button>
  <app-toggle-lock-button [locked]="floorplanState.pipelinesLocked" (toggle)="floorplanState.pipelinesLocked = $event">
  </app-toggle-lock-button>
</app-floorplan-menu-item-buttons>
