import { Floorplan } from "@domain/project/floorplan/floorplan";
import { FloorplanEventType } from "@domain/project/floorplan/floorplan-event";
import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { Expose } from "class-transformer";
import { IsNumber } from "class-validator";
import { v4 as uuidv4 } from "uuid";

export class FloorplanText extends FloorplanItem {
  @IsNumber()
  @Expose({ name: "size" })
  private _size: number;

  constructor(floorplan: Floorplan, id: string, x: number, y: number, size: number) {
    super(floorplan, id, x, y);
    this._size = size;
  }

  get size(): number {
    return this._size;
  }

  updateSize(value: number, x: number, y: number) {
    this._size = value;
    this._floorplan.publishUpdate(FloorplanEventType.ITEM_SIZE_CHANGED, this);
    this.updatePosition(x, y);
  }

  override duplicate(x: number, y: number): FloorplanItem {
    return this._floorplan.addFloorplanText(x, y, this._size);
  }

  override delete() {
    this._floorplan.deleteFloorplanText(this);
  }

  override get relatedItems(): FloorplanItem[] {
    return this._floorplan.floorplanTexts;
  }

  protected override refreshFloorplanLockState() {
    this._floorplan.floorplanState.refreshFloorplanTextsLock();
  }

  static create(floorplan: Floorplan, x: number, y: number, size: number) {
    return new FloorplanText(floorplan, uuidv4(), x, y, size);
  }
}
