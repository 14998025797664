import { Floorplan } from "@domain/project/floorplan/floorplan";
import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { Expose } from "class-transformer";
import { IsNumber, IsPositive, Max, Min } from "class-validator";
import { v4 as uuidv4 } from "uuid";

export class DangerArea extends FloorplanItem {
  @IsNumber()
  @IsPositive({ message: "The value must be higher than 0" })
  @Expose({ name: "width" })
  private _width: number;

  @IsNumber()
  @IsPositive({ message: "The value must be higher than 0" })
  @Expose({ name: "height" })
  private _height: number;

  @IsNumber()
  @Min(-180)
  @Max(180)
  @Expose({ name: "rotation" })
  private _rotation: number;

  constructor(floorplan: Floorplan, id: string, x: number, y: number, width: number, height: number, rotation: number) {
    super(floorplan, id, x, y);
    this._width = width;
    this._height = height;
    this._rotation = rotation;
  }

  get width(): number {
    return this._width;
  }

  get height(): number {
    return this._height;
  }

  get rotation(): number {
    return this._rotation;
  }

  override duplicate(x: number, y: number): FloorplanItem {
    return this._floorplan.addDangerArea(x, y, this._width, this._height, this._rotation);
  }

  override delete() {
    this._floorplan.deleteDangerArea(this);
  }

  override get relatedItems(): FloorplanItem[] {
    return this._floorplan.dangerAreas;
  }

  updateForm(width: number, height: number, rotation: number, x: number, y: number) {
    this._width = width;
    this._height = height;
    this._rotation = rotation;
    this.updatePosition(x, y);
  }

  protected override refreshFloorplanLockState() {
    this._floorplan.floorplanState.refreshDangerAreasLock();
  }

  static create(floorplan: Floorplan, x: number, y: number, width: number, height: number, rotation: number) {
    return new DangerArea(floorplan, uuidv4(), x, y, width, height, rotation);
  }
}
