<div odxLayout="flex vertical-center" class="space-between">
  <div *ngIf="gas">
    <p class="odx-title-6 header-title">{{ gas | localizeGasName }}</p>
    <p class="odx-subtitle-6">{{ gas.id }}</p>
  </div>
  <div *ngIf="product" odxLayout="flex vertical-center">
    <div class="image-wrapper">
      <app-standard-image [imageUrl]="imageUrl" [fallbackImageUrl]="fallbackImageUrl" [iconSize]="'56px'"></app-standard-image>
    </div>
    <div>
      <p class="odx-title-6 header-title" [odxTooltip]="product | localizeProductName">{{ product | localizeProductName }}</p>
      <p class="odx-subtitle-6">{{ dimensions ? product.id + ", " + dimensions : product.id }}</p>
    </div>
  </div>
  <p *ngIf="product && showCosts" class="odx-text">
    {{ product.price | currency: "EUR" }}
  </p>
</div>
