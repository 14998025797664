import { Component, EventEmitter, Output } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";

@Component({
  selector: "app-placeholder-button",
  templateUrl: "./placeholder-button.component.html",
  styleUrl: "./placeholder-button.component.scss",
  standalone: true,
  imports: [UiKitModule],
})
export class PlaceholderButtonComponent {
  @Output() add = new EventEmitter<void>();

  protected readonly tooltipTextPlaceholder: string = $localize`:@@floorplan.button.placeholder:Platzhalter platzieren`;

  addPlaceholder() {
    this.add.emit();
  }
}
