import { Component, Input } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { LogoComponent } from "@components/logo/logo.component";

@Component({
  selector: "app-full-screen-error",
  standalone: true,
  imports: [UiKitModule, LogoComponent],
  templateUrl: "./full-screen-error.component.html",
  styleUrl: "./full-screen-error.component.scss",
})
export class FullScreenErrorComponent {
  @Input({ required: true }) title!: string;
  @Input({ required: true }) description!: string[];
  @Input() showReloadButton: boolean = false;

  reload() {
    window.location.reload();
  }
}
