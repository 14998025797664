import { AirPath } from "@domain/project/floorplan/air-path";
import { TableColumn } from "@pdf/helpers/default-table/table-column";
import { Headlines, ImagePaths, TableProperties } from "@pdf/pdf-properties";
import { jsPDF } from "jspdf";
import { FloorplanInformationTable } from "../floorplan-information-table";
import { AirPathsRow } from "./air-paths-row";

export class AirPathsRowHeaders {
  public readonly NUMBER: string = $localize`:@@pdfExport.airPathsTable.header.number:Nr.`;
  public readonly NOTES: string = $localize`:@@pdfExport.airPathsTable.header.notes:Notizen`;
}

export class AirPathsTable extends FloorplanInformationTable {
  readonly headlines = new Headlines();
  readonly rowHeaders = new AirPathsRowHeaders();
  override readonly headline = this.headlines.AIR_PATHS;
  override readonly columns: TableColumn[];
  override readonly rows: AirPathsRow[];

  constructor(pdf: jsPDF, airPaths: AirPath[]) {
    super(ImagePaths.AIR_PATH);
    this.columns = [new TableColumn(this.rowHeaders.NUMBER, 60, 96), new TableColumn(this.rowHeaders.NOTES, 180, 1260)];

    this.rows = airPaths
      .map(
        (airPath) =>
          new AirPathsRow(
            pdf,
            this.textToColumnWidth(
              pdf,
              TableProperties.TEXT_FONT_SIZE,
              this.preProcessText(airPath.positionId),
              this.rowHeaders.NUMBER,
            ),
            this.textToColumnWidth(
              pdf,
              TableProperties.TEXT_FONT_SIZE,
              this.preProcessText(airPath.notes),
              this.rowHeaders.NOTES,
            ),
          ),
      )
      .sort((a, b) => a.number.join().localeCompare(b.number.join()));

    this.height = this.calculateHeight(this.rows);
  }
}
