<app-image-upload-overlay (image$)="onImageUploaded($event)">
  <div class="container scroll h-100 p-0-24-24">
    <!-- prettier-ignore -->
    <h6 class="odx-subtitle-6" *ngIf="images.length === 0" i18n="@@imageUpload.noImageYet">Sie haben noch keine Fotos hinzugefügt.</h6>
    <div odxLayout="grid 3@desktop 4@tablet 6@phone">
      <app-project-detail-file-card
        *ngFor="let image of images"
        (delete$)="onDelete(image)"
        (submit$)="onCardSubmit($event, image)"
        (click$)="openModal(image)"
        [fileUrl]="image.fileUrl"
        [fileName]="image.name"
      >
      </app-project-detail-file-card>
    </div>
  </div>
</app-image-upload-overlay>
