import { Product } from "@domain/product/product";

import { SignalElementConfiguration } from "@domain/project/configurations/signal-element-configuration";

export class OriginalSignalElementConfiguration {
  productConfiguration: SignalElementConfiguration;
  signalElement?: Product;

  constructor(productConfiguration: SignalElementConfiguration, signalElement?: Product) {
    this.productConfiguration = productConfiguration;
    this.signalElement = signalElement;
  }
}
