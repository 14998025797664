<app-product-card
  *ngIf="transmitter"
  [product]="transmitter!"
  [showCosts]="showCosts"
  [selectionMode]="isSelectable ? 'single' : 'none'"
  [isSelected]="isSelected"
  [imageUrl]="'https://product-assets.draeger.net/156px/navinta/' + transmitter.id + '.png'"
  [fallbackImageUrl]="'https://product-assets.draeger.net/documents/navinta/placeholderProductImages/' + productDataName + '.png'"
  (select$)="select(transmitter)"
>
  <div *ngIf="transmitter.id" class="feature">
    <p class="p-label odx-text--small" i18n="@@global.partNumber">Sachnummer</p>
    <p class="odx-text--small">{{ transmitter.id }}</p>
  </div>
  <div *ngIf="dimensionsProperty" class="feature">
    <p class="p-label odx-text--small" i18n="@@transmitterConfiguration.transmitterCard.dimensions.header">
      Abmessungen (H x B x T)
    </p>
    <p class="odx-text--small" i18n="@@transmitterConfiguration.transmitterCard.dimensions">
      ({{ dimensionsProperty.height * 1000 }}mm × {{ dimensionsProperty.width * 1000 }}mm ×
      {{ dimensionsProperty.depth * 1000 }}mm)
    </p>
  </div>
  <div *ngIf="displayProperty" class="feature" odxLayout="flex">
    <odx-icon *ngIf="displayProperty.value" name="check" inline></odx-icon>
    <odx-icon *ngIf="!displayProperty.value" name="close" class="icon-red" inline></odx-icon>
    <p i18n="@@transmitterConfiguration.transmitterCard.displayProperty">Display</p>
  </div>
  <div *ngIf="flameproofProperty" class="feature" odxLayout="flex">
    <odx-icon *ngIf="flameproofProperty.value" name="check" inline></odx-icon>
    <odx-icon *ngIf="!flameproofProperty.value" name="close" class="icon-red" inline></odx-icon>
    <p i18n="@@transmitterConfiguration.transmitterCard.flameproofProperty">Druckfest gekapselt</p>
  </div>
  <div *ngIf="wirelessProperty" class="feature" odxLayout="flex">
    <odx-icon *ngIf="wirelessProperty.value" name="check" inline></odx-icon>
    <odx-icon *ngIf="!wirelessProperty.value" name="close" class="icon-red" inline></odx-icon>
    <p i18n="@@transmitterConfiguration.transmitterCard.wirelessProperty">Wireless</p>
  </div>
  <div class="feature" odxLayout="flex">
    <a
      odxLink
      [href]="'https://product-assets.draeger.net/documents/navinta/datasheets/' + productDataName + '.pdf'"
      target="_blank"
      (click)="$event.stopPropagation()"
    >
      <odx-icon name="document" inline></odx-icon>
      <span i18n="@@global.productDatasheet">Produktdatenblatt</span>
    </a>
  </div>
</app-product-card>
