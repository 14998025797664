import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { LocalizeProductNamePipe, Product } from "@domain//product/product";
import { CheckBoxControl } from "@odx/angular/cdk/checkbox-control";

@Component({
  selector: "app-attachment-card",
  templateUrl: "./attachment-card.component.html",
  styleUrls: ["./attachment-card.component.scss"],
  standalone: true,
  imports: [UiKitModule, LocalizeProductNamePipe],
})
export class AttachmentCardComponent {
  @Input() set attachmentData(attachment: Product) {
    if (attachment) {
      this.attachment = attachment;
    }
  }

  @Input() set isSelected(selected: boolean) {
    if (selected) {
      this.checkboxSelectionChanged$.emit(selected);
    }
    this.selected = selected;
  }

  @Input() showCosts!: boolean;
  @Input() selectionMode!: "single" | "multi";

  @Output() checkboxSelectionChanged$ = new EventEmitter<boolean>();

  @ViewChild("checkboxAttachment")
  protected checkboxAttachment?: CheckBoxControl;

  protected attachment!: Product;
  protected selected = false;

  constructor(private localizeProductName: LocalizeProductNamePipe) {}

  protected isTooltipDisabled(): boolean {
    return this.localizeProductName.transform(this.attachment).length <= 80;
  }

  protected onCardClicked() {
    if (this.checkboxAttachment) {
      this.checkboxAttachment.checked = !this.checkboxAttachment.checked;
      this.checkboxSelectionChanged$.emit(this.checkboxAttachment.checked);
    }
  }

  protected onCheckedChanged() {
    this.checkboxSelectionChanged$.emit(this.checkboxAttachment!.checked);
  }
}
