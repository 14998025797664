<div odxLayout="flex" class="flex-column h-100">
  <odx-content-box>
    <div odxLayout="flex">
      <h6 class="odx-title-6 header-prefix" i18n="@@plasticSignConfiguration.selectSpecs.headerPrefix">Beschriftung für</h6>
      <app-configuration-wizard-header class="product-header" [product]="selectedPlasticSign!" [showCosts]="showCosts">
      </app-configuration-wizard-header>
    </div>
  </odx-content-box>

  <odx-content-box *ngIf="selectedPlasticSign" class="flex-column h-100 scroll" odxLayout="flex">
    <form [formGroup]="specsForm" class="h-100 w-100">
      <h6 class="odx-title-6" i18n="@@plasticSignConfiguration.selectText.label">Gewünschter Text</h6>
      <odx-form-field>
        <textarea
          formControlName="text"
          odxFormFieldControl
          placeholder="Gewünschter Text"
          i18n-placeholder="@@plasticSignConfiguration.selectText.placeholder"
        ></textarea>
      </odx-form-field>
      <h6 class="odx-title-6" i18n="@@plasticSignConfiguration.selectBackgroundColor.label">Hintergrundfarbe</h6>
      <odx-form-field>
        <textarea
          formControlName="backgroundColor"
          odxFormFieldControl
          placeholder="Hintergrundfarbe"
          i18n-placeholder="@@plasticSignConfiguration.selectBackgroundColor.placeholder"
        ></textarea>
      </odx-form-field>
      <h6 class="odx-title-6" i18n="@@plasticSignConfiguration.selectTextColor.label">Textfarbe</h6>
      <odx-form-field>
        <textarea
          formControlName="textColor"
          odxFormFieldControl
          placeholder="Textfarbe"
          i18n-placeholder="@@plasticSignConfiguration.selectTextColor.placeholder"
        ></textarea>
      </odx-form-field>
    </form>
  </odx-content-box>
</div>
