import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { ProjectFormsService } from "@components/project-forms/project-forms.service";
import { Project } from "@domain/project/project";
import { AreaHeaderComponent } from "@odx/angular/components/area-header";
import { FormFieldComponent } from "@odx/angular/components/form-field";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-goods-recipient-form",
  templateUrl: "./goods-recipient-form.component.html",
  styleUrls: ["./goods-recipient-form.component.scss"],
  standalone: true,
  imports: [AreaHeaderComponent, FormFieldComponent, UiKitModule, ReactiveFormsModule],
})
export class GoodsRecipientFormComponent implements OnInit, OnDestroy {
  @Input() isReadonly: boolean = false;
  @Output() goodsRecipientFormEmitter$ = new EventEmitter<FormGroup>();
  goodsRecipientForm!: FormGroup;

  @ViewChild("submitButton")
  submitButton?: ElementRef;

  private readonly _destroying = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private projectFormsService: ProjectFormsService,
  ) {
    this.goodsRecipientForm = this.formBuilder.group({
      information: [],
    });
    this.projectFormsService.projectInitialized$.pipe(takeUntil(this._destroying)).subscribe((project) => this.initForm(project));

    this.projectFormsService.projectReset$.pipe(takeUntil(this._destroying)).subscribe((project) => {
      this.goodsRecipientForm.reset();
      this.initForm(project);
    });

    this.goodsRecipientForm.statusChanges
      .pipe(takeUntil(this._destroying))
      .subscribe(() => this.goodsRecipientFormEmitter$.emit(this.goodsRecipientForm));
  }

  ngOnInit(): void {
    this.goodsRecipientFormEmitter$.emit(this.goodsRecipientForm);
  }

  ngOnDestroy(): void {
    this._destroying.next();
    this._destroying.complete();
  }

  initForm(project: Project) {
    this.goodsRecipientForm.patchValue({
      information: project.goodsRecipient?.information,
    });
  }

  triggerSubmit() {
    this.submitButton?.nativeElement.click();
  }
}
