import { ValidationArguments, ValidatorConstraint, ValidatorConstraintInterface } from "class-validator";

@ValidatorConstraint({ name: "isInstanceOf", async: false })
export class IsInstanceOfConstraint implements ValidatorConstraintInterface {
  validate(value: any, args: ValidationArguments) {
    const targetClass = args.constraints[0];
    return value instanceof targetClass;
  }

  defaultMessage(args: ValidationArguments) {
    const targetClassName = args.constraints[0].name;
    return `${args.property} must be an instance of ${targetClassName}`;
  }
}
