<odx-modal-header>
  <odx-area-header>{{ header }}</odx-area-header>
</odx-modal-header>
<odx-modal-content>
  <p>{{ content }}</p>
  <br />
  <p i18n="@@modal.operateOnConfig.askForContinue">Möchten Sie fortfahren?</p>
</odx-modal-content>
<odx-modal-footer>
  <button odxButton variant="secondary" odxModalDismiss i18n="@@global.dismiss">Abbrechen</button>
  <button odxButton variant="primary" (click)="onConfirm()" data-testid="operateOnConfigModal.btn.confirm">
    {{ continueButtonText }}
  </button>
</odx-modal-footer>
