<div odxLayout="flex gap-small" class="menu-buttons flex-column">
  <app-new-configuration-button (newConfiguration)="goToConfiguration()"></app-new-configuration-button>
  <app-floorplan-menu-item-buttons>
    <app-placeholder-button (add)="addPlaceholder()"></app-placeholder-button>
    <ng-content select="[additionalButton]"></ng-content>
    <app-toggle-visibility-button [visible]="visible" (toggle)="updateVisibility($event)"> </app-toggle-visibility-button>
    <app-toggle-lock-button [locked]="locked" (toggle)="updateLock($event)"> </app-toggle-lock-button>
  </app-floorplan-menu-item-buttons>
</div>
<hr />
<div class="configured-products">
  <h6 class="odx-title-6" i18n="@@floorplan.configuredProducts.header">Konfigurierte {{ title }}</h6>
  <p *ngIf="!hasConfigurations" class="odx-text--small" i18n="@@floorplan.configuredProducts.noConfigurationsYet">
    Sie haben noch keine {{ title }} konfiguriert.
  </p>
</div>
<div class="list-container">
  <ng-content></ng-content>
</div>
