<app-floorplan-menu-item-buttons>
  <app-add-button
    (add)="addFloorplanText()"
    [odxTooltip]="tooltipTextAddNote"
    [odxTooltipOptions]="{ position: 'bottom' }"
  ></app-add-button>
  <app-toggle-visibility-button
    [visible]="floorplanState.floorplanTextsVisible"
    (toggle)="floorplanState.floorplanTextsVisible = $event"
  >
  </app-toggle-visibility-button>
  <app-toggle-lock-button [locked]="floorplanState.floorplanTextsLocked" (toggle)="floorplanState.floorplanTextsLocked = $event">
  </app-toggle-lock-button>
</app-floorplan-menu-item-buttons>
