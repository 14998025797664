import { Injectable } from "@angular/core";
import { ProductService } from "@domain/product/product.service";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { PlaceholderProduct } from "@domain/project/floorplan/placeholder-product";
import { FloorplanWorkspace } from "@project/floorplanner/floorplan-workspace";
import { forkJoin, map, Observable, tap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FloorplanWorkspaceWarningSignService {
  constructor(private productService: ProductService) {}

  public updateWarningSigns(floorplan: Floorplan, floorplanWorkspace: FloorplanWorkspace): Observable<void> {
    const allProductIds$ = this.productService.getAllProductIds();
    const discontinuedProductIds$ = this.productService.getDiscontinuedProductIds();

    return forkJoin([allProductIds$, discontinuedProductIds$]).pipe(
      tap(([allProductIds, discontinuedProductIds]) => {
        this.updatePlaceholderWarningSigns(floorplan, floorplanWorkspace, allProductIds);
        this.updateProductWarningSigns(floorplan, floorplanWorkspace, discontinuedProductIds);
      }),
      map(() => undefined),
    );
  }

  private updateProductWarningSigns(
    floorplan: Floorplan,
    floorplanWorkspace: FloorplanWorkspace,
    discontinuedProductIds: string[],
  ) {
    const discontinuedConfigIds = floorplan.project.productConfigurations
      .filter((config) =>
        config.configuredProductIds.some((configuredProductId) => discontinuedProductIds.includes(configuredProductId)),
      )
      .map((config) => config.id);
    [
      floorplan.plasticSigns,
      floorplan.signalElements,
      floorplan.transmitters,
      floorplan.gasWarningCenters,
      floorplan.alarmDevices,
    ]
      .flat()
      .forEach((item) => {
        floorplanWorkspace.refreshWarningSignVisibility(item, discontinuedConfigIds.includes(item.config.id));
      });
  }

  private updatePlaceholderWarningSigns(floorplan: Floorplan, floorplanWorkspace: FloorplanWorkspace, allProductIds: string[]) {
    [
      floorplan.transmitterPlaceholders,
      floorplan.gasWarningCenterPlaceholders,
      floorplan.alarmDevicePlaceholders,
      floorplan.plasticSignPlaceholders,
      floorplan.signalElementPlaceholders,
    ]
      .flat()
      .forEach((item) => {
        floorplanWorkspace.refreshWarningSignVisibility(
          item,
          item.products.length > 0 && item.products.every((product: PlaceholderProduct) => allProductIds.includes(product.id)),
        );
      });
  }
}
