import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { AttachmentCardComponent } from "@components/attachment-card/attachment-card.component";
import { ConfigurationWizardHeaderAccordionComponent } from "@components/configuration-wizard-header-accordion/configuration-wizard-header-accordion.component";
import { ConfigurationWizardHeaderComponent } from "@components/configuration-wizard-header/configuration-wizard-header.component";
import { FilterProductComponent } from "@components/filter-product/filter-product.component";
import { RefreshProductsHintComponent } from "@components/refresh-products-hint/refresh-products-hint.component";
import { Gas } from "@domain/gas/gas";
import {
  LocalizeProductNamePipe,
  MatchType,
  Product,
  ProductType,
  Relationship,
  RelationshipType,
} from "@domain/product/product";
import { ProductService } from "@domain/product/product.service";
import { untilDestroyed } from "@odx/angular/utils";
import { DeviceClassSelectorComponent } from "@project/alarm-device-configuration/device-class-selector/device-class-selector.component";
import { Observable, first, forkJoin, map, of } from "rxjs";

interface AttachmentSelectorProducts {
  selectedProducts: Product[];
  preselectedAttachments?: Product[];
}

@Component({
  selector: "app-attachment-selector",
  templateUrl: "./attachment-selector.component.html",
  styleUrls: ["./attachment-selector.component.scss"],
  standalone: true,
  imports: [
    UiKitModule,
    ReactiveFormsModule,
    DeviceClassSelectorComponent,
    AttachmentCardComponent,
    ConfigurationWizardHeaderComponent,
    ConfigurationWizardHeaderAccordionComponent,
    FilterProductComponent,
    RefreshProductsHintComponent,
    LocalizeProductNamePipe,
  ],
  providers: [LocalizeProductNamePipe],
})
export class AttachmentSelectorComponent implements OnInit {
  @Input({ required: true }) set products(products: AttachmentSelectorProducts) {
    this.selectedProducts = products.selectedProducts;
    this.collectAttachments();
    this.updateHeaderTitle();

    this.clearSelection();
    if (products.preselectedAttachments) {
      forkJoin([this.reqAttachments$, this.optAttachments$])
        .pipe(
          first(),
          map((attachments) =>
            attachments
              .flat(1)
              .filter((attachment) =>
                products
                  .preselectedAttachments!.map((existingAttachment) => existingAttachment.isAvailable && existingAttachment.id)
                  .some((id) => id === attachment.id),
              ),
          ),
        )
        .subscribe((products) => products.forEach((attachment) => this.updateAttachmentSelection(true, attachment)));
    } else {
      this.reqAttachments$
        .pipe(first())
        .subscribe((attachments) => attachments.forEach((attachment) => this.updateAttachmentSelection(true, attachment)));
    }
  }

  @Input() showCosts = false;
  @Input() selectionMode: "single" | "multi" = "multi";
  @Input() headerProducts: (Product | Gas)[] = [];

  @Output() attachmentsSelect$ = new EventEmitter<Product[]>();

  protected radioButtonForm = this.formBuilder.group({
    selectedAttachment: new FormControl<Product | null>(null, Validators.required),
  });

  @Output()
  formReady = of(this.radioButtonForm);

  protected selectedProducts: Product[] = [];
  protected filteredReqAttachments$!: Observable<Product[]>;
  protected filteredOptAttachments$!: Observable<Product[]>;
  protected headerTitle: string = "";
  protected headerAccordionPrefix: string = $localize`:@@productConfiguration.attachments.headerAccordionPrefix:Zubehör für`;

  private optAttachments$!: Observable<Product[]>;
  private reqAttachments$!: Observable<Product[]>;
  private selectedAttachments: Product[] = [];
  private takeUntilDestroyed = untilDestroyed();

  constructor(
    private productService: ProductService,
    private formBuilder: FormBuilder,
    private localizeProductNamePipe: LocalizeProductNamePipe,
  ) {}

  ngOnInit(): void {
    this.radioButtonForm.valueChanges
      .pipe(this.takeUntilDestroyed())
      .subscribe((formValue) => this.attachmentsSelect$.emit(formValue.selectedAttachment ? [formValue.selectedAttachment] : []));
  }

  search(value: string) {
    this.filteredReqAttachments$ = this.productService.filter(this.reqAttachments$, value);
    this.filteredOptAttachments$ = this.productService.filter(this.optAttachments$, value);
  }

  updateAttachmentSelection(selected: boolean, attachment: Product) {
    if (!attachment) return;
    if (this.selectionMode === "single") {
      this.updateSingleSelectedAttachment(attachment);
    } else {
      this.updateMultiSelectedAttachments(selected, attachment);
    }
  }

  checkIfSelected(attachment: Product) {
    return (
      this.selectedAttachments.map((attachment) => attachment.id).indexOf(attachment.id) >= 0 ||
      this.radioButtonForm.value.selectedAttachment?.id === attachment.id
    );
  }

  private clearSelection() {
    if (this.selectionMode === "single") {
      this.radioButtonForm.patchValue({ selectedAttachment: null });
    } else {
      this.selectedAttachments = [];
      this.attachmentsSelect$.emit(this.selectedAttachments);
    }
  }

  private updateSingleSelectedAttachment(attachment: Product) {
    this.radioButtonForm.patchValue({ selectedAttachment: attachment });
  }

  protected updateMultiSelectedAttachments(selected: boolean, attachment: Product) {
    if (selected) {
      if (!this.selectedAttachments.map((attachment) => attachment.id).includes(attachment.id)) {
        this.selectedAttachments.push(attachment);
      }
    } else {
      this.selectedAttachments = this.selectedAttachments.filter((item) => item.id !== attachment.id);
    }
    this.attachmentsSelect$.emit(this.selectedAttachments);
  }

  private collectAttachments() {
    const reqIds = [
      ...new Set(
        this.selectedProducts
          .flatMap((product) => product.relationships)
          .filter((relationship) => this.isRequiredAttachment(relationship))
          .map((attachment) => attachment.productId),
      ),
    ];

    const optIds = [
      ...new Set(
        this.selectedProducts
          .flatMap((product) => product.relationships)
          .filter((relationship) => this.isOptionalAttachment(relationship))
          .map((attachment) => attachment.productId),
      ),
    ];

    this.reqAttachments$ = this.getAttachments(reqIds);
    this.filteredReqAttachments$ = this.reqAttachments$;
    this.optAttachments$ = this.getAttachments(optIds);
    this.filteredOptAttachments$ = this.optAttachments$;
  }

  private getAttachments(ids: string[]) {
    return ids.length
      ? this.productService
          .getProductsByIds(ids, ProductType.ATTACHMENT)
          .pipe(map((products) => products.filter((product) => product.isAvailable)))
      : of([]);
  }

  private isRequiredAttachment(relationship: Relationship): boolean {
    const isMatchTypeDirect = relationship.matchType == MatchType.DIRECT;
    const isRequired = relationship.relationshipType == RelationshipType.EXTRAREQ;
    const isGuide = relationship.relationshipType == RelationshipType.EXTRAGUIDE;
    return isMatchTypeDirect && (isRequired || isGuide);
  }

  private isOptionalAttachment(relationship: Relationship): boolean {
    const isMatchTypeDirect = relationship.matchType == MatchType.DIRECT;
    const isOptional = relationship.relationshipType == RelationshipType.EXTRA;
    const isModule = relationship.relationshipType == RelationshipType.MODULE;
    return isMatchTypeDirect && (isOptional || isModule);
  }

  private updateHeaderTitle() {
    if (this.selectedProducts.length === 1) {
      this.headerTitle = this.localizeProductNamePipe.transform(this.selectedProducts[0]);
    } else if (this.selectedProducts.length > 1) {
      this.headerTitle = $localize`:@@alarmDeviceConfiguration.summaryHeader:${this.selectedProducts.length}:numberOfSelectedProducts: ausgewählte Alarmmittel`;
    }
  }
}
