import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { ProjectFormsService } from "@components/project-forms/project-forms.service";
import { Project } from "@domain/project/project";
import { AreaHeaderComponent } from "@odx/angular/components/area-header";
import { FormFieldComponent, FormGroupComponent } from "@odx/angular/components/form-field";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-customer-form",
  templateUrl: "./customer-form.component.html",
  styleUrls: ["./customer-form.component.scss"],
  standalone: true,
  imports: [UiKitModule, ReactiveFormsModule, AreaHeaderComponent, FormFieldComponent, FormGroupComponent],
})
export class CustomerFormComponent implements OnInit, OnDestroy {
  @Input() isReadonly: boolean = false;
  @Output() customerFormEmitter$ = new EventEmitter<FormGroup>();
  customerForm!: FormGroup;

  @ViewChild("submitButton")
  submitButton?: ElementRef;

  private readonly _destroying = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private projectFormsService: ProjectFormsService,
  ) {
    this.customerForm = this.formBuilder.group({
      name: [null, Validators.required],
      customerNumber: [],
      street: [],
      zipCode: [],
      city: [],
    });
    this.projectFormsService.projectInitialized$.pipe(takeUntil(this._destroying)).subscribe((project) => this.initForm(project));

    this.projectFormsService.projectReset$.pipe(takeUntil(this._destroying)).subscribe((project) => {
      this.customerForm.reset();
      this.initForm(project);
    });

    this.customerForm.statusChanges
      .pipe(takeUntil(this._destroying))
      .subscribe(() => this.customerFormEmitter$.emit(this.customerForm));
  }

  ngOnInit(): void {
    this.customerFormEmitter$.emit(this.customerForm);
  }

  ngOnDestroy(): void {
    this._destroying.next();
    this._destroying.complete();
  }

  initForm(project: Project) {
    this.customerForm.patchValue({
      name: project.customer?.name,
      customerNumber: project.customer?.customerNumber,
      street: project.customer?.street,
      zipCode: project.customer?.zipCode,
      city: project.customer?.city,
    });
  }

  triggerSubmit() {
    this.submitButton?.nativeElement.click();
  }
}
