import { Component, inject, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { ConfirmModalComponent } from "@components/confirm-modal/confirm-modal.component";
import { FileModalComponent } from "@components/file-modal/file-modal.component";
import { ImageUploadOverlayComponent } from "@components/image-upload-overlay/image-upload-overlay.component";
import { ProjectDetailFileCardComponent } from "@components/project-cards/project-detail-file-card/project-detail-file-card.component";
import { Project } from "@domain/project/project";
import { ProjectImage } from "@domain/project/project-image";
import { ModalOptions, ModalService } from "@odx/angular/components/modal";
import { map } from "rxjs";

@Component({
  selector: "app-image-upload",
  templateUrl: "./image-upload.component.html",
  styleUrls: ["./image-upload.component.scss"],
  standalone: true,
  imports: [UiKitModule, ReactiveFormsModule, ImageUploadOverlayComponent, ProjectDetailFileCardComponent],
})
export class ImageUploadComponent {
  private readonly modalService = inject(ModalService);

  @Input() set projectData(project: Project) {
    this.project = project;
    this.images = [...project.images];
  }

  project!: Project;
  images: ProjectImage[] = [];

  onImageUploaded(projectImages: ProjectImage[]) {
    this.assignImagesToProject(this.project, projectImages);
    this.images.push(...projectImages);
  }

  onDelete(image: ProjectImage) {
    const options: Partial<ModalOptions> = {
      data: {
        text: $localize`:@@modal.deleteImage:Wollen Sie das Bild wirklich löschen?`,
      },
    };
    this.modalService
      .open(ConfirmModalComponent, options)
      .onClose$.pipe(
        map(() => {
          this.project.deleteImage(image);
          return this.project;
        }),
      )
      .subscribe(() => this.deleteImageFromList(image));
  }

  onCardSubmit(form: FormGroup, image: ProjectImage) {
    image.name = form.value.name;
  }

  openModal(image: ProjectImage) {
    const options: Partial<ModalOptions> = {
      data: {
        fileName: image.name,
        fileUrl: image.fileUrl,
      },
    };

    this.modalService.open(FileModalComponent, options);
  }

  private deleteImageFromList(image: ProjectImage) {
    this.images = this.images.filter((img) => image !== img);
  }

  private assignImagesToProject(project: Project, images: ProjectImage[]) {
    project.addImages(...images);
  }
}
