import { Project } from "@domain/project/project";
import { ProjectEventType } from "@domain/project/project-event";
import { Exclude, Expose } from "class-transformer";
import { IsDataURI, IsString, IsUUID } from "class-validator";
import { v4 as uuidv4 } from "uuid";

export class ProjectImage {
  @Exclude()
  private project?: Project;

  @IsString()
  @Expose({ name: "name" })
  private _name: string;

  @IsUUID()
  public readonly id: string;

  @IsDataURI()
  public readonly fileUrl: string;

  public readonly addDate: Date;

  constructor(id: string, fileUrl: string, name: string, addDate: Date) {
    this._name = name;
    this.id = id;
    this.fileUrl = fileUrl;
    this.addDate = addDate;
  }

  init(project: Project) {
    this.project = project;
  }

  get name(): string {
    return this._name;
  }

  set name(name: string) {
    if (this._name === name) return;
    this._name = name;
    this.project?.publishUpdate(ProjectEventType.IMAGE_UPDATED, this);
  }

  static create(fileUrl: string, name: string): ProjectImage {
    return new ProjectImage(uuidv4(), fileUrl, name, new Date());
  }
}
