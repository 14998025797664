<odx-content-box class="file-content-box">
  <odx-area-header>
    <h6 class="odx-title-6" data-testid="heading.newAsset">{{ title }}</h6>
    <odx-area-header-content>
      <odx-action-group>
        <button odxButton (click)="onDelete()">
          <odx-icon name="delete"></odx-icon>
        </button>
      </odx-action-group>
    </odx-area-header-content>
  </odx-area-header>
  <!-- prettier-ignore-attribute style -->
  <div class="file-wrapper" style="background-image: url({{fileUrl}});">
    <div *ngIf="!fileUrl" class="file-placeholder h-100" i18n="@@newProject.emptyFloorplanName">Leerer Grundrissplan</div>
  </div>
  <form odxForm [formGroup]="newFileForm">
    <odx-form-field i18n-label="@@newProject.emptyFloorplanNameForm.label" label="Name" odxLayout="6">
      <input
        formControlName="name"
        odxFormFieldControl
        i18n-placeholder="@@newProject.emptyFloorplanNameForm.placeholder"
        placeholder="Name"
        data-testid="input.newAsset"
      />
    </odx-form-field>
    <button type="submit" class="hidden" #submitButton></button>
  </form>
</odx-content-box>
