import { Component, Input } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { StandardImageComponent } from "@components/standard-image/standard-image.component";

@Component({
  selector: "app-picture-card",
  templateUrl: "./picture-card.component.html",
  styleUrl: "./picture-card.component.scss",
  standalone: true,
  imports: [UiKitModule, StandardImageComponent],
})
export class PictureCardComponent {
  @Input({ required: true }) title!: string;
  @Input() imageUrl?: string;
  @Input() isSelected: boolean = false;
}
