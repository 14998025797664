<app-floorplan-product-menu
  [title]="tooltipTextSignalElements"
  [hasConfigurations]="floorplan.project.signalElements.length > 0"
  [visible]="floorplan.floorplanState.signalElementsVisible"
  [locked]="floorplan.floorplanState.signalElementsLocked"
  (visibilityUpdate)="floorplan.floorplanState.signalElementsVisible = $event"
  (lockUpdate)="floorplan.floorplanState.signalElementsLocked = $event"
  (addPlaceholderToFloorplan)="addPlaceholder()"
  configPath="signal-element"
>
  <div *ngFor="let signalElement of floorplan.project.signalElements">
    <hr />
    <app-configured-product
      [productConfiguration]="signalElement"
      [numberOfFloorplanItems]="floorplan.countPlacedSignalElements(signalElement)"
      (add)="addSignalElement(signalElement)"
      (delete)="deleteSignalElement(signalElement)"
      editPath="signal-element"
      imageUrl="./assets/leuchttransparente.svg"
    >
    </app-configured-product>
  </div>
</app-floorplan-product-menu>
