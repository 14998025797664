import { Floorplan } from "@domain/project/floorplan/floorplan";
import { FloorplanEventType } from "@domain/project/floorplan/floorplan-event";
import { Project } from "@domain/project/project";
import { IsPastDate } from "@utils/class-validator/class-validator-constraints";
import { Exclude, Expose, Transform, Type } from "class-transformer";
import { IsBoolean, IsDate, IsNumber, IsOptional, IsString, IsUUID, MaxLength } from "class-validator";

export abstract class FloorplanItem {
  @Exclude()
  protected _floorplan!: Floorplan;

  @IsBoolean()
  @Expose({ name: "locked" })
  protected _locked: boolean;

  @IsNumber()
  @Expose({ name: "x" })
  private _x: number;

  @IsNumber()
  @Expose({ name: "y" })
  private _y: number;

  @Type(() => Date)
  @Transform((params) => params.value || new Date(), { toClassOnly: true })
  @IsDate()
  @IsPastDate()
  @Expose({ name: "lockTimestamp" })
  protected _lockTimestamp: Date = new Date();

  @IsString()
  @IsOptional()
  @Expose({ name: "positionId" })
  protected _positionId?: string;

  @IsString()
  @MaxLength(2000, { message: "Notes must not exceed 2000 characters in length" })
  @Expose({ name: "notes" })
  protected _notes: string = "";

  @IsUUID()
  public readonly id: string;

  protected constructor(
    floorplan: Floorplan,
    id: string,
    x: number,
    y: number,
    notes: string = "",
    locked: boolean = false,
    lockTimestamp: Date = new Date(),
  ) {
    this._floorplan = floorplan;
    this._x = x;
    this._y = y;
    this._lockTimestamp = lockTimestamp;
    this._locked = locked;
    this._notes = notes;
    this.id = id;
  }

  init(project: Project, floorplan: Floorplan) {
    this._floorplan = floorplan;
  }

  get x(): number {
    return this._x;
  }

  get y(): number {
    return this._y;
  }

  updatePosition(x: number, y: number) {
    this._x = x;
    this._y = y;
    this._floorplan.publishUpdate(FloorplanEventType.ITEM_UPDATED, this);
  }

  get positionId(): string | undefined {
    return this._positionId;
  }

  set positionId(positionId) {
    if (this._positionId === positionId) return;
    this._positionId = positionId;
    this._floorplan.publishUpdate(FloorplanEventType.ITEM_POSITION_ID_CHANGED, this);
  }

  get notes(): string {
    return this._notes;
  }

  set notes(notes: string) {
    if (this._notes === notes) return;
    this._notes = notes;
    this._floorplan.publishUpdate(FloorplanEventType.ITEM_UPDATED, this);
  }

  get locked(): boolean {
    return this._locked;
  }

  set locked(locked: boolean) {
    if (this._locked === locked) return;
    this.setLockedWithoutRefresh(locked);
    this.refreshFloorplanLockState();
  }

  get lockTimestamp(): Date {
    return this._lockTimestamp;
  }

  setLockedWithoutRefresh(locked: boolean) {
    if (this._locked === locked) return;
    this._locked = locked;
    this._lockTimestamp = new Date();
    this._floorplan.publishUpdate(FloorplanEventType.ITEM_LOCK_CHANGED, this);
  }

  protected abstract refreshFloorplanLockState(): void;

  abstract duplicate(x: number, y: number): FloorplanItem;

  abstract delete(): void;

  abstract get relatedItems(): FloorplanItem[];
}
