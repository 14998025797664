import { DangerArea } from "@domain/project/floorplan/zones/danger-area";
import { ExZone, ExZoneType } from "@domain/project/floorplan/zones/ex-zone";
import { TableColumn } from "@pdf/helpers/default-table/table-column";
import { Headlines, ImagePaths, TableProperties } from "@pdf/pdf-properties";
import { jsPDF } from "jspdf";
import { FloorplanInformationTable } from "../floorplan-information-table";
import { ZoneRow } from "./zone-row";

export class ZonesRowHeaders {
  public readonly NUMBER = $localize`:@@pdfExport.zonesTable.header.number:Nr.`;
  public readonly TYPE = $localize`:@@pdfExport.zonesTable.header.type:Typ`;
  public readonly NOTES = $localize`:@@pdfExport.zonesTable.header.notes:Notizen`;
}

export class ZonesTable extends FloorplanInformationTable {
  private static readonly DANGER_AREA_TYPE: string = $localize`:@@global.dangerArea:Gefahrenbereich`;
  readonly headlines = new Headlines();
  readonly rowHeaders = new ZonesRowHeaders();
  override readonly headline = this.headlines.ZONES;
  override readonly columns: TableColumn[] = [];
  override readonly rows: ZoneRow[];

  constructor(pdf: jsPDF, exZones: ExZone[], dangerAreas: DangerArea[]) {
    super(ImagePaths.ZONE);
    this.columns = [
      new TableColumn(this.rowHeaders.NUMBER, 48, 120),
      new TableColumn(this.rowHeaders.TYPE, 168, 180),
      new TableColumn(this.rowHeaders.NOTES, 348, 1104),
    ];
    this.rows = this.collectZoneRows(pdf, exZones, dangerAreas);
    this.height = this.calculateHeight(this.rows);
  }

  private extractNumber(array: string[]) {
    const value: string = array.join(" ");
    const match = value.match(/\d+/);
    return match ? parseInt(match[0], 10) : 0;
  }

  private getExZoneTypeText(type: ExZoneType) {
    switch (type) {
      case ExZoneType.ZONE_0:
        return $localize`:@@global.exZone:Ex-Zone` + " 0";
      case ExZoneType.ZONE_1:
        return $localize`:@@global.exZone:Ex-Zone` + " 1";
      case ExZoneType.ZONE_2:
        return $localize`:@@global.exZone:Ex-Zone` + " 2";
    }
  }

  private collectZoneRows(pdf: jsPDF, exZones: ExZone[], dangerAreas: DangerArea[]) {
    const exZoneRows = exZones.map(
      (zone) =>
        new ZoneRow(
          pdf,
          this.textToColumnWidth(
            pdf,
            TableProperties.TEXT_FONT_SIZE,
            this.preProcessText(zone.positionId),
            this.rowHeaders.NUMBER,
          ),
          this.textToColumnWidth(
            pdf,
            TableProperties.TEXT_FONT_SIZE,
            this.preProcessText(this.getExZoneTypeText(zone.exZoneType)),
            this.rowHeaders.TYPE,
          ),
          this.textToColumnWidth(pdf, TableProperties.TEXT_FONT_SIZE, this.preProcessText(zone.notes), this.rowHeaders.NOTES),
        ),
    );
    const dangerAreaRows = dangerAreas.map(
      (area) =>
        new ZoneRow(
          pdf,
          this.textToColumnWidth(
            pdf,
            TableProperties.TEXT_FONT_SIZE,
            this.preProcessText(area.positionId),
            this.rowHeaders.NUMBER,
          ),
          this.textToColumnWidth(
            pdf,
            TableProperties.TEXT_FONT_SIZE,
            this.preProcessText(ZonesTable.DANGER_AREA_TYPE),
            this.rowHeaders.TYPE,
          ),
          this.textToColumnWidth(pdf, TableProperties.TEXT_FONT_SIZE, this.preProcessText(area.notes), this.rowHeaders.NOTES),
        ),
    );
    return exZoneRows.concat(dangerAreaRows).sort((a, b) => this.extractNumber(a.number) - this.extractNumber(b.number));
  }
}
