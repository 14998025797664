export const environment = {
   production: true,
   dataBackendUrl: 'https://navinta-prod-backend.azurewebsites.net/api',
   oktaUri: 'https://login.draeger.com/oauth2/default',
   oktaClientId: '0oaccohk0vWgzlnKT417',
   crmUrl: 'https://draeger-prod.crm4.dynamics.com/main.aspx?appid=77beae7e-f2d7-4796-bca1-0b695ad74bf7&forceUCI=1&pagetype=entitylist&etn=contact&viewid=00000000-0000-0000-00aa-000010001004&viewType=1039',
   sentryIngestUrl: 'https://ec9310aefe5d273082dadf99bc76908f@o1382842.ingest.sentry.io/4506302460788736',
   sentryEnvironment: 'prod',
   googleTagManagerId: 'G-8XRP77C5Z9',
   lokaliseNavintaProjectId: 'undefined',
   lokaliseApiUrl: 'https://api.lokalise.com/api2/projects/undefined',
   lokaliseApiToken: 'f21114b343c111cea2b4763a788102cd85ea21cc',
   apiUrl: "https://func-navinta-api-prod-west-eu.azurewebsites.net/api",
};
