import { Product } from "@domain/product/product";
import { ProductConfiguration } from "@domain/project/configurations/product-configuration";
import { Project } from "@domain/project/project";
import { ProjectEventType } from "@domain/project/project-event";
import { IsString } from "class-validator";
import { v4 as uuidv4 } from "uuid";

export class SignalElementConfiguration extends ProductConfiguration {
  @IsString()
  public productId: string;
  constructor(id: string, name: string, project: Project, productId: string) {
    super(id, name, project);
    this.productId = productId;
  }

  static create(name: string, project: Project, productId: string): SignalElementConfiguration {
    return new SignalElementConfiguration(uuidv4(), name, project, productId);
  }

  countPlacedProducts(project = this.project): number {
    return project ? project.countPlacedSignalElements(this) : 0;
  }

  update(signalElement: Product) {
    this.name = signalElement.name;
    this.productId = signalElement.id;
    this.project.publishUpdate(ProjectEventType.SIGNAL_ELEMENT_CONFIGURATION_UPDATED, this);
  }

  override get mainProductId(): string {
    return this.productId;
  }
}
