<button
  odxButton
  variant="secondary"
  (click)="addPlaceholder()"
  i18n="@@floorplan.menu.button.newPlaceholder"
  [odxTooltip]="tooltipTextPlaceholder"
  [odxTooltipOptions]="{ position: 'bottom' }"
  data-testid="btn.newPlaceholder"
>
  Platzhalter
</button>
