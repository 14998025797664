import { Injectable } from "@angular/core";
import { STORE_NAME_LAST_PRODUCT_UPDATE } from "@app/indexed-db-config";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { catchError, Observable, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LastProductUpdateService {
  private static readonly DB_ID = 1;

  constructor(private dbService: NgxIndexedDBService) {}

  saveLastUpdate(): Observable<{ date: string; id: number }> {
    return this.dbService.update(STORE_NAME_LAST_PRODUCT_UPDATE, {
      id: LastProductUpdateService.DB_ID,
      date: new Date().toISOString(),
    });
  }

  getLastUpdated(): Observable<any> {
    return this.dbService.getByKey(STORE_NAME_LAST_PRODUCT_UPDATE, LastProductUpdateService.DB_ID).pipe(
      catchError((error) => {
        console.error("Error fetching last update:", error);
        return of({ date: null }); // Return an object with a default date if not found or error
      }),
    );
  }
}
