import { AlarmDeviceConfiguration } from "@domain/project/configurations/alarm-device-configuration";
import { GasWarningCenterConfiguration } from "@domain/project/configurations/gas-warning-center-configuration";
import { PlasticSignConfiguration } from "@domain/project/configurations/plastic-sign-configuration";
import { ProductConfiguration } from "@domain/project/configurations/product-configuration";
import { SignalElementConfiguration } from "@domain/project/configurations/signal-element-configuration";
import { TransmitterConfiguration } from "@domain/project/configurations/transmitter-configuration";
import { AirPath, AirPathDirection } from "@domain/project/floorplan/air-path";
import { FloorplanAlarmDevice } from "@domain/project/floorplan/floorplan-alarm-device";
import { FloorplanEventType } from "@domain/project/floorplan/floorplan-event";
import { FloorplanGasWarningCenter } from "@domain/project/floorplan/floorplan-gas-warning-center";
import { FloorplanImage } from "@domain/project/floorplan/floorplan-image";
import { FloorplanPlaceholder } from "@domain/project/floorplan/floorplan-placeholder";
import { FloorplanPlasticSign } from "@domain/project/floorplan/floorplan-plastic-sign";
import { FloorplanProductItem } from "@domain/project/floorplan/floorplan-product-item";
import { FloorplanSignalElement } from "@domain/project/floorplan/floorplan-signal-element";
import { FloorplanState } from "@domain/project/floorplan/floorplan-state";
import { FloorplanText } from "@domain/project/floorplan/floorplan-text";
import { FloorplanTransmitter } from "@domain/project/floorplan/floorplan-transmitter";
import { FloorplanTransmitterPlaceholder } from "@domain/project/floorplan/floorplan-transmitter-placeholder";
import { MeasurementLine } from "@domain/project/floorplan/measurement-line";
import { Pipeline } from "@domain/project/floorplan/pipeline";
import { DangerArea } from "@domain/project/floorplan/zones/danger-area";
import { ExZone, ExZoneType } from "@domain/project/floorplan/zones/ex-zone";
import { Project } from "@domain/project/project";
import { ProjectEventType } from "@domain/project/project-event";
import { ProjectImage } from "@domain/project/project-image";
import { IsArrayOfInstancesOf, IsInstanceOf } from "@utils/class-validator/class-validator-constraints";
import { Exclude, Expose, Transform, Type } from "class-transformer";
import { IsBoolean, IsDataURI, IsOptional, IsString, IsUUID, ValidateNested } from "class-validator";
import { v4 as uuidv4 } from "uuid";

export class Floorplan {
  @Exclude()
  private _project?: Project;

  @IsString()
  @Expose({ name: "name" })
  private _name: string;

  @ValidateNested()
  @IsArrayOfInstancesOf(FloorplanImage)
  @Type(() => FloorplanImage)
  @Expose({ name: "images" })
  private readonly _images: FloorplanImage[] = [];

  @IsOptional()
  @IsArrayOfInstancesOf(FloorplanAlarmDevice)
  @ValidateNested()
  @Type(() => FloorplanAlarmDevice)
  @Expose({ name: "alarmDevices" })
  private readonly _alarmDevices: FloorplanAlarmDevice[] = [];

  @ValidateNested()
  @IsArrayOfInstancesOf(FloorplanPlaceholder)
  @Type(() => FloorplanPlaceholder)
  @Expose({ name: "alarmDevicePlaceholders" })
  @Transform((params) => params.value || [], { toClassOnly: true })
  private readonly _alarmDevicePlaceholders: FloorplanPlaceholder[] = [];

  @ValidateNested()
  @IsArrayOfInstancesOf(FloorplanTransmitter)
  @Type(() => FloorplanTransmitter)
  @Expose({ name: "transmitters" })
  private readonly _transmitters: FloorplanTransmitter[] = [];

  @ValidateNested()
  @IsArrayOfInstancesOf(FloorplanTransmitterPlaceholder)
  @Type(() => FloorplanTransmitterPlaceholder)
  @Expose({ name: "transmitterPlaceholders" })
  @Transform((params) => params.value || [], { toClassOnly: true })
  private readonly _transmitterPlaceholders: FloorplanTransmitterPlaceholder[] = [];

  @ValidateNested()
  @IsArrayOfInstancesOf(FloorplanGasWarningCenter)
  @Type(() => FloorplanGasWarningCenter)
  @Expose({ name: "gasWarningCenters" })
  private readonly _gasWarningCenters: FloorplanGasWarningCenter[] = [];

  @ValidateNested()
  @IsArrayOfInstancesOf(FloorplanPlaceholder)
  @Type(() => FloorplanPlaceholder)
  @Expose({ name: "gasWarningCenterPlaceholders" })
  @Transform((params) => params.value || [], { toClassOnly: true })
  private readonly _gasWarningCenterPlaceholders: FloorplanPlaceholder[] = [];

  @ValidateNested()
  @IsArrayOfInstancesOf(FloorplanSignalElement)
  @Type(() => FloorplanSignalElement)
  @Expose({ name: "signalElements" })
  private readonly _signalElements: FloorplanSignalElement[] = [];

  @ValidateNested()
  @IsArrayOfInstancesOf(FloorplanPlaceholder)
  @Type(() => FloorplanPlaceholder)
  @Expose({ name: "signalElementPlaceholders" })
  @Transform((params) => params.value || [], { toClassOnly: true })
  private readonly _signalElementPlaceholders: FloorplanPlaceholder[] = [];

  @ValidateNested()
  @IsArrayOfInstancesOf(FloorplanPlasticSign)
  @Type(() => FloorplanPlasticSign)
  @Expose({ name: "plasticSigns" })
  private readonly _plasticSigns: FloorplanPlasticSign[] = [];

  @ValidateNested()
  @IsArrayOfInstancesOf(FloorplanPlaceholder)
  @Type(() => FloorplanPlaceholder)
  @Expose({ name: "plasticSignPlaceholders" })
  @Transform((params) => params.value || [], { toClassOnly: true })
  private readonly _plasticSignPlaceholders: FloorplanPlaceholder[] = [];

  @ValidateNested()
  @IsArrayOfInstancesOf(FloorplanText)
  @Type(() => FloorplanText)
  @Expose({ name: "floorplanTexts" })
  private readonly _floorplanTexts: FloorplanText[] = [];

  @ValidateNested()
  @IsArrayOfInstancesOf(ExZone)
  @Type(() => ExZone)
  @Expose({ name: "exZones" })
  private readonly _exZones: ExZone[] = [];

  @ValidateNested()
  @IsArrayOfInstancesOf(DangerArea)
  @Type(() => DangerArea)
  @Expose({ name: "dangerAreas" })
  private readonly _dangerAreas: DangerArea[] = [];

  @ValidateNested()
  @IsArrayOfInstancesOf(MeasurementLine)
  @Type(() => MeasurementLine)
  @Expose({ name: "measurementLines" })
  private readonly _measurementLines: MeasurementLine[] = [];

  @ValidateNested()
  @IsArrayOfInstancesOf(AirPath)
  @Type(() => AirPath)
  @Expose({ name: "airPaths" })
  private readonly _airPaths: AirPath[] = [];

  @ValidateNested()
  @IsArrayOfInstancesOf(Pipeline)
  @Type(() => Pipeline)
  @Expose({ name: "pipelines" })
  private readonly _pipelines: Pipeline[] = [];

  @ValidateNested()
  @IsInstanceOf(FloorplanState)
  @Type(() => FloorplanState)
  @Expose({ name: "floorplanState" })
  private _floorplanState: FloorplanState = new FloorplanState();

  @IsUUID()
  public readonly id: string;

  @IsDataURI()
  @IsOptional()
  public fileUrl?: string;

  @IsBoolean()
  public selected: boolean = false;

  private constructor(project: Project, id: string, name: string, fileUrl?: string, selected: boolean = false) {
    this._project = project;
    this._name = name;
    this.id = id;
    this.fileUrl = fileUrl;
    this.selected = selected;
  }

  init(project: Project) {
    this._project = project;
    this._images.forEach((image) => image.init(project, this));
    this._alarmDevices.forEach((alarmDevice) => alarmDevice.init(project, this));
    this._alarmDevicePlaceholders.forEach((placeholder) => placeholder.init(project, this));
    this._transmitters.forEach((transmitter) => transmitter.init(project, this));
    this._transmitterPlaceholders.forEach((placeholder) => placeholder.init(project, this));
    this._gasWarningCenters.forEach((gasWarningCenter) => gasWarningCenter.init(project, this));
    this._gasWarningCenterPlaceholders.forEach((placeholder) => placeholder.init(project, this));
    this._signalElements.forEach((signalElement) => signalElement.init(project, this));
    this._signalElementPlaceholders.forEach((placeholder) => placeholder.init(project, this));
    this._plasticSigns.forEach((plasticSign) => plasticSign.init(project, this));
    this._plasticSignPlaceholders.forEach((placeholder) => placeholder.init(project, this));
    this._floorplanTexts.forEach((floorplanText) => floorplanText.init(project, this));
    this._exZones.forEach((exZone) => exZone.init(project, this));
    this._dangerAreas.forEach((dangerArea) => dangerArea.init(project, this));
    this._measurementLines.forEach((measurementLine) => measurementLine.init(project, this));
    this._airPaths.forEach((airPath) => airPath.init(project, this));
    this._pipelines.forEach((pipeline) => pipeline.init(project, this));
    this._floorplanState.init(this);
  }

  static create(project: Project, name: string, fileUrl?: string): Floorplan {
    return new Floorplan(project, uuidv4(), name, fileUrl, false);
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
    this.project.publishUpdate(ProjectEventType.FLOORPLAN_UPDATED, this);
  }

  get project(): Project {
    if (!this._project) {
      throw Error("Project must be set before accessing the floorplan");
    }
    return this._project;
  }

  get floorplanState(): FloorplanState {
    return this._floorplanState;
  }

  get images(): FloorplanImage[] {
    return this._images;
  }

  get alarmDevices(): FloorplanAlarmDevice[] {
    return this._alarmDevices;
  }

  get alarmDevicePlaceholders(): FloorplanPlaceholder[] {
    return this._alarmDevicePlaceholders;
  }

  get transmitters(): FloorplanTransmitter[] {
    return this._transmitters;
  }

  get transmitterPlaceholders(): FloorplanTransmitterPlaceholder[] {
    return this._transmitterPlaceholders;
  }

  get signalElements(): FloorplanSignalElement[] {
    return this._signalElements;
  }

  get signalElementPlaceholders(): FloorplanPlaceholder[] {
    return this._signalElementPlaceholders;
  }

  get plasticSigns(): FloorplanPlasticSign[] {
    return this._plasticSigns;
  }

  get plasticSignPlaceholders(): FloorplanPlaceholder[] {
    return this._plasticSignPlaceholders;
  }

  get gasWarningCenters(): FloorplanGasWarningCenter[] {
    return this._gasWarningCenters;
  }

  get gasWarningCenterPlaceholders(): FloorplanPlaceholder[] {
    return this._gasWarningCenterPlaceholders;
  }

  get floorplanTexts(): FloorplanText[] {
    return this._floorplanTexts;
  }

  get exZones(): ExZone[] {
    return this._exZones;
  }

  get dangerAreas(): DangerArea[] {
    return this._dangerAreas;
  }

  get measurementLines(): MeasurementLine[] {
    return this._measurementLines;
  }

  get airPaths(): AirPath[] {
    return this._airPaths;
  }

  get pipelines(): Pipeline[] {
    return this._pipelines;
  }

  addImage(imageId: string, x: number, y: number, size: number = 60): FloorplanImage {
    if (!this._project) {
      throw Error("Project must be set to add image");
    }
    const image = this._project.getImageById(imageId);
    if (!image) {
      throw Error(`Image with id '${imageId}' is not contained in project with id '${this._project.id}'`);
    }
    const img = FloorplanImage.create(this, image, x, y, size);
    this._images.push(img);
    this.publishUpdate(FloorplanEventType.IMAGE_ADDED, img);
    this.floorplanState.refreshImagesLock();
    this.project.setImagePositionIds();
    return img;
  }

  addAlarmDevice(alarmDevice: AlarmDeviceConfiguration, x: number, y: number, size: number): FloorplanAlarmDevice {
    if (!this._project) {
      throw Error("Project must be set to add alarm device");
    }
    const floorplanAlarmDevice = FloorplanAlarmDevice.create(this, alarmDevice, x, y, size);
    this._alarmDevices.push(floorplanAlarmDevice);
    this.publishUpdate(FloorplanEventType.ALARM_DEVICE_ADDED, floorplanAlarmDevice);
    this.floorplanState.refreshAlarmDevicesLock();
    this.project.setPositionNumbers();
    return floorplanAlarmDevice;
  }

  addAlarmDevicePlaceholder(x: number, y: number, size: number): FloorplanPlaceholder {
    if (!this._project) {
      throw Error("Project must be set to add placeholder");
    }
    const floorplanPlaceholder = FloorplanPlaceholder.create(this, x, y, size, "alarmDevice");
    this._alarmDevicePlaceholders.push(floorplanPlaceholder);
    this.publishUpdate(FloorplanEventType.ALARM_DEVICE_PLACEHOLDER_ADDED, floorplanPlaceholder);
    this.floorplanState.refreshAlarmDevicesLock();
    this.project.setAlarmDevicePlaceholderPositionIds();
    return floorplanPlaceholder;
  }

  addTransmitter(
    transmitter: TransmitterConfiguration,
    x: number,
    y: number,
    size: number,
    monitoringAreaRadius: number,
  ): FloorplanTransmitter {
    if (!this._project) {
      throw Error("Project must be set to add transmitter");
    }
    const floorplanTransmitter = FloorplanTransmitter.create(this, transmitter, x, y, size, monitoringAreaRadius);
    this._transmitters.push(floorplanTransmitter);
    this.publishUpdate(FloorplanEventType.TRANSMITTER_ADDED, floorplanTransmitter);
    this.floorplanState.refreshTransmittersLock();
    this.project.setPositionNumbers();
    return floorplanTransmitter;
  }

  addTransmitterPlaceholder(x: number, y: number, size: number, monitoringAreaRadius: number): FloorplanTransmitterPlaceholder {
    if (!this._project) {
      throw Error("Project must be set to add placeholder");
    }
    const floorplanPlaceholder = FloorplanTransmitterPlaceholder.create(this, x, y, size, monitoringAreaRadius);
    this._transmitterPlaceholders.push(floorplanPlaceholder);
    this.publishUpdate(FloorplanEventType.TRANSMITTER_PLACEHOLDER_ADDED, floorplanPlaceholder);
    this.floorplanState.refreshTransmittersLock();
    this.project.setTransmitterPlaceholderPositionIds();
    return floorplanPlaceholder;
  }

  addGasWarningCenter(
    gasWarningCenter: GasWarningCenterConfiguration,
    x: number,
    y: number,
    size: number,
  ): FloorplanGasWarningCenter {
    if (!this._project) {
      throw Error("Project must be set to add gas warning center");
    }
    const floorplanGasWarningCenter = FloorplanGasWarningCenter.create(this, gasWarningCenter, x, y, size);
    this._gasWarningCenters.push(floorplanGasWarningCenter);
    this.publishUpdate(FloorplanEventType.GAS_WARNING_CENTER_ADDED, floorplanGasWarningCenter);
    this.floorplanState.refreshGasWarningCentersLock();
    this.project.setPositionNumbers();
    return floorplanGasWarningCenter;
  }

  addGasWarningCenterPlaceholder(x: number, y: number, size: number): FloorplanPlaceholder {
    if (!this._project) {
      throw Error("Project must be set to add placeholder");
    }
    const floorplanPlaceholder = FloorplanPlaceholder.create(this, x, y, size, "gasWarningCenter");
    this._gasWarningCenterPlaceholders.push(floorplanPlaceholder);
    this.publishUpdate(FloorplanEventType.GAS_WARNING_CENTER_PLACEHOLDER_ADDED, floorplanPlaceholder);
    this.floorplanState.refreshGasWarningCentersLock();
    this.project.setGasWarningCenterPlaceholderPositionIds();
    return floorplanPlaceholder;
  }

  addSignalElement(signalElement: SignalElementConfiguration, x: number, y: number, size: number): FloorplanSignalElement {
    if (!this._project) {
      throw Error("Project must be set to add signal element");
    }
    const floorplanSignalElement = FloorplanSignalElement.create(this, signalElement, x, y, size);
    this._signalElements.push(floorplanSignalElement);
    this.publishUpdate(FloorplanEventType.SIGNAL_ELEMENT_ADDED, floorplanSignalElement);
    this.floorplanState.refreshSignalElementsLock();
    this.project.setPositionNumbers();
    return floorplanSignalElement;
  }

  addSignalElementPlaceholder(x: number, y: number, size: number): FloorplanPlaceholder {
    if (!this._project) {
      throw Error("Project must be set to add placeholder");
    }
    const floorplanPlaceholder = FloorplanPlaceholder.create(this, x, y, size, "signalElement");
    this._signalElementPlaceholders.push(floorplanPlaceholder);
    this.publishUpdate(FloorplanEventType.SIGNAL_ELEMENT_PLACEHOLDER_ADDED, floorplanPlaceholder);
    this.floorplanState.refreshSignalElementsLock();
    this.project.setSignalElementPlaceholderPositionIds();
    return floorplanPlaceholder;
  }

  addPlasticSign(plasticSign: PlasticSignConfiguration, x: number, y: number, size: number): FloorplanPlasticSign {
    if (!this._project) {
      throw Error("Project must be set to add plastic sign");
    }
    const floorplanPlasticSign = FloorplanPlasticSign.create(this, plasticSign, x, y, size);
    this._plasticSigns.push(floorplanPlasticSign);
    this.publishUpdate(FloorplanEventType.PLASTIC_SIGN_ADDED, floorplanPlasticSign);
    this.floorplanState.refreshPlasticSignsLock();
    this.project.setPositionNumbers();
    return floorplanPlasticSign;
  }

  addPlasticSignPlaceholder(x: number, y: number, size: number): FloorplanPlaceholder {
    if (!this._project) {
      throw Error("Project must be set to add placeholder");
    }
    const floorplanPlaceholder = FloorplanPlaceholder.create(this, x, y, size, "plasticSign");
    this._plasticSignPlaceholders.push(floorplanPlaceholder);
    this.publishUpdate(FloorplanEventType.PLASTIC_SIGN_PLACEHOLDER_ADDED, floorplanPlaceholder);
    this.floorplanState.refreshPlasticSignsLock();
    this.project.setPlasticSignPlaceholderPositionIds();
    return floorplanPlaceholder;
  }

  addFloorplanText(x: number, y: number, size: number): FloorplanText {
    const floorplanText = FloorplanText.create(this, x, y, size);
    this._floorplanTexts.push(floorplanText);
    this.publishUpdate(FloorplanEventType.TEXT_ADDED, floorplanText);
    this.floorplanState.refreshFloorplanTextsLock();
    this.project.setFloorplanTextPositionIds();
    return floorplanText;
  }

  addExZone(
    exZoneType: ExZoneType,
    x: number,
    y: number,
    width: number = 100,
    height: number = 150,
    rotation: number = 0,
  ): ExZone {
    const exZone = ExZone.create(this, exZoneType, x, y, width, height, rotation);
    this._exZones.push(exZone);
    this.publishUpdate(FloorplanEventType.EX_ZONE_ADDED, exZone);
    this.floorplanState.refreshExZonesLock();
    this.project.setExZonePositionIds();
    return exZone;
  }

  addDangerArea(x: number, y: number, width: number = 100, height: number = 150, rotation: number = 0): DangerArea {
    if (!this._project) {
      throw Error("Project must be set to add placeholder");
    }
    const dangerArea = DangerArea.create(this, x, y, width, height, rotation);
    this._dangerAreas.push(dangerArea);
    this.publishUpdate(FloorplanEventType.DANGER_AREA_ADDED, dangerArea);
    this.floorplanState.refreshDangerAreasLock();
    this.project.setDangerAreaPositionIds();
    return dangerArea;
  }

  addMeasurementLine(
    x: number,
    y: number,
    text: string,
    width: number = 150,
    height: number = 50,
    rotation: number = 0,
  ): MeasurementLine {
    if (!this._project) {
      throw Error("Project must be set to add placeholder");
    }
    const measurementLine = MeasurementLine.create(this, x, y, width, height, rotation, text);
    this._measurementLines.push(measurementLine);
    this.publishUpdate(FloorplanEventType.MEASUREMENT_LINE_ADDED, measurementLine);
    this.floorplanState.refreshMeasurementLinesLock();
    this.project.setMeasurementLinePositionIds();
    return measurementLine;
  }

  addAirPath(
    direction: AirPathDirection,
    x: number,
    y: number,
    length: number = 150,
    strokeWidth: number = 20,
    rotation: number = 0,
  ): AirPath {
    const airPath = AirPath.create(this, direction, x, y, length, strokeWidth, rotation);
    this._airPaths.push(airPath);
    this.publishUpdate(FloorplanEventType.AIR_PATH_ADDED, airPath);
    this.floorplanState.refreshAirPathsLock();
    this.project.setAirPathPositionIds();
    return airPath;
  }

  addPipeline(
    x: number,
    y: number,
    width: number = 150,
    height: number = 20,
    diameter?: string,
    medium?: string,
    temperature?: string,
    material?: string,
    rotation: number = 0,
  ): Pipeline {
    const pipeline = Pipeline.create(this, x, y, width, height, diameter, medium, temperature, material, rotation);
    this._pipelines.push(pipeline);
    this.publishUpdate(FloorplanEventType.PIPELINE_ADDED, pipeline);
    this.floorplanState.refreshPipelinesLock();
    this.project.setPipelinePositionIds();
    return pipeline;
  }

  countPlacedAlarmDevices(alarmDevice: AlarmDeviceConfiguration): number {
    return this._alarmDevices.filter((floorplanAlarmDevice) => floorplanAlarmDevice.config.id === alarmDevice?.id).length;
  }

  countPlacedTransmitters(transmitter: TransmitterConfiguration): number {
    return this._transmitters.filter((floorplanTransmitter) => floorplanTransmitter.config.id === transmitter?.id).length;
  }

  countPlacedGasWarningCenters(gasWarningCenter: GasWarningCenterConfiguration): number {
    return this._gasWarningCenters.filter(
      (floorplanGasWarningCenter) => floorplanGasWarningCenter.config.id === gasWarningCenter?.id,
    ).length;
  }

  countPlacedSignalElements(signalElement: SignalElementConfiguration): number {
    return this._signalElements.filter((floorplanSignalElement) => floorplanSignalElement.config.id == signalElement?.id).length;
  }

  countPlacedPlasticSigns(plasticSign: PlasticSignConfiguration): number {
    return this._plasticSigns.filter((floorplanPlasticSign) => floorplanPlasticSign.config.id == plasticSign?.id).length;
  }

  deleteImagesByProjectImage(projectImage: ProjectImage) {
    this._images
      .filter((image) => image.projectImage.id === projectImage.id)
      .forEach((floorplanImage) => this.deleteImage(floorplanImage));
  }

  deleteImage(floorplanImage: FloorplanImage) {
    this.deleteFromArray(this._images, floorplanImage);
    this.publishUpdate(FloorplanEventType.IMAGE_DELETED, floorplanImage);
    this.project.setImagePositionIds();
  }

  deleteTransmitter(transmitter: FloorplanTransmitter) {
    this.deleteFromArray(this._transmitters, transmitter);
    this.publishUpdate(FloorplanEventType.TRANSMITTER_DELETED, transmitter);
    this.project.setAllPositionIds();
  }

  deleteTransmitterPlaceholder(placeholder: FloorplanTransmitterPlaceholder) {
    this.deleteFromArray(this._transmitterPlaceholders, placeholder);
    this.publishUpdate(FloorplanEventType.TRANSMITTER_PLACEHOLDER_DELETED, placeholder);
    this.project.setAllPositionIds();
  }

  deleteGasWarningCenter(gasWarningCenter: FloorplanGasWarningCenter) {
    this.deleteFromArray(this._gasWarningCenters, gasWarningCenter);
    this.publishUpdate(FloorplanEventType.GAS_WARNING_CENTER_DELETED, gasWarningCenter);
    this.project.setAllPositionIds();
  }

  deleteGasWarningCenterPlaceholder(placeholder: FloorplanPlaceholder) {
    this.deleteFromArray(this._gasWarningCenterPlaceholders, placeholder);
    this.publishUpdate(FloorplanEventType.GAS_WARNING_CENTER_PLACEHOLDER_DELETED, placeholder);
    this.project.setAllPositionIds();
  }

  deleteAlarmDevice(alarmDevice: FloorplanAlarmDevice) {
    this.deleteFromArray(this._alarmDevices, alarmDevice);
    this.publishUpdate(FloorplanEventType.ALARM_DEVICE_DELETED, alarmDevice);
    this.project.setAllPositionIds();
  }

  deleteAlarmDevicePlaceholder(placeholder: FloorplanPlaceholder) {
    this.deleteFromArray(this._alarmDevicePlaceholders, placeholder);
    this.publishUpdate(FloorplanEventType.ALARM_DEVICE_PLACEHOLDER_DELETED, placeholder);
    this.project.setAllPositionIds();
  }

  deleteSignalElement(signalElement: FloorplanSignalElement) {
    this.deleteFromArray(this._signalElements, signalElement);
    this.publishUpdate(FloorplanEventType.SIGNAL_ELEMENT_DELETED, signalElement);
    this.project.setAllPositionIds();
  }

  deleteSignalElementPlaceholder(placeholder: FloorplanPlaceholder) {
    this.deleteFromArray(this._signalElementPlaceholders, placeholder);
    this.publishUpdate(FloorplanEventType.SIGNAL_ELEMENT_PLACEHOLDER_DELETED, placeholder);
    this.project.setAllPositionIds();
  }

  deletePlasticSign(plasticSign: FloorplanPlasticSign) {
    this.deleteFromArray(this._plasticSigns, plasticSign);
    this.publishUpdate(FloorplanEventType.PLASTIC_SIGN_DELETED, plasticSign);
    this.project.setAllPositionIds();
  }

  deletePlasticSignPlaceholder(placeholder: FloorplanPlaceholder) {
    this.deleteFromArray(this._plasticSignPlaceholders, placeholder);
    this.publishUpdate(FloorplanEventType.PLASTIC_SIGN_PLACEHOLDER_DELETED, placeholder);
    this.project.setAllPositionIds();
  }

  deleteFloorplanText(floorplanText: FloorplanText) {
    this.deleteFromArray(this._floorplanTexts, floorplanText);
    this.publishUpdate(FloorplanEventType.TEXT_DELETED, floorplanText);
    this.project.setFloorplanTextPositionIds();
  }

  deleteExZone(exZone: ExZone) {
    this.deleteFromArray(this._exZones, exZone);
    this.publishUpdate(FloorplanEventType.EX_ZONE_DELETED, exZone);
    this.project.setExZonePositionIds();
  }

  deleteDangerArea(dangerArea: DangerArea) {
    this.deleteFromArray(this._dangerAreas, dangerArea);
    this.publishUpdate(FloorplanEventType.DANGER_AREA_DELETED, dangerArea);
    this.project.setDangerAreaPositionIds();
  }

  deleteMeasurementLine(measurementLine: MeasurementLine) {
    this.deleteFromArray(this._measurementLines, measurementLine);
    this.publishUpdate(FloorplanEventType.MEASUREMENT_LINE_DELETED, measurementLine);
    this.project.setMeasurementLinePositionIds();
  }

  deleteAirPath(airPath: AirPath) {
    this.deleteFromArray(this._airPaths, airPath);
    this.publishUpdate(FloorplanEventType.AIR_PATH_DELETED, airPath);
    this.project.setAirPathPositionIds();
  }

  deletePipeline(pipeline: Pipeline) {
    this.deleteFromArray(this._pipelines, pipeline);
    this.publishUpdate(FloorplanEventType.PIPELINE_DELETED, pipeline);
    this.project.setPipelinePositionIds();
  }

  deleteAlarmDevicesByConfig(config: AlarmDeviceConfiguration) {
    this.deleteFloorplanItemsByConfig(config, this._alarmDevices);
  }

  deleteTransmittersByConfig(config: TransmitterConfiguration) {
    this.deleteFloorplanItemsByConfig(config, this._transmitters);
  }

  deleteGasWarningCentersByConfig(config: GasWarningCenterConfiguration) {
    this.deleteFloorplanItemsByConfig(config, this._gasWarningCenters);
  }

  deleteSignalElementsByConfig(config: SignalElementConfiguration) {
    this.deleteFloorplanItemsByConfig(config, this._signalElements);
  }

  deletePlasticSignsByConfig(config: PlasticSignConfiguration) {
    this.deleteFloorplanItemsByConfig(config, this._plasticSigns);
  }

  updateGasWarningCentersPositionIds(config: GasWarningCenterConfiguration) {
    this.updatePositionIds(config, this._gasWarningCenters);
  }

  updateTransmittersPositionIds(config: TransmitterConfiguration) {
    this.updatePositionIds(config, this._transmitters);
  }

  updateAlarmDevicesPositionIds(config: AlarmDeviceConfiguration) {
    this.updatePositionIds(config, this._alarmDevices);
  }

  updateSignalElementsPositionIds(config: SignalElementConfiguration) {
    this.updatePositionIds(config, this._signalElements);
  }

  updatePlasticSignsPositionIds(config: PlasticSignConfiguration) {
    this.updatePositionIds(config, this._plasticSigns);
  }

  publishUpdate(type: FloorplanEventType, subject: any) {
    if (this._project) {
      this._project.publishFloorplanUpdate(this, type, subject);
    }
  }

  private deleteFromArray<T>(array: T[], object: T) {
    const index = array.indexOf(object);
    if (index > -1) {
      array.splice(index, 1);
    }
  }

  private deleteFloorplanItemsByConfig<T extends ProductConfiguration>(
    productConfiguration: T,
    floorplanProductItems: FloorplanProductItem<T>[],
  ) {
    floorplanProductItems
      .filter((floorplanProductItem) => floorplanProductItem.config.id === productConfiguration.id)
      .forEach((floorplanProductItem) => floorplanProductItem.delete());
  }

  private updatePositionIds<T extends ProductConfiguration>(config: T, floorplanItems: FloorplanProductItem<T>[]) {
    let indexCount: number = 0;
    floorplanItems.forEach((floorplanItem: FloorplanProductItem<T>) => {
      if (floorplanItem.config === config) {
        floorplanItem.positionId = `${config.positionNumber}.${++indexCount}`;
      }
    });
  }
}
