<odx-content-box class="flex-column h-100 scroll" odxLayout="flex">
  <odx-content-box-header>
    <h5
      class="odx-title-5"
      i18n="@@transmitterConfiguration.selectGas.header"
      data-testid="transmitterConfiguration.selectGas.header"
    >
      Gasart auswählen
    </h5>
  </odx-content-box-header>
  <odx-form-field label="Gasart" i18n-label="@@transmitterConfiguration.filter.label" id="search-field">
    <input
      odxFormFieldControl
      type="search"
      placeholder="Name, CAS-Nummer, chem.Summen-Formel"
      i18n-placeholder="@@transmitterConfiguration.filter.placeholder"
      class="input"
      #searchField
    />
  </odx-form-field>
  <div class="fixed-head">
    <table class="navinta-table table">
      <thead>
        <td i18n="@@transmitterConfiguration.selectGas.tableHeader.column1">Gas Name</td>
        <td i18n="@@transmitterConfiguration.selectGas.tableHeader.column2">CAS Nummer</td>
        <td i18n="@@transmitterConfiguration.selectGas.tableHeader.column3">chem.Summen-Formel</td>
      </thead>
      <tbody>
        <tr *ngFor="let gas of filteredGases$ | async" (click)="select(gas)" [ngClass]="{ selected: selectedGas?.id === gas.id }">
          <td>{{ gas | localizeGasName }}</td>
          <td>{{ gas.id }}</td>
          <td>{{ gas.definition.formula }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</odx-content-box>
