import { DefaultRow } from "@pdf/helpers/default-row/default-row";
import { jsPDF } from "jspdf";

export class PipelineRow extends DefaultRow {
  readonly number: string[];
  readonly name: string[];
  readonly diameter: string[];
  readonly medium: string[];
  readonly temperature: string[];
  readonly material: string[];
  readonly notes: string[];

  public constructor(
    pdf: jsPDF,
    number: string[],
    name: string[],
    diameter: string[],
    medium: string[],
    temperature: string[],
    material: string[],
    notes: string[],
  ) {
    super();
    this.number = number;
    this.name = name;
    this.diameter = diameter;
    this.medium = medium;
    this.temperature = temperature;
    this.material = material;
    this.notes = notes;
    this._textHeight = this.calculateRowTextHeight(pdf);
    this._rowHeight = this.calculateRowHeight(this.textHeight);
  }

  public calculateRowTextHeight(pdf: jsPDF) {
    return Math.max(
      ...[
        this.calculateCellTextHeight(pdf, this.number),
        this.calculateCellTextHeight(pdf, this.name),
        this.calculateCellTextHeight(pdf, this.diameter),
        this.calculateCellTextHeight(pdf, this.medium),
        this.calculateCellTextHeight(pdf, this.temperature),
        this.calculateCellTextHeight(pdf, this.material),
        this.calculateCellTextHeight(pdf, this.notes),
      ],
    );
  }
}
