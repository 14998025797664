<odx-content-box
  class="content-box flex-column"
  [ngClass]="{
    selected: selected,
    selectable: selectionMode !== 'none',
  }"
  odxLayout="flex gap-none"
  *ngIf="product"
  (click)="onCardClicked()"
>
  <odx-area-header class="header">
    <h6
      class="odx-title-6 card-title"
      (click)="onTooltipClicked($event)"
      [odxTooltip]="product | localizeProductName"
      [odxTooltipVisible]="tooltipVisible"
      [odxTooltipDisabled]="isTooltipDisabled()"
      odxTooltipSize="large"
    >
      {{ product | localizeProductName | displayName }}
    </h6>
  </odx-area-header>
  <div class="h-100 content">
    <div class="image-wrapper">
      <app-standard-image [imageUrl]="imageUrl" [fallbackImageUrl]="fallbackImageUrl"></app-standard-image>
    </div>
    <ng-content></ng-content>
  </div>
  <odx-content-box-footer class="footer w-100">
    <div odxLayout="flex" class="space-between">
      <!-- do not propagate radio button click to prevent from multiple click events when card is clicked -->
      <odx-radio-button
        (click)="$event.stopPropagation()"
        [value]="product"
        *ngIf="selectionMode === 'single'"
        i18n="@@global.select"
        >Auswählen</odx-radio-button
      >

      <!-- do not propagate checkbox click to prevent from multiple click events when card is clicked -->
      <odx-checkbox
        (click)="$event.stopPropagation()"
        (checkedChange)="onCheckedChanged()"
        *ngIf="selectionMode === 'multi'"
        [checked]="selected"
        #checkbox
        i18n="@@global.select"
        >Auswählen</odx-checkbox
      >
      <div *ngIf="selectionMode === 'none'"></div>
      <p>{{ showCosts ? (product.price | currency: "EUR") : "" }}</p>
    </div>
  </odx-content-box-footer>
</odx-content-box>
