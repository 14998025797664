import { Floorplan } from "@domain/project/floorplan/floorplan";
import { FloorplanEventType } from "@domain/project/floorplan/floorplan-event";
import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { PlaceholderProduct } from "@domain/project/floorplan/placeholder-product";
import { ProjectEventType } from "@domain/project/project-event";
import { IsArrayOfInstancesOf } from "@utils/class-validator/class-validator-constraints";
import { Expose, Transform, Type } from "class-transformer";
import { IsNumber, IsPositive, ValidateNested } from "class-validator";
import { v4 as uuidv4 } from "uuid";

export class FloorplanTransmitterPlaceholder extends FloorplanItem {
  @IsNumber()
  @IsPositive({ message: "The value must be higher than 0" })
  @Expose({ name: "monitoringAreaRadius" })
  private _monitoringAreaRadius: number;

  @IsNumber()
  @Expose({ name: "size" })
  private _size: number;

  @ValidateNested({ each: true })
  @IsArrayOfInstancesOf(PlaceholderProduct)
  @Type(() => PlaceholderProduct)
  @Expose({ name: "products" })
  @Transform((params) => params.value || [], { toClassOnly: true })
  private _products: PlaceholderProduct[];

  constructor(
    floorplan: Floorplan,
    id: string,
    x: number,
    y: number,
    size: number,
    monitoringAreaRadius: number,
    notes?: string,
  ) {
    super(floorplan, id, x, y, notes);
    this._monitoringAreaRadius = monitoringAreaRadius;
    this._size = size;
    this._products = [];
  }

  override duplicate(x: number, y: number): FloorplanItem {
    return this._floorplan.addTransmitterPlaceholder(x, y, this._size, this._monitoringAreaRadius);
  }

  override delete() {
    this._floorplan.deleteTransmitterPlaceholder(this);
  }

  override get relatedItems(): FloorplanItem[] {
    return this._floorplan.transmitterPlaceholders;
  }

  get monitoringAreaRadius(): number {
    return this._monitoringAreaRadius;
  }

  get size(): number {
    return this._size;
  }

  get products(): PlaceholderProduct[] {
    return this._products;
  }

  set products(products: PlaceholderProduct[]) {
    this._products = products;
    this._floorplan.publishUpdate(FloorplanEventType.ITEM_UPDATED, this);
    this._floorplan.project.publishUpdate(ProjectEventType.PLACEHOLDER_PRODUCTS_UPDATED, this);
  }

  set monitoringAreaRadius(value: number) {
    this._monitoringAreaRadius = value;
    this._floorplan.publishUpdate(FloorplanEventType.ITEM_UPDATED, this);
  }

  updateSize(value: number, x: number, y: number) {
    this._size = value;
    this._floorplan.publishUpdate(FloorplanEventType.ITEM_SIZE_CHANGED, this);
    this.updatePosition(x, y);
  }

  protected override refreshFloorplanLockState() {
    this._floorplan.floorplanState.refreshTransmittersLock();
  }

  static create(
    floorplan: Floorplan,
    x: number,
    y: number,
    size: number,
    monitoringAreaRadius: number,
  ): FloorplanTransmitterPlaceholder {
    return new FloorplanTransmitterPlaceholder(floorplan, uuidv4(), x, y, size, monitoringAreaRadius);
  }
}
