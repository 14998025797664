<odx-content-box class="h-100">
  <odx-content-box-header>
    <h5 class="odx-title-5" i18n="@@signalElementConfiguration.header">Leuchttransparent auswählen</h5>
  </odx-content-box-header>

  <app-filter-product (search)="search($event)"></app-filter-product>

  <form [formGroup]="signalElementForm" class="h-100 overflow-hidden" *ngIf="filteredSignalElements$ | async; let signalElements">
    <odx-radio-group formControlName="selectedSignalElement" required="true">
      <div odxLayout="grid 3@desktop 6@tablet 12@phone" class="w-100">
        <app-product-card
          class="overflow-hidden"
          *ngFor="let signalElement of signalElements"
          [product]="signalElement"
          [showCosts]="project.showCostFlag"
          [isSelected]="selectedSignalElement?.id === signalElement.id"
          (click)="select(signalElement)"
          (select$)="select(signalElement)"
          selectionMode="single"
        >
          <div *ngIf="signalElement.id" class="feature">
            <p class="p-label odx-text--small" i18n="@@global.partNumber">Sachnummer</p>
            <p class="odx-text--small">{{ signalElement.id }}</p>
          </div>
        </app-product-card>
      </div>
    </odx-radio-group>
  </form>
</odx-content-box>
