import { Injectable } from "@angular/core";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { TableOfContents } from "@pdf/export-services/table-of-contents/table-of-contents";
import { Container } from "@pdf/helpers/container/container";
import { ContainerService } from "@pdf/helpers/container/container-service";
import { TableService } from "@pdf/helpers/default-table/table-service";
import { Headlines, ImagePaths } from "@pdf/pdf-properties";
import { jsPDF } from "jspdf";
import { ProductNotes } from "./product-notes";
import { ProductNotesTable } from "./product-notes-table";

@Injectable({
  providedIn: "root",
})
export class ProductNotesPdfService {
  private tableService!: TableService;
  private headlines = new Headlines();

  constructor(private containerService: ContainerService) {}

  public generatePages(pdf: jsPDF, floorplan: Floorplan, container: Container, tableOfContents: TableOfContents) {
    const productNotes = new ProductNotes(floorplan);
    this.tableService = new TableService();

    if (productNotes.isDataAvailable) {
      this.containerService.addOnNewPage(pdf, container, `${this.headlines.PRODUCT_NOTES} | ${floorplan.name}`, tableOfContents);

      if (productNotes.gasWarningCenterNotes.length) {
        this.tableService.tableToPdf(
          pdf,
          new ProductNotesTable(
            pdf,
            productNotes.gasWarningCenterNotes,
            ImagePaths.GASWARNINGCENTER,
            this.headlines.GASWARNINGCENTERS,
          ),
          container,
          tableOfContents,
          `${this.headlines.PRODUCT_NOTES} | ${floorplan.name}`,
        );
      }
      if (productNotes.gasWarningCenterPlaceholderNotes.length) {
        this.tableService.tableToPdf(
          pdf,
          new ProductNotesTable(
            pdf,
            productNotes.gasWarningCenterPlaceholderNotes,
            ImagePaths.GASWARNINGCENTER_PLACEHOLDER,
            this.headlines.GASWARNINGCENTERS_PLACEHOLDERS,
          ),
          container,
          tableOfContents,
          `${this.headlines.PRODUCT_NOTES} | ${floorplan.name}`,
        );
      }
      if (productNotes.transmitterNotes.length) {
        this.tableService.tableToPdf(
          pdf,
          new ProductNotesTable(
            pdf,
            productNotes.transmitterNotes,
            ImagePaths.TRANSMITTER_PLACEHOLDER,
            this.headlines.TRANSMITTERS,
          ),
          container,
          tableOfContents,
          `${this.headlines.PRODUCT_NOTES} | ${floorplan.name}`,
        );
      }
      if (productNotes.transmitterPlaceholderNotes.length) {
        this.tableService.tableToPdf(
          pdf,
          new ProductNotesTable(
            pdf,
            productNotes.transmitterPlaceholderNotes,
            ImagePaths.TRANSMITTER_PLACEHOLDER,
            this.headlines.TRANSMITTERS_PLACEHOLDERS,
          ),
          container,
          tableOfContents,
          `${this.headlines.PRODUCT_NOTES} | ${floorplan.name}`,
        );
      }
      if (productNotes.alarmDeviceNotes.length) {
        this.tableService.tableToPdf(
          pdf,
          new ProductNotesTable(pdf, productNotes.alarmDeviceNotes, ImagePaths.ALARMDEVICE, this.headlines.ALARMDEVICES),
          container,
          tableOfContents,
          `${this.headlines.PRODUCT_NOTES} | ${floorplan.name}`,
        );
      }
      if (productNotes.alarmDevicePlaceholderNotes.length) {
        this.tableService.tableToPdf(
          pdf,
          new ProductNotesTable(
            pdf,
            productNotes.alarmDevicePlaceholderNotes,
            ImagePaths.ALARMDEVICE_PLACEHOLDER,
            this.headlines.ALARMDEVICES_PLACEHOLDERS,
          ),
          container,
          tableOfContents,
          `${this.headlines.PRODUCT_NOTES} | ${floorplan.name}`,
        );
      }
      if (productNotes.signalElementNotes.length) {
        this.tableService.tableToPdf(
          pdf,
          new ProductNotesTable(pdf, productNotes.signalElementNotes, ImagePaths.SIGNALELEMENT, this.headlines.SIGNALELEMENTS),
          container,
          tableOfContents,
          `${this.headlines.PRODUCT_NOTES} | ${floorplan.name}`,
        );
      }
      if (productNotes.signalElementPlaceholderNotes.length) {
        this.tableService.tableToPdf(
          pdf,
          new ProductNotesTable(
            pdf,
            productNotes.signalElementPlaceholderNotes,
            ImagePaths.SIGNALELEMENT_PLACEHOLDER,
            this.headlines.SIGNALELEMENTS_PLACEHOLDERS,
          ),
          container,
          tableOfContents,
          `${this.headlines.PRODUCT_NOTES} | ${floorplan.name}`,
        );
      }
      if (productNotes.plasticSignNotes.length) {
        this.tableService.tableToPdf(
          pdf,
          new ProductNotesTable(pdf, productNotes.plasticSignNotes, ImagePaths.PLASTICSIGN, this.headlines.PLASTICSIGNS),
          container,
          tableOfContents,
          `${this.headlines.PRODUCT_NOTES} | ${floorplan.name}`,
        );
      }
      if (productNotes.plasticSignPlaceholderNotes.length) {
        this.tableService.tableToPdf(
          pdf,
          new ProductNotesTable(
            pdf,
            productNotes.plasticSignPlaceholderNotes,
            ImagePaths.PLASTICSIGN_PLACEHOLDER,
            this.headlines.PLASTICSIGNS_PLACEHOLDERS,
          ),
          container,
          tableOfContents,
          `${this.headlines.PRODUCT_NOTES} | ${floorplan.name}`,
        );
      }
    }
  }
}
