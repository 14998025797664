import { Component, Input, OnInit } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { LocalizeGasNamePipe } from "@domain/gas/gas";
import { GasService } from "@domain/gas/gas.service";
import { ProductImage } from "@domain/product/product-image";
import { ProductService } from "@domain/product/product.service";
import { TransmitterConfiguration } from "@domain/project/configurations/transmitter-configuration";
import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { FloorplanTransmitter } from "@domain/project/floorplan/floorplan-transmitter";
import { FloorplanItemAttachmentsComponent } from "@project/floorplanner/floorplan-additional-notes/floorplan-item-attachments/floorplan-item-attachments.component";
import { FloorplanItemDetailsComponent } from "@project/floorplanner/floorplan-additional-notes/floorplan-item-details/floorplan-item-details.component";
import { ProjectStateService } from "@project/project-state.service";
import { EMPTY, Observable, first, map } from "rxjs";

@Component({
  selector: " app-transmitter-notes",
  templateUrl: "./transmitter-notes.component.html",
  styleUrls: ["./transmitter-notes.component.scss"],
  standalone: true,
  imports: [UiKitModule, FloorplanItemDetailsComponent, FloorplanItemAttachmentsComponent],
  providers: [LocalizeGasNamePipe],
})
export class TransmitterNotesComponent implements OnInit {
  @Input({ required: true }) set floorplanItem(item: FloorplanItem) {
    this.transmitter = item as FloorplanTransmitter;
  }

  protected transmitter!: FloorplanTransmitter;
  protected imageUrl!: string;
  protected fallbackImageUrl!: string;
  protected transmitterName$!: Observable<string>;
  protected gasName$!: Observable<string>;
  protected sensorName$!: Observable<string>;
  protected isDiscontinued$!: Observable<boolean>;

  constructor(
    private productService: ProductService,
    private gasService: GasService,
    private projectStateService: ProjectStateService,
    private localizeGasName: LocalizeGasNamePipe,
  ) {}

  ngOnInit() {
    this.imageUrl = ProductImage.getSourceUrl(this.transmitter.config.productId);
    this.productService
      .getProductById(this.transmitter.config.mainProductId)
      .pipe(first())
      .subscribe((product) => {
        this.fallbackImageUrl = ProductImage.getPlaceholderSourceUrl(
          TransmitterConfiguration.getProductDataName(product?.localizedName["de-DE"] || ""),
        );
      });
    this.transmitterName$ = this.resolveTransmitterName();
    this.gasName$ = this.resolveGasName();
    this.sensorName$ = this.resolveSensorName();
    this.isDiscontinued$ = this.projectStateService.isConfigDiscontinued(this.transmitter.config.id);
  }

  private resolveTransmitterName(): Observable<string> {
    return this.productService
      .getProductNameById(this.transmitter.config.productId)
      .pipe(map((name) => name || this.transmitter.config.name));
  }

  private resolveGasName(): Observable<string> {
    return this.gasService
      .getGasById(this.transmitter.config.gasId)
      .pipe(map((gas) => (gas ? this.localizeGasName.transform(gas) : this.transmitter.config.gasId)));
  }

  private resolveSensorName(): Observable<string> {
    if (!this.transmitter.config.sensorId) {
      return EMPTY;
    }
    return this.productService
      .getProductNameById(this.transmitter.config.sensorId)
      .pipe(map((name) => name || this.transmitter.config.sensorId!));
  }
}
