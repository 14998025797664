<div class="odx-bar-parent h-100" odxLayout="flex">
  <app-configuration-wizard-back-button #backButton [project]="project" [floorplanGroup]="FloorplanMenuType.ALARM_DEVICE">
  </app-configuration-wizard-back-button>
  <odx-wizard #configurationWizard [vertical]="true" (activeStepChanged)="activeStep = $event" class="odx-alarm-device-wizard">
    <odx-wizard-step i18n="@@alarmDeviceConfiguration.wizard.step1"> Schutzart</odx-wizard-step>
    <odx-wizard-step class="{{ skipAttachments ? 'wizard-hide-line-after' : '' }}" i18n="@@alarmDeviceConfiguration.wizard.step2">
      Arbeitsmittel</odx-wizard-step
    >
    <odx-wizard-step [class.odx-wizard-step--visible]="!skipAttachments" i18n="@@alarmDeviceConfiguration.wizard.step3">
      Zubehör</odx-wizard-step
    >
  </odx-wizard>
  <odx-content-box class="h-100 w-100" [ngClass]="activeStep !== 0 ? 'hidden' : ''">
    <form [formGroup]="deviceClassSelectorForm" odxLayout="flex" class="w-100 flex-column">
      <app-device-class-selector
        [title]="titleSelectSafetyClass"
        [deviceClassType]="SafetyClass"
        [selectedSafetyClass]="selectedSafetyClass"
        (formReady)="deviceClassSelectorForm.addControl('safetyClass', $event)"
        (deviceClassSelected)="selectedSafetyClass = $event; updateAvailableSteps()"
      ></app-device-class-selector>
      <app-device-class-selector
        [title]="titleSelectVoltageClass"
        [deviceClassType]="VoltageClass"
        [selectedVoltageClass]="selectedVoltageClass"
        (formReady)="deviceClassSelectorForm.addControl('voltageClass', $event)"
        (deviceClassSelected)="selectedVoltageClass = $event; updateAvailableSteps()"
      ></app-device-class-selector>
    </form>
  </odx-content-box>
  <app-alarm-device-selector
    class="h-100 w-100"
    [hidden]="activeStep !== 1"
    [selectedSafetyClass]="selectedSafetyClass!"
    [selectedVoltage]="selectedVoltageClass!"
    [project]="project"
    [originalAlarmDevice]="originalConfiguration?.alarmDevices"
    (alarmDeviceSelect$)="updateSelectedAlarmDevices($event)"
    (formReady)="deviceSelectorForm = $event"
  >
  </app-alarm-device-selector>
  <app-attachment-selector
    *ngIf="selectedAlarmDevices?.length"
    class="h-100 w-100"
    [selectionMode]="'single'"
    [hidden]="activeStep !== 2 || skipAttachments"
    [headerProducts]="selectedAlarmDevices!"
    [products]="
      isOriginalAlarmDevicesSelected
        ? { selectedProducts: selectedAlarmDevices!, preselectedAttachments: originalConfiguration!.attachments }
        : { selectedProducts: selectedAlarmDevices! }
    "
    [showCosts]="project.showCostFlag"
    (attachmentsSelect$)="selectedAttachments = $event"
  >
  </app-attachment-selector>
  <app-product-config-stepper-bar
    (dismiss)="backButton.navigateBack()"
    (next)="onSubmit()"
    (previous)="configurationWizard.previousStep()"
    [activeStep]="activeStep"
    [steps]="steps"
  >
  </app-product-config-stepper-bar>
</div>
