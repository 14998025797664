import { Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { Subject, interval } from "rxjs";

@Injectable({ providedIn: "root" })
export class ServiceWorkerUpdateService {
  private newUpdateVersion: Subject<string> = new Subject<string>();

  constructor(public updateService: SwUpdate) {
    this.init();
  }

  isUpdateAvailable() {
    return this.newUpdateVersion.asObservable();
  }

  async executeUpdate(latestVersion: string) {
    await this.clearUnusedCache(latestVersion);
    window.location.reload();
  }

  private init() {
    if (this.updateService.isEnabled) {
      interval(60000).subscribe(async () => await this.updateService.checkForUpdate());
      this.updateService.versionUpdates.subscribe(async (versionEvent) => {
        switch (versionEvent.type) {
          case "VERSION_READY":
            this.newUpdateVersion.next(versionEvent.latestVersion.hash);
            break;
          case "VERSION_INSTALLATION_FAILED":
            console.error(`Failed to install app version '${versionEvent.version.hash}': ${versionEvent.error}`);
            await this.clearCacheAndReload();
            break;
          default:
            break;
        }
      });
      this.updateService.unrecoverable.subscribe(async (unrecoverableStateEvent) => {
        if (unrecoverableStateEvent) {
          await this.clearCacheAndReload();
        }
      });
    }
  }

  private async clearCacheAndReload() {
    const cacheKeys = await caches.keys();
    await Promise.all(cacheKeys.map((key) => caches.delete(key)));
    document.location.reload();
  }

  private async clearUnusedCache(latestVersion: string) {
    const cacheKeys = await caches.keys();
    const cachesToKeep = ["db:control", "dynamicResources", latestVersion];
    const cacheKeysToDelete = cacheKeys.filter((key) => !cachesToKeep.some((cache) => key.includes(cache)));
    return Promise.all(cacheKeysToDelete.map((key) => caches.delete(key)));
  }
}
