<div odxLayout="flex" class="flex-column h-100 overflow-hidden">
  <odx-form-field i18n-label="@@filterProduct.label" label="Filter nach Name oder Sachnummer" id="search-field">
    <input
      odxFormFieldControl
      type="search"
      i18n-placeholder="@@filterProduct.placeholder"
      placeholder="Name, Sachnummer"
      class="input"
      #searchField
    />
  </odx-form-field>
</div>
