<div class="odx-bar-parent h-100" odxLayout="flex gap-none">
  <app-configuration-wizard-back-button #backButton [project]="project" [floorplanGroup]="FloorplanMenuType.SIGNAL_ELEMENT">
  </app-configuration-wizard-back-button>

  <div class="w-100 h-100 scroll bg-white">
    <app-signal-element-selector
      [project]="project"
      [originalSignalElement]="originalConfiguration?.signalElement"
      (signalElementSelect$)="selectedSignalElement = $event"
    ></app-signal-element-selector>
  </div>
  <app-product-config-stepper-bar (dismiss)="backButton.navigateBack()" (next)="onSubmit()"></app-product-config-stepper-bar>
</div>
