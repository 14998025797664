<app-floorplan-upload-overlay (floorplan$)="onPlanUploaded($event)">
  <form odxForm [formGroup]="floorplanForm" class="container scroll h-100" style="padding: 24px">
    <!-- prettier-ignore -->
    <h6 class="odx-subtitle-6" *ngIf="floorplans.length === 0" i18n="@@floorplanUpload.noFloorplanYet">Sie haben noch keinen Grundrissplan hinzugefügt.</h6>
    <div odxLayout="grid 3@desktop 4@tablet 6@phone" class="mb-36">
      <app-new-project-file-card
        *ngFor="let plan of floorplans"
        i18n-title="@@floorplanUpload.fileCard.header"
        title="Neuer Grundrissplan"
        [fileUrl]="plan.fileUrl"
        (delete$)="onDelete(plan)"
        (submit$)="onCardSubmit($event, plan)"
        #floorplanCard
      ></app-new-project-file-card>
    </div>
  </form>
</app-floorplan-upload-overlay>
