<odx-content-box class="content-box" [ngClass]="{ selected: isSelected }">
  <odx-area-header>
    <h6 class="odx-title-6">{{ title }}</h6>
  </odx-area-header>

  <div class="image-wrapper">
    <app-standard-image [imageUrl]="imageUrl"></app-standard-image>
  </div>

  <odx-content-box-footer>
    <ng-content></ng-content>
  </odx-content-box-footer>
</odx-content-box>
