import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";

@Component({
  selector: "app-product-config-stepper-bar",
  templateUrl: "./product-config-stepper-bar.component.html",
  styleUrls: ["./product-config-stepper-bar.component.scss"],
  standalone: true,
  imports: [UiKitModule],
})
export class ProductConfigStepperBarComponent {
  @Input() activeStep: number = 0;
  @Input() steps: number = 1;
  @Input() isPreviousButtonDisabled = false;

  @Output() next = new EventEmitter<void>();
  @Output() previous = new EventEmitter<void>();
  @Output() dismiss = new EventEmitter<void>();
}
