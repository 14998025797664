import { Injectable } from "@angular/core";
import { Checklist } from "@domain/project/checklist";
import { Project } from "@domain/project/project";
import { ChecklistRow } from "@pdf/export-services/checklist-page/checklist-row";
import { ChecklistTable } from "@pdf/export-services/checklist-page/checklist-table";
import { TableOfContents } from "@pdf/export-services/table-of-contents/table-of-contents";
import { Container } from "@pdf/helpers/container/container";
import { ContainerService } from "@pdf/helpers/container/container-service";
import { TableColumn } from "@pdf/helpers/default-table/table-column";
import { DraegerFonts } from "@pdf/helpers/draeger-fonts";
import {
  ContainerProperties,
  FontNames,
  Headlines,
  ImagePaths,
  PdfProperties,
  ProductListProperties,
  TableProperties,
} from "@pdf/pdf-properties";
import { jsPDF } from "jspdf";
import { Observable } from "rxjs";

interface ChecklistTableRowData {
  title: string;
  text: string;
  checked?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class ChecklistPagePdfService {
  private headlines = new Headlines();

  constructor(private containerService: ContainerService) {}

  generate(pdf: jsPDF, project: Project, tableOfContents: TableOfContents): Observable<jsPDF> {
    return new Observable((observer) => {
      this.addFonts(pdf);
      pdf.setFont(FontNames.DRAEGER_PANGEA, "normal");
      pdf.setTextColor(PdfProperties.DRAEGERBLUE);
      const container = new Container();
      this.addChecklistTable(pdf, container, tableOfContents, project.checklist);
      observer.next(pdf);
      observer.complete();
    });
  }

  private addChecklistTable(pdf: jsPDF, container: Container, tableOfContents: TableOfContents, checklist: Checklist) {
    const checklistTableRows: ChecklistTableRowData[] = this.getChecklistTableRowData(checklist);
    this.drawChecklist(pdf, container, tableOfContents, new ChecklistTable(pdf, checklistTableRows));
  }

  private drawChecklist(pdf: jsPDF, container: Container, tableOfContents: TableOfContents, checklistTable: ChecklistTable) {
    pdf.setFontSize(TableProperties.TEXT_FONT_SIZE);
    const pageHeadline = this.headlines.CHECKLIST;
    this.containerService.addOnNewPage(pdf, container, pageHeadline, tableOfContents);
    container.yPosition += 48;
    checklistTable.rows.forEach((row) => {
      const isEnoughSpace = this.checkIfIsEnoughSpace(row.textHeight + TableProperties.ROW_PADDING_Y, container);
      if (!isEnoughSpace) {
        this.containerService.add(pdf, container, pageHeadline, tableOfContents);
        container.yPosition += 48;
      }
      this.addChecklistRow(pdf, container, checklistTable.columns, row);
    });
    container.yPosition += TableProperties.GAP_BETWEEN_TABLES;
  }

  private addChecklistRow(pdf: jsPDF, container: Container, columns: TableColumn[], row: ChecklistRow) {
    container.yPosition += TableProperties.ROW_PADDING_TOP;
    pdf.setFontSize(TableProperties.TEXT_FONT_SIZE);
    row.title.forEach((line, index) => {
      pdf.text(
        line,
        container.xPosition + columns[0].xPosition,
        container.yPosition + index * pdf.getTextDimensions(line).h * PdfProperties.POINT_TO_PIXEL_RATIO,
        { baseline: "top" },
      );
    });
    if (row.checked !== undefined) {
      const icon = row.checked ? ImagePaths.SWITCH_CHECKED : ImagePaths.SWITCH_UNCHECKED;
      pdf.addImage(icon, "PNG", container.xPosition + columns[1].xPosition, container.yPosition - 3, 52, 30);
    }
    row.text.forEach((line, index) => {
      pdf.text(
        line,
        container.xPosition + columns[2].xPosition,
        container.yPosition + index * pdf.getTextDimensions(line).h * PdfProperties.POINT_TO_PIXEL_RATIO,
        { baseline: "top" },
      );
    });
    container.yPosition += row.textHeight;
    container.yPosition += TableProperties.ROW_PADDING_BOTTOM;
  }

  private checkIfIsEnoughSpace(height: number, container: Container) {
    return (
      ContainerProperties.Y + ContainerProperties.HEIGHT - ProductListProperties.FOOTER_HEIGHT - container.yPosition > height
    );
  }

  private getChecklistTableRowData(checklist: Checklist): ChecklistTableRowData[] {
    return [
      { title: $localize`:@@checklist.operator.label:Wer ist Betreiber der Anlage?`, text: checklist.operator || "" },
      { title: $localize`:@@checklist.client.label:Wer ist Auftraggeber?`, text: checklist.client || "" },
      {
        title: $localize`:@@checklist.switch1.label:Sind alle Sensoren mit Gasart und Messbereich berücksichtigt?`,
        checked: checklist.sensorGasAndMeasurementRangeConsidered.checked,
        text: checklist.sensorGasAndMeasurementRangeConsidered.notes || "",
      },
      {
        title: $localize`:@@checklist.switch2.label:Gibt es Störeinflüsse (Sensorgifte, etc.)?`,
        checked: checklist.peturbationsExist.checked,
        text: checklist.peturbationsExist.notes || "",
      },
      {
        title: $localize`:@@checklist.switch3.label:Sind alle benötigten Relaisausgänge berücksichtigt?`,
        checked: checklist.relayOutputsConsidered.checked,
        text: checklist.relayOutputsConsidered.notes || "",
      },
      {
        title: $localize`:@@checklist.switch4.label:Ist die Betriebsspannung der Alarmmittel definiert?`,
        checked: checklist.operatingVoltageOfMeansOfAlarmDefined.checked,
        text: checklist.operatingVoltageOfMeansOfAlarmDefined.notes || "",
      },
      {
        title: $localize`:@@checklist.switch5.label:Sind alle Sensoren / Gaswarnzentrale / Alarmmittel positioniert?`,
        checked: checklist.sensorsGasAlarmDeviceAndMeansOfAlarmSet.checked,
        text: checklist.sensorsGasAlarmDeviceAndMeansOfAlarmSet.notes || "",
      },
      {
        title: $localize`:@@checklist.switch6.label:Anforderungen Installation / Montage`,
        checked: checklist.installationRequirementsDefined.checked,
        text: checklist.installationRequirementsDefined.notes || "",
      },
      {
        title: $localize`:@@checklist.switch7.label:Gibt es Werksnormen / besondere Auflagen?`,
        checked: checklist.specialStandardsExist.checked,
        text: checklist.specialStandardsExist.notes || "",
      },
      {
        title: $localize`:@@checklist.switch8.label:Werden Steiger / Gerüste benötigt / bereitgestellt?`,
        checked: checklist.scaffoldingRequired.checked,
        text: checklist.scaffoldingRequired.notes || "",
      },
      {
        title: $localize`:@@checklist.switch9.label:Gibt es Ex-Zonen?`,
        checked: checklist.exZonesExist.checked,
        text: checklist.exZonesExist.notes || "",
      },
      {
        title: $localize`:@@checklist.switch10.label:Freimessung notwendig? Wenn ja, durch wen?`,
        checked: checklist.clearanceMeasurementRequired.checked,
        text: checklist.clearanceMeasurementRequired.notes || "",
      },
      {
        title: $localize`:@@checklist.switch11.label:Ist ein Erdungskonzept vorhanden?`,
        checked: checklist.electricalGroundingConceptExists.checked,
        text: checklist.electricalGroundingConceptExists.notes || "",
      },
      {
        title: $localize`:@@checklist.switch12.label:Ist eine Be-/Entlüftung vorhanden?`,
        checked: checklist.ventilationExists.checked,
        text: checklist.ventilationExists.notes || "",
      },
      {
        title: $localize`:@@checklist.personToInstruct.label:Wer ist bei der IBN zugegen, bzw. wer soll eingewiesen werden?`,
        text: checklist.personToInstruct || "",
      },
    ];
  }

  private addFonts(doc: jsPDF) {
    new DraegerFonts().fonts.forEach((font) => {
      doc.addFileToVFS(font.ttfName, font.base64);
      doc.addFont(font.ttfName, font.fontName, font.fontWeight);
    });
  }
}
