import { Injectable } from "@angular/core";
import { ProductType } from "@domain/product/product";
import { ProductService } from "@domain/product/product.service";
import { PlasticSignConfiguration } from "@domain/project/configurations/plastic-sign-configuration";
import { ProductData } from "@domain/project/product-data/product-data";
import { Observable, forkJoin, map, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PlasticSignDataService {
  constructor(private productService: ProductService) {}

  getPlasticSignData(plasticSigns: readonly PlasticSignConfiguration[], localeId: string): Observable<ProductData[][]> {
    if (plasticSigns.length == 0) {
      return of([]);
    }
    return forkJoin(plasticSigns.map((plasticSign) => this.getProducts(plasticSign, localeId))).pipe(
      map((products) => products.filter((product): product is ProductData[] => !!product)),
    );
  }

  private getProducts(plasticSignsConfig: PlasticSignConfiguration, localeId: string): Observable<ProductData[] | undefined> {
    const productPlacements = plasticSignsConfig.countPlacedProducts();
    return this.productService.getProductById(plasticSignsConfig.productId).pipe(
      map((product) => {
        if (!product || product.type !== ProductType.PLASTICSIGN) {
          throw new Error(`Plastic sign with product id '${plasticSignsConfig.productId}' not found.`);
        }
        const productData = ProductData.create(product, productPlacements, localeId);
        productData.position = plasticSignsConfig.positionNumber!;
        return [productData];
      }),
    );
  }
}
