<odx-modal-header>
  <odx-area-header i18n="@@modal.takePhoto.header">Foto aufnehmen</odx-area-header>
</odx-modal-header>

<odx-modal-content>
  <video autoplay="true" id="video"></video>
</odx-modal-content>

<odx-modal-footer>
  <button odxButton variant="secondary" odxModalDismiss i18n="@@global.dismiss">Abbrechen</button>
  <button
    odxButton
    variant="primary"
    (click)="switchCamera()"
    [disabled]="!!(isLoading$ | async)"
    i18n="@@modal.takePhoto.button.switchCamera"
  >
    Kamera wechseln
  </button>
  <button
    odxButton
    variant="primary"
    (click)="takePhoto()"
    [odxLoadingSpinner]="!!(isLoading$ | async)"
    i18n="@@modal.takePhoto.button.takePhoto"
  >
    Foto aufnehmen
  </button>
</odx-modal-footer>
