import { ValidationArguments, ValidatorConstraint, ValidatorConstraintInterface } from "class-validator";

@ValidatorConstraint({ name: "isEnumValue", async: false })
export class IsEnumValueConstraint implements ValidatorConstraintInterface {
  validate(value: any, args: ValidationArguments) {
    const enumType = args.constraints[0];
    return Object.values(enumType).includes(value);
  }

  defaultMessage(args: ValidationArguments) {
    return `${args.property} must be a valid enum value of ${args.constraints[0].name}`;
  }
}
