<odx-area-header
  ><h6 class="odx-title-6" i18n="@@newProject.contactPersonForm.header.contactPerson">Ansprechpartner</h6></odx-area-header
>
<form odxForm [formGroup]="contactPersonForm" class="form">
  <odx-form-group>
    <odx-form-field i18n-label="@@newProject.contactPersonForm.label.firstName" label="Vorname" odxLayout="6">
      <input
        formControlName="firstname"
        odxFormFieldControl
        i18n-placeholder="@@newProject.contactPersonForm.label.firstName"
        placeholder="Vorname"
        [readonly]="isReadonly"
        data-testid="contactPersonForm.firstName"
      />
    </odx-form-field>
    <odx-form-field i18n-label="@@newProject.contactPersonForm.label.lastName" label="Nachname" odxLayout="6">
      <input
        formControlName="lastname"
        odxFormFieldControl
        i18n-placeholder="@@newProject.contactPersonForm.label.lastName"
        placeholder="Nachname"
        [readonly]="isReadonly"
        data-testid="contactPersonForm.lastName"
      />
    </odx-form-field>
  </odx-form-group>
  <odx-form-field i18n-label="@@newProject.contactPersonForm.label.division" label="Abteilung">
    <input
      formControlName="department"
      odxFormFieldControl
      i18n-placeholder="@@newProject.contactPersonForm.label.division"
      placeholder="Abteilung"
      [readonly]="isReadonly"
      data-testid="contactPersonForm.department"
    />
  </odx-form-field>
  <odx-form-field i18n-label="@@newProject.contactPersonForm.label.email" label="E-Mail">
    <input
      formControlName="email"
      odxFormFieldControl
      i18n-placeholder="@@newProject.contactPersonForm.label.email"
      placeholder="E-Mail"
      [readonly]="isReadonly"
      data-testid="contactPersonForm.email"
    />
    <ng-template odxFormFieldError="pattern">
      <ng-container i18n="@@newProject.contactPersonForm.errorMessage.invalidEmail"
        >Bitte eine korrekte E-Mail eintragen</ng-container
      >
    </ng-template>
  </odx-form-field>
  <odx-form-field i18n-label="@@newProject.contactPersonForm.label.phoneNumber" label="Telefonnummer">
    <input
      formControlName="phoneNumber"
      odxFormFieldControl
      i18n-placeholder="@@newProject.contactPersonForm.label.phoneNumber"
      placeholder="Telefonnummer"
      [readonly]="isReadonly"
      data-testid="contactPersonForm.phoneNumber"
    />
    <ng-template odxFormFieldError="pattern">
      <ng-container i18n="@@newProject.contactPersonForm.errorMessage.invalidPhoneNumber"
        >Bitte eine korrekte Telefonnummer eintragen</ng-container
      >
    </ng-template>
  </odx-form-field>
  <!--Needed to show validation errors on submit without userinput-->
  <button type="submit" class="hidden" #submitButton></button>
</form>
