import { Component, Input, OnInit } from "@angular/core";
import { UiKitModule } from "@app/ui-kit.module";
import { ProductType } from "@domain/product/product";
import { ProductService } from "@domain/product/product.service";
import { FloorplanAlarmDevice } from "@domain/project/floorplan/floorplan-alarm-device";
import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { FloorplanItemAttachmentsComponent } from "@project/floorplanner/floorplan-additional-notes/floorplan-item-attachments/floorplan-item-attachments.component";
import { FloorplanItemDetailsComponent } from "@project/floorplanner/floorplan-additional-notes/floorplan-item-details/floorplan-item-details.component";
import { ProjectStateService } from "@project/project-state.service";
import { Observable, of, tap } from "rxjs";

@Component({
  selector: " app-alarm-notes",
  templateUrl: "./alarm-device-notes.component.html",
  styleUrls: ["./alarm-device-notes.component.scss"],
  standalone: true,
  imports: [UiKitModule, FloorplanItemDetailsComponent, FloorplanItemAttachmentsComponent],
})
export class AlarmDeviceNotesComponent implements OnInit {
  @Input({ required: true }) set floorplanItem(item: FloorplanItem) {
    this.alarmDevice = item as FloorplanAlarmDevice;
  }

  protected alarmDeviceName$!: Observable<string>;
  protected alarmDevice!: FloorplanAlarmDevice;
  protected alarmDevices$!: Observable<string[]>;
  protected isDiscontinued$!: Observable<boolean>;

  constructor(
    private productService: ProductService,
    private projectStateService: ProjectStateService,
  ) {}

  ngOnInit() {
    this.alarmDevices$ = this.resolveAlarmDevices();
    this.isDiscontinued$ = this.projectStateService.isConfigDiscontinued(this.alarmDevice.config.id);

    this.alarmDevices$
      .pipe(
        tap((alarmDevices: string[]) => {
          this.alarmDeviceName$ = of(
            this.alarmDevice.config.productIds.length === 1
              ? alarmDevices[0]
              : $localize`:@@alarmDeviceConfiguration.combination.name:Alarm-Kombi (${this.alarmDevice.config.productIds.join(", ")}:listOfAlarmDeviceIds:)`,
          );
        }),
      )
      .subscribe();
  }

  private resolveAlarmDevices(): Observable<string[]> {
    return this.productService.getProductNamesByIds(this.alarmDevice.config.productIds, ProductType.ALARMDEVICE);
  }
}
