<odx-modal-header>
  <odx-area-header i18n="@@modal.deleteProject.header1">Bitte bestätigen</odx-area-header>
</odx-modal-header>
<odx-modal-content>
  <p i18n="@@modal.deleteProject.header2">Wollen Sie das Projekt wirklich löschen?</p>
  <div [formGroup]="formGroup">
    <odx-checkbox formControlName="confirmationCheckbox" i18n="@@modal.deleteProject.checkbox.deleteFromCloud">
      Projekt auch aus der Cloud löschen (eine Wiederherstellung ist nicht mehr möglich)
    </odx-checkbox>
  </div>
</odx-modal-content>
<odx-modal-footer>
  <button odxButton variant="secondary" odxModalDismiss i18n="@@global.dismiss">Abbrechen</button>
  <button
    odxButton
    variant="primary"
    (click)="onConfirm()"
    i18n="@@modal.deleteProject.button.confirm"
    data-testid="modal.deleteProject.button.confirm"
  >
    OK
  </button>
</odx-modal-footer>
