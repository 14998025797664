<div class="odx-content">
  <h2 i18n="@@privacyPolicy.header">Datenschutzerklärung</h2>
  <p i18n="@@privacyPolicy.section1.row1">
    Dräger fühlt sich dem Schutz der Persönlichkeitsrechte eines jedem verpflichtet, dessen personenbezogene Daten von Dräger
    verarbeitet werden. Daher beantworten wir in dieser Datenschutzerklärung die wichtigsten Fragen zur Verarbeitung Ihrer Daten.
    Diese Datenschutzerklärung betrifft die Webseiten von Dräger und alle mit Dräger verbundenen Unternehmen, die im Rahmen ihres
    Webauftritts auf diese Seite verweisen. Sie gilt nicht für Dräger-Webseiten, die auf eine eigene Datenschutzerklärung
    verweisen. Den für die Datenverarbeitung Verantwortlichen („Dräger“ oder „wir“) im Sinne des Datenschutzrechts (Für Nutzer aus
    der Europäischen Union: Art. 4 Nr. 7 Datenschutz-Grundverordnung) entnehmen Sie bitte unserem Impressum. Für die
    Bereitstellung von Waren oder Dienstleistungen ist abweichend hiervon dasjenige Dräger-Unternehmen verantwortliche Stelle im
    Sinne des Datenschutzrechts, das Ihr Vertragspartner für die Bereitstellung der Ware oder Dienstleistung ist. Angaben zu dem
    betreffenden Dräger-Unternehmen finden Sie in den Bedingungen für die Bereitstellung der jeweiligen Ware oder Dienstleistung.
  </p>
  <h3 i18n="@@privacyPolicy.section2.header">An wen kann ich mich bei Dräger zum Thema Datenschutz wenden?</h3>
  <p i18n="@@privacyPolicy.section2.row1">
    Fragen zur Verarbeitung Ihrer personenbezogenen Daten sowie Anträge auf Auskünfte, Berichtigung oder Löschung richten Sie
    gerne an unseren Konzerndatenschutzbeauftragten per E-Mail: dataprivacy&#64;draeger.com oder per Post:
  </p>
  <p>
    <span i18n="@@privacyPolicy.section2.row2">Drägerwerk AG & Co. KGaA</span><br />
    <span i18n="@@privacyPolicy.section2.row3">Konzerndatenschutzbeauftragter</span><br />
    <span i18n="@@privacyPolicy.section2.row4">Moislinger Allee 53-55</span><br />
    <span i18n="@@privacyPolicy.section2.row5">D-23558 Lübeck</span>
  </p>
  <h3 i18n="@@privacyPolicy.section3.header">Wie verarbeitet Dräger Daten von mir?</h3>
  <p i18n="@@privacyPolicy.section3.row1">
    Wenn Sie unsere Website besuchen, verarbeiten wir personenbezogene Daten, die Ihr Browser an unseren Server übermittelt. Diese
    Daten (sog. Logfiles) sind technisch erforderlich, um unsere Webseite anzuzeigen und die Stabilität und Sicherheit
    gewährleisten zu können.
  </p>

  <p i18n="@@privacyPolicy.section3.row2">Diese Daten werden über Logfiles protokolliert:</p>

  <ul>
    <li i18n="@@privacyPolicy.section3.row3">IP-Adresse</li>
    <li i18n="@@privacyPolicy.section3.row4">Datum und Uhrzeit der Anfrage</li>
    <li i18n="@@privacyPolicy.section3.row5">Inhalt der Anforderung (konkrete Seite)</li>
    <li i18n="@@privacyPolicy.section3.row6">Zugriffsstatus/HTTP-Statuscode</li>
    <li i18n="@@privacyPolicy.section3.row7">jeweils übertragene Datenmenge</li>
    <li i18n="@@privacyPolicy.section3.row8">Webseite, von der die Anforderung kommt</li>
    <li i18n="@@privacyPolicy.section3.row9">Browser</li>
    <li i18n="@@privacyPolicy.section3.row10">Betriebssystem und dessen Oberfläche</li>
    <li i18n="@@privacyPolicy.section3.row11">Sprache und Version der Browsersoftware</li>
  </ul>

  <p i18n="@@privacyPolicy.section3.row12">
    Die Verarbeitung erfolgt für Nutzer aus der Europäischen Union auf Grundlage von Art. 6 Abs. 1 lit. f) DSGVO zur Wahrung
    unseres berechtigten Interesses am störungsfreien Betrieb unserer Website. Die Daten werden für einen Zeitraum von bis zu 30
    Tagen gespeichert und danach automatisch gelöscht bzw. anonymisiert.
  </p>
  <p i18n="@@privacyPolicy.section3.row13">
    Darüber hinaus verarbeiten wir grundsätzlich die personenbezogenen Daten, die Sie uns freiwillig zukommen lassen. Das kann zum
    Beispiel im Rahmen Ihrer Registrierung oder bei Ihrem Login geschehen.
  </p>

  <p i18n="@@privacyPolicy.section3.row14">
    Wir erheben und verarbeiten Ihre Daten ausschließlich zu vorab definierten Zwecken. Diese können sich aus technischen
    Notwendigkeiten, vertraglichen Erfordernissen oder ausdrücklichen Nutzerwünschen ergeben. Darüber hinaus nutzen wir Ihre Daten
    nur, wenn Sie uns zuvor Ihre Einwilligung erteilt haben. Einige unserer Webangebote befinden sich in geschlossenen
    Nutzerbereichen, für die eine Registrierung mit Login notwendig ist. Über ein solches Login werden Ihr Zugang und Ihre
    Berechtigungen für die jeweiligen Funktionen gesteuert. Ihre personenbezogenen Daten, die bei einem Login erhoben und
    gespeichert werden, fragen wir im Rahmen des jeweiligen Webangebots ab.
  </p>
  <p i18n="@@privacyPolicy.section3.row15">
    Weitere Informationen über die Speicherung, Verarbeitung und den Zweck der jeweiligen Erhebung finden Sie nachfolgend sowie
    gegebenenfalls zusätzlich in den Nutzungshinweisen der einzelnen Webangebote.
  </p>
  <h3 i18n="@@privacyPolicy.section4.header">Wann verarbeitet Dräger meine personenbezogenen Daten?</h3>
  <h4 i18n="@@privacyPolicy.section4.row1">Kontaktaufnahme</h4>
  <p i18n="@@privacyPolicy.section4.row2">
    Sie haben eines unserer Kontaktformulare genutzt, um z.B. Informationen, etwa über ein Produkt und eine Dienstleistung von
    Dräger, anzufordern? Ihre im Formular angegebenen Daten nutzen wir grundsätzlich, um Ihr Anliegen zu bearbeiten. Sind Sie
    darüber hinaus an weiteren Informationen, z.B. Einladungen zu Veranstaltungen, Webinaren oder Produktinformationen,
    interessiert, haben Sie die Möglichkeit, uns vor Versand der Kontaktanfrage Ihre gesonderte Einwilligung hierfür zu geben.
  </p>
  <p i18n="@@privacyPolicy.section4.row3">
    Für Nutzer aus der Europäischen Union: Die Rechtsgrundlage für die Verarbeitung dieser Daten ist die durch Sie erklärte
    Einwilligung im Sinne von Art. 6 Abs. 1 lit. b) DSGVO.
  </p>
  <h4 i18n="@@privacyPolicy.section4.row4">Optimierung der Website</h4>
  <p i18n="@@privacyPolicy.section4.row5">
    Unser Ziel ist es, unsere Website für Sie weiter zu verbessern. Zu diesem Zweck verwenden wir verschiedene Web-Analyse-Tools,
    die uns Antworten auf bestimmte Fragen liefern, wie z. B: Welche unserer Seiten haben Sie besucht? Welche Links haben Sie
    angeklickt? Eine Liste dieser Tools finden Sie unter: „Welche Dienste nutzt Dräger auf der Website?“.
  </p>
  <p i18n="@@privacyPolicy.section4.row6">
    Soweit personenbezogene Daten verarbeitet werden, beruht diese Verarbeitung auf unserem berechtigten Interesse für Nutzer aus
    der Europäischen Union gemäß Art. 6 Abs.1 lit. f) DSGVO. Unser berechtigtes Interesse ergibt sich aus den folgenden Zwecken
    der Datenerhebung: Gewährleistung eines reibungslosen Verbindungsaufbaus, Gewährleistung einer komfortablen Nutzung unserer
    Website und/oder Anwendung sowie Analyse der Systemsicherheit und -stabilität.
  </p>
  <h4 i18n="@@privacyPolicy.section4.row7">Application Insights</h4>
  <p i18n="@@privacyPolicy.section4.row8">
    Dabei verfügen wir über automatisierte Analysedienste. Zu diesem Zweck nutzen wir Application Insights, ein Dienst der
    Microsoft Ireland Operations Limited im South County Business Park, One Microsoft Place, Carmanhall and Leopardstown, Dublin,
    D18 P521, Irland ("Application Insights"). Dieser Cloud-Dienst, der von Microsoft Azure gehostet wird, analysiert das
    Nutzerverhalten auf unserer Website. Dazu sammeln wir verschiedene technische Nutzerinformationen, die beim Aufruf der Website
    übermittelt werden. Dies betrifft insbesondere Datum, Uhrzeit und Adresse (URL) der Anfrage. Darüber hinaus wird die
    IP-Adresse zur Lokalisierung erfasst und anschließend anonymisiert.
  </p>
  <p i18n="@@privacyPolicy.section4.row9">
    Weitere Informationen über die Verarbeitung personenbezogener Daten im Zusammenhang mit Application Insights finden Sie unter
    https://learn.microsoft.com/en-us/azure/azure-monitor/app/app-insights-overview?tabs=net und
    https://www.microsoft.com/en-us/trust-center/privacy.
  </p>
  <h3 i18n="@@privacyPolicy.section5.header">Nutzt Dräger Cookies?</h3>
  <p i18n="@@privacyPolicy.section5.row1">
    Um unser Angebot so nutzerfreundlich wie möglich zu gestalten, verwenden wir zudem, so wie viele andere Unternehmen auch, sog.
    Cookies und ähnliche Technologien.
  </p>

  <h4 i18n="@@privacyPolicy.section5.row2">Was sind Cookies?</h4>
  <p i18n="@@privacyPolicy.section5.row3">
    Cookies sind kleine Textdateien, die Ihr Browser automatisch erstellt und die auf Ihrem Endgerät (z.B. Laptop) gespeichert
    werden, wenn Sie unsere Website besuchen. In dem Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit
    dem spezifisch eingesetzten Endgerät ergeben. Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von Ihrer
    Identität erhalten. Der Einsatz von Cookies dient dazu, die Nutzung unserer Angebote für Sie fortlaufend angenehmer und
    passender zu gestalten. So können wir z.B. sog. Session-Cookies einsetzen. Die Session-Cookies werden nach Verlassen unserer
    Website automatisch gelöscht.
  </p>
  <p i18n="@@privacyPolicy.section5.row4">
    Darüber hinaus können wir ebenfalls zum Zweck der Benutzerfreundlichkeit sog. temporäre Cookies einsetzen, die für einen
    bestimmten festgelegten Zeitraum auf Ihrem Endgerät gespeichert werden. Sobald Sie unsere Website erneut besuchen, wird
    automatisch erkannt, dass Sie bereits bei uns waren und welche Eingaben und Einstellungen Sie vorgenommen haben, um diese
    nicht nochmal eingeben zu müssen. Die temporären Cookies werden in der Regel nach spätestens 30 Tagen gelöscht.
  </p>
  <h4 i18n="@@privacyPolicy.section5.row5">Rechtgrundlage für den Einsatz von Cookies</h4>
  <p i18n="@@privacyPolicy.section5.row6">
    Soweit die Verwendung von Cookies für den Betrieb unserer Website technisch notwendig ist, ist die Verwendung der Cookies ohne
    Einwilligung des Websitebesuchers zulässig.
  </p>

  <h3 i18n="@@privacyPolicy.section6.header">Gibt Dräger meine Daten weiter?</h3>
  <p i18n="@@privacyPolicy.section6.row1">
    Dräger behält sich vor, die Daten, die Sie uns übermittelt haben, an unsere weltweiten Tochtergesellschaften und Fachhändler
    weiterzugeben. Allerdings nur sofern dies erforderlich ist, um Ihre Daten für den bestimmungsmäßigen Zweck zu verarbeiten. Um
    Ihre Daten technisch zu verarbeiten, bedient sich Dräger teilweise externer Dienstleister. Möglicherweise übertragen und
    verarbeiten wir Ihre Daten außerhalb des Landes, in dem Sie Ihren Wohnsitz haben oder in einem der Länder, in denen Dräger,
    seine Tochtergesellschaften, Fachhändler oder Dienstleister und Zulieferer tätig sind. Diese können auch außerhalb des
    europäischen Wirtschaftsraums angesiedelt sein. Unternehmensintern – wie auch mit unseren Fachhändlern, Dienstleistern und
    Zulieferern – bestehen vertragliche Verpflichtungen, die datenschutzrechtlichen Vorschriften einzuhalten.
  </p>
  <h3 i18n="@@privacyPolicy.section7.header">Werden Daten in Länder außerhalb der EU bzw. des EWR übermittelt?</h3>
  <p i18n="@@privacyPolicy.section7.row1">
    Grundsätzlich erfolgt die Verarbeitung der personenbezogenen Daten durch uns ausschließlich innerhalb der EU bzw. des
    Europäischen Wirtschaftsraums.
  </p>
  <p i18n="@@privacyPolicy.section7.row2">
    In Einzelfällen kann es jedoch erforderlich sein, dass wir Informationen an Empfänger in sog. „Drittländern“ übermitteln
    müssen. „Drittländer“ sind Länder außerhalb der Europäischen Union oder des Abkommens über den Europäischen Wirtschaftsraum,
    in denen ohne Weiteres nicht von einem Datenschutzniveau ausgegangen werden kann, das mit dem in der Europäischen Union
    vergleichbar ist. Sofern die übermittelten Informationen auch personenbezogene Daten umfassen, stellen wir vor einer solchen
    Übermittlung sicher, dass in dem jeweiligen Drittland oder bei dem Empfänger in dem Drittland das erforderliche angemessene
    Datenschutzniveau gewährleistet ist. Dies kann sich insbesondere aus einem sog. „Angemessenheitsbeschluss“ der Europäischen
    Kommission ergeben, mit der ein angemessenes Datenschutzniveau für ein bestimmtes Drittland insgesamt festgestellt wird.
    Alternativ können wir die Datenübermittlung auch auf die mit einem Empfänger vereinbarten sog. EU-Standardvertragsklauseln
    stützen oder auf eine von Ihnen entsprechend abgegebene Einwilligungserklärung.
  </p>
  <p i18n="@@privacyPolicy.section7.row3">
    Weitere Informationen zu den geeigneten und angemessenen Garantien zur Einhaltung eines angemessenen Datenschutzniveaus
    stellen wir Ihnen gerne auf Anfrage zur Verfügung. Weitere Informationen zu den sog. EU Standardvertragsklauseln (in Englisch)
    finden Sie
    unter https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en und
    Informationen zu den Angemessenheitsbeschlüssen (in Englisch)
    unter https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en.
  </p>
  <h3 i18n="@@privacyPolicy.section8.header">Wie lange werden personenbezogene Daten gespeichert?</h3>
  <p i18n="@@privacyPolicy.section8.row1">
    Grundsätzlich speichern wir personenbezogene Daten solange dies für den Verarbeitungszweck erforderlich ist bzw. wir an dieser
    Speicherung ein berechtigtes Interesse haben und Ihre Interessen an der Nichtfortführung der Speicherung bzw. der Verarbeitung
    nicht überwiegen. Das bedeutet, wir speichern Ihre Daten grundsätzlich nur solange, wie dies zur Bereitstellung unserer
    Website und den damit verbundenen Services erforderlich ist bzw. wir gesetzlich zur Speicherung dieser Daten verpflichtet
    sind. Wir löschen personenbezogenen Daten auch ohne Zutun der jeweils betroffenen Person, sobald diese für den
    Verarbeitungszweck nicht mehr erforderlich sind bzw. die Speicherung sonst rechtlich unzulässig ist.
  </p>
  <p i18n="@@privacyPolicy.section8.row2">In der Regel werden</p>
  <ul>
    <li i18n="@@privacyPolicy.section8.row3">
      die Daten nach der Dauer gelöscht oder anonymisiert, die bei der jeweiligen näher beschriebenen Datenverarbeitung bzw.
      Funktion der Website angegeben ist;
    </li>
    <li i18n="@@privacyPolicy.section8.row4">
      die im Zusammenhang mit einer Geschäftsbeziehung (insbesondere im Zusammenhang mit bestellten Produkten oder Inanspruchnahme
      eines Service bei uns) verarbeiteten Daten spätestens nach Ablauf der gesetzlichen Aufbewahrungsfristen gelöscht; und
    </li>
    <li i18n="@@privacyPolicy.section8.row5">
      die im Zusammenhang mit dem Kundenkonto verarbeiteten Daten mit dem Löschen des jeweiligen Kundenkontos gelöscht, soweit die
      weitere Aufbewahrung nicht zur Erfüllung gesetzlicher oder vertraglicher Aufbewahrungsfristen im Zusammenhang mit der
      jeweiligen Geschäftsbeziehung erforderlich ist.
    </li>
  </ul>
  <p i18n="@@privacyPolicy.section8.row6">
    Diejenigen personenbezogenen Daten, die wir zur Erfüllung von Aufbewahrungspflichten speichern müssen, werden bis zum Ende der
    jeweiligen Aufbewahrungspflicht gespeichert. Soweit wir personenbezogene Daten ausschließlich zur Erfüllung von
    Aufbewahrungspflichten speichern, wird die diesbezügliche Verarbeitung in der Regel eingeschränkt, so dass nur dann darauf
    zugegriffen werden kann, wenn dies mit Hinblick auf den Zweck der Aufbewahrungspflicht erforderlich ist.
  </p>
  <h3 i18n="@@privacyPolicy.section9.header">Welche Rechte habe ich als betroffene Person?</h3>
  <p i18n="@@privacyPolicy.section9.row1">
    Als Nutzer aus der Europäischen Union und zugleich von der Datenverarbeitung betroffene Person im Sinne von Art. 4 Nr. 1 DSGVO
    stehen Ihnen bei Vorliegen der gesetzlichen Vorrausetzungen uns gegenüber verschiedene Rechte zu, über die wir Sie im
    Folgenden gerne näher informieren möchten. Einzelheiten hierzu finden Sie zudem direkt in den Art. 15 bis 21 der DSGVO.
  </p>

  <p i18n="@@privacyPolicy.section9.row2">
    Um diese Rechte auszuüben, können Sie sich einfach gerne an unseren Datenschutzbeauftragten wenden, dessen Kontaktdaten oben
    angegeben sind, oder bequem die von uns zur Verfügung gestellten technischen Mittel nutzen (z.B. die Cookie-Einstellungen zu
    Verwaltung bzw. zum Widerruf von hierüber abgegebenen Einwilligungen).
  </p>

  <h4 i18n="@@privacyPolicy.section9.row3">Auskunftsrecht, Art. 15 DSGVO</h4>
  <p i18n="@@privacyPolicy.section9.row4">
    Sie haben das Recht, von uns Auskunft darüber zu erhalten, ob und welche Daten wir über Sie verarbeiten. Dies beinhaltet unter
    anderem auch Angaben dazu, wie lange und zu welchem Zweck wir die Daten verarbeiten, woher diese stammen und an welche
    Empfänger oder Empfängerkategorien wir diese weitergeben. Zudem können Sie von uns eine Kopie dieser Daten zur Verfügung
    gestellt bekommen.
  </p>
  <h4 i18n="@@privacyPolicy.section9.row5">Recht auf Berichtigung, Art. 16 DSGVO</h4>
  <p i18n="@@privacyPolicy.section9.row6">
    Als betroffene Person haben Sie das Recht, dass wir nicht oder nicht mehr zutreffende Angaben über Sie unverzüglich
    berichtigen. Zudem können Sie eine Vervollständigung Ihrer unvollständigen personenbezogenen Daten verlangen. Wenn dies
    gesetzlich vorgeschrieben ist, informieren wir über diese Berichtigung auch Dritte, wenn wir Ihre Daten an diese weitergegeben
    haben.
  </p>
  <h4 i18n="@@privacyPolicy.section9.row7">Recht auf Löschung (sog. „Recht auf Vergessenwerden“), Art. 17 DSGVO</h4>
  <p i18n="@@privacyPolicy.section9.row8">
    Als betroffene Person haben Sie das Recht, von uns die unverzügliche Löschung Ihrer personenbezogenen Daten zu verlangen, wenn
    einer der folgenden Gründe zutrifft:
  </p>

  <ul>
    <li i18n="@@privacyPolicy.section9.row9">
      Ihre Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig oder
      der Zweck ist erreicht;
    </li>
    <li i18n="@@privacyPolicy.section9.row10">
      Sie widerrufen eine Einwilligung und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung;
    </li>
    <li i18n="@@privacyPolicy.section9.row11">
      Sie legen Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung
      vor; bei der Nutzung von personenbezogenen Daten zur Direktwerbung genügt ein alleiniger Widerspruch von Ihnen gegen die
      Verarbeitung;
    </li>
    <li i18n="@@privacyPolicy.section9.row12">Ihre personenbezogenen Daten wurden unrechtmäßig verarbeitet;</li>
    <li i18n="@@privacyPolicy.section9.row13">
      die Löschung Ihrer personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem
      Recht der Mitgliedstaaten erforderlich, dem wir unterliegen.
    </li>
  </ul>

  <p i18n="@@privacyPolicy.section9.row14">
    Bitte beachten Sie, dass Ihr Recht auf Löschung durch gesetzliche Bestimmungen eingeschränkt sein kann. Dazu gehören
    insbesondere die Einschränkungen, die in Art.17 DSGVO und § 35 BDSG aufgeführt sind.
  </p>
  <h4 i18n="@@privacyPolicy.section9.row15">Recht auf Einschränkung der Verarbeitung (Sperrung), Art. 18 DSGVO</h4>
  <p i18n="@@privacyPolicy.section9.row16">
    Als betroffene Person haben Sie außerdem das Recht, von uns eine Einschränkung der Verarbeitung Ihrer personenbezogenen Daten
    zu verlangen, wenn eine der folgenden Voraussetzungen gegeben ist:
  </p>
  <ul>
    <li i18n="@@privacyPolicy.section9.row17">
      Sie bestreiten die Richtigkeit Ihrer personenbezogenen Daten, und zwar für eine Dauer, die es uns ermöglicht, die
      Richtigkeit der personenbezogenen Daten zu überprüfen;
    </li>
    <li i18n="@@privacyPolicy.section9.row18">
      die Verarbeitung ist unrechtmäßig und Sie lehnen die Löschung der personenbezogenen Daten ab und verlangen stattdessen die
      Einschränkung der Nutzung Ihrer personenbezogenen Daten;
    </li>

    <li i18n="@@privacyPolicy.section9.row19">
      wir benötigen Ihre personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger, Sie benötigen diese jedoch zur
      Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen, oder
    </li>
    <li i18n="@@privacyPolicy.section9.row20">
      Sie haben Widerspruch gegen die Verarbeitung eingelegt, solange noch nicht feststeht, ob unsere berechtigten Gründe
      gegenüber Ihre überwiegen.
    </li>
  </ul>
  <p i18n="@@privacyPolicy.section9.row21">
    Wenn Sie eine Einschränkung der Verarbeitung nach der vorgenannten Aufzählung erwirkt haben, werden wir Sie unterrichten,
    bevor die Einschränkung aufgehoben wird.
  </p>
  <h4 i18n="@@privacyPolicy.section9.row22">Widerrufsrecht bei Einwilligungen, Art. 7 Abs. 3 DSGVO</h4>
  <p i18n="@@privacyPolicy.section9.row23">
    Sie können uns gegenüber erteilte Einwilligungen jederzeit mit Wirkung für die Zukunft widerrufen. Dieser Widerruf kann in
    Form einer formlosen Mitteilung an die o.g. Kontaktadressen erfolgen oder über die hierzu von uns zur Verfügung gestellten
    technischen Mittel. Falls Sie eine Einwilligung widerrufen, wird davon die Rechtmäßigkeit der bis dahin erfolgten
    Datenverarbeitung nicht berührt.
  </p>

  <h4 i18n="@@privacyPolicy.section9.row24">Recht auf Datenübertragbarkeit, Art. 20 DSGVO</h4>
  <p i18n="@@privacyPolicy.section9.row25">
    Als betroffene Person haben Sie das Recht, personenbezogene Daten, die Sie betreffen und die Sie uns bereitgestellt haben, in
    einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten und diese Daten anderen zu übermitteln. Einzelheiten
    und Einschränkungen können Sie Art. 20 DSGVO entnehmen. Die Ausübung dieses Rechts lässt Ihr Recht auf Löschung unberührt.
  </p>
  <h4 i18n="@@privacyPolicy.section9.row26">Recht auf Beschwerde bei der Aufsichtsbehörde, Art. 77 DSGVO</h4>
  <p i18n="@@privacyPolicy.section9.row27">
    Wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer Daten durch uns gegen geltendes Datenschutzrecht verstößt, haben Sie
    das Recht auf Beschwerde bei einer der zuständigen Aufsichtsbehörden, d. h. insbesondere dem Unabhängigen Landeszentrum für
    Datenschutz Schleswig-Holstein oder der jeweiligen Aufsichtsbehörde in dem Mitgliedsstaat Ihres Aufenthaltsorts, Ihres
    Arbeitsplatzes oder des Orts des mutmaßlichen Datenschutzverstoßes.
  </p>
  <h4 i18n="@@privacyPolicy.section9.row28">Widerspruchsrecht, Art. 21 DSGVO</h4>
  <p i18n="@@privacyPolicy.section9.row29">
    Sie haben als betroffene Person das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die
    Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e) oder f) DSGVO erfolgt,
    Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Im Falle eines solchen
    Widerspruchs verarbeiten wir die Sie betreffenden personenbezogenen Daten nicht mehr, es sei denn, wir können zwingende
    schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten als betroffene Person
    überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen. Wenn wir
    personenbezogenen Daten verarbeiten, um Direktwerbung zu betreiben, haben Sie als betroffene Person das Recht, jederzeit
    Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies
    gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht. Widersprechen Sie als betroffene Person
    der Verarbeitung für Zwecke der Direktwerbung, so verarbeiten wir die betreffenden personenbezogenen Daten nicht mehr für
    diese Zwecke.
  </p>

  <h3 i18n="@@privacyPolicy.section10.header">Änderung der Datenschutzerklärung</h3>

  <p i18n="@@privacyPolicy.section10.row1">Sie lesen gerade die Datenschutzerklärung Version 6.2 Stand 03/2023.</p>

  <p i18n="@@privacyPolicy.section10.row2">Dräger kann die Datenschutzerklärung jederzeit aktualisieren, wenn...</p>
  <ul>
    <li i18n="@@privacyPolicy.section10.row3">...wir neue Produkte oder Dienstleistungen einführen</li>
    <li i18n="@@privacyPolicy.section10.row4">...sich Internet-Verfahren ändern</li>
    <li i18n="@@privacyPolicy.section10.row5">...sich die Internet- und IT-Sicherheitstechnik weiterentwickelt</li>
    <li i18n="@@privacyPolicy.section10.row6">...neue rechtliche Anforderungen bestehen.</li>
  </ul>

  <p i18n="@@privacyPolicy.section10.row7">
    Wir behalten uns deshalb das Recht vor, die Datenschutzerklärung nach Bedarf zu ändern oder zu ergänzen. Die überarbeitete
    Version werden wir an dieser Stelle veröffentlichen. Sie gilt dann für die zukünftige Nutzung unserer Website. Sie sollten die
    Datenschutzerklärung regelmäßig aufrufen, um sich über den aktuellen Stand zu informieren. Eine Zweckänderung bei der
    Verwendung bereits gespeicherter Daten ist damit nicht verbunden. 
  </p>
  <p i18n="@@privacyPolicy.section10.row8">
    Alle Informationen, die auf unseren Webseiten enthaltenen sind, haben wir mit großer Sorgfalt geprüft. Wir übernehmen jedoch
    keine Gewähr dafür, dass die Inhalte unserer Webseiten jederzeit korrekt, vollständig und auf dem neuesten Stand sind.
  </p>
</div>
