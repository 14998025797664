<app-floorplan-item-details
  i18n-title="@@floorplan.details.measurement.header"
  title="Bemaßung"
  [floorplanItem]="measurementLine"
>
  <form odxForm [formGroup]="measurementLineForm" [disabled]="measurementLine.locked">
    <odx-form-field i18n-label="@@floorplan.details.measurement.form.text.label" label="Bemaßungstext">
      <input
        formControlName="text"
        odxFormFieldControl
        i18n-placeholder="@@floorplan.details.measurement.form.text.placeholder"
        placeholder="Bemaßungstext"
      />
    </odx-form-field>
  </form>
</app-floorplan-item-details>
