<odx-toast-container></odx-toast-container>
<div>
  <ng-container *ngIf="initStatus$ | async; let initStatus">
    <div
      *ngIf="initStatus === MasterDataInitStatus.IN_PROGRESS"
      odxLayout="flex vertical-center gap-small"
      style="flex-direction: column"
      class="w-100 loading-indicator"
    >
      <app-logo></app-logo>
      <h3 class="odx-title-3" i18n="@@app.hint.loadingProductData.title">Lade Produktdaten</h3>
      <p i18n="@@app.hint.loadingProductData.text">Bitte haben Sie einen Moment Geduld</p>
      <p [odxLoadingSpinner]="true" [odxLoadingSpinnerMinHeight]="100"></p>
    </div>

    <app-full-screen-error
      *ngIf="initStatus === MasterDataInitStatus.ERROR"
      i18n-title="@@app.errorMessage.loadingProductData.title"
      title="Produktdaten konnten nicht geladen werden"
      [description]="errorMessageLoadingProductData"
      [showReloadButton]="true"
    >
    </app-full-screen-error>
  </ng-container>

  <app-full-screen-error
    *ngIf="authError$ && (authError$ | async)?.code === AuthError.WRONG_AUTH_LEVEL"
    title="Willkommen bei Navinta!"
    i18n-title="@@app.errorMessage.noAccess.header"
    [description]="errorMessageNoAccess"
  >
  </app-full-screen-error>

  <app-full-screen-error
    *ngIf="authError$ && (authError$ | async)?.code === AuthError.INVALID_LOGIN"
    title="Fehlerhafter Login"
    i18n-title="@@app.errorMessage.invalidLogin.header"
    [description]="errorMessageInvalidLogin"
    [showReloadButton]="true"
  >
  </app-full-screen-error>

  <app-full-screen-error
    *ngIf="authError$ && (authError$ | async)?.code === AuthError.OFFLINE"
    title="Offline"
    i18n-title="@@app.errorMessage.offline.header"
    [description]="errorMessageOffline"
    [showReloadButton]="true"
  >
  </app-full-screen-error>

  <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
  <router-outlet *ngIf="!isIframe"></router-outlet>
</div>
