import { Injectable } from "@angular/core";
import { CrmCompany, CrmContact, CrmEntry } from "@domain/crm/crm";
import { ContactPerson } from "@domain/project/contact-person";
import { Customer } from "@domain/project/customer";
import { Project } from "@domain/project/project";

@Injectable({
  providedIn: "root",
})
export class CrmProjectMapperService {
  createProjectFromCrmEntry(crmEntry: CrmEntry): Project {
    const project = Project.create("");
    project.crmEntryId = crmEntry.id;
    this.mapCrmCompanyToCustomer(crmEntry.company, project.customer);
    this.mapCrmContactToCustomer(crmEntry.contact, project.contactPerson);
    return project;
  }

  overwriteProjectWithCrmEntry(project: Project, crmEntry: CrmEntry) {
    project.crmEntryId = crmEntry.id;
    this.mapCrmCompanyToCustomer(crmEntry.company, project.customer);
    this.mapCrmContactToCustomer(crmEntry.contact, project.contactPerson);
    project.lastModified = new Date();
  }

  private mapCrmCompanyToCustomer(crmCompany: CrmCompany, customer: Customer): Customer {
    customer.name = crmCompany.name;
    customer.customerNumber = crmCompany.accountnumber;
    customer.street = crmCompany.address1_line1;
    customer.zipCode = crmCompany.address1_postalcode;
    customer.city = crmCompany.address1_city;
    return customer;
  }

  private mapCrmContactToCustomer(crmContact: CrmContact, contactPerson: ContactPerson): ContactPerson {
    contactPerson.firstname = crmContact.firstname;
    contactPerson.lastname = crmContact.lastname;
    contactPerson.department = crmContact.department;
    contactPerson.email = crmContact.emailaddress1;
    contactPerson.phoneNumber = crmContact.mobilephone ? crmContact.mobilephone : crmContact.telephone1;
    return contactPerson;
  }
}
