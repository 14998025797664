<div class="odx-content">
  <h2 i18n="@@impressum.header">Impressum</h2>
  <p>
    <span i18n="@@impressum.section1.row1">Dräger Safety AG & Co. KGaA</span> <br />
    <span i18n="@@impressum.section1.row2">Revalstraße 1</span><br />
    <span i18n="@@impressum.section1.row3">23560 Lübeck</span> <br />
    <span i18n="@@impressum.section1.row4">Deutschland</span> <br />
    <br />
    <span i18n="@@impressum.section2.row1">Telefon: +49 451-882 0</span><br />
    <span i18n="@@impressum.section2.row2">Fax: +49 451-882 2080</span><br />
    <span i18n="@@impressum.section2.row3.prefix">E-Mail:</span> <a href="mailto:info@draeger.com"> info&#64;draeger.com</a><br />
    <a href="" i18n="@@impressum.section2.row4">Link zur allgemeinen Kontaktseite</a>
  </p>
  <br />
  <p>
    <span i18n="@@impressum.section3.row1">Vorstand:</span><br />
    <span i18n="@@impressum.section3.row2">Dipl.-Ing. (BA) Stefan Dräger (Vorsitzender),</span><br />
    <span i18n="@@impressum.section3.row3">Gert-Hartwig Lescow,</span><br />
    <span i18n="@@impressum.section3.row4">Rainer Klug,</span><br />
    <span i18n="@@impressum.section3.row5">Dr. Reiner Piske,</span><br />
    <span i18n="@@impressum.section3.row6">Anton Schrofner.</span>
  </p>
  <br />
  <p>
    <span i18n="@@impressum.section4.row1">Registergericht: Amtsgericht Lübeck</span><br />
    <span i18n="@@impressum.section4.row2">Registernummer: HRB 4097 HL</span>
  </p>
  <br />
  <p i18n="@@impressum.section5.row1">Umsatzsteuer-Identifikations-Nr. gemäß § 27a Umsatzsteuergesetz: DE 812128834.</p>
  <br />
  <p>
    <span i18n="@@impressum.section6.row1">Komplementär:</span><br />
    <span i18n="@@impressum.section6.row2">Dräger Safety Verwaltungs AG</span><br />
    <span i18n="@@impressum.section6.row3">Registergericht: Amtsgericht Lübeck</span><br />
    <span i18n="@@impressum.section6.row4">Registernummer: HRB 5036 HL</span>
  </p>
  <br />
  <p>
    <span i18n="@@impressum.section7.row1">Inhaltlich verantwortlich gem. § 18 Abs. 2 MStV:</span><br />
    <span i18n="@@impressum.section7.row2">Thomas Rathcke</span><br />
    <span i18n="@@impressum.section7.row3">Dräger Safety AG & Co. KGaA</span><br />
    <span i18n="@@impressum.section7.row4">Revalstraße 1</span><br />
    <span i18n="@@impressum.section7.row5">23560 Lübeck</span>
  </p>
</div>
