import { registerLocaleData } from "@angular/common";
import { APP_INITIALIZER, Injectable, LOCALE_ID } from "@angular/core";
import { loadTranslations } from "@angular/localize";
import { LanguageService } from "@app/language.service";
import { User } from "@domain/user/user";
import { UserService } from "@domain/user/user.service";
import { firstValueFrom } from "rxjs";

@Injectable({
  providedIn: "root",
})
class I18n {
  public localeString: string = "en-US";

  constructor(private languageService: LanguageService) {}

  async setLocale(user: User): Promise<void> {
    this.localeString = user.language;
    const localeModule = await this.importLocaleModule();
    registerLocaleData(localeModule.default);

    await this.updateTranslationsInLocalStorage();

    const translations = this.loadTranslationsFromLocalStorage();
    loadTranslations(translations);
  }

  private loadTranslationsFromLocalStorage() {
    const translationFromLocalStorage: string | null = this.getTranslationsFromLocalStorage(this.localeString);
    if (!translationFromLocalStorage) {
      throw new Error(`translation for language code ${this.localeString} not found`);
    }
    return JSON.parse(translationFromLocalStorage);
  }

  private async updateTranslationsInLocalStorage() {
    await firstValueFrom(this.languageService.updateTranslations());
  }

  private async importLocaleModule() {
    const webpackLocaleFilename = this.mapLanguageIsoToWebpackLocaleFilename(this.localeString);
    return await import(
      /* webpackInclude: /(de|en)\.mjs$/ */
      `/node_modules/@angular/common/locales/${webpackLocaleFilename}`
    );
  }

  private mapLanguageIsoToWebpackLocaleFilename(localeString: string) {
    const defaultRegionLocaleStrings: string[] = [
      "en-US",
      "de-DE",
      "es-ES",
      "fr-FR",
      "it-IT",
      "pt-BR",
      "zh-CN",
      "ja-JP",
      "ru-RU",
      "ko-KR",
    ];
    return defaultRegionLocaleStrings.includes(localeString) ? `${localeString.split("-")[0]}.mjs` : `${localeString}.mjs`;
  }

  private getTranslationsFromLocalStorage(languageIso: string): string | null {
    return localStorage.getItem(`${languageIso}_translations`);
  }
}

function setLocale() {
  return {
    provide: APP_INITIALIZER,
    useFactory: (i18n: I18n, userService: UserService) => () =>
      firstValueFrom(userService.user$).then((user) => i18n.setLocale(user)),
    deps: [I18n, UserService],
    multi: true,
  };
}

function setLocaleId() {
  return {
    provide: LOCALE_ID,
    useFactory: (i18n: I18n) => i18n.localeString,
    deps: [I18n],
  };
}

export const I18nModule = {
  setLocale: setLocale,
  setLocaleId: setLocaleId,
};
