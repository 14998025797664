export class CrmContact {
  department!: string;
  salutation!: string;
  firstname!: string;
  lastname!: string;
  jobtitle!: string;
  telephone1!: string;
  mobilephone!: string;
  emailaddress1!: string;
}

export class CrmCompany {
  accountnumber!: string;
  name!: string;
  speed1_name2!: string;
  speed1_name3!: string;
  speed1_name4!: string;
  address1_line1!: string;
  address1_city!: string;
  address1_postalcode!: string;
}

export class CrmEntry {
  id!: string;
  contact!: CrmContact;
  company!: CrmCompany;
}
