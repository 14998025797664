<odx-content-box odxLayout="flex" class="flex-column h-100 scroll">
  <odx-content-box-header>
    <h5 class="odx-title-5" i18n="@@alarmDeviceConfiguration.selectAlarmDevice.header">Alarmmittel auswählen</h5>
  </odx-content-box-header>
  <app-filter-product (search)="search($event)"></app-filter-product>
  <div class="h-100" *ngIf="project" odxLayout="flex gap-none">
    <div class="h-100" *ngIf="filteredAlarmDevices$ | async; let alarmDevices">
      <div *ngIf="safetyClass === SafetyClass.NON_EX" class="h-100">
        <div odxLayout="grid 3@desktop 6@tablet 12@phone" class="w-100 mb-36">
          <app-product-card
            class="overflow-hidden"
            *ngFor="let alarmDevice of alarmDevices"
            [product]="alarmDevice"
            [showCosts]="project.showCostFlag"
            (select$)="checkboxSelectionChanged($event, alarmDevice)"
            [isSelected]="checkIfSelected(alarmDevice)"
            selectionMode="multi"
          >
            <div *ngIf="alarmDevice.id" class="feature">
              <p class="p-label odx-text--small" i18n="@@global.partNumber">Sachnummer</p>
              <p class="odx-text--small">{{ alarmDevice.id }}</p>
            </div>
          </app-product-card>
        </div>
      </div>
      <form [formGroup]="radioButtonForm">
        <odx-radio-group
          *ngIf="safetyClass === SafetyClass.EX"
          formControlName="selectedAlarmDevice"
          required="true"
          class="h-100"
        >
          <div odxLayout="grid 3@desktop 6@tablet 12@phone" class="w-100 mb-36">
            <app-product-card
              class="overflow-hidden"
              *ngFor="let alarmDevice of alarmDevices"
              [product]="alarmDevice"
              [showCosts]="project.showCostFlag"
              [isSelected]="
                checkedAlarmDevices.indexOf(alarmDevice) >= 0 || radioButtonForm.value.selectedAlarmDevice?.id === alarmDevice.id
              "
              (click)="
                radioButtonForm.patchValue({
                  selectedAlarmDevice: alarmDevice,
                })
              "
              (select$)="
                radioButtonForm.patchValue({
                  selectedAlarmDevice: alarmDevice,
                })
              "
              selectionMode="single"
            >
              <div *ngIf="alarmDevice.id" class="feature">
                <p class="p-label odx-text--small" i18n="@@global.partNumber">Sachnummer</p>
                <p class="odx-text--small">{{ alarmDevice.id }}</p>
              </div>
            </app-product-card>
          </div>
        </odx-radio-group>
      </form>
    </div>
  </div>
</odx-content-box>
