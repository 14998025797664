<odx-modal-header>
  <odx-area-header i18n="@@modal.deviceNotRecommended.header">Hinweis</odx-area-header>
</odx-modal-header>
<odx-modal-content>
  <p>
    {{ introduction }} <b>{{ highlighted }}</b>
  </p>
  <br />
  <p>{{ solution }}</p>
</odx-modal-content>
<odx-modal-footer>
  <button odxButton variant="primary" (click)="onConfirm()" i18n="@@modal.deviceNotRecommended.button.confirm">OK</button>
</odx-modal-footer>
