<app-floorplan-product-menu
  [title]="tooltipTextGasWarningCenters"
  [hasConfigurations]="floorplan.project.gasWarningCenters.length > 0"
  [visible]="floorplan.floorplanState.gasWarningCentersVisible"
  [locked]="floorplan.floorplanState.gasWarningCentersLocked"
  (visibilityUpdate)="floorplan.floorplanState.gasWarningCentersVisible = $event"
  (lockUpdate)="floorplan.floorplanState.gasWarningCentersLocked = $event"
  (addPlaceholderToFloorplan)="addPlaceholder()"
  configPath="gas-warning-center"
>
  <div *ngFor="let gasWarningCenter of floorplan.project.gasWarningCenters">
    <hr />
    <app-configured-product
      [productConfiguration]="gasWarningCenter"
      [numberOfFloorplanItems]="floorplan.countPlacedGasWarningCenters(gasWarningCenter)"
      [attachmentIds]="gasWarningCenter.attachmentIds"
      (add)="addGasWarningCenter(gasWarningCenter)"
      (delete)="deleteGasWarningCenter(gasWarningCenter)"
      editPath="gas-warning-center"
    >
    </app-configured-product>
  </div>
</app-floorplan-product-menu>
