<odx-area-header><h6 class="odx-title-6" i18n="@@newProject.customerForm.header.customer">Kunde</h6></odx-area-header>
<form odxForm [formGroup]="customerForm" class="form">
  <odx-form-field i18n-label="@@newProject.customerForm.label.customerName" label="Kundenname">
    <input
      formControlName="name"
      odxFormFieldControl
      i18n-placeholder="@@newProject.customerForm.placeholder.customerName"
      placeholder="Kundenname"
      [readonly]="isReadonly"
      data-testid="customerForm.customerName"
    />
  </odx-form-field>
  <odx-form-field i18n-label="@@newProject.customerForm.label.customerNumber" label="Kundennummer">
    <input
      formControlName="customerNumber"
      odxFormFieldControl
      i18n-placeholder="@@newProject.customerForm.label.customerNumber"
      placeholder="Kundennummer"
      [readonly]="isReadonly"
      data-testid="customerForm.customerNumber"
    />
  </odx-form-field>
  <odx-form-field i18n-label="@@newProject.customerForm.label.streetAndHouseNumber" label="Straße und Hausnummer">
    <input
      formControlName="street"
      odxFormFieldControl
      i18n-placeholder="@@newProject.customerForm.label.streetAndHouseNumber"
      placeholder="Straße und Hausnummer"
      [readonly]="isReadonly"
      data-testid="customerForm.streetAndNumber"
    />
  </odx-form-field>
  <odx-form-group>
    <odx-form-field i18n-label="@@newProject.customerForm.label.postalCode" label="PLZ" odxLayout="6">
      <input
        formControlName="zipCode"
        odxFormFieldControl
        i18n-placeholder="@@newProject.customerForm.label.postalCode"
        placeholder="PLZ"
        [readonly]="isReadonly"
        data-testid="customerForm.zipCode"
      />
    </odx-form-field>
    <odx-form-field i18n-label="@@newProject.customerForm.label.city" label="Ort" odxLayout="6">
      <input
        formControlName="city"
        odxFormFieldControl
        i18n-placeholder="@@newProject.customerForm.label.city"
        placeholder="Ort"
        [readonly]="isReadonly"
        data-testid="customerForm.city"
      />
    </odx-form-field>
  </odx-form-group>
  <!--Needed to show validation errors on submit without userinput-->
  <button type="submit" class="hidden" #submitButton></button>
</form>
