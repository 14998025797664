import { IsString } from "class-validator";

export class CloudProjectMetadata {
  @IsString()
  public id: string;

  @IsString()
  public displayName: string;

  @IsString()
  public lastModifiedOn: string;

  constructor(id: string, displayName: string, lastModifiedOn: string) {
    this.id = id;
    this.displayName = displayName;
    this.lastModifiedOn = lastModifiedOn;
  }
}
