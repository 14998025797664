import { Component, EventEmitter, inject, OnDestroy, OnInit, Output, ViewChildren } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { ConfirmModalComponent } from "@components/confirm-modal/confirm-modal.component";
import {
  FloorplanUploadOverlayComponent,
  UploadedFloorplan,
} from "@components/floorplan-upload-overlay/floorplan-upload-overlay.component";
import { NewProjectFileCardComponent } from "@components/project-cards/new-project-file-card/new-project-file-card.component";
import { ProjectFormsService } from "@components/project-forms/project-forms.service";
import { ProjectService } from "@domain/project/project.service";
import { ModalRef, ModalService } from "@odx/angular/components/modal";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-new-project-floorplan-upload",
  templateUrl: "./new-project-floorplan-upload.component.html",
  styleUrls: ["./new-project-floorplan-upload.component.scss"],
  standalone: true,
  imports: [UiKitModule, ReactiveFormsModule, FloorplanUploadOverlayComponent, NewProjectFileCardComponent],
})
export class NewProjectFloorplanUploadComponent implements OnInit, OnDestroy {
  private readonly modalService = inject(ModalService);
  private modalRef: ModalRef<unknown, any> | null = null;

  @ViewChildren("floorplanCard")
  floorplanCards!: NewProjectFileCardComponent[];

  @Output() floorplanFormEmitter$ = new EventEmitter<FormGroup>();
  floorplanForm!: FormGroup;

  floorplans: UploadedFloorplan[];

  private readonly _destroying = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private projectFormsService: ProjectFormsService,
    private projectService: ProjectService,
  ) {
    this.floorplans = [];
    this.floorplanForm = this.formBuilder.group({
      floorplans: this.formBuilder.array([]),
    });

    this.projectFormsService.projectReset$.pipe(takeUntil(this._destroying)).subscribe(() => {
      this.floorplanForm.reset();
    });

    this.floorplanForm.statusChanges
      .pipe(takeUntil(this._destroying))
      .subscribe(() => this.floorplanFormEmitter$.emit(this.floorplanForm));
  }

  ngOnInit(): void {
    this.floorplanFormEmitter$.emit(this.floorplanForm);
  }

  ngOnDestroy(): void {
    this._destroying.next();
    this._destroying.complete();
  }

  triggerSubmit() {
    this.floorplanCards.forEach((card) => card.triggerSubmit());
  }

  onCardSubmit(cardForm: FormGroup, floorplan: UploadedFloorplan) {
    floorplan.name = cardForm.value.name;
    this.floorplansForm.push(cardForm);
  }

  onDelete(plan: UploadedFloorplan) {
    this.modalService
      .open(ConfirmModalComponent, {
        data: {
          text: $localize`:@@modal.deleteFloorplan:Wollen Sie den Grundrissplan wirklich löschen?`,
        },
      })
      .onClose$.subscribe(() => {
        this.deleteFloorplan(plan);
      });
  }

  onPlanUploaded(plan: UploadedFloorplan) {
    this.floorplans.push(plan);
  }

  private deleteFloorplan(plan: UploadedFloorplan) {
    this.floorplans = this.floorplans.filter((item: UploadedFloorplan) => item !== plan);
  }

  private get floorplansForm() {
    return this.floorplanForm.controls["floorplans"] as FormArray;
  }
}
