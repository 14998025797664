export class TableColumn {
  title: string = "";
  width: number = 0;
  xPosition: number = 0;

  constructor(title: string, xPosition: number, width: number) {
    this.title = title;
    this.xPosition = xPosition;
    this.width = width;
  }
}
