import Konva from "konva";
import { Shape } from "konva/lib/Shape";
import { Rect } from "konva/lib/shapes/Rect";

export class FloorplanKonva {
  private static readonly EMPTY_HEIGHT = 1975;
  private static readonly EMPTY_WIDTH = 3241;
  private static readonly BORDER_ASPECT_RATIO = FloorplanKonva.EMPTY_WIDTH / FloorplanKonva.EMPTY_HEIGHT;

  static initEmptyPlan(): Rect {
    return new Rect({
      fill: "white",
      height: this.EMPTY_HEIGHT,
      width: this.EMPTY_WIDTH,
    });
  }

  static initBorder(floorplanShape: Shape): Rect {
    const padding = 1.1;
    let borderWidth = floorplanShape.width();
    let borderHeight = floorplanShape.height();
    if (floorplanShape.width() < floorplanShape.height() * this.BORDER_ASPECT_RATIO) {
      borderWidth = floorplanShape.height() * this.BORDER_ASPECT_RATIO * padding;
      borderHeight *= padding;
    } else {
      borderHeight = (floorplanShape.width() / this.BORDER_ASPECT_RATIO) * padding;
      borderWidth *= padding;
    }
    return new Konva.Rect({
      stroke: "#C7D3E0",
      strokeWidth: 8,
      dash: [12, 12],
      dashEnabled: true,
      width: borderWidth,
      height: borderHeight,
    });
  }

  static setFloorplanInCenterOfBorder(floorplanShape: Shape, floorplanBorderShape: Rect) {
    floorplanShape.x(floorplanBorderShape.width() / 2 - floorplanShape.width() / 2);
    floorplanShape.y(floorplanBorderShape.height() / 2 - floorplanShape.height() / 2);
  }
}
