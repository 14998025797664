import { DefaultRow } from "@pdf/helpers/default-row/default-row";
import { TableProperties } from "@pdf/pdf-properties";

interface RowData {
  position: string;
  quantity: string;
  name: string;
  id: string;
  productCosts: number;
  totalCosts: number;
  isPlaceholder: boolean;
}

export class ProductListPlaceholderRow extends DefaultRow {
  readonly position: string;
  readonly quantity: string;
  readonly name: string[];
  readonly id: string;
  readonly productCosts: string;
  readonly totalCosts: string;
  readonly isPlaceholder: boolean;
  readonly isAvailable: boolean = true;

  constructor(placeholderRowData: RowData) {
    super();
    this.position = placeholderRowData.position;
    this.quantity = placeholderRowData.quantity;
    this.name = [placeholderRowData.name];
    this.id = placeholderRowData.id;
    this.productCosts = "--";
    this.totalCosts = "--";
    this._textHeight = TableProperties.TEXT_HEIGHT;
    this.isPlaceholder = placeholderRowData.isPlaceholder;
  }
}
