<app-floorplan-product-menu
  [title]="tooltipTextAlarmDevices"
  [hasConfigurations]="floorplan.project.alarmDevices.length > 0"
  [visible]="floorplan.floorplanState.alarmDevicesVisible"
  [locked]="floorplan.floorplanState.alarmDevicesLocked"
  (visibilityUpdate)="floorplan.floorplanState.alarmDevicesVisible = $event"
  (lockUpdate)="floorplan.floorplanState.alarmDevicesLocked = $event"
  (addPlaceholderToFloorplan)="addPlaceholder()"
  configPath="alarm-device"
>
  <div *ngFor="let alarmDevice of floorplan.project.alarmDevices">
    <hr />
    <app-configured-product
      [productConfiguration]="alarmDevice"
      [numberOfFloorplanItems]="floorplan.countPlacedAlarmDevices(alarmDevice)"
      [attachmentIds]="alarmDevice.attachmentIds"
      (add)="addAlarmDevice(alarmDevice)"
      (delete)="deleteAlarmDevice(alarmDevice)"
      editPath="alarm-device"
      imageUrl="./assets/alarmmittel_odx.svg"
    >
    </app-configured-product>
  </div>
</app-floorplan-product-menu>
