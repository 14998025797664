<odx-menu>
  <button odxButton odxMenuItem variant="ghost" (click)="onClickDeleteFromCloud()">
    <odx-icon name="cloud"></odx-icon>
    <span i18n="@@menu.button.deleteCloudProjects">Cloudprojekte löschen</span>
  </button>
  <a href="mailto:DLCDE-Navinta@draeger.com" odxButton odxMenuItem variant="ghost">
    <odx-icon name="comment"></odx-icon>
    <span i18n="@@menu.button.contactNavintaTeam">Navinta-Team kontaktieren</span>
  </a>
  <button odxButton odxMenuItem variant="ghost" (click)="onClickRefresh()">
    <odx-icon name="sync"></odx-icon>
    <span i18n="@@menu.button.RefreshProductData">Produktdaten aktualisieren</span>
  </button>
  <button odxButton odxMenuItem variant="ghost" routerLink="/privacy-policy" i18n="@@menu.button.privacyPolicy">
    Datenschutzerklärung
  </button>
  <button odxButton odxMenuItem variant="ghost" onClick="UC_UI.showSecondLayer();" i18n="@@menu.button.privacySettings">
    Datenschutzeinstellungen
  </button>
  <button odxButton odxMenuItem variant="ghost" routerLink="/impressum" i18n="@@menu.button.impressum">Impressum</button>
  <div odxButton><img id="logo" src="../../assets/draeger-logo.png" alt="Draeger Logo" /></div>
</odx-menu>
