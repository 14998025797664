import { Injectable } from "@angular/core";
import { Project } from "@domain/project/project";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ProjectFormsService {
  private readonly _projectInitialized$ = new Subject<Project>();
  private readonly _projectReset$ = new Subject<Project>();

  get projectInitialized$(): Observable<Project> {
    return this._projectInitialized$.asObservable();
  }

  get projectReset$(): Observable<Project> {
    return this._projectReset$.asObservable();
  }

  initializeProject(project: Project) {
    this._projectInitialized$.next(project);
  }

  resetProject(project: Project) {
    this._projectReset$.next(project);
  }
}
