import { FloorplanText } from "@domain/project/floorplan/floorplan-text";
import { TableColumn } from "@pdf/helpers/default-table/table-column";
import { Headlines, ImagePaths, TableProperties } from "@pdf/pdf-properties";
import { jsPDF } from "jspdf";
import { FloorplanInformationTable } from "../floorplan-information-table";
import { NotesRow } from "./notes-row";

export class FloorplanTextsRowHeaders {
  public readonly NUMBER: string = $localize`:@@pdfExport.notesTable.header.number:Nr.`;
  public readonly NOTES: string = $localize`:@@pdfExport.notesTable.header.notes:Notizen`;
}

export class NotesTable extends FloorplanInformationTable {
  readonly headlines = new Headlines();
  readonly rowHeaders = new FloorplanTextsRowHeaders();
  override readonly headline = this.headlines.NOTES;
  override readonly columns: TableColumn[];
  override readonly rows: NotesRow[];

  constructor(pdf: jsPDF, floorplanTexts: FloorplanText[]) {
    super(ImagePaths.NOTE);
    this.columns = [new TableColumn(this.rowHeaders.NUMBER, 60, 96), new TableColumn(this.rowHeaders.NOTES, 180, 1260)];

    this.rows = floorplanTexts.map(
      (floorplanText) =>
        new NotesRow(
          pdf,
          this.textToColumnWidth(
            pdf,
            TableProperties.TEXT_FONT_SIZE,
            this.preProcessText(floorplanText.positionId),
            this.rowHeaders.NUMBER,
          ),
          this.textToColumnWidth(
            pdf,
            TableProperties.TEXT_FONT_SIZE,
            this.preProcessText(floorplanText.notes),
            this.rowHeaders.NOTES,
          ),
        ),
    );
    this.height = this.calculateHeight(this.rows);
  }
}
