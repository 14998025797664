import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { UiKitModule } from "@app/ui-kit.module";
import { ConfigurationWizardBackButtonComponent } from "@components/configuration-wizard-back-button/configuration-wizard-back-button.component";
import { ConfigurationWizardHeaderComponent } from "@components/configuration-wizard-header/configuration-wizard-header.component";
import { ProductConfigStepperBarComponent } from "@components/stepper/product-config-stepper-bar/product-config-stepper-bar.component";
import { LocalizeProductNamePipe, Product } from "@domain/product/product";
import { PlasticSignConfiguration } from "@domain/project/configurations/plastic-sign-configuration";
import { Project } from "@domain/project/project";
import { WizardComponent } from "@odx/angular/components/wizard";
import { FloorplanMenuType } from "@project/floorplanner/floorplan-menu/floorplan-menu-type";
import { OriginalProductConfigurationFactory } from "@project/original-product-configuration-factory";
import { OriginalPlasticSignConfiguration } from "@project/plastic-sign-configuration/original-plastic-sign-configuration";
import { PlasticSignSelectorComponent } from "@project/plastic-sign-configuration/plastic-sign-selector/plastic-sign-selector.component";
import {
  PlasticSignSpecs,
  PlasticSignSpecsComponent,
} from "@project/plastic-sign-configuration/plastic-sign-specs/plastic-sign-specs.component";

@Component({
  selector: "app-plastic-sign-configuration",
  templateUrl: "./plastic-sign-configuration.component.html",
  styleUrls: ["./plastic-sign-configuration.component.scss"],
  standalone: true,
  imports: [
    UiKitModule,
    ConfigurationWizardBackButtonComponent,
    ConfigurationWizardHeaderComponent,
    PlasticSignSelectorComponent,
    PlasticSignSpecsComponent,
    ProductConfigStepperBarComponent,
  ],
  providers: [OriginalProductConfigurationFactory, LocalizeProductNamePipe],
})
export class PlasticSignConfigurationComponent implements OnInit {
  protected readonly FloorplanMenuType = FloorplanMenuType;

  protected project!: Project;
  protected activeStep = 0;
  protected selectedPlasticSignForm!: FormGroup;
  protected selectedSpecsForm!: FormGroup;
  protected selectedPlasticSign?: Product;
  protected selectedSpecs?: PlasticSignSpecs;
  protected originalConfiguration?: OriginalPlasticSignConfiguration;

  @ViewChild("configurationWizard")
  private configurationWizard!: WizardComponent;

  @ViewChild("backButton")
  private backButton!: ConfigurationWizardBackButtonComponent;

  constructor(
    private activeRoute: ActivatedRoute,
    private productConfigurationService: OriginalProductConfigurationFactory,
    private localizeProductName: LocalizeProductNamePipe,
  ) {}

  ngOnInit(): void {
    this.project = this.activeRoute.parent!.snapshot.data["project"];
    if (this.activeRoute.snapshot.queryParams["config_id"]) {
      this.productConfigurationService
        .createOriginalPlasticSignConfiguration(this.project, this.activeRoute.snapshot.queryParams["config_id"])
        .subscribe((originalConfiguration) => {
          this.originalConfiguration = originalConfiguration;
        });
    }
  }

  onSubmit() {
    if (this.activeStep === 0) {
      this.selectedPlasticSignForm.markAllAsTouched();
      if (!this.selectedPlasticSignForm.valid) return;
    } else if (this.activeStep === 1) {
      this.selectedSpecsForm.markAllAsTouched();
      if (!this.selectedSpecsForm.valid) return;

      if (this.originalConfiguration) {
        this.updatePlasticSignConfig(this.originalConfiguration.productConfiguration!);
      } else {
        this.createNewPlasticSignConfig();
      }
    }
    this.configurationWizard.setActiveStepValid();
    this.configurationWizard.nextStep();
  }

  private createNewPlasticSignConfig() {
    const plasticSignConfig = PlasticSignConfiguration.create(
      this.localizeProductName.transform(this.selectedPlasticSign!),
      this.project,
      this.selectedPlasticSign!.id,
      this.selectedSpecs!.text,
      this.selectedSpecs!.backgroundColor,
      this.selectedSpecs!.textColor,
    );
    this.project.addPlasticSigns(plasticSignConfig);
    this.backButton.navigateBack();
  }

  private updatePlasticSignConfig(originalConfig: PlasticSignConfiguration) {
    originalConfig.update(
      this.selectedPlasticSign!,
      this.selectedSpecs!.text,
      this.selectedSpecs!.backgroundColor,
      this.selectedSpecs!.textColor,
    );
    this.backButton.navigateBack();
  }
}
