import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ProductCardTooltipService implements OnDestroy {
  _visibleTooltip$ = new BehaviorSubject<any>(null);

  get visibleTooltip(): Observable<any> {
    return this._visibleTooltip$.asObservable();
  }

  tooltipSelected(tooltip: any) {
    this._visibleTooltip$.next(tooltip);
  }

  ngOnDestroy(): void {
    this._visibleTooltip$.complete();
  }
}
