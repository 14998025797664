import { IsOptional, IsString } from "class-validator";

export class Customer {
  @IsString()
  @IsOptional()
  public name?: string;

  @IsString()
  @IsOptional()
  public customerNumber?: string;

  @IsString()
  @IsOptional()
  public street?: string;

  @IsString()
  @IsOptional()
  public zipCode?: string;

  @IsString()
  @IsOptional()
  public city?: string;

  constructor(name?: string, customerNumber?: string, street?: string, zipCode?: string, city?: string) {
    this.name = name;
    this.customerNumber = customerNumber;
    this.street = street;
    this.zipCode = zipCode;
    this.city = city;
  }
}
