export interface Content {
  main: string;
  sub?: string;
}

export class TableOfContents {
  contents: Content[] = [];

  appendToTableOfContents(headline: string) {
    const splitHeadline = headline.split(" | ");
    const main = splitHeadline[1] || headline;
    const sub = splitHeadline.length > 1 ? splitHeadline[0] : undefined;
    this.contents.push({ main, sub });
  }

  groupedByMain(): Content[][] {
    return this.contents.reduce((acc: Content[][], item: Content) => {
      const key = item.main;
      const existingGroup = acc.find((group) => group[0].main === key);
      if (existingGroup) {
        existingGroup.push(item);
      } else {
        acc.push([item]);
      }
      return acc;
    }, []);
  }
}
