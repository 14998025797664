<app-product-card
  *ngIf="gasWarningCenter"
  [product]="gasWarningCenter!"
  [showCosts]="showCosts"
  [selectionMode]="isSelectable ? 'single' : 'none'"
  [isSelected]="isSelected"
  [imageUrl]="ProductImage.getSourceUrl(gasWarningCenter.id)"
  [fallbackImageUrl]="ProductImage.getPlaceholderSourceUrl(gasWarningCenterType.key)"
  (select$)="gasWarningCenterSelected.emit(gasWarningCenter)"
>
  <div *ngIf="gasWarningCenter.id" class="feature">
    <p class="p-label odx-text--small" i18n="@@global.partNumber">Sachnummer</p>
    <p class="odx-text--small">{{ gasWarningCenter.id }}</p>
  </div>
  <div *ngIf="powersupplyProperty" class="feature">
    <p class="odx-text--small p-label">{{ powersupplyProperty.name }}</p>
    <p class="odx-text--small">{{ powersupplyProperty.value }}</p>
  </div>
  <div *ngIf="dimensionsProperty" class="feature">
    <p class="odx-text--small p-label">{{ dimensionsProperty.name }}</p>
    <p class="odx-text--small" i18n="@@gasWarningCenterConfiguration.gasWarningCenterCard.dimensions">
      ({{ dimensionsProperty.height * 1000 }}mm × {{ dimensionsProperty.width * 1000 }}mm ×
      {{ dimensionsProperty.depth * 1000 }}mm)
    </p>
  </div>
  <div *ngIf="degreeprotectionProperty" class="feature">
    <p class="odx-text--small p-label">{{ degreeprotectionProperty.name }}</p>
    <p class="odx-text--small">{{ degreeprotectionProperty.value }}</p>
  </div>

  <div class="feature" odxLayout="flex">
    <a
      odxLink
      [href]="'https://product-assets.draeger.net/documents/navinta/datasheets/' + gasWarningCenterType.key + '.pdf'"
      target="_blank"
      (click)="$event.stopPropagation()"
    >
      <odx-icon name="document" inline></odx-icon>
      <span i18n="@@global.productDatasheet">Produktdatenblatt</span>
    </a>
  </div>
  <div class="feature" odxLayout="flex">
    <a
      *ngIf="gasWarningCenterType.hasExcelsheet"
      odxLink
      [href]="'/assets/products/excelsheets/' + gasWarningCenterType.key + '.xlsm'"
      target="_blank"
      (click)="$event.stopPropagation()"
    >
      <odx-icon name="document" inline></odx-icon>
      <span i18n="@@gasWarningCenterConfiguration.gasWarningCenterCard.excelSheetLinkText"
        >Konfiguration {{ gasWarningCenterType.name }}</span
      >
    </a>
  </div>
</app-product-card>
