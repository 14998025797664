import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";

@Pipe({
  standalone: true,
  name: "localizeTime",
})
export class LocalizeTimePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private localeId: string) {}

  transform(date: Date): string {
    return date.toLocaleTimeString(this.localeId);
  }
}
