<app-floorplan-item-details
  [title]="(gasWarningCenterName$ | async) || gasWarningCenter.config.name"
  [floorplanItem]="gasWarningCenter"
  [imageUrl]="imageUrl"
  [fallbackImageUrl]="fallbackImageUrl"
  [showWarning]="(isDiscontinued$ | async) || false"
>
  <app-floorplan-item-attachments class="feature" [attachmentIds]="gasWarningCenter.config.attachmentIds">
  </app-floorplan-item-attachments>
</app-floorplan-item-details>
