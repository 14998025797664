import { Injectable } from "@angular/core";
import { ProductType } from "@domain/product/product";
import { ProductService } from "@domain/product/product.service";
import { ProductConfiguration } from "@domain/project/configurations/product-configuration";
import { ProductData } from "@domain/project/product-data/product-data";
import { ALPHABET } from "@utils/alphabet";
import { forkJoin, map, Observable, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AttachmentDataService {
  constructor(private productService: ProductService) {}

  getProductData(
    productConfiguration: ProductConfiguration,
    attachmentIds: string[],
    localeId: string,
    productPlacements: number,
    positionNumberOffset: number = 0,
  ): Observable<ProductData[]> {
    if (!attachmentIds.length) {
      return of([]);
    }
    return forkJoin(this.getProducts(attachmentIds)).pipe(
      map((attachments) => {
        const attachmentData = ProductData.mapAttachmentsAsProductData(attachments, productPlacements, localeId);
        attachmentData.map(
          (attachment, index) =>
            (attachment.position = productConfiguration.positionNumber! + "." + ALPHABET[index + positionNumberOffset]),
        );
        return attachmentData;
      }),
    );
  }

  private getProducts(attachmentIds: string[]) {
    return attachmentIds.map((attachmentId) =>
      this.productService.getProductById(attachmentId).pipe(
        map((attachment) => {
          if (!attachment || attachment.type != ProductType.ATTACHMENT) {
            throw new Error(`Attachment with id '${attachmentId}' not found.`);
          }
          return attachment;
        }),
      ),
    );
  }
}
