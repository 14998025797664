<odx-content-box
  class="content-box flex-column"
  odxLayout="flex gap-none"
  [ngClass]="{
    selected: selected,
  }"
  (click)="onCardClicked()"
>
  <odx-area-header class="header">
    <h6
      class="odx-title-6 card-title"
      [odxTooltip]="attachment | localizeProductName"
      [odxTooltipDisabled]="isTooltipDisabled()"
      odxTooltipSize="large"
    >
      {{ attachment | localizeProductName }}
    </h6>
  </odx-area-header>
  <div class="h-100 content">
    <!-- prettier-ignore-attribute style -->
    <div class="image-wrapper">
      <odx-icon *ngIf="true" name="picture"></odx-icon>
    </div>
    <div *ngIf="attachment.id" class="feature">
      <p class="p-label odx-text--small" i18n="@@global.partNumber">Sachnummer</p>
      <p class="odx-text--small">{{ attachment.id }}</p>
    </div>
  </div>
  <odx-content-box-footer class="footer w-100">
    <div odxLayout="flex" class="space-between">
      <!-- do not propagate radio button click to prevent from multiple click events when card is clicked -->
      <odx-radio-button
        *ngIf="selectionMode === 'single'"
        (click)="$event.stopPropagation()"
        [value]="attachment"
        i18n="@@global.select"
        >Auswählen</odx-radio-button
      >

      <!-- do not propagate checkbox click to prevent from multiple click events when card is clicked -->
      <odx-checkbox
        *ngIf="selectionMode === 'multi'"
        (click)="$event.stopPropagation()"
        [checked]="selected"
        (checkedChange)="onCheckedChanged()"
        #checkboxAttachment
        i18n="@@global.select"
        >Auswählen</odx-checkbox
      >
      <p>{{ showCosts ? (attachment.price | currency: "EUR") : "" }}</p>
    </div>
  </odx-content-box-footer>
</odx-content-box>
