<div>
  <h6 class="odx-title-6" i18n="@@global.exZones">Ex-Zonen</h6>

  <app-floorplan-menu-item-buttons>
    <button
      odxButton
      class="ex-zero"
      (click)="addExZone(0)"
      [odxTooltip]="tooltipTextAddExZone0"
      [odxTooltipOptions]="{ position: 'bottom-start' }"
    >
      <odx-icon name="plus"></odx-icon>
    </button>
    <button
      odxButton
      class="ex-one"
      (click)="addExZone(1)"
      [odxTooltip]="tooltipTextAddExZone1"
      [odxTooltipOptions]="{ position: 'bottom-start' }"
    >
      <odx-icon name="plus"></odx-icon>
    </button>
    <button
      odxButton
      class="ex-two"
      (click)="addExZone(2)"
      [odxTooltip]="tooltipTextAddExZone2"
      [odxTooltipOptions]="{ position: 'bottom-start' }"
    >
      <odx-icon name="plus"></odx-icon>
    </button>
    <app-toggle-visibility-button [visible]="floorplanState.exZonesVisible" (toggle)="floorplanState.exZonesVisible = $event">
    </app-toggle-visibility-button>
    <app-toggle-lock-button [locked]="floorplanState.exZonesLocked" (toggle)="floorplanState.exZonesLocked = $event">
    </app-toggle-lock-button>
  </app-floorplan-menu-item-buttons>
</div>
