<odx-content-box class="flex-column h-100 scroll" odxLayout="flex">
  <odx-content-box-header>
    <h5
      class="odx-title-5"
      i18n="@@gasWarningCenterConfiguration.selectType.header"
      data-testid="gasWarningCenterConfiguration.selectType.header"
    >
      Serie auswählen
    </h5>
  </odx-content-box-header>

  <form [formGroup]="typeForm">
    <odx-radio-group formControlName="selectedType">
      <div odxLayout="grid 3@desktop 6@tablet 12@phone" class="w-100 scroll mb-36">
        <app-picture-card
          class="overflow-hidden"
          title="{{ gasWarningCenterType.name }} Serie"
          i18n-title="@@gasWarningCenterConfiguration.selectType.pictureCardTitle"
          [isSelected]="typeForm.value.selectedType === gasWarningCenterType"
          [imageUrl]="ProductImage.getPlaceholderSourceUrl(gasWarningCenterType.key)"
          *ngFor="let gasWarningCenterType of GasWarningCenterType.TYPES"
          (click)="onTypeClicked(gasWarningCenterType)"
        >
          <odx-radio-button (click)="$event.stopPropagation()" [value]="gasWarningCenterType" i18n="@@global.select"
            >Auswählen</odx-radio-button
          >
        </app-picture-card>
      </div>
    </odx-radio-group>
  </form>
</odx-content-box>
