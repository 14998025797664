import { MeasurementLine } from "@domain/project/floorplan/measurement-line";
import { TableColumn } from "@pdf/helpers/default-table/table-column";
import { Headlines, ImagePaths, TableProperties } from "@pdf/pdf-properties";
import { jsPDF } from "jspdf";
import { FloorplanInformationTable } from "../floorplan-information-table";
import { MeasurementLineRow } from "./measurement-line-row";

export class MeasurementLineRowHeaders {
  public readonly NUMBER: string = $localize`:@@pdfExport.measurementsTable.header.number:Nr.`;
  public readonly TEXT: string = $localize`:@@pdfExport.measurementsTable.header.text:Bemaßungstext`;
  public readonly NOTES: string = $localize`:@@pdfExport.measurementsTable.header.notes:Notizen`;
}

export class MeasurementLinesTable extends FloorplanInformationTable {
  readonly headlines = new Headlines();
  readonly rowHeaders = new MeasurementLineRowHeaders();
  override readonly headline = this.headlines.MEASUREMENT_LINES;
  override readonly columns: TableColumn[];
  override readonly rows: MeasurementLineRow[];

  constructor(pdf: jsPDF, measurementLines: MeasurementLine[]) {
    super(ImagePaths.MEASUREMENT_LINE);
    this.columns = [
      new TableColumn(this.rowHeaders.NUMBER, 60, 96),
      new TableColumn(this.rowHeaders.TEXT, 180, 156),
      new TableColumn(this.rowHeaders.NOTES, 360, 1080),
    ];

    this.rows = measurementLines.map(
      (measurementLine) =>
        new MeasurementLineRow(
          pdf,
          this.textToColumnWidth(
            pdf,
            TableProperties.TEXT_FONT_SIZE,
            this.preProcessText(measurementLine.positionId),
            this.rowHeaders.NUMBER,
          ),
          this.textToColumnWidth(
            pdf,
            TableProperties.TEXT_FONT_SIZE,
            this.preProcessText(measurementLine.text),
            this.rowHeaders.TEXT,
          ),
          this.textToColumnWidth(
            pdf,
            TableProperties.TEXT_FONT_SIZE,
            this.preProcessText(measurementLine.notes),
            this.rowHeaders.NOTES,
          ),
        ),
    );
    this.height = this.calculateHeight(this.rows);
  }
}
