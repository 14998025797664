<div odxLayout="flex gap-small" class="flex-column h-100">
  <odx-area-header>
    <h5 class="odx-title-5" i18n="@@floorplanUpload.floorplanOverview.header">Grundrisspläne</h5>
    <odx-area-header-content>
      <odx-action-group>
        <button odxButton (click)="onEmptySelected()">
          <odx-icon name="plus" alignleft></odx-icon>
          <span i18n="@@floorplanUpload.button.emptyFloorplan">Leeren Grundrissplan</span>
        </button>
        <button odxButton (click)="fileUpload.click()" variant="primary" data-testid="btn.uploadFloorplan">
          <odx-icon name="upload" alignleft></odx-icon>
          <span i18n="@@floorplanUpload.button.uploadFloorplan">Grundrissplan hochladen</span>
        </button>
      </odx-action-group>
      <input
        type="file"
        (change)="onFileSelected($event)"
        accept="application/pdf, image/svg, image/svg+xml, image/png, image/jpg, image/jpeg, image/gif"
        class="hidden"
        multiple
        #fileUpload
      />
    </odx-area-header-content>
  </odx-area-header>
  <ng-content></ng-content>
</div>
