import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { FileModalComponent } from "@components/file-modal/file-modal.component";
import { FloorplanImage } from "@domain/project/floorplan/floorplan-image";
import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { ModalService } from "@odx/angular/components/modal";
import { FloorplanItemDetailsComponent } from "@project/floorplanner/floorplan-additional-notes/floorplan-item-details/floorplan-item-details.component";

@Component({
  selector: "app-image-notes",
  templateUrl: "./image-notes.component.html",
  styleUrls: ["./image-notes.component.scss"],
  standalone: true,
  imports: [UiKitModule, ReactiveFormsModule, FloorplanItemDetailsComponent],
})
export class ImageNotesComponent implements OnInit {
  @Input({ required: true }) set floorplanItem(item: FloorplanItem) {
    this.image = item as FloorplanImage;
  }

  protected imageForm!: FormGroup;
  protected image!: FloorplanImage;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: ModalService,
  ) {}

  ngOnInit() {
    this.initForm();
  }

  toggleLock() {
    this.image.locked = !this.image.locked;
  }

  onShowImage() {
    this.modalService.open(FileModalComponent, {
      data: {
        fileName: this.image.projectImage.name,
        fileUrl: this.image.projectImage.fileUrl,
      },
    });
  }

  private update() {
    this.image.projectImage.name = this.imageForm.value.name;
  }

  private initForm() {
    this.imageForm = this.formBuilder.group({
      name: [this.image.projectImage.name, Validators.required],
    });

    this.imageForm.valueChanges.subscribe(() => {
      if (!this.imageForm.valid || this.imageForm.pristine) return;
      this.update();
    });
  }
}
