<app-floorplan-item-details [title]="pipeline.name || fallbackTitle" [floorplanItem]="pipeline">
  <form odxForm [formGroup]="pipelineForm" [disabled]="pipeline.locked">
    <odx-form-field i18n-label="@@floorplan.details.pipeline.form.name.label" label="Name">
      <input
        formControlName="name"
        odxFormFieldControl
        i18n-placeholder="@@floorplan.details.pipeline.form.name.placeholder"
        placeholder="Name"
      />
    </odx-form-field>

    <odx-form-field i18n-label="@@floorplan.details.pipeline.form.diameter.label" label="Durchmesser (mm)">
      <input
        formControlName="diameter"
        odxFormFieldControl
        i18n-placeholder="@@floorplan.details.pipeline.form.diameter.placeholder"
        placeholder="Durchmesser"
      />
    </odx-form-field>

    <odx-form-field i18n-label="@@floorplan.details.pipeline.form.medium.label" label="Medium (Inhalt)">
      <input
        formControlName="medium"
        odxFormFieldControl
        i18n-placeholder="@@floorplan.details.pipeline.form.medium.placeholder"
        placeholder="Medium (Inhalt)"
      />
    </odx-form-field>

    <odx-form-field i18n-label="@@floorplan.details.pipeline.form.temperature.label" label="Temperatur">
      <input
        formControlName="temperature"
        odxFormFieldControl
        i18n-placeholder="@@floorplan.details.pipeline.form.temperature.placeholder"
        placeholder="Temperatur"
      />
    </odx-form-field>

    <odx-form-field i18n-label="@@floorplan.details.pipeline.form.material.label" label="Material">
      <input
        formControlName="material"
        odxFormFieldControl
        i18n-placeholder="@@floorplan.details.pipeline.form.material.placeholder"
        placeholder="Material"
      />
    </odx-form-field>
  </form>
</app-floorplan-item-details>
