import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CrmEntry } from "@domain/crm/crm";
import { environment } from "@environments/environment";
import { plainToInstance } from "class-transformer";
import { map, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CrmService {
  public static readonly CRM_URL = environment.apiUrl + "/crm";

  constructor(private httpClient: HttpClient) {}

  findById(contactId: string): Observable<CrmEntry> {
    return this.httpClient.get(CrmService.CRM_URL, { params: { crmContactId: contactId } }).pipe(
      map((response) => {
        const crmEntry = plainToInstance(CrmEntry, response);
        crmEntry.id = contactId;
        return crmEntry;
      }),
    );
  }
}
