<app-floorplan-menu-item-buttons>
  <button
    odxButton
    variant="ghost"
    class="air-path-button"
    (click)="addExhaustAirPath()"
    [odxTooltip]="tooltipTextAddExhaustAirPath"
    [odxTooltipOptions]="{ position: 'bottom' }"
  >
    <odx-icon name="arrow-right" class="exhaust-air"></odx-icon>
  </button>
  <button
    odxButton
    variant="ghost"
    class="air-path-button"
    (click)="addSupplyAirPath()"
    [odxTooltip]="tooltipTextAddSupplyAirPath"
    [odxTooltipOptions]="{ position: 'bottom' }"
  >
    <odx-icon name="arrow-right"></odx-icon>
  </button>
  <app-toggle-visibility-button [visible]="floorplanState.airPathsVisible" (toggle)="floorplanState.airPathsVisible = $event">
  </app-toggle-visibility-button>
  <app-toggle-lock-button [locked]="floorplanState.airPathsLocked" (toggle)="floorplanState.airPathsLocked = $event">
  </app-toggle-lock-button>
</app-floorplan-menu-item-buttons>
