import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";
import { Exclude, Expose, Type } from "class-transformer";

export class Gas {
  @Expose()
  id!: string;

  @Type(() => GasProduct)
  @Expose()
  products!: GasProduct[];

  @Type(() => GasDefinition)
  @Expose()
  definition!: GasDefinition;

  @Type(() => GasProductSensor)
  @Exclude({ toPlainOnly: true })
  products_sensor!: GasProductSensor[];

  @Expose()
  productSensors!: GasProductSensor[];
}

export class GasDefinition {
  @Exclude({ toPlainOnly: true })
  name!: string;

  @Expose()
  localizedName!: Record<string, string>;

  @Expose()
  casNumber!: string;

  @Expose()
  formula!: string;
}

export class GasProduct {
  @Expose()
  productId!: string;

  @Expose()
  detectingRange!: string[];
}

export class GasProductSensor {
  @Expose()
  productId!: string;
}

@Pipe({
  standalone: true,
  name: "localizeGasName",
})
export class LocalizeGasNamePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private localeId: string) {}

  transform(gas: Gas): string {
    return gas.definition.localizedName[this.localeId];
  }
}
