import { Product } from "@domain/product/product";
import { ProductConfiguration } from "@domain/project/configurations/product-configuration";
import { Project } from "@domain/project/project";
import { ProjectEventType } from "@domain/project/project-event";
import { IsArray, IsString } from "class-validator";
import { v4 as uuidv4 } from "uuid";

export class GasWarningCenterConfiguration extends ProductConfiguration {
  private static readonly NAME_REGEX = /((?:\bRegard\b|\bREGARD\b)\s\d+)/g;

  @IsString()
  public productId: string;

  @IsArray()
  @IsString({ each: true })
  public attachmentIds: string[];

  constructor(id: string, name: string, project: Project, productId: string, attachmentIds: string[]) {
    super(id, name, project);
    this.productId = productId;
    this.attachmentIds = attachmentIds;
  }

  static create(name: string, project: Project, productId: string, attachmentIds: string[]): GasWarningCenterConfiguration {
    return new GasWarningCenterConfiguration(uuidv4(), name, project, productId, attachmentIds);
  }

  countPlacedProducts(project = this.project): number {
    return project ? project.countPlacedGasWarningCenters(this) : 0;
  }

  update(gasWarningCenter: Product, attachmentIds: string[]) {
    this.name = gasWarningCenter.name;
    this.productId = gasWarningCenter.id;
    this.attachmentIds = attachmentIds;
    this.project.publishUpdate(ProjectEventType.GAS_WARNING_CENTER_CONFIGURATION_UPDATED, this);
  }

  override get mainProductId(): string {
    return this.productId;
  }

  override get configuredProductIds(): string[] {
    return this.attachmentIds.concat(this.productId);
  }

  override get productDataName(): string {
    return GasWarningCenterConfiguration.getProductDataName(this.name);
  }

  static getProductDataName(name: string): string {
    const regexMatch = name.match(GasWarningCenterConfiguration.NAME_REGEX);
    if (!regexMatch || !regexMatch.length) {
      return "";
    }
    const modifiedName = regexMatch[0].replace(" ", "_").toLowerCase();
    return modifiedName.charAt(0).toUpperCase() + modifiedName.slice(1);
  }
}
