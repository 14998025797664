import { Floorplan } from "@domain/project/floorplan/floorplan";
import { FloorplanEventType } from "@domain/project/floorplan/floorplan-event";
import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { Expose } from "class-transformer";
import { IsNumber, IsOptional, IsString } from "class-validator";
import { v4 as uuidv4 } from "uuid";

export class Pipeline extends FloorplanItem {
  @IsNumber()
  @Expose({ name: "width" })
  private _width: number;

  @IsNumber()
  @Expose({ name: "height" })
  private _height: number;

  @IsNumber()
  @Expose({ name: "rotation" })
  private _rotation: number;

  @IsString()
  @IsOptional()
  public diameter?: string;
  @IsString()
  @IsOptional()
  public medium?: string;
  @IsString()
  @IsOptional()
  public temperature?: string;
  @IsString()
  @IsOptional()
  public material?: string;
  @IsString()
  @IsOptional()
  public name?: string;

  constructor(
    floorplan: Floorplan,
    id: string,
    x: number,
    y: number,
    width: number,
    height: number,
    diameter?: string,
    medium?: string,
    temperature?: string,
    material?: string,
    rotation: number = 0,
    name?: string,
  ) {
    super(floorplan, id, x, y);
    this._width = width;
    this._height = height;
    this._rotation = rotation;
    this.diameter = diameter;
    this.medium = medium;
    this.temperature = temperature;
    this.material = material;
    this.name = name;
  }

  get width(): number {
    return this._width;
  }

  get height(): number {
    return this._height;
  }

  get rotation(): number {
    return this._rotation;
  }

  updateMetadata(diameter?: string, medium?: string, temperature?: string, material?: string, name?: string) {
    this.diameter = diameter;
    this.medium = medium;
    this.temperature = temperature;
    this.material = material;
    this.name = name;
    this._floorplan.publishUpdate(FloorplanEventType.ITEM_UPDATED, this);
  }

  updateForm(width: number, height: number, rotation: number, x: number, y: number) {
    this._width = width;
    this._height = height;
    this._rotation = rotation;
    this.updatePosition(x, y);
  }

  override duplicate(x: number, y: number): FloorplanItem {
    return this._floorplan.addPipeline(
      x,
      y,
      this.width,
      this.height,
      this.diameter,
      this.medium,
      this.temperature,
      this.material,
      this.rotation,
    );
  }

  override delete() {
    this._floorplan.deletePipeline(this);
  }

  override get relatedItems(): FloorplanItem[] {
    return this._floorplan.pipelines;
  }

  protected override refreshFloorplanLockState() {
    this._floorplan.floorplanState.refreshPipelinesLock();
  }

  static create(
    floorplan: Floorplan,
    x: number,
    y: number,
    width: number,
    height: number,
    diameter?: string,
    medium?: string,
    temperature?: string,
    material?: string,
    rotation: number = 0,
  ) {
    return new Pipeline(floorplan, uuidv4(), x, y, width, height, diameter, medium, temperature, material, rotation);
  }
}
