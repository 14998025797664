import { Product } from "@domain/product/product";
import { PlasticSignConfiguration } from "@domain/project/configurations/plastic-sign-configuration";
import { PlasticSignSpecs } from "@project/plastic-sign-configuration/plastic-sign-specs/plastic-sign-specs.component";

export class OriginalPlasticSignConfiguration {
  productConfiguration: PlasticSignConfiguration;
  plasticSign?: Product;
  specs?: PlasticSignSpecs;

  constructor(productConfiguration: PlasticSignConfiguration, plasticSign?: Product) {
    this.productConfiguration = productConfiguration;
    this.plasticSign = plasticSign;
    this.specs = {
      text: this.productConfiguration.text,
      backgroundColor: this.productConfiguration.backgroundColor,
      textColor: this.productConfiguration.textColor,
    };
  }
}
