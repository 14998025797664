import { ContainerProperties } from "@pdf/pdf-properties";

export enum ActiveSide {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export class Container {
  public activeSide: ActiveSide = ActiveSide.LEFT;
  public xPosition: number = ContainerProperties.LEFT_X;
  public yPosition: number = ContainerProperties.Y;

  public toggleActiveSide() {
    if (this.activeSide === ActiveSide.LEFT) {
      this.activeSide = ActiveSide.RIGHT;
      this.xPosition = ContainerProperties.RIGHT_X;
    } else {
      this.activeSide = ActiveSide.LEFT;
      this.xPosition = ContainerProperties.LEFT_X;
    }
    this.yPosition = ContainerProperties.Y;
  }

  public resetActiveSideToLeft() {
    this.activeSide = ActiveSide.LEFT;
    this.xPosition = ContainerProperties.LEFT_X;
    this.yPosition = ContainerProperties.Y;
  }
}
