import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";

@Pipe({
  standalone: true,
  name: "localizeDate",
})
export class LocalizeDatePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private localeId: string) {}

  transform(date: Date): string {
    return date.toLocaleDateString(this.localeId);
  }
}
