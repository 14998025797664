import { ProductConfiguration } from "@domain/project/configurations/product-configuration";
import { Floorplan } from "@domain/project/floorplan/floorplan";
import { FloorplanEventType } from "@domain/project/floorplan/floorplan-event";
import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { Project } from "@domain/project/project";
import { Exclude, Expose, Transform } from "class-transformer";
import { IsNumber, IsUUID } from "class-validator";

export abstract class FloorplanProductItem<T extends ProductConfiguration> extends FloorplanItem {
  @IsNumber()
  @Expose({ name: "size" })
  private _size: number;

  @Expose({ name: "configId" })
  @Transform(
    (params) => {
      if (params.value) {
        return params.value;
      }
      return (
        params.obj.alarmDeviceId ||
        params.obj.transmitterId ||
        params.obj.gasWarningCenterId ||
        params.obj.signalElementId ||
        params.obj.plasticSignId
      );
    },
    { toClassOnly: true },
  )
  @IsUUID()
  private readonly _configId: string;

  @Exclude()
  private _config: T;

  protected constructor(floorplan: Floorplan, config: T, id: string, x: number, y: number, size: number, notes: string = "") {
    super(floorplan, id, x, y, notes);
    this._size = size;
    this._configId = config?.id; // optional because class transformer initializes without arguments once at beginning
    this._config = config;
  }

  override init(project: Project, floorplan: Floorplan) {
    super.init(project, floorplan);
    if (this._config) {
      return;
    }
    const config = this.getConfigFromProject(project);
    if (!config) {
      throw Error(`Config with id '${this._configId}' is not contained in project with id '${project.id}' anymore`);
    }
    this._config = config;
  }

  get size(): number {
    return this._size;
  }

  get config(): T {
    return this._config;
  }

  updateSize(value: number, x: number, y: number) {
    this._size = value;
    this._floorplan.publishUpdate(FloorplanEventType.ITEM_SIZE_CHANGED, this);
    this.updatePosition(x, y);
  }

  abstract getConfigFromProject(project: Project): T | undefined;
}
