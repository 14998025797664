import { Product } from "@domain/product/product";
import { ProductConfiguration } from "@domain/project/configurations/product-configuration";
import { Project } from "@domain/project/project";
import { ProjectEventType } from "@domain/project/project-event";
import { IsString } from "class-validator";
import { v4 as uuidv4 } from "uuid";

export class PlasticSignConfiguration extends ProductConfiguration {
  @IsString()
  public productId: string;

  @IsString()
  public text: string;

  @IsString()
  public backgroundColor: string;

  @IsString()
  public textColor: string;

  constructor(
    id: string,
    name: string,
    project: Project,
    productId: string,
    text: string,
    backgroundColor: string,
    textColor: string,
  ) {
    super(id, name, project);
    this.productId = productId;
    this.text = text;
    this.backgroundColor = backgroundColor;
    this.textColor = textColor;
  }

  static create(name: string, project: Project, productId: string, text: string, backgroundColor: string, textColor: string) {
    return new PlasticSignConfiguration(uuidv4(), name, project, productId, text, backgroundColor, textColor);
  }

  countPlacedProducts(project = this.project): number {
    return project ? project.countPlacedPlasticSigns(this) : 0;
  }

  update(plasticSign: Product, text: string, backgroundColor: string, textColor: string) {
    this.name = plasticSign.name;
    this.productId = plasticSign.id;
    this.text = text;
    this.backgroundColor = backgroundColor;
    this.textColor = textColor;
    this.project.publishUpdate(ProjectEventType.PLASTIC_SIGN_CONFIGURATION_UPDATED, this);
  }

  override get mainProductId(): string {
    return this.productId;
  }
}
