import { Floorplan } from "@domain/project/floorplan/floorplan";
import { FloorplanEventType } from "@domain/project/floorplan/floorplan-event";
import { FloorplanItem } from "@domain/project/floorplan/floorplan-item";
import { Expose } from "class-transformer";
import { IsNumber, IsString } from "class-validator";
import { v4 as uuidv4 } from "uuid";

export class MeasurementLine extends FloorplanItem {
  @IsString()
  @Expose({ name: "text" })
  private _text: string;

  @IsNumber()
  @Expose({ name: "width" })
  private _width: number;

  @IsNumber()
  @Expose({ name: "height" })
  private _height: number;

  @IsNumber()
  @Expose({ name: "rotation" })
  private _rotation: number;

  constructor(
    floorplan: Floorplan,
    id: string,
    x: number,
    y: number,
    width: number,
    height: number,
    rotation: number,
    text: string,
  ) {
    super(floorplan, id, x, y);
    this._text = text;
    this._width = width;
    this._height = height;
    this._rotation = rotation;
  }

  get width(): number {
    return this._width;
  }

  get height(): number {
    return this._height;
  }

  get rotation(): number {
    return this._rotation;
  }

  get text(): string {
    return this._text;
  }

  set text(text: string) {
    if (this._text === text) return;
    this._text = text;
    this._floorplan.publishUpdate(FloorplanEventType.MEASUREMENT_LINE_UPDATED, this);
  }

  override duplicate(x: number, y: number): FloorplanItem {
    return this._floorplan.addMeasurementLine(x, y, this._text, this.width, this.height, this.rotation);
  }

  override delete() {
    this._floorplan.deleteMeasurementLine(this);
  }

  override get relatedItems(): FloorplanItem[] {
    return this._floorplan.measurementLines;
  }

  updateForm(width: number, height: number, rotation: number, x: number, y: number) {
    this._width = width;
    this._height = height;
    this._rotation = rotation;
    this.updatePosition(x, y);
  }

  protected override refreshFloorplanLockState() {
    this._floorplan.floorplanState.refreshMeasurementLinesLock();
  }

  static create(floorplan: Floorplan, x: number, y: number, width: number, height: number, rotation: number, text: string) {
    return new MeasurementLine(floorplan, uuidv4(), x, y, width, height, rotation, text);
  }
}
