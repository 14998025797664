import { Component, Input, Output } from "@angular/core";
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { UiKitModule } from "@app/ui-kit.module";
import { PictureCardComponent } from "@components/picture-card/picture-card.component";
import { LocalizeDeviceClassPipe, SafetyClass, VoltageClass } from "@project/alarm-device-configuration/device-class";
import { defer, map, of, startWith } from "rxjs";

@Component({
  selector: "app-device-class-selector",
  templateUrl: "./device-class-selector.component.html",
  styleUrls: ["./device-class-selector.component.scss"],
  standalone: true,
  imports: [UiKitModule, ReactiveFormsModule, PictureCardComponent, LocalizeDeviceClassPipe],
})
export class DeviceClassSelectorComponent {
  @Input() deviceClassType!: any;
  @Input() title!: string;

  @Input() set selectedSafetyClass(safetyClass: SafetyClass | undefined) {
    if (safetyClass) {
      this.selectDeviceClass(safetyClass);
    }
  }

  @Input() set selectedVoltageClass(voltageClass: VoltageClass | undefined) {
    if (voltageClass) {
      this.selectDeviceClass(voltageClass);
    }
  }

  protected form = this.formBuilder.nonNullable.group({
    deviceClass: new FormControl<any | null>(null, Validators.required),
  });

  @Output()
  formReady = of(this.form);

  @Output()
  deviceClassSelected = defer(() =>
    this.form.valueChanges.pipe(
      startWith(this.form.value),
      map((formValue) => formValue.deviceClass!),
    ),
  );

  constructor(private formBuilder: FormBuilder) {}

  protected selectDeviceClass(deviceClass: any) {
    this.form.patchValue({ deviceClass });
  }
}
